import axios from "axios";

export const mapAPIError = (error, code) => {
  const custom = "Something went wrong! Kindly try refreshing the page.";
  if (!axios.isAxiosError(error)) return custom;

  let errData = error?.response?.data;
  if (typeof errData === "string") {
    // If errData is a simple string
    // if (code !== 401 && code !== 403) toast.error(errData);
  } else if (errData?.detail) {
    // If errData is an object with a "details" property
    // if (code !== 401 && code !== 403) toast.error(errData?.detail);
  } else if (typeof errData === "object" && Object.keys(errData).length > 0) {
    // If errData is an object with validation errors
    let firstErrorKey = Object.keys(errData)[0];
    let firstErrorMsg = `${firstErrorKey} ${errData[firstErrorKey]}`;
    // if (code !== 401 && code !== 403) toast.error(firstErrorMsg);
  }

  if (error.response) {
    const err = error.response.data;
    return err || custom;
  } else if (error.request) {
    return error.message || custom;
  } else {
    return error.message;
  }
};

import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const esrdData = [
  111786, 165708, 154783, 189507, 142388, 159048, 173800, 147841, 178584,
  137273, 166767, 185147,
];

const esrdMinValue = Math.min(...esrdData);
const esrdMaxValue = Math.max(...esrdData);
const esrdPadding = 10000;

const esrdYAxisConfig = {
  min: Math.floor((esrdMinValue - esrdPadding) / 1000) * 1000,
  max: Math.ceil((esrdMaxValue + esrdPadding) / 1000) * 1000,
  tickAmount: 5,
  labels: {
    formatter: function (value) {
      const inThousands = value / 1000;
      return inThousands.toFixed(0) + "k";
    },
  },
};

const ESRDGraph = ({ isRevenu }) => {
  const [chartData] = useState({
    series: [
      {
        name: "ESRD",
        data: esrdData,
      },
    ],

    options: {
      chart: {
        height: 300,
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (value) {
          const inThousands = value / 1000;
          return inThousands.toFixed(1) + "k";
        },
      },
      markers: {
        size: 5,
      },
      stroke: {
        curve: "straight",
        colors: ["#3475DC"],
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: esrdYAxisConfig,
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        offsetY: 12,
        offsetX: -10,
        itemMargin: {
          horizontal: 5,
          vertical: 7,
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData?.series}
          type="line"
          height={300}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ESRDGraph;

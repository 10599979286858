import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const ContractTable = () => {
  return (
    <Box sx={{ marginBottom: "15px" }}>
      <Table
        sx={{
          border: "1px solid #B3B4BD",
          borderRadius: "10px 10px 10px 10px",
          borderCollapse: "separate",
          background: "#fff",
          marginBottom: "15px",
        }}
        aria-label="Contract table"
      >
        <TableHead
          sx={{
            borderBottom: "1px solid #B3B4BD",
            backgroundColor: "#fff",
            borderTopRightRadius: "10px",
          }}
        >
          <TableRow>
            <TableCell
              align="center"
              sx={{
                borderTopLeftRadius: "10px",
                fontWeight: "600",
                padding: "22px 16px",
                borderTopRightRadius: "10px",
              }}
            >
              Contracts due next month for Nephrologists
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
          >
            <TableCell
              align="center"
              sx={{
                padding: "22px 16px",
                fontSize: "18px",
                fontWeight: "600",
                color: "#CF63C2",
              }}
            >
              3 / 12
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table
        sx={{
          border: "1px solid #B3B4BD",
          borderRadius: "10px 10px 10px 10px",
          borderCollapse: "separate",
          background: "#fff",
          marginBottom: "15px",
        }}
        aria-label="Contract table"
      >
        <TableHead
          sx={{
            border: "1px solid #B3B4BD",
            borderBottom: "transparent",
            backgroundColor: "#fff",
            borderTopRightRadius: "10px",
          }}
        >
          <TableRow>
            <TableCell
              align="center"
              sx={{
                borderTopLeftRadius: "10px",
                fontWeight: "600",
                padding: "22px 16px",
                borderTopRightRadius: "10px",
              }}
            >
              Contracts due next month for Nurse Practitioners
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
          >
            <TableCell
              align="center"
              sx={{
                padding: "22px 16px",
                fontSize: "18px",
                fontWeight: "600",
                color: "#CF63C2",
              }}
            >
              4 / 10
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table
        sx={{
          border: "1px solid #B3B4BD",
          borderRadius: "10px 10px 10px 10px",
          borderCollapse: "separate",
          background: "#fff",
          marginBottom: "15px",
        }}
        aria-label="Contract table"
      >
        <TableHead
          sx={{
            border: "1px solid #B3B4BD",
            borderBottom: "transparent",
            backgroundColor: "#fff",
            borderTopRightRadius: "10px",
          }}
        >
          <TableRow>
            <TableCell
              align="center"
              sx={{
                borderTopLeftRadius: "10px",
                fontWeight: "600",
                padding: "22px 16px",
                borderTopRightRadius: "10px",
              }}
            >
              Salary increments due next month Nephrologists
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
          >
            <TableCell
              align="center"
              sx={{
                padding: "22px 16px",
                fontSize: "18px",
                fontWeight: "600",
                color: "#CF63C2",
              }}
            >
              2 / 12
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table
        sx={{
          border: "1px solid #B3B4BD",
          borderRadius: "10px 10px 10px 10px",
          borderCollapse: "separate",
          background: "#fff",
          marginBottom: "15px",
        }}
        aria-label="Contract table"
      >
        <TableHead
          sx={{
            border: "1px solid #B3B4BD",
            borderBottom: "transparent",
            backgroundColor: "#fff",
            borderTopRightRadius: "10px",
          }}
        >
          <TableRow>
            <TableCell
              align="center"
              sx={{
                borderTopLeftRadius: "10px",
                fontWeight: "600",
                padding: "22px 16px",
                borderTopRightRadius: "10px",
              }}
            >
              Salary increments due next month Nurse Practitioners
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
          >
            <TableCell
              align="center"
              sx={{
                padding: "22px 16px",
                fontSize: "18px",
                fontWeight: "600",
                color: "#CF63C2",
              }}
            >
              2 / 10
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default ContractTable;

import { Box, Grid, IconButton } from "@mui/material";
import Flex from "Layouts/Flex/Flex";
import Button from "components/common/Button/Button";
import { useEffect, useState } from "react";
import {
  ContainerStying,
  iconButtonStyle,
  menuImgStyle,
} from "styles/Financials/Financials";
import PatientMonthIndex from ".";
import MenuIcon from "../../../../../assets/svg/bold/Hamburgericon.svg";
import downloadIcon from "../../../../../assets/svg/colored/download.svg";
import MenuComponent from "pages/financials/components/MenuComponent/MenuComponent";
import { useSelector } from "react-redux";
import ChatBotComponent from "pages/financials/components/ChatBotComponent/ChatBotComponent";
import { barChartOptionsData, barChartSeriesData } from "assets/static";

const PatientMonth = () => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "",
        data: [128, 159, 140, 136, 152, 149, 130],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
      },
      colors: ["#3475DC"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      },
      yaxis: {
        min: 120,
        max: 160,
        tickAmount: 5,
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    },
  });

  const [activePatients, setActivePatients] = useState(1776);
  const [totalPatients, setTotalPatients] = useState(2345);
  const percentage = Math.round((activePatients / totalPatients) * 100);
  const totalPercentage = Math.round((activePatients / totalPatients) * 100);

  const [series, setSeries] = useState([percentage, 100 - percentage]);

  useEffect(() => {
    const newPercentage = Math.round((activePatients / totalPatients) * 100);
    setSeries([newPercentage, 100 - newPercentage]);
  }, [activePatients, totalPatients]);

  const options = {
    chart: {
      type: "donut",
      width: "100%",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: ["#3475DC", "#ccc"],
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          labels: {
            show: true,
            name: {
              show: false, // Hide the name label
            },
            value: {
              show: true,
              fontSize: "30px",
              color: "#3475DC",
              formatter: function () {
                return totalPercentage + "%";
              },
            },
          },
        },
      },
    },
    labels: [`${percentage}% Active`],
  };
  const [patientSeries, setPatientSeries] = useState([74, 26]);
  //
  const [barChartSeries] = useState(barChartSeriesData);
  const [barChartOptions] = useState(barChartOptionsData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const { isChatOpen } = useSelector((state) => state.globalStates);
  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={isChatOpen ? 9 : 12}
        xxl={isChatOpen ? 9 : 12}
        md={isChatOpen ? 8 : 12}
        sm={12}
      >
        <Box sx={ContainerStying}>
          <Flex justifyContent="space-between">
            <IconButton
              aria-describedby={"simple-popper"}
              sx={iconButtonStyle}
              onClick={handleClick("bottom-end")}
            >
              <img style={menuImgStyle} src={MenuIcon} alt="menuIcon" />
            </IconButton>
            <MenuComponent
              setOpen={setOpen}
              open={open}
              setPlacement={setPlacement}
              placement={placement}
              anchorEl={anchorEl}
            />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={downloadIcon} alt="" />
              <Button
                color="#246EFD"
                btnColor="#246EFD"
                sx={{ height: "20px" }}
                text={"Download Reports"}
                backgroundColor="none"
                boxShadow={"none"}
                fontSize={"11px"}
              />
            </Box>
          </Flex>

          <Box>
            {Array(1)
              .fill("")
              ?.map((s, i) => (
                <PatientMonthIndex />
              ))}
          </Box>
        </Box>
      </Grid>
      {isChatOpen && <ChatBotComponent isChatOpen={true} />}
    </Grid>
  );
};

export default PatientMonth;

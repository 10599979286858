import { Margin } from "@mui/icons-material";

export let mainHealthSummaryBoxStyle = (viewWidth, viewHeight) => {
  return {
    display: "flex",
    justifyContent:
      (viewWidth >= 600 && viewWidth <= 992) ||
      (viewWidth === 1024 && viewHeight === 1366)
        ? "space-between"
        : "flex-start",
  };
};

export let healthsummaryMain = () => {
  return {
    height: "100%",
    borderRadius: "24px",
    backgroundColor: "#ffffff",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  };
};

export let healthSummaryDescriptionStyle = (viewWidth) => {
  return {
    display: "flex",
    backgroundColor: "#FFFFFFBF",
    borderBottomLeftRadius: "24px",
    borderBottomRightRadius: "24px",
    padding:
      viewWidth >= 600 && viewWidth <= 1024
        ? "10px 20px 35px 20px"
        : "10px 20px",
    height: "auto",
  };
};
export let riskAssesmentHeadingStyle = {
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  maxHeight: "150px",
};

export let healthSummaryFieldsBoxStyle = (healthSummarySuccess) => {
  return {
    marginBottom: `${healthSummarySuccess ? "20px" : "0px"}`,
  };
};

export let divider = {
  height: "1px",
  border: "1px solid #D9D9D9",
  width: "100%",
  marginTop: "15px",
  marginBottom: "20px",
};

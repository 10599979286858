import { Box, TableSortLabel } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";

// Sample data
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// Sorting rows by "ROIC Improvement Per 5% Improvement in Metric" (carbs property)
const sortedRows = [
  createData("Number of MDs", "67.35%", "68.17%", "0.82%", 82.34046345),
  createData(
    "Number of Encounters per MD",
    "67.35%",
    "86.82% ",
    "19.47% ",
    1946.620132
  ),
  createData("Number of NPs", "67.35%", "69.30%  ", "1.95%  ", 195.4451694),
  createData(
    "Number of Encounters per NP",
    "67.35%",
    "81.81%",
    "14.46% ",
    1445.670338
  ),
  createData("Number of Clinics", "67.35%", "62.48%", "-4.87%  ", -487.1667175),
  createData(
    "Clinic Patient Increase",
    "67.35%",
    "65.12% ",
    "-2.23%   ",
    -223.0577636
  ),
  createData(
    "Number of IP Locations",
    "67.35%",
    "66.69%",
    "-66.69%",
    -66.19419125
  ),
  createData(
    "IP Patient Increase",
    "67.35%",
    "66.69%",
    "-66.69%",
    -66.19419125
  ),
  createData(
    "Number of Kidney Dialysis Locations ",
    "67.35%",
    "72.86%",
    "5.51% ",
    550.730579
  ),
  createData(
    "Dialysis Patient Increase ",
    "67.35%",
    "72.86%",
    "5.51% ",
    550.730579
  ),
  createData("NP Salary", "67.35%", "84.77% ", "5.82%  ", 582),
  createData("Benefits Per Resource", "67.35%", "69.51%  ", "2.16%", 216),
  createData("Rent Per Clinic ", "67.35%", "69.84%  ", "2.49%  ", 249),
];

export default function RevenueTable() {
  const [sortBy, setSortBy] = useState("carbs"); // Default sort by the last column
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    const sorted = [...sortedRows].sort((a, b) => {
      if (sortOrder === "asc") {
        return parseFloat(a[sortBy]) - parseFloat(b[sortBy]);
      } else {
        return parseFloat(b[sortBy]) - parseFloat(a[sortBy]);
      }
    });
    setSortedData(sorted);
  }, [sortOrder]);

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <Box sx={{ background: "#fff", borderRadius: "24px", height: "100%" }}>
      <Box sx={{ borderBottom: "1px solid #ccc" }}>
        <Box sx={{ padding: "24px" }}>
          <Text variant="h2">
            Return on Invested Capital (ROIC) - Profitability ratio that
            measures the return an investment for added capital
          </Text>
        </Box>
      </Box>

      <Box sx={{ padding: "24px" }} className="responsive">
        <Table
          sx={{
            border: "1px solid #B3B4BD",
            borderRadius: "24px 24px 10px 10px",
            borderCollapse: "separate",
          }}
          aria-label="ROIC table"
        >
          <TableHead
            sx={{
              border: "1px solid #B3B4BD",
              borderBottom: "transparent",
              backgroundColor: "#fff",
            }}
          >
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  borderTopLeftRadius: "24px",
                  fontWeight: "600",
                  padding: "10px 16px",
                }}
              >
                Metric
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: "600", padding: "10px 16px" }}
              >
                Base ROIC
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderTopRightRadius: "24px",
                  fontWeight: "600",
                  padding: "10px 16px",
                }}
              >
                <TableSortLabel
                  active={sortBy === "carbs"}
                  direction={sortOrder}
                  onClick={handleSort}
                >
                  ROIC Improvement Per 5% Improvement in Metric
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row) => {
              // Determine the background color based on the carbs value
              let bgColor;
              const roicImprovement = parseFloat(row.carbs);

              if (roicImprovement < 0) {
                bgColor = "#FFACAB";
              } else if (roicImprovement <= 6) {
                bgColor = "#FDC1A8";
              } else {
                bgColor = "#C1FFC1";
              }

              return (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      borderBottomLeftRadius: "10px",
                    },
                    "&:last-child td:nth-child(0)": {
                      borderBottomLeftRadius: "10px",
                    },
                    " &:last-child td:last-child ": {
                      borderBottomRightRadius: "10px",
                    },
                    borderBottomLeftRadius: "10px",
                    background: bgColor,
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "10px 16px" }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "10px 16px" }}>
                    {row.calories}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "10px 16px" }}>
                    {row.carbs}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

import { Box } from "@mui/material";
import React from "react";
import Text from "components/common/Typography/Text";
import { Avatar } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

const Urgent = ({ data, setAlert }) => {
  const navigate = useNavigate();
  const getStatusColor = (status) => {
    switch (status) {
      case "urgent":
        return "#FF5E5E";
      case "important":
        return "#246EFD";
      case "normal":
        return "#59D281";
      default:
        return "#4645BF";
    }
  };
  console.log(!!data?.length);
  return (
    <Box
      sx={{
        fontFamily: "Montserrat",
        marginTop: "25px",
        borderTop: "1px solid #f7f7f7",
        padding: "10px 20px!important",
        minHeight: "250px",
      }}
    >
      {!!data?.length === false && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Box>No Patients Found</Box>
        </Box>
      )}
      {data.map((item, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "15px 13px!important",
              "&:hover": {
                backgroundColor: "#f0f8ff",
              },
            }}
            key={index}
          >
            <Box
              onClick={() => {
                localStorage.setItem("appointment_img", item?.patient?.photo);
                localStorage.setItem(
                  "appointment_time",
                  moment(item?.datetime).format("h:mm A")
                );
                navigate(
                  `/schedule/patient/${item?.patient_id}/appointment/${item?.id}`
                );
                setAlert(false);
              }}
              sx={{
                cursor: "pointer",
                width: "45%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  marginRight: "10px",
                  border: "1px solid #e1e1e1",
                  borderRadius: "50%",
                }}
              >
                <Avatar
                  alt="Patient Avatar"
                  src={item?.patient?.photo ?? "https://placehold.co/60x60"}
                />
              </Box>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Text
                    id={"alert-patient-name"}
                    variant="h3"
                    color={"black"}
                    marginRight="10px"
                  >
                    {`${item?.patient?.first_name} ${item?.patient?.last_name}`}
                  </Text>

                  <span style={{}} />
                  <Box
                    sx={{
                      backgroundColor: "#D9FFE5",
                      borderRadius: "30px",
                      padding: "5px 8px",
                      marginLeft: "10px",
                    }}
                  ></Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Text id={"scd-age"} variant="bodyXs" color={"lightBlack"}>
                    {item?.ckd_info?.[0]?.ckd_code}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "55%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "120px",
                  textAlign: "center",
                  border: `1px solid ${getStatusColor(item?.health_status)}`,
                  backgroundColor: getStatusColor(item?.health_status),
                  borderRadius: "30px",
                  padding: "5px 20px !important",
                }}
              >
                <Text
                  sx={{
                    fontSize: "11px !important",
                    fontWeight: "600 !important",
                  }}
                  color="white"
                >
                  {item?.health_status.charAt(0).toUpperCase() +
                    item?.health_status.slice(1)}
                </Text>
              </Box>
              <Box
                onClick={() => {
                  toast.success("Appointment has been Scheduled!");
                }}
                sx={{
                  border: "1px solid #D1D5DB",
                  borderRadius: "30px",
                  padding: "5px 20px !important",
                  cursor: "pointer",
                }}
              >
                <Text
                  sx={{
                    fontSize: "12px !important",
                    fontWeight: "600 !important",
                  }}
                  color={"blue"}
                >
                  Schedule
                </Text>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Urgent;

import { useFormik } from "formik";
import * as toast from "hooks/notify";
import { flexRowCenter } from "styles";
import LoginWrapper from "./LoginWrapper";
import { Box, Grid } from "@mui/material";
import { useTimer } from "react-timer-hook";
import email from "assets/svg/light/email.svg";
import { useEffect, useState } from "react";
import Button from "components/common/Button/Button";
import Text from "components/common/Typography/Text";
import { useDispatch, useSelector } from "react-redux";
import { getCSRF } from "redux/features/Auth/creatNewPasswordSlice";
import InputField from "components/common/FormComponents/InputField";
import { forgotPasswordValidationSchema } from "validations/auth/login/forgotPassword.schema";
import {
  clearRequestForgotPassword,
  userRequestForgotPassword,
} from "redux/features/Auth/forgotPasswordSlice";
const ForgotPassword = () => {
  let time = new Date();
  const dispatch = useDispatch();
  time.setSeconds(time.getSeconds() + 60);
  // To show the timer in ui
  const [startTimer, setStartTimer] = useState(false);
  // To change the text of button once email is sent
  const [emailSentCount, setEmailSentCount] = useState(0);
  const { forgotPasswordSuccess, forgotPasswordIsLoading } = useSelector(
    (state) => state.forgotPassword
  );
  // Pre-built function proviede by timer-hook
  const { seconds, minutes, restart } = useTimer({
    // On expiry (count down completion)
    expiryTimestamp: time,
    onExpire: () => {
      setStartTimer(false);
      // Remove all the forgot pass related data from local storage
      localStorage.removeItem("forPassTime");
      localStorage.removeItem("forPassEmail");
    },
  });
  const formik = useFormik({
    initialValues: {
      // defailt value for input when the timer is going on
      email: localStorage.getItem("forPassEmail") || "",
    },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: (value) => {
      dispatch(userRequestForgotPassword(value));
    },
  });
  useEffect(() => {
    dispatch(getCSRF());
    let passedTime = localStorage.getItem("forPassTime");
    // Calculation from where the time started and the time when page was reloaded
    if (passedTime) {
      const currentTime = new Date();
      // Subtracting both time to check the differenc of time and then deleting it from timer total time (60 s)
      let persistentTime =
        passedTime - (currentTime.getMinutes() * 60 + currentTime.getSeconds());
      if (persistentTime >= -120) {
        setEmailSentCount(emailSentCount + 1);
        currentTime.setSeconds(
          time.getSeconds() + (60 - Math.abs(persistentTime))
        );
        restart(currentTime);
        setStartTimer(true);
      } else {
        // No timer will show up if there is no data in storage
        setStartTimer(false);
      }
    }
  }, []);
  useEffect(() => {
    if (forgotPasswordSuccess === true) {
      toast.success("Email has been sent");
      // starting timer when email has been sent
      getTimerStarted();
      // Dispatching right after sent (reason) => clear state for the next resend
      dispatch(clearRequestForgotPassword());
    }
    if (forgotPasswordSuccess === false) {
      toast.error("User not found");
      dispatch(clearRequestForgotPassword());
    }
  }, [forgotPasswordSuccess]);
  useEffect(() => {
    return () => {
      dispatch(clearRequestForgotPassword());
    };
  }, []);
  // getting the timer started
  function getTimerStarted() {
    setStartTimer(true);
    const time = new Date();
    // storing email value in the local storage
    let getEmailValue = document.getElementById("email").value;
    // storing starting time (minutes converted to seconds) and second in local storage
    localStorage.setItem(
      "forPassTime",
      time.getMinutes() * 60 + time.getSeconds()
    );
    localStorage.setItem("forPassEmail", getEmailValue);
    time.setSeconds(time.getSeconds() + 60);
    // restarting on button
    restart(time);
    setEmailSentCount(emailSentCount + 1);
  }
  return (
    <LoginWrapper>
      <Box
        sx={{
          maxWidth: "400px",
          marginTop: "50px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <Text id={"password-heading"} center marginY={1} variant="h1">
            Forgot Password?
          </Text>
          <Box sx={{ ...flexRowCenter, textAlign: "center" }}>
            <Box sx={{ maxWidth: "355px", marginBottom: "20px" }}>
              <Text
                id={"password-validation-msg"}
                center
                variant="bodyS"
                marginY={2}
              >
                Please enter the email address associated with your account to
                reset your password
              </Text>
            </Box>
          </Box>
        </Box>
        {startTimer ? (
          <div
            style={{ fontSize: "20px" }}
            data-testid={"timer-box-forgot-password"}
          >
            <span>{minutes}</span>:<span>{seconds}</span>
          </div>
        ) : null}
        <Box sx={{ maxWidth: "300px", width: "100%" }}>
          <Grid container spacing={1}>
            <InputField
              xs={12}
              sm={12}
              md={12}
              lg={12}
              name="email"
              positionLeft
              type={"email"}
              formik={formik}
              Placeholder={"E-mail Address"}
              iconDetails={{ allowIcon: true, iconName: email }}
            />
          </Grid>
          <Button
            id="save-password-btn"
            disabled={startTimer}
            borderRadius={"12px"}
            text={emailSentCount > 0 ? "Resend" : "Next"}
            isLoading={forgotPasswordIsLoading}
            sx={{
              width: "100%",
              height: "38px",
              marginTop: "10px",
              "&:hover": {
                color: "#fff",
              },
              backgroundImage:
                "linear-gradient(270deg, #4C2631 1.99%, #285677 90.93%)",
              color: "#FFFFFF",
              border: "none",
            }}
            onClick={formik.handleSubmit}
          />
        </Box>
      </Box>
    </LoginWrapper>
  );
};
export default ForgotPassword;

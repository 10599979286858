import { Box } from "@mui/material";
import Text from "components/common/Typography/Text";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const KeyIndicatorExpectedRevenuPieChart = () => {
  const [series, setSeries] = useState([99]);
  const options = {
    chart: {
      type: "pie",
      width: "100%",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: ["#4FD1C5", "#F5F5F5"],
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "30px",
              color: "#4FD1C5",
              fontWeight: "500",
              formatter: function () {
                return "99%";
              },
            },
          },
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "99%";
        },
      },
    },
    labels: [""],
  };
  return (
    <Box sx={{ textAlign: "center" }}>
      <Box className="chart-wrap">
        <Box id="chart">
          <ReactApexChart options={options} series={series} type="donut" />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Text
            marginY={2}
            sx={{
              fontSize: "25px !important",
              fontWeight: "bold",
            }}
          >
            $3,918,133
          </Text>
          <Text
            sx={{
              fontSize: "20px !important",
              fontWeight: "bold",
            }}
          >
            Revenue
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default KeyIndicatorExpectedRevenuPieChart;

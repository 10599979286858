import { Box, Skeleton, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import circleleftarrow from "assets/svg/colored/circleleftarrow.svg";
import correctBox from "assets/svg/colored/correctBox.svg";
import wrong from "assets/svg/colored/wrong.svg";
import ListViewSchedules from "components/common/Cards/ListViewSchedules";
import CardsSkeleton from "components/common/Loaders/Skeleton/CardsSkeleton";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getScheduledAppointments } from "redux/features/Patients/ScheduledAppointmentSlice";
import { alignItemsCenter, flexRow, flexRowCenterCenter } from "styles";
import { swiperLibraryStyling } from "styles/Dashboard/SchedulePatientsStyle";
import {
  EffectCoverflow,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Cards from "../../components/common/Cards";

const CardsContainer = ({
  healthFilter,
  isVerticalView,
  date,
  searchData,
  isChatOpen,
}) => {
  let theme = useTheme();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [patientsData, setPatientsData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [filteredPatientsData, setFilteredPatientsData] =
    useState(patientsData);
  const [activeSlide, setActiveSlide] = useState({
    isCalculating: false,
    id: null,
  });
  const containerRef = useRef(null);

  useEffect(() => {
    if (filteredPatientsData?.length) {
      // Scroll to the fourth item after the component mounts
      if (containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [filteredPatientsData, containerRef, isVerticalView]);

  const { account_id } = useSelector((state) => state.auth);
  const { results, scheduleAppointmentsMessage, isLoading } = useSelector(
    (state) => state.patients
  );

  useEffect(() => {
    if (results.length > 0) {
      setPatientsData((prevData) => [...prevData, ...results]);
      setFilteredPatientsData((prevData) => [...prevData, ...results]);
    }
  }, [results]);

  useEffect(() => {
    setPage(1);
    setPatientsData([]);
    setShowMessage(false);
    setFilteredPatientsData([]);
    setActiveSlide({ isCalculating: false, id: null });
    if (account_id !== null) {
      dispatch(getScheduledAppointments(null, 1, date, 1, account_id));
    }
  }, [date, account_id]);
  useEffect(() => {
    if (!isLoading && results.length <= 0) {
      setTimeout(() => {
        setShowMessage(true);
      }, 1000);
    }
  }, [isLoading, results]);

  useEffect(() => {
    function filterActiveSlider() {
      const formattedDates = [];
      patientsData?.forEach((s, i) => {
        if (s.checkin_status === "Checkin") {
          formattedDates.push({
            dateTimeFormat: moment(s.datetime).format("DD-MM-YYYY-h:mm A"),
            dateTime: s.datetime,
            id: i,
            status: s.checkin_status,
          });
        }
      });
      const currentDate = moment(); // Gets the current date and time
      const specificDate = moment(date); // Creates a moment object for the specific date

      // merge the provided date with current time
      const mergedDateTime = specificDate.set({
        hour: currentDate.hours(),
        minute: currentDate.minutes(),
        second: currentDate.seconds(),
        millisecond: currentDate.milliseconds(),
      });

      // Format the result in the desired format
      const now = mergedDateTime.toISOString();
      const beforeAllDates = formattedDates?.filter((ele) =>
        moment(ele.dateTime).isBefore(now)
      );
      let beforeDate =
        beforeAllDates.length > 0
          ? beforeAllDates[beforeAllDates.length - 1]
          : undefined;
      const afterDate = formattedDates?.filter((ele) =>
        moment(ele.dateTime).isAfter(now)
      )[0];
      let beforeDateMinutesDifference =
        beforeDate?.dateTime !== undefined
          ? Math.abs(moment(beforeDate?.dateTime).diff(now, "minutes"))
          : undefined;
      let afterDateMinutesDifference =
        afterDate?.dateTime !== undefined
          ? +Math.abs(moment(afterDate?.dateTime).diff(now, "minutes"))
          : undefined;
      if (
        beforeDateMinutesDifference === undefined &&
        afterDateMinutesDifference === undefined
      ) {
        setActiveSlide({ isCalculating: false, id: 1 });
      } else if (beforeDateMinutesDifference === undefined) {
        setActiveSlide({ isCalculating: false, id: afterDate?.id });
      } else if (afterDateMinutesDifference === undefined) {
        setActiveSlide({ isCalculating: false, id: beforeDate?.id });
      } else {
        setActiveSlide({
          isCalculating: false,
          id:
            beforeDateMinutesDifference < afterDateMinutesDifference
              ? beforeDate?.id
              : beforeDateMinutesDifference === afterDateMinutesDifference
                ? beforeDate?.id
                : afterDate?.id,
        });
      }
    }
    if (patientsData.length > 0 && patientsData.length <= 30) {
      setActiveSlide({ isCalculating: true, id: null });
      filterActiveSlider();
    }
  }, [patientsData]);

  useEffect(() => {
    if (searchData !== "") {
      setFilteredPatientsData(
        patientsData.filter((data) => {
          return (
            data.patient.first_name
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            data.patient.last_name
              .toLowerCase()
              .includes(searchData.toLowerCase()) ||
            (
              data.patient.first_name.toLowerCase() +
              " " +
              data.patient.last_name.toLowerCase()
            ).includes(searchData.toLowerCase())
          );
        })
      );
    } else {
      setFilteredPatientsData(patientsData);
    }
  }, [searchData]);

  function handleSlideChange(swiper) {
    setActiveSlideIndex(swiper.activeIndex);
    if (filteredPatientsData.length === patientsData.length) {
      if (swiper.activeIndex == 28 + (page > 1 ? 10 * (page - 1) : 0)) {
        setTimeout(() => {
          dispatch(
            getScheduledAppointments(null, page + 1, date, 1, account_id)
          );
          setPage(page + 1);
        }, 100);
      }
    }
  }

  // handling checkin-status images
  const statusImgs = {
    "Checked Out": circleleftarrow,
    Cancelled: wrong,
    Scheduled: correctBox,
  };

  const getHealthStatusData = () => {
    const obj = {
      urgent: "urgent",
      normal: "normal",
      important: "important",
      "all patients": "all patients",
    };

    return healthFilter ? obj[healthFilter] : "all patients";
  };
  return (
    <Box
      sx={{
        marginTop: "8px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        ...swiperLibraryStyling(
          theme,
          filteredPatientsData?.length,
          isChatOpen
        ),
        [`@media (min-width: 1024px) and (max-width:1024px) and (min-height:1366px) and (max-height:1366px)`]:
          {
            height: "75vh",
          },
      }}
      ref={containerRef}
    >
      {!isVerticalView ? (
        <>
          {(activeSlide.isCalculating && activeSlide.id === null) ||
          (isLoading && patientsData?.length < 9) ? (
            <Swiper
              className="skeleton-swiper swiper_container"
              style={{ height: "100%", flex: "1" }}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              initialSlide={3}
              slidesPerView={"auto"}
              speed={0}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
              }}
              modules={[EffectCoverflow, Pagination]}
            >
              {Array(10)
                .fill("")
                .map((_, i) => (
                  <SwiperSlide>
                    <CardsSkeleton key={i} />
                  </SwiperSlide>
                ))}
            </Swiper>
          ) : (
            ""
          )}
          {!activeSlide.isCalculating &&
          activeSlide.id !== null &&
          patientsData.length > 0 ? (
            <Swiper
              style={{ flex: "1" }}
              onSlideChange={(swiper) => handleSlideChange(swiper)}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              initialSlide={activeSlide.id}
              slidesPerView={"auto"}
              mousewheel={{ sensitivity: 0.5 }}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
              }}
              pagination={{ el: ".swiper-pagination", clickable: true }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
                clickable: true,
              }}
              modules={[EffectCoverflow, Pagination, Navigation, Mousewheel]}
              className="swiper_container"
            >
              {filteredPatientsData?.filter((v) =>
                healthFilter === "all patients"
                  ? v
                  : getHealthStatusData() === v.health_status
              )?.length ? (
                filteredPatientsData
                  ?.filter((v) =>
                    healthFilter === "all patients"
                      ? v
                      : getHealthStatusData() === v.health_status
                  )
                  ?.map((el, i) => (
                    <SwiperSlide>
                      <Cards
                        isReviewLater={el?.review_later}
                        key={i}
                        className="cards"
                        id={el?.account_id}
                        time={el?.datetime}
                        patientId={el?.patient_id}
                        latest_lab_date={el?.latest_lab_date}
                        status={el?.checkin_status}
                        lastVisitDate={el?.last_visit}
                        description={el?.visit_reason}
                        isActive={activeSlideIndex === i}
                        appointmentId={el?.id}
                        img={el?.patient.photo}
                        healthStatus={el?.health_status}
                        latestVitals={el?.patient?.vitals?.reduce(
                          (latest, current) => {
                            return new Date(latest.date_time) >
                              new Date(current.date_time)
                              ? latest
                              : current;
                          }
                        )}
                        name={
                          el?.patient?.first_name + " " + el?.patient?.last_name
                        }
                        disease={
                          el?.ckd_info?.length > 0
                            ? el?.ckd_info[0]?.ckd_code
                            : ""
                        }
                      />
                    </SwiperSlide>
                  ))
              ) : (
                <Box
                  sx={{
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No Data Found
                </Box>
              )}

              {isLoading && patientsData?.length > 9 ? (
                <SwiperSlide>
                  <Box
                    sx={{ ...flexRow, ...alignItemsCenter, height: "545px" }}
                  >
                    <CircularProgress />
                  </Box>
                </SwiperSlide>
              ) : (
                ""
              )}
              {isLoading && patientsData?.length > 9 ? (
                <SwiperSlide>
                  <Box
                    sx={{ ...flexRow, ...alignItemsCenter, height: "545px" }}
                  >
                    <CircularProgress />
                  </Box>
                </SwiperSlide>
              ) : (
                ""
              )}
              <div className="slider-controler">
                <div className="swiper-button-prev slider-arrow">
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </div>
                <div className="swiper-button-next slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </Swiper>
          ) : (
            !isLoading &&
            patientsData.length <= 0 && (
              <Box
                sx={{ width: "100%", height: "50vh", ...flexRowCenterCenter }}
              >
                <Text
                  variant="h2"
                  fontWeight="500"
                  marginTop="30px"
                  alignSelf="center"
                >
                  {scheduleAppointmentsMessage}
                </Text>
              </Box>
            )
          )}
        </>
      ) : (
        <>
          {!isLoading ? (
            <>
              {patientsData.length > 0 ? (
                <>
                  {filteredPatientsData?.length ? (
                    <Box ref={containerRef}>
                      {filteredPatientsData?.map((el, index) => (
                        <ListViewSchedules
                          key={index}
                          className="cards"
                          id={el?.account_id}
                          time={el?.datetime}
                          patientId={el?.patient_id}
                          status={el?.checkin_status}
                          lastVisitDate={el?.last_visit}
                          description={el?.visit_reason}
                          isActive={activeSlideIndex === index}
                          appointmentId={el?.id}
                          img={el?.patient.photo}
                          name={
                            el?.patient?.first_name +
                            " " +
                            el?.patient?.last_name
                          }
                          latest_lab_date={el?.latest_lab_date}
                          healthStatus={el?.health_status}
                          latestVitals={el?.patient?.vitals?.reduce(
                            (latest, current) => {
                              return new Date(latest.date_time) >
                                new Date(current.date_time)
                                ? latest
                                : current;
                            }
                          )}
                          disease={
                            el?.ckd_info?.length > 0
                              ? el?.ckd_info[0]?.ckd_code
                              : ""
                          }
                        />
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        height: "60vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No Data Found
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    height: "60vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No Data Found
                </Box>
              )}
            </>
          ) : (
            <>
              <Skeleton
                variant="rounded"
                width={"99%"}
                height={60}
                sx={{ marginBottom: "10px" }}
              />
              <Skeleton
                variant="rounded"
                width={"99%"}
                height={60}
                sx={{ marginBottom: "10px" }}
              />
              <Skeleton
                variant="rounded"
                width={"99%"}
                height={60}
                sx={{ marginBottom: "10px" }}
              />
              <Skeleton
                variant="rounded"
                width={"99%"}
                height={60}
                sx={{ marginBottom: "10px" }}
              />
              <Skeleton
                variant="rounded"
                width={"99%"}
                height={60}
                sx={{ marginBottom: "10px" }}
              />
              <Skeleton
                variant="rounded"
                width={"99%"}
                height={60}
                sx={{ marginBottom: "10px" }}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default CardsContainer;

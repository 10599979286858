export let SkeletonPropsStyleGenerator = (
  width,
  height,
  borderRadius,
  marginRight
) => {
  return {
    width: width,
    height: height,
    borderRadius: borderRadius,
    marginRight: marginRight,
  };
};

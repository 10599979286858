import { Box, Grid, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTeamMemberAPI, getUsersList, softClearAddTeamStatuses } from "redux/features/MyTeam/myTeamSlice";
import { getGroupsOptions } from "redux/features/common/optionSlice";
import * as Yup from "yup";
import Button from '../Button/Button';
import InputField from '../FormComponents/InputField';
import SelectField from "../FormComponents/SelectField";
import CustomModal from './CustomModal';

const AddTeamMemberModal = ({ open, setOpen }) => {
    const { isGroupsLoading, groupsOptions } = useSelector(state => state.options);
    const theme = useTheme();
    const dispatch = useDispatch();
    const { account_id } = useSelector(state => state.auth);
    const { isAddTeamMemberLoading, isInviteLoading, addTeamMemberSuccess } = useSelector(state => state.MyTeams);

    const formik = useFormik({
        initialValues: {
            groups: "",
            email: "",
            accounts: [account_id]
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is Required'),
            groups: Yup.number().required('Role is Required'),
        }),
        onSubmit: (values) => {
            const data = { ...values, groups: [values.groups] }
            dispatch(addTeamMemberAPI(data))
        },
    })

    useEffect(() => {
        dispatch(getGroupsOptions())
    }, [])

    useEffect(() => {
        addTeamMemberSuccess && formik.resetForm();
        addTeamMemberSuccess && dispatch(softClearAddTeamStatuses())
        addTeamMemberSuccess && toast.success("Invitation sent");
        addTeamMemberSuccess && setOpen(false);
        addTeamMemberSuccess && dispatch(getUsersList(account_id))
    }, [addTeamMemberSuccess])

    return (
        <CustomModal
            setOpen={() => {
                setOpen(false)
                formik.resetForm();
            }}
            open={open}
            title={"Add Team Member"}
            width="auto"
            maxWidth={"sm"}
            fullWidth={true}
        >
            <Grid container spacing={2} alignItems={"center"}>
                <SelectField
                    required
                    options={groupsOptions}
                    loading={isGroupsLoading}
                    formik={formik}
                    name='groups'
                    label={"Choose a role"}
                    cols={6}
                    placeholder="Select Role"
                />
                <InputField
                    required
                    label="Enter E-mail Address"
                    formik={formik}
                    flex={1}
                    name="email"
                    cols={6}
                    placeholder={"example@gmail.com"}
                />
            </Grid>
            <Box sx={{ display: "flex", padding: "25px 0px 0px 10px", justifyContent: "center" }}>
                <Button id="Send-Invitation-btn" isLoading={isAddTeamMemberLoading || isInviteLoading} text="Send Invitation" onClick={() => formik.handleSubmit()} sx={{ width: "190px", color: theme.palette.button }} />
                <Button id="Cancel-btn" onClick={() => {
                    setOpen(false)
                    formik.resetForm()
                }} text="Cancel" sx={{ width: "150px", backgroundColor: theme.palette.common.white, color: theme.palette.common.black, marginLeft: "15px" }} />
            </Box>
        </CustomModal>
    )
}

export default AddTeamMemberModal 
import { Box } from "@mui/material";
import ChloeMartin from "assets/images/ChloeMartin.pdf";
import MouhamedElhanan from "assets/images/MouhamedElhanan.pdf";
import SofiaPatel from "assets/images/SofiaPatel.pdf";
import { useSelector } from "react-redux";
import CustomModal from "./CustomModal";

const PDFModal = ({
  date = "",
  open,
  setOpen,
  patientName = "Chloe Martin",
}) => {
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );
  const pdfData = {
    "Chloe Martin": ChloeMartin,
    "Sofia Patel": SofiaPatel,
    "Mouhamed Elhanan": MouhamedElhanan,
  };

  return (
    <CustomModal
      setOpen={setOpen}
      open={open}
      title={"Discharge Report"}
      width="auto"
      padding="10px 10px"
      maxWidth={"lg"}
      fullWidth={true}
    >
      <Box sx={{ padding: "3px 0px" }}>
        <iframe
          src={
            pdfData?.[
              getSpecificPatientData?.first_name +
                " " +
                getSpecificPatientData?.last_name
            ] ?? SofiaPatel
          }
          width="100%"
          height="500px"
          title="PDF Viewer"
        />
      </Box>
    </CustomModal>
  );
};

export default PDFModal;

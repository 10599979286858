import { CancelRounded } from "@mui/icons-material";
import { Box, Grid, Skeleton } from "@mui/material";
import { ckdVals } from "assets/static";
import SelectField from "components/common/FormComponents/SelectField";
import CustomModal from "components/common/Modal/CustomModal";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getLabsList, getPerLab } from "redux/features/Labs/labsSlice";
import { getMedicinesList } from "redux/features/Medications/MedicinesSlice";
import { clearOrderMedicationsData } from "redux/features/Medications/OrderMedicationsSlice";
import { flexRowCenterCenter, flexWrap } from "styles";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import { debounce } from "utils/debouncer";
import OrderLabModal from "../OrderLabModal";
import PlanMedicationModal from "./components/PlanMedicationModal";
import LabResultDetailCard from "../LabResults/LabResultDetailCard";
import Button from "components/common/Button/Button";

const Plan = ({
  followUp,
  planLabsData,
  setHelperMedication,
  helperMedication,
  createOrUpdate,
  setPlanLabsData,
  setFollowUpData,
  planMedicineData,
  callAutoEncSaver,
  setAutoSaveStatus,
  setPlanMedicineData,
  createDeleteMedicine,
  createUpdateDeleteLabs,
}) => {
  let dispatch = useDispatch();
  const [pendingOrder, setPendingOrder] = useState(null);

  const [medicineId, setMedicineId] = useState(null);
  const [latestLab, setLatestLab] = useState({});
  const {
    labsData,
    labsByEncounterData,
    updateLabsLoading,
    labsByEncounterLoading,
    labsIsLoading,
  } = useSelector((state) => state.labs);
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );
  const { healthSummaryData } = useSelector((state) => state.healthSummary);
  const {
    medicinesData,
    medicinesIsLoading,
    medicinesEncounterData,
    medicinesEncounterDataOptions,
    medicinesEncounterLoading,
  } = useSelector((state) => state.medicines);

  const [date, setDate] = useState("");
  const [labEdited, setLabEdited] = useState(false);
  const [showLabModal, setShowLabModal] = useState(false);
  const [showReOrderLabModal, setShowReOrderLabModal] = useState(false);
  const [showMedicationModal, setShowMedicationModal] = useState(false);

  const [toBeEditedLab, settoBeEditedLab] = useState("");
  const [selectedLabsData, setSelectedLabsData] = useState([]);
  const [dateStripEnable, setDateStripEnable] = useState(false);
  const [selectedMedicineData, setSelectedMedicineData] = useState(
    helperMedication ?? []
  );
  const { labResults, isLabResultsLoading } = useSelector(state => state.resultsLabs);
  const [activeTime, setActiveTime] = useState({ num: 0, time: "months" });

  // For setting the side strip timeline according to status
  useEffect(() => {
    let status = healthSummaryData?.[0]?.ckd_status;

    const validCKDS = ["CKD-1", "CKD-2", "CKD-3", "CKD-4", "CKD-5"];
    if (validCKDS.includes(status)) {
      setActiveTime({ num: ckdVals[status].num, time: ckdVals[status].time });
    } else {
      setActiveTime({ num: 2, time: "weeks" });
    }
  }, [healthSummaryData?.[0]]);

  useEffect(() => {
    if (followUp?.date !== null) {
      setDate(moment(followUp?.date).utc().clone());
    }
    if (followUp?.selection !== null) {
      setFollowUpData((prevState) => ({
        ...prevState,
        selection: followUp.selection,
      }));
    }
    // because if the selection is not selected by user
    if (followUp?.selection?.[0] === "a" || followUp?.selection?.[0] === "i") {
      setDateStripEnable(true);
    }
  }, [followUp]);

  useEffect(() => {
    if (dateStripEnable) {
      setFollowUpData((prevState) => ({
        ...prevState,
        selection: `${activeTime.time === "weeks" ? "in" : "after"} ${activeTime.num
          } ${activeTime.time}`,
      }));
    }
  }, [dateStripEnable]);

  useEffect(() => {
    if (date !== "") {
      setFollowUpData((prevState) => ({
        ...prevState,
        date: date,
      }));
    }
  }, [date]);

  useEffect(() => {
    if (medicinesEncounterDataOptions?.length > 0) {
      setSelectedMedicineData(medicinesEncounterDataOptions);
      setPlanMedicineData(medicinesEncounterData);
    }
  }, [medicinesEncounterDataOptions?.length, medicinesEncounterData]);

  useEffect(() => {
    if (labsByEncounterData?.length) {
      setSelectedLabsData(
        labsByEncounterData?.map((s) => ({
          val: s?.id,
          test_id: s?.test_id,
          label: s?.test?.test_name,
          instructions: s?.instructions,
        }))
      );
      setPlanLabsData(
        labsByEncounterData?.map((s, i) => ({
          id: s?.id,
          test_id: s?.test_id,
          name: s?.test?.test_name,
          instruction: s?.instructions,
        }))
      );
    }
  }, [labsByEncounterData?.length]);

  const getLabs = debounce((value) => {
    if (value?.length > 0) {
      dispatch(getLabsList(value));
    }
  }, 400);

  useEffect(() => {
    getLabs("a");
  }, []);

  const orderLab = (e, v, instruction) => {
    if (v?.label !== undefined && v?.label?.length > 0) {
      let existingLab = planLabsData.filter((s) => s.name === v.label);
      if (existingLab?.length === 0) {
        setSelectedLabsData((prevState) => [
          { label: v?.label, val: v?.value },
          ...prevState,
        ]);
        setPlanLabsData((prevState) => [
          ...prevState,
          {
            name: v?.label,
            id: "",
            instruction: instruction,
            test_id: v?.value,
          },
        ]);
        setAutoSaveStatus(" ");
        createUpdateDeleteLabs(v?.value, v?.value, instruction, "POST");
      } else {
        toast.warning("Lab already exists");
      }
    }
  }

  const onLabsSelect = (e, v, instruction) => {
    const re_order_labs = ["Na+", "Chol"];
    if (re_order_labs.includes(v?.label)) {
      setPendingOrder({ e, v, instruction })
      setShowReOrderLabModal(true);
    } else {
      orderLab(e, v, instruction);
    }
  };

  const onLabsUpdate = (e, v) => {
    if (v?.label !== undefined) {
      setAutoSaveStatus(" ");
      createUpdateDeleteLabs(v?.value, v?.test_id, v?.instructions, "PATCH");
      setLabEdited(true);
    }
  };

  const excludeLabs = (val, index) => {
    let filteredLabsArray = selectedLabsData.filter(
      (s) => s.label !== val.label
    );
    let filteredPlanLabs = planLabsData.filter((s) => s.id !== val.val);
    setSelectedLabsData(filteredLabsArray);
    setPlanLabsData(filteredPlanLabs);
    setAutoSaveStatus(" ");
    createUpdateDeleteLabs(val?.val, "", "", "DELETE");
  };

  const getMedicines = debounce((value) => {
    if (value?.length > 0) {
      dispatch(getMedicinesList(value));
    }
  }, 400);

  const onMedicineSelect = (e, v) => {
    /* This api is designed in such a way that previous meds should be
    passed in with the new one to add a new med.*/

    if (v?.label !== undefined) {
      if (!selectedMedicineData.includes(v?.label)) {
        setSelectedMedicineData((prevState) => [v?.label, ...prevState]);
        setPlanMedicineData((prevState) => [
          ...prevState,
          {
            dispensable_drug_id: v?.value,
            drug_name: v?.label,
            dosage: 1,
            prescribed_on: new Date(),
            instructions: "instructions",
            status_ind: 1,
          },
        ]);
        let medicineData = [...medicinesEncounterData];
        medicineData?.push({
          dispensable_drug_id: v?.value,
          drug_name: v?.label,
          dosage: 1,
          prescribed_on: new Date(),
          instructions: "instructions",
          status_ind: 1,
        });
        setAutoSaveStatus(" ");
        createDeleteMedicine(medicineData);
      } else {
        toast.warning("Medicine already exists");
      }
    }
  };
  const excludeMedicine = (val) => {
    //just for adding or removing smart suggestions
    if (helperMedication?.includes(val)) {
      setHelperMedication(helperMedication?.filter((s) => s !== val));
    }
    let filteredMedicineArray = selectedMedicineData.filter((s) => s !== val);
    let filteredPlanMedicineData = planMedicineData.filter(
      (s) => s.drug_name !== val
    );
    setSelectedMedicineData(filteredMedicineArray);
    setPlanMedicineData(filteredPlanMedicineData);
    setAutoSaveStatus(" ");
    createDeleteMedicine(filteredPlanMedicineData);
  };

  const SetSelectedMedicineID = (name) => {
    const selectedMeds = planMedicineData?.find((s) => s.drug_name === name);
    setMedicineId(selectedMeds?.medicine_id);
    setShowMedicationModal(true);
  };

  function onLabClickModal(val, index) {
    dispatch(getPerLab(val?.val));
    setLabEdited(false);
    settoBeEditedLab(val);
    setShowLabModal(true);
  }

  useEffect(() => {
    const latestObject = labResults && labResults.length > 0
      ? labResults.reduce((latest, current) => {
          return new Date(latest?.date_time) > new Date(current?.date_time) ? latest : current;
        })
      : null;
    setLatestLab(latestObject);
  }, [labResults]);

  return (
    <Grid container spacing={2}>
      <CustomModal
        fullWidth
        maxWidth="md"
        title="Re-Order Lab"
        open={showReOrderLabModal}
        setOpen={() => {
          setShowReOrderLabModal(false)
          setPendingOrder(null);
        }}
        actions={[
          <Box position={"relative"}>
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                text="Re-order"
                backgroundColor="#C41313"
                color="#fff"
                btnColor="#fff"
                border={"1px solid #C41313"}
                borderRadius="12px"
                sx={{ padding: "1px 35px", marginRight: "20px" }}
                onClick={() => {
                  const { e, v, instruction } = pendingOrder;
                  orderLab(e, v, instruction);
                  setShowReOrderLabModal(false);
                  setPendingOrder(null);
                }}
              />
              <Button
                text="Close"
                backgroundColor="#246EFD"
                color="#fff"
                btnColor="#fff"
                border={"1px solid #246EFD"}
                borderRadius="12px"
                sx={{ padding: "1px 30px", marginRight: "20px" }}
              />
            </Box>
          </Box>,
        ]}
      >
        <Box padding={2}>
          <Text center variant="h3" color={'red'}>Last month, on {moment(latestLab?.date_time).format('MM/DD/YYYY')} the patient underwent a {pendingOrder?.v?.label}.</Text>
        </Box>
        <LabResultDetailCard name={
          (getSpecificPatientData?.first_name
            ? getSpecificPatientData?.first_name
            : "") +
          " " +
          (getSpecificPatientData?.last_name
            ? getSpecificPatientData?.last_name
            : "")
        }
          data={latestLab ?? []}
          active={true}
        />
      </CustomModal>
      <Grid item xs={12} sm={6} md={6}>
        <Box
          sx={{
            borderRight: "1px solid #ccc",
            padding: "10px",
            paddingLeft: {
              xs: "0px",
              sm: "5px",
              md: "25px",
              lg: "25px",
              xl: "25px",
              xxl: "25px",
            },
            paddingRight: {
              xs: "0px",
              sm: "5px",
              md: "20px",
              lg: "20px",
              xl: "20px",
              xxl: "20px",
            },
            minHeight: "225px",
          }}
        >
          <Text id={"scd-plan-label-labs"} variant="h3">
            Labs
          </Text>
          <Box
            sx={{
              marginTop: "5px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <SelectField
                cols={12}
                name="labs"
                placeholder="Search"
                border="1px solid #000"
                onSearch={getLabs}
                onChange={onLabsSelect}
                loading={labsIsLoading}
                options={labsData}
              />
              <Box
                sx={{
                  backgroundColor: "#315FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "5px",
                  cursor: "pointer",
                  width: "42px",
                  borderRadius: "5px",
                  color: "#fff",
                }}
                onClick={() => {
                  setShowLabModal(true);
                  settoBeEditedLab({ label: "", value: "" });
                }}
              >
                +
              </Box>
              <CustomModal
                fullWidth
                maxWidth="md"
                title="Order Labs"
                open={showLabModal}
                setOpen={setShowLabModal}
              >
                <OrderLabModal
                  getLabs={getLabs}
                  setOpen={setShowLabModal}
                  onLabsSelect={onLabsSelect}
                  onLabsUpdate={onLabsUpdate}
                  toBeEditedLab={toBeEditedLab}
                  labsIsLoading={labsIsLoading}
                  options={labsData}
                />
              </CustomModal>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap,
                padding: "10px",
                gap: "10",
                minHeight: "120px",
                overflowY: "auto",
              }}
            >
              <SkeletonWrapper
                multipleCount={2}
                id={"patient-detail-header-age"}
                condition={!labsByEncounterLoading}
                props={[SkeletonPropsStyleGenerator("100%", "44px", "150px")]}
              >
                <>
                  {selectedLabsData?.length ? (
                    [...selectedLabsData]?.map((val, index) => (
                      <Box
                        key={index}
                        onClick={() => onLabClickModal(val, index)}
                        sx={{
                          backgroundColor: "#E2E2E2",
                          cursor: "pointer",
                          color: "#272A40",
                          minWidth: "100%",
                          height: "auto",
                          maxHeight: "44px",
                          fontSize: "11px",
                          lineHeight: "13px",
                          fontWeight: "700",
                          borderRadius: "30px",
                          display: "flex",
                          padding: "10px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "6px",
                        }}
                      >
                        {val?.label}{" "}
                        {true ? (
                          <CancelRounded
                            onClick={(e) => {
                              e.stopPropagation();
                              excludeLabs(val, index);
                            }}
                            data-testid={`strip-lab-cancel-icon-${index}`}
                            style={{
                              width: "15px",
                              height: "15px",
                              marginLeft: "4px",
                            }}
                          />
                        ) : null}
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        ...flexRowCenterCenter,
                        width: "100%",
                        marginTop: "15px",
                      }}
                    >
                      Search labs
                    </Box>
                  )}
                </>
              </SkeletonWrapper>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box
          sx={{
            padding: "10px",
            minHeight: "225px",
          }}
        >
          <Text id={"scd-plan-label-meds"} variant="h3">
            Medications
          </Text>
          <Box
            sx={{
              marginTop: "5px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <SelectField
                cols={12}
                name="medicines"
                placeholder="Search"
                border="1px solid #000"
                onSearch={getMedicines}
                onChange={onMedicineSelect}
                extendedSearchOptions={["description"]}
                showMoreInLabel={{ show: true, value: "description" }}
                loading={medicinesIsLoading}
                options={medicinesData}
              />
              <Box
                sx={{
                  backgroundColor: "#315FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "5px",
                  cursor: "pointer",
                  width: "42px",
                  borderRadius: "5px",
                  color: "#fff",
                }}
                onClick={() => {
                  setShowMedicationModal(true);
                }}
              >
                +
              </Box>
              <CustomModal
                setOpen={() => {
                  setShowMedicationModal(false);
                  setMedicineId(null);
                  dispatch(clearOrderMedicationsData());
                }}
                open={showMedicationModal}
                title="Add Prescription"
                fullWidth
                maxWidth="md"
              >
                <PlanMedicationModal
                  setMedicineId={setMedicineId}
                  medicineId={medicineId}
                  setShowMedicationModal={setShowMedicationModal}
                  medicinesData={medicinesData}
                  medicinesIsLoading={medicinesIsLoading}
                  getMedicines={getMedicines}
                />
              </CustomModal>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap,
                padding: "10px",
                gap: "10",
                minHeight: "120px",
                overflowY: "auto",
              }}
            >
              {!medicinesEncounterLoading ? (
                <>
                  {selectedMedicineData?.length ? (
                    [...helperMedication, ...selectedMedicineData]?.map(
                      (val, index) => (
                        <Box
                          sx={{
                            backgroundColor: "#E2E2E2",
                            cursor: "pointer",
                            color: "#272A40",
                            minWidth: "100%",
                            height: "auto",
                            maxHeight: "44px",
                            fontSize: "11px",
                            lineHeight: "13px",
                            fontWeight: "700",
                            borderRadius: "30px",
                            display: "flex",
                            padding: "10px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "6px",
                            position: "relative",
                            zIndex: "999",
                          }}
                        >
                          <Box
                            sx={{ flex: 1 }}
                            onClick={() => SetSelectedMedicineID(val)}
                            key={index}
                          >
                            {val}
                          </Box>
                          {true ? (
                            <CancelRounded
                              onClick={() => excludeMedicine(val)}
                              data-testid={`strip-medicine-cancel-icon-${index}`}
                              style={{
                                width: "15px",
                                height: "15px",
                                marginLeft: "4px",
                                position: "po",
                                zIndex: "1000",
                              }}
                            />
                          ) : null}
                        </Box>
                      )
                    )
                  ) : (
                    <Box
                      sx={{
                        ...flexRowCenterCenter,
                        width: "100%",
                        marginTop: "15px",
                      }}
                    >
                      Search medications
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Skeleton
                    sx={{
                      height: "auto",
                      minWidth: "100%",
                      maxHeight: "44px",
                      lineHeight: "13px",
                    }}
                  />
                  <Skeleton
                    sx={{
                      height: "auto",
                      minWidth: "100%",
                      maxHeight: "44px",
                      lineHeight: "13px",
                    }}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(Plan);

import { CancelRounded } from "@mui/icons-material";
import { Box, Tooltip, styled, tooltipClasses } from "@mui/material";
import SelectField from "components/common/FormComponents/SelectField";
import CustomModal from "components/common/Modal/CustomModal";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCptCodeList } from "redux/features/CptCodes/CptCodeSlice";
import { getIcdCodeList } from "redux/features/IcdCodes/IcdCodesSlice";
import { flexRowCenterCenter, flexWrap } from "styles";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import elipses from "utils/ellipsis";
import DiagnosisModal from "./components/DiagnosisModal";

const Assessment = ({
  getIcdCodes,
  assesmentObj,
  removeIcdCode,
  onIcdCodeSelect,
  showEmptyMessages,
  encounterByPatientSuccess,
}) => {
  let dispatch = useDispatch();
  // destructure of state of parent component for CRUD
  const { selectedIcdCodes } = assesmentObj;
  const [showDiagnosisModal, setShowDiagnosisModal] = useState(false);
  const [icdCodeObj, setIcdCodeObj] = useState([]);

  let { icdCodesOptions, isIcdCodeLoading } = useSelector(
    (state) => state.icdCode
  );

  useEffect(() => {
    dispatch(getCptCodeList("25"));
    dispatch(getIcdCodeList("z958"));
  }, []);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      padding: "9px",
      fontSize: "12px",
    },
  }));
  const [currentIcdCode, setCurrentIcdCode] = useState(null);
  const [currentIcdDescription, setCurrentIcdDescription] = useState(null);

  function onDiagnosisClickModal(icdCode, icdDescription) {
    setCurrentIcdCode(icdCode);
    setCurrentIcdDescription(icdDescription);
    setShowDiagnosisModal(true);
  }

  return (
    <Box sx={{ borderRight: "1px solid #ccc", width: "100%" }}>
      <CustomModal
        fullWidth
        maxWidth="sm"
        title="Diagnosis"
        open={showDiagnosisModal}
        setOpen={setShowDiagnosisModal}
      >
        <DiagnosisModal
          currentIcdDescription={currentIcdDescription}
          currentIcdCode={currentIcdCode}
        />
      </CustomModal>
      <Box
        sx={{
          padding: "10px",
          paddingRight: "25px",
          minHeight: "225px",
        }}
      >
        <Text id={"scd-assesment-diagnosis"} variant="h3">
          Diagnosis
        </Text>
        <Box
          data-testid="scd-assesment-search"
          sx={{
            marginTop: "5px",
          }}
        >
          <SelectField
            cols={12}
            name="icdCodes"
            placeholder="Search"
            onSearch={getIcdCodes}
            onChange={onIcdCodeSelect}
            border="1px solid #000"
            loading={isIcdCodeLoading}
            extendedSearchOptions={["description"]}
            showMoreInLabel={{ show: true, value: "description" }}
            options={icdCodesOptions}
          />

          <Box
            sx={{
              display: "flex",
              flexWrap,
              padding: "10px",
              gap: "10",
              minHeight: "120px",
              overflowY: "auto",
            }}
          >
            <SkeletonWrapper
              condition={encounterByPatientSuccess}
              multipleCount={2}
              props={[SkeletonPropsStyleGenerator("100%", "44px", "150px")]}
            >
              {selectedIcdCodes?.length > 0 ? (
                selectedIcdCodes.map((icd_code, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: "#E2E2E2",
                      cursor: "pointer",
                      color: "#272A40",
                      minWidth: "100%",
                      height: "auto",
                      fontSize: "11px",
                      lineHeight: "13px",
                      maxHeight: "44px",
                      fontWeight: "700",
                      borderRadius: "30px",
                      display: "flex",
                      padding: "10px",
                      justifyContent: false ? "space-around" : "space-between",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <Box
                      onClick={() =>
                        onDiagnosisClickModal(
                          icd_code?.icd_code,
                          icd_code?.description
                        )
                      }
                    >
                      {elipses(
                        `${icd_code?.icd_code}  - ${icd_code?.description}`,
                        55
                      )}
                    </Box>
                    <CancelRounded
                      onClick={() => removeIcdCode(index)}
                      key={`delete-icon-${index}`}
                      // data-testid={`strip-medicine-cancel-icon-${index}`}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "4px",
                      }}
                    />
                  </Box>
                ))
              ) : (
                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    width: "100%",
                    marginTop: "15px",
                  }}
                >
                  {showEmptyMessages ? "Search diagnosis" : ""}
                </Box>
              )}
            </SkeletonWrapper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default memo(Assessment);

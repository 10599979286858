import { Box, Tabs } from "@mui/material";
import { graphStaticApiFullFormValue, graphStaticColors } from "assets/static";
import CustomTabs from "components/common/Tabs/CustomTabs";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { trendsMainVitalsTabBoxStyle } from "styles/SCD/trendsStyle";
import { toCapitalize } from "utils/toCapitalize";
import { getVariance } from "utils/variance";

const VitalTrends = () => {
  // in order to keep track of active properties data
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [value, setValue] = useState(0);
  const [graphColors, setGraphColors] = useState([]);

  // in order to keep track of active properties in graph
  const [areActives, setAreActives] = useState([]);

  // in order to keep track of all active units as well as variance in graphs
  const [areActiveUnits, setAreActiveUnits] = useState([]);

  const [vitalsSortedData, setVitalsSortedData] = useState([]);

  const { vitalsData } = useSelector((state) => state.vitals);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* to remove active property from graph 
  it is designed in a way that it can remove all
  properties that are given in Array form*/
  function removeFromActiveGraph(value) {
    let findIndexesOfProperties = value.map((v) => areActives.indexOf(v));
    setAreActives((oldValues) => {
      return oldValues.filter((_, i) => !findIndexesOfProperties.includes(i));
    });
    setAreActiveUnits((oldValues) => {
      return oldValues.filter((_, i) => !findIndexesOfProperties.includes(i));
    });
    setData((oldValues) => {
      return oldValues.filter((_, i) => !findIndexesOfProperties.includes(i));
    });
    setGraphColors((oldValues) => {
      return oldValues.filter((_, i) => !findIndexesOfProperties.includes(i));
    });
  }

  /* to add an inactive property to graph 
  it is designed in a way that it can add all 
  properties that are given in Array form*/
  function addToGraph(value) {
    let result = value?.map((v) =>
      vitalsSortedData?.map((s) => Number(s?.[graphStaticApiFullFormValue?.[v]]))
    );
    
    setData([...data, ...result]);
    setAreActives([...areActives, ...value]);
    setGraphColors([
      ...graphColors,
      ...value?.map((v) => graphStaticColors[v]),
    ]);

    // setting units & variance according to the values provided
    setAreActiveUnits([
      ...areActiveUnits,
      ...result?.map(
        (s, i) =>
          `${toCapitalize(
            graphStaticApiFullFormValue?.[value?.[i]]
          )} Variance: ${getVariance(s?.slice(-4))}`
      ),
    ]);
  }

  // to invoke addition or removal process according to condition
  function setActiveGraphs(checked, value) {
    if (vitalsSortedData?.[0]?.account_id !== undefined) {
      if (checked)
        value === "BP" ? addToGraph(["BPS", "BPD"]) : addToGraph([value]);
      else removeFromActiveGraph(value === "BP" ? ["BPS", "BPD"] : [value]);
    }
  }

  // To set the date once data is recieved or changed
  useEffect(() => {
    if (vitalsData?.length > 0) {
      // First sorting out the data in asc format
      let tempData = [...vitalsData]

      setVitalsSortedData(tempData?.sort((a, b) =>
        new Date(b?.date_time) -
        new Date(a?.date_time)
      ));

      // extracting the dates to show
      setDate(tempData?.map((a, b) =>
        moment(a?.date_time).format("MM/DD/YYYY")
      ));
    }
  }, [vitalsData]);

  // Once data is sorted default values are added
  useEffect(() => {
    if (vitalsSortedData?.[0]?.account_id !== undefined) {
      addToGraph(["BPS", "BPD", "HR", "WT"]);
    }
  }, [vitalsSortedData])

  let graphData = {
    series: data.map((s, i) => ({ name: areActiveUnits[i], data: data?.[i] })),
    options: {
      colors: graphColors,
      chart: {
        height: 250,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: date,
      },
      yaxis: {
        min: 0,
        max: Math.max(...data?.map((s) => Math.max(...s))) + 20,
        labels: {
          formatter: function (val) {
            return Math.round(val);
          },
        },
        tickAmount: 4,
      },
    },
  };

  return (
    <Box
      data-testid="lab-trends-id"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "10px",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        sx={trendsMainVitalsTabBoxStyle}
        indicatorColor="red"
        orientation="vertical"
      >
        <CustomTabs
          label="BP"
          defaultChecked={true}
          areActives={areActives}
          setAreActives={setAreActives}
          setActiveGraphs={setActiveGraphs}
        />

        <CustomTabs
          label="HR"
          defaultChecked={true}
          areActives={areActives}
          setAreActives={setAreActives}
          setActiveGraphs={setActiveGraphs}
        />

        <CustomTabs
          label="WT"
          defaultChecked={true}
          areActives={areActives}
          setAreActives={setAreActives}
          setActiveGraphs={setActiveGraphs}
        />

        <CustomTabs
          label="RR"
          areActives={areActives}
          setAreActives={setAreActives}
          setActiveGraphs={setActiveGraphs}
        />

        <CustomTabs
          label="Temp"
          areActives={areActives}
          setAreActives={setAreActives}
          setActiveGraphs={setActiveGraphs}
        />

        <CustomTabs
          label="Spo2"
          areActives={areActives}
          setAreActives={setAreActives}
          setActiveGraphs={setActiveGraphs}
        />
      </Tabs>
      <Box data-testid="vitals-trends-id" sx={{ width: "82%" }}>
        <Box id={"chart"}>
          <ReactApexChart
            options={graphData.options}
            series={graphData.series}
            type="line"
            height={300}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default memo(VitalTrends);

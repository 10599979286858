export const helper = () => {
  return {
    checkNullOrUndefinedString: (str) => {
      if (str == null || str === undefined) {
        return "";
      } else {
        // Replace occurrences of "null" and "undefined" with an empty string
        str = str.replace(/null/gi, "").replace(/undefined/gi, "");

        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    },
  };
};

import { Box, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearUserStatuses, deleteUserAPI, getUsersList } from "redux/features/MyTeam/myTeamSlice";
import Button from '../Button/Button';
import Text from "../Typography/Text";
import CustomModal from './CustomModal';

const RemoveTeamMemberModal = ({ open, setOpen, name, userId = "" }) => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const { account_id } = useSelector(state => state.auth);
    const { userDeleteSuccess, isUserDeleteLoading } = useSelector(state => state.MyTeams);

    const handleRemoveTeamMember = () => {
        dispatch(deleteUserAPI(userId))
    }

    //update users list after the user deleted
    useEffect(() => {
        userDeleteSuccess && dispatch(getUsersList(account_id));
    }, [dispatch, userDeleteSuccess])

    //close modal after delete successfull
    useEffect(() => {
        userDeleteSuccess && (() => {
            setOpen(false)
            dispatch(clearUserStatuses())
        })()
    }, [dispatch, isUserDeleteLoading])

    return (
        <CustomModal
            setOpen={setOpen}
            open={open}
            title={"Remove Team Member"}
            width="auto"
            maxWidth={"sm"}
            fullWidth={true}
        >
            <Text id={'remove-confirmation-text-id'} variant="h3" fontweight="400" color="black" textAlign="center"> Are you sure you want to remove {name} from your team?</Text>
            <Box sx={{ display: "flex", padding: "25px 0px 0px 10px", justifyContent: "center" }}>
                <Button id={'cancel-button-id'} onClick={() => setOpen(false)} text="cancel" sx={{ width: "150px", backgroundColor: theme.palette.common.white, color: theme.palette.common.black }} />
                <Button id={'yes-button-id'} variant={'contained'} isLoading={isUserDeleteLoading} text="Yes, Remove" onClick={() => handleRemoveTeamMember()} sx={{ width: "190px", color: theme.palette.common.white, backgroundColor: theme.palette.common.red, marginLeft: "15px" }} />
            </Box>
        </CustomModal>
    )
}

export default RemoveTeamMemberModal
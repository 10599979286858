import { Box } from "@mui/material";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LabResultDetailCard from "../LabResults/LabResultDetailCard";
import VitalDetailCard from "../Vitals/VitalDetailCard";

const PastVisitModalComponent = ({ data }) => {
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );
  const pastVisitTabs = [
    {
      title: "Notes",
      tab: <AccordionNotes marginTop="20px" notes={data?.notes} />,
    },

    {
      title: "Lab Results",
      tab: (
        <LabResultDetailCard
          pastVisit={true}
          name={`${getSpecificPatientData?.first_name
              ? `${getSpecificPatientData?.first_name} ${" "}`
              : ""
            } ${getSpecificPatientData?.last_name
              ? getSpecificPatientData?.last_name
              : ""
            }`}
          data={data?.labs}
          active={0 === 0 ? true : false}
          marginTop="20px"
        />
      ),
    },
    {
      title: "Vitals",
      tab: (
        <VitalDetailCard
          pastVisit
          vitalData={data?.vitals}
          patientData={getSpecificPatientData}
          active={0 === 0 ? true : false}
          marginTop="20px"
        />
      ),
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box>
          <Box>
            <Text variant="propertyLabel">Created by</Text>
          </Box>
          <Text variant="bodyXs">Khaled Janom MD</Text>
        </Box>
        <Box>
          <Box>
            <Text variant="propertyLabel">Reason for Visit</Text>
          </Box>
          <Text variant="bodyXs">
            increased fatigue, swelling in my extremities, and changes in
            urination patterns
          </Text>
        </Box>
      </Box>
      <HorizontalTabs childrenArray={pastVisitTabs} />
    </>
  );
};

const AccordionNotes = ({ notes = null, marginTop }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
      subjective: notes?.soap_notes?.split("|")
        ?.find((v) => v?.includes("Subjective -"))
        ?.split("Subjective -")?.[1],
      objective: notes?.soap_notes?.split("|")
        ?.find((v) => v?.includes("Objective -"))
        ?.split("Objective -")?.[1],
      assessment: notes?.soap_notes?.split("|")
        ?.find((v) => v?.includes("Assesment -"))
        ?.split("Assesment -")?.[1],
      plan: notes?.soap_notes?.split("|")?.find((v) => v?.includes("Plan -"))?.split("Plan -")?.[1],
    })
  }, [notes])
  const textDesign = {
    width: "100%",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "21px",
    fontFamily: "Istok Web",
    color: "#272A40",
  };
  const labelDesign = {
    fontWeight: "700",
    fontSize: "26px",
    fontFamily: "Istok Web",
    color: "#272A40",
  };
  return (
    <Box
      className="cus-textFocus"
      sx={{
        padding: "10px",
        borderRadius: "11px",
        background: "white",
        opacity: 0.5,
        cursor: "not-allowed",
        marginTop: marginTop,
      }}
    >
      {notes !== null ? (
        <>
          <div>
            <label htmlFor="subjectiveInput">
              <h2 style={labelDesign}>Subjective</h2>
            </label>
            <div
              data-testid="subjective-area"
              placeholder="Subjective notes ..."
              className="cus-textarea"
              style={{ ...textDesign, border: "none", outline: "none" }}
              dangerouslySetInnerHTML={{ __html: formData.subjective }}
            ></div>
          </div>
          <div>
            <label htmlFor="ObjInput">
              <h2 style={labelDesign}>Objective</h2>
            </label>
            <div
              data-testid="objective-area"
              placeholder="Objective notes ..."
              className="cus-textarea"
              style={{ ...textDesign, border: "none", outline: "none" }}
              dangerouslySetInnerHTML={{ __html: formData.objective }}
            ></div>
          </div>
          <div>
            <label htmlFor="AssesmentInput">
              <h2 style={labelDesign}>Assesment</h2>
            </label>
            <div
              data-testid="assesment-area"
              placeholder="Assesment notes ..."
              className="cus-textarea"
              style={{ ...textDesign, border: "none", outline: "none" }}
              dangerouslySetInnerHTML={{ __html: formData.assessment }}
            ></div>
          </div>
          <div>
            <label htmlFor="planInput">
              <h2 style={labelDesign}>Plan</h2>
            </label>
            <div
              data-testid="plan-area"
              placeholder="Plan notes ..."
              className="cus-textarea"
              style={{ ...textDesign, border: "none", outline: "none" }}
              dangerouslySetInnerHTML={{ __html: formData.plan }}
            ></div>
          </div>
        </>
      ) : (
        <Box marginY={4}>
          <Text variant="bodyS">No Data Found.</Text>
        </Box>
      )}
    </Box>
  );
};

export default PastVisitModalComponent;

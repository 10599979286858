import { Box, useTheme } from "@mui/material";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { useNavigate } from "react-router-dom";
import elipses from "utils/ellipsis";
import circleleftarrow from "../../../assets/svg/colored/circleleftarrow.svg";
import correct from "../../../assets/svg/colored/correct.svg";
import correctBox from "../../../assets/svg/colored/correctBox.svg";
import wrong from "../../../assets/svg/colored/wrong.svg";
import CardAlert from "./CardAlert";
import { helper } from "utils/helper";

const ListViewSchedles = ({
  key,
  id,
  healthStatus,
  latest_lab_date,
  latestVitals,
  time,
  status,
  img,
  name,
  disease,
  description,
  lastVisitDate,
  isActive,
  patientId,
  appointmentId,
}) => {
  const navigate = useNavigate();
  let theme = useTheme();
  const [imageUrl, setImageUrl] = useState("");
  const [statusImg, setStatusImg] = useState("");
  const [isImageLoading, setIsImageLoading] = useState(false);

  // handling checkin-status images
  useEffect(() => {
    if (status == "Checked Out") {
      setStatusImg(circleleftarrow);
    } else if (status == "Cancelled") {
      setStatusImg(wrong);
    } else if (status == "Scheduled") {
      setStatusImg(correctBox);
    } else {
      setStatusImg(correct);
    }
  }, []);

  return (
    <Box
      id={`active`}
      key={key}
      onClick={() =>
        navigate(`/schedule/patient/${patientId}/appointment/${appointmentId}`)
      }
      sx={{
        cursor: "pointer",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",

        display: "flex",
        width: "99%",
        padding: "15px",
        alignItems: "center",
        margin: "5px auto 10px auto",
        transition: "scale 300ms",
        ...(isActive
          ? {
              border: "1px solid #1344f1",
              boxShadow: "0px 0px 14px 0px #ABBEFF",
              scale: 1.01,
              height: "90px",
            }
          : { height: "80px" }),
        // "&:hover": {

        // },
      }}
    >
      <Box
        sx={{
          maxWidth: "100px",
          marginRight: "10px",
        }}
      >
        <LazyLoadImage
          src={img}
          className="profile-pic"
          effect="opacity"
          style={{
            height: "48px",
            width: "48px",
            borderRadius: "10px",
          }}
        />
      </Box>
      <Box
        sx={{
          maxWidth: "200px",
          marginRight: "10px",
          width: {
            xs: "auto",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "130px",
            xxl: "130px",
          },
        }}
      >
        <Text variant="bodyS">{moment(time).format("h:mm A")}</Text>
      </Box>
      <Box
        sx={{
          maxWidth: "200px",
          marginRight: "10px",
          width: {
            xs: "auto",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "200px",
            xxl: "200px",
          },
        }}
      >
        <Text variant="h3">{helper().checkNullOrUndefinedString(name)}</Text>
      </Box>
      <Box
        sx={{
          maxWidth: "200px",
          marginRight: "10px",
          width: {
            xs: "auto",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "120px",
            xxl: "120px",
          },
        }}
      >
        <Text variant="bodyS">{disease}</Text>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginRight: "10px",
          overflow: "hidden",
        }}
      >
        <Text variant="bodyS">{elipses(description, 230)}</Text>
      </Box>
      <Box
        sx={{
          maxWidth: "200px",
          marginRight: "10px",
          width: {
            xs: "auto",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "150px",
            xxl: "150px",
          },
        }}
      >
        <Text variant="bodyS">
          {moment(lastVisitDate).format("MM/DD/YYYY")}
        </Text>
      </Box>
      <Box
        sx={{
          maxWidth: "200px",
          marginRight: "10px",
          width: {
            xs: "auto",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "150px",
            xxl: "150px",
          },
        }}
      >
        <CardAlert
          healthStatus={healthStatus}
          latest_lab_date={latest_lab_date}
          latestVitals={latestVitals}
          disease={disease}
          name={name}
          img={img}
        />
      </Box>
      <Box
        sx={{
          maxWidth: "130px",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          maxWidth: "200px",
          width: {
            xs: "auto",
            sm: "auto",
            md: "auto",
            lg: "auto",
            xl: "130px",
            xxl: "130px",
          },
        }}
      >
        <img src={statusImg} className="status-image" />
        <Text
          variant="bodyS"
          fontWeight="400"
          marginLeft="10px"
          sx={{ minWidth: "80px" }}
        >
          <span>{status}</span>
        </Text>
      </Box>
    </Box>
  );
};
export default memo(ListViewSchedles);

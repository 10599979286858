import { Box, Card, Grid, IconButton } from "@mui/material";
import Flex from "Layouts/Flex/Flex";
import Button from "components/common/Button/Button";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cardImageStyle,
  financialsCardStyle,
  iconButtonStyle,
  menuImgStyle,
  parentContainerBox,
} from "styles/Financials/Financials";
import MenuIcon from "../../assets/svg/bold/Hamburgericon.svg";
import downloadIcon from "../../assets/svg/light/downloadIcon.svg";
import PatiendGraph from "./FinancialGraphs/KeyIndicatorsCharts/PatiendGraph";
import PatientSeenChart from "./FinancialGraphs/PatientSeenChart";
import PerformanceIndicator from "./FinancialGraphs/PerformanceIndicatorGraph";
import MenuComponent from "./components/MenuComponent/MenuComponent";
import ChatBotComponent from "./components/ChatBotComponent/ChatBotComponent";
import Text from "components/common/Typography/Text";
import sparkleIcon from "assets/svg/colored/ai_sparkle.svg";
import closeIcon from "assets/svg/bold/close.svg";
import { useLocation, useNavigate } from "react-router-dom";
import cardImage from "../../assets/svg/colored/cardicon.svg";
import arrowIconUp from "../../assets/svg/colored/arrowIconDown.svg";
import { getGroupsOptions } from "redux/features/common/optionSlice";
import { getAccountID } from "redux/features/Auth/loginSlice";
import { getScheduledAppointments } from "redux/features/Patients/ScheduledAppointmentSlice";
import moment from "moment";
const Financials = () => {
  const { isChatOpen } = useSelector((state) => state.globalStates);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(true);
  const [placement, setPlacement] = useState("");

  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { account_id } = useSelector((state) => state.auth);
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const { results } = useSelector((state) => state.patients);

  const [graphFlag, setGraphFlag] = useState(0);
  const targetRef = useRef(null);

  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEsrdCardClick = () => {
    setGraphFlag(1);
    scrollToTarget();
  };

  useEffect(() => {
    dispatch(getAccountID());
    dispatch(getGroupsOptions());
  }, [dispatch]);

  useEffect(() => {
    if (account_id !== null) {
      !results?.length &&
        dispatch(
          getScheduledAppointments(
            null,
            1,
            moment().format("YYYY-MM-DD"),
            1,
            account_id
          )
        );
    }
  }, [dispatch, account_id]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          item
          lg={isChatOpen ? 9 : 12}
          xl={isChatOpen ? 9 : 12}
          xxl={isChatOpen ? 9 : 12}
          md={isChatOpen ? 8 : 12}
          sm={12}
          xs={12}
        >
          <Box sx={parentContainerBox}>
            <Flex justifyContent="space-between" marginBottom="10px">
              <IconButton
                aria-describedby={"simple-popper"}
                sx={iconButtonStyle}
                onClick={handleClick("bottom-end")}
              >
                <img style={menuImgStyle} src={MenuIcon} alt="menuIcon" />
              </IconButton>
              <MenuComponent
                setOpen={setOpen}
                open={open}
                setPlacement={setPlacement}
                placement={placement}
                anchorEl={anchorEl}
              />
              <Box>
                <img src={downloadIcon} alt="" />
                <Button
                  color="#246EFD"
                  btnColor="#246EFD"
                  sx={{ height: "20px" }}
                  text={"Download Reports"}
                  backgroundColor="none"
                  boxShadow={"none"}
                  fontSize={"11px"}
                />
              </Box>
            </Flex>
            <Box
              sx={{
                width: "100%",
                padding: "16px",
                background: "#fff",
                borderRadius: "24px",
                display: message ? "flex" : "none",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              }}
            >
              <Flex
                flexWrap="noWrap"
                justifyContent="start"
                alignItems="center"
              >
                <img
                  src={sparkleIcon}
                  style={{ width: "24px", height: "24px", marginRight: "10px" }}
                  alt="sparkle"
                />
                <Text variant="bodyXs">
                  Your practice is on track for outperforming this quarter last
                  year by 12%. This can be primarily attributed to increased
                  billing on a per-patient basis, especially in late stage CKD
                  patients.
                </Text>
              </Flex>
              <IconButton onClick={() => setMessage(false)}>
                <img
                  src={closeIcon}
                  style={{ width: "24px", height: "24px" }}
                  alt="closeIcon"
                />
              </IconButton>
            </Box>
            <Grid container spacing={1}>
              <Grid
                item
                xxl={3}
                xl={isChatOpen ? 6 : 3}
                lg={isChatOpen ? 6 : 3}
                md={3}
                sm={6}
              >
                <Box sx={{ marginTop: "10px" }}>
                  <Card
                    onClick={() => {
                      navigate("/financials/revenue/summary");
                    }}
                    sx={{
                      ...financialsCardStyle,
                      cursor: "pointer",
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Box>
                        <Text variant="bodyS">Revenue vs Expense</Text>
                        <Flex>
                          <img
                            src={arrowIconUp}
                            alt="trendIcon"
                            style={{ marginRight: "5px" }}
                          />
                          <Text variant="h2">$4018k / $3610k</Text>
                        </Flex>
                      </Box>
                      <Box sx={cardImageStyle}>
                        <img
                          src={cardImage}
                          style={{ width: "47.66px", height: "46px" }}
                          alt="cardImage"
                        />
                      </Box>
                    </Flex>
                  </Card>
                </Box>
              </Grid>
              <Grid
                item
                xxl={3}
                xl={isChatOpen ? 6 : 3}
                lg={isChatOpen ? 6 : 3}
                md={3}
                sm={6}
              >
                <Box sx={{ marginTop: "10px" }}>
                  <Card
                    onClick={() => {
                      navigate(
                        "/financials/revenue/summary#revenue-patient-seen-graph"
                      );
                    }}
                    sx={{
                      ...financialsCardStyle,
                      cursor: "pointer",
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Box>
                        <Text variant="bodyS">Patient Seen</Text>
                        <Flex>
                          <img
                            src={arrowIconUp}
                            alt="trendIcon"
                            style={{ marginRight: "5px" }}
                          />
                          <Text variant="h2">21,970</Text>
                        </Flex>
                      </Box>
                      <Box sx={cardImageStyle}>
                        <img
                          src={cardImage}
                          style={{ width: "47.66px", height: "46px" }}
                          alt="cardImage"
                        />
                      </Box>
                    </Flex>
                  </Card>
                </Box>
              </Grid>
              <Grid
                item
                xxl={3}
                xl={isChatOpen ? 6 : 3}
                lg={isChatOpen ? 6 : 3}
                md={3}
                sm={6}
              >
                <Box sx={{ marginTop: "10px" }}>
                  <Card
                    onClick={handleEsrdCardClick}
                    sx={{
                      ...financialsCardStyle,
                      cursor: "pointer",
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Box>
                        <Text variant="bodyS">ESRD Patients</Text>
                        <Flex>
                          <img
                            src={arrowIconUp}
                            alt="trendIcon"
                            style={{ marginRight: "5px" }}
                          />
                          <Text variant="h2">2,257</Text>
                        </Flex>
                      </Box>
                      <Box sx={cardImageStyle}>
                        <img
                          src={cardImage}
                          style={{ width: "47.66px", height: "46px" }}
                          alt="cardImage"
                        />
                      </Box>
                    </Flex>
                  </Card>
                </Box>
              </Grid>
              <Grid
                item
                xxl={3}
                xl={isChatOpen ? 6 : 3}
                lg={isChatOpen ? 6 : 3}
                md={3}
                sm={6}
              >
                <Box sx={{ marginTop: "10px" }}>
                  <Card
                    onClick={() => {
                      navigate(
                        "/financials/revenue/summary#revenue-new-patients-graph"
                      );
                    }}
                    sx={{
                      ...financialsCardStyle,
                      cursor: "pointer",
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Box>
                        <Text variant="bodyS">New Patients</Text>
                        <Flex>
                          <img
                            src={arrowIconUp}
                            alt="trendIcon"
                            style={{ marginRight: "5px" }}
                          />
                          <Text variant="h2">857</Text>
                        </Flex>
                      </Box>
                      <Box sx={cardImageStyle}>
                        <img
                          src={cardImage}
                          style={{ width: "47.66px", height: "46px" }}
                          alt="cardImage"
                        />
                      </Box>
                    </Flex>
                  </Card>
                </Box>
              </Grid>
              {/* Charts */}
              <Box sx={{ my: 1, width: "100%" }}>
                <PatientSeenChart />
              </Box>
              <Box sx={{ width: "100%" }}>
                <PerformanceIndicator />
              </Box>
              <Box sx={{ width: "100%" }}>
                <PatiendGraph
                  setGraphFlag={setGraphFlag}
                  graphFlag={graphFlag}
                  targetRef={targetRef}
                  isChatOpen={isChatOpen}
                />
              </Box>
            </Grid>
          </Box>
        </Grid>
        {isChatOpen && <ChatBotComponent isChatOpen={true} />}
      </Grid>
    </Box>
  );
};

export default Financials;

import { createSlice } from "@reduxjs/toolkit";

export const initialMedicinesState = {
  medicinesData: [],
  medicinesSuccess: null,
  medicinesIsLoading: false,
  medicinesSearchData: [],
  medicinesSearchSuccess: null,
  medicinesSearchIsLoading: false,
  updateMedicineSuccess: null,
  updateMedicineLoading: false,
  medicinesEncounterLoading: false,
  medicinesEncounterSuccess: null,
  medicinesEncounterData: [],
  medicinesEncounterDataOptions: [],
};

const name = "medicines";

export const medicinesSlice = createSlice({
  name,
  initialState: initialMedicinesState,
  reducers: {
    getMedicines: (state, action) => {
      state.medicinesIsLoading = true;
    },

    getSearchMedicine: (state, action) => {
      state.medicinesSearchIsLoading = true;
    },
    updateMedicine: (state, action) => {
      state.updateMedicineLoading = true;
      state.updateMedicineSuccess = null;
    },
    getMedicinesEncounter: (state, action) => {
      state.medicinesEncounterLoading = true;
    },
    getMedicinesSuccess: (state, action) => {
      state.medicinesSuccess = true;
      state.medicinesIsLoading = false;
      state.medicinesData = action.payload.results?.length
        ? action.payload.results.map((s) => ({
            label: s.drug_name,
            // id is going up
            value: s.dispensable_drug_id,
            description: s.purpose,
          }))
        : state.medicinesData;
    },

    getSearchMedicineSuccess: (state, action) => {
      state.medicinesSearchSuccess = true;
      state.medicinesSearchIsLoading = false;
      state.medicinesSearchData = action.payload.results?.length
        ? action.payload.results.map((s) => ({
            label: s.drug_name,
            value: s.dispensable_drug_id,
            description: s.purpose,
          }))
        : [];
    },
    updateMedicineSuccess: (state, action) => {
      state.updateMedicineSuccess = true;
      state.updateMedicineLoading = false;
    },
    getMedicinesEncounterSuccess: (state, action) => {
      state.medicinesEncounterLoading = false;
      state.medicinesEncounterData = action.payload?.medicines?.filter(
        (s) => s.discontinued_on === null
      );
      state.medicinesEncounterDataOptions = action.payload?.medicines
        ?.filter((s) => s.discontinued_on === null)
        .map((s) => s.drug_name);
      state.medicinesEncounterSuccess = true;
    },
    getMedicinesFail: (state, action) => {
      state.medicinesData = [];
      state.medicinesSuccess = false;
      state.medicinesIsLoading = false;
    },
    getSearchMedicineFail: (state, action) => {
      state.medicinesSearchData = [];
      state.medicinesSearchSuccess = false;
      state.medicinesSearchIsLoading = false;
    },
    updateMedicineFail: (state, action) => {
      state.updateMedicineSuccess = null;
      state.updateMedicineLoading = false;
    },
    getMedicinesEncounterFail: (state, action) => {
      state.medicinesEncounterLoading = false;
      state.medicinesEncounterData = [];
      state.medicinesEncounterDataOptions = [];
      state.medicinesEncounterSuccess = false;
    },
    clearMedicinesData: (state, action) => {
      state.medicinesData = [];
      state.medicinesSuccess = null;
      state.medicinesIsLoading = false;
      state.updateMedicineSuccess = null;
      state.updateMedicineLoading = false;
      state.medicinesEncounterLoading = false;
      state.medicinesEncounterSuccess = null;
      state.medicinesEncounterData = [];
      state.medicinesEncounterDataOptions = [];
    },
    clearMedicinesStates: (state, action) => {
      state.medicinesSuccess = null;
      state.medicinesIsLoading = false;
      state.updateMedicineSuccess = null;
      state.updateMedicineLoading = false;
      state.medicinesEncounterLoading = false;
      state.medicinesEncounterSuccess = null;
    },
    clearMedicineSearchData : (state, action) => {
      state.medicinesSearchSuccess = null;
      state.medicinesSearchIsLoading = false;
      state.medicinesSearchData = [];
    },

  },
});

export const {
  getmedicines,
  updateMedicines,
  getMedicinesEncounter,
  clearMedicinesStates,
  clearMedicinesData,
  clearMedicineSearchData,
} = medicinesSlice.actions;

export default medicinesSlice.reducer;

export const getMedicinesList = (searchQuery) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/medicines/search/?drug_name=${searchQuery}`,
  },
  type: "getMedicines",
});

export const getSearchMedicineList = (searchQuery) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/medicines/search/?drug_name=${searchQuery}`,
  },
  type: "getSearchMedicine",
});

export const getMedicinesByEncounter = (id) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/prescriptions/get_prescription_data/?encounter_id=${id}`,
  },
  type: "getMedicinesEncounter",
});

export const updateMedicineByEncounter = (data) => ({
  payload: {
    apiName: name,
    data,
    method: "POST",
    formData: false,
    url: `/api/prescriptions/save_prescription_data/`,
  },
  type: "updateMedicine",
});

import { Box, Slider } from "@mui/material";
import React from "react";
import { cardPatientStatus } from "styles/Dashboard/SchedulePatientsStyle";
import { red, green, yellow } from "@mui/material/colors";
import { Avatar, Typography } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import moment from "moment";
import MenuList from "@mui/material/MenuList";

const useStyles = makeStyles({
  thumb: {
    "& .MuiSlider-thumb": {
      width: "2px",
      height: "15px",
      backgroundColor: "#2D3748",
      "&:hover": {
        backgroundColor: "#2D3748",
        boxShadow: "none",
      },
    },
  },
  appointmentCardMenu: {
    "& .MuiList-root.MuiList-padding": {
      width: "200px",
    },
    "& .MuiAvatar-img": {
      objectFit: "contain",
    },
  },
});

const CardAlert = ({
  img,
  latest_lab_date,
  healthStatus,
  latestVitals,
  name,
  disease,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    setAnchorEl(null);
  };

  const generateText = () => {
    const obj = {
      urgent: "Urgent",
      important: "Important",
      normal: "Normal",
    };
    return healthStatus ? obj[healthStatus] : "Normal";
  };

  const generateColor = () => {
    const properties = {
      urgent: {
        cardTextClr: "#FFFFFF",
        cardUrgentBackgroundColor: "#FF5E5E",
        urgentCardBorder: "#BC4A4A",
      },
      important: {
        cardTextClr: "#FFFFFF",
        cardUrgentBackgroundColor: "#246EFD",
        urgentCardBorder: "#256199",
      },
      normal: {
        cardTextClr: "#FFFFFF",
        cardUrgentBackgroundColor: "#59D281",
        urgentCardBorder: "#68BC4A",
      },
    };
    return healthStatus ? properties[healthStatus] : properties["normal"];
  };

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box>
      <Box
        sx={{
          ...cardPatientStatus(generateColor()),
        }}
        ref={anchorRef}
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {generateText()}
      </Box>
      <Box className={classes.appointmentCardMenu}>
        <Popper
          open={open}
          sa
          anchorEl={anchorRef.current}
          role={""}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    ModalProps={{
                      disableScrollLock: true,
                      disableAutoFocus: true,
                      disableEnforceFocus: true,
                      disablePortal: true,
                    }}
                  >
                    <Box sx={{ padding: "10px" }}>
                      <Box display="flex" alignItems="center" mb={2}>
                        <Avatar alt="Patient Avatar" src={img} />
                        <Box ml={2}>
                          <Typography
                            style={{ fontSize: "10px", fontWeight: 600 }}
                          >
                            {name}
                          </Typography>
                          <Typography
                            style={{ fontSize: "8px", fontWeight: 400 }}
                            color="textSecondary"
                          >
                            {disease}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        style={{ fontSize: "11px", fontWeight: 600 }}
                        mt={2}
                      >
                        Risk Assessment:
                      </Typography>
                      <Box display="flex" alignItems="center" marginY={1}>
                        <Slider
                          className={classes.thumb}
                          sx={{
                            color: "transparent",
                            height: "6px !important",
                            padding: "1px 0px",
                            backgroundImage:
                              "linear-gradient(90deg, #57D281 0%, #EEAB2A 50.5%, #E55D55 100%)",
                            borderRadius: "30px",
                          }}
                          max={10}
                          value={7}
                          defaultValue={1}
                        />
                      </Box>
                      <Box marginY={1}>
                        <Typography
                          style={{ fontSize: "11px", fontWeight: 600 }}
                        >
                          Last Hospitalization
                        </Typography>
                        <Typography
                          style={{ fontSize: "8px", fontWeight: 400 }}
                          color="textSecondary"
                        >
                          11/01/2023
                        </Typography>
                      </Box>

                      <Box marginY={1}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "11px", fontWeight: 600 }}
                          >
                            Vitals
                          </Typography>
                          <Typography
                            style={{ fontSize: "8px", fontWeight: 400 }}
                            color="textSecondary"
                          >
                            {moment("2024-01-15").format("MM/DD/YYYY")}
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontSize: "8px", fontWeight: 400 }}
                          color="textSecondary"
                        >
                          BP <span style={{ color: red[500] }}>185/90</span> |
                          HR{" "}
                          <span style={{ color: green[500] }}>
                            {latestVitals?.pulse} bpm
                          </span>{" "}
                          | Wt{" "}
                          <span style={{ color: yellow[700] }}>
                            {latestVitals?.weight} lbs
                          </span>
                        </Typography>
                      </Box>
                      <Box marginY={1}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "11px", fontWeight: 600 }}
                          >
                            Lab Results
                          </Typography>
                          <Typography
                            style={{ fontSize: "8px", fontWeight: 400 }}
                            color="textSecondary"
                          >
                            {moment(latest_lab_date).format("MM/DD/YYYY")}
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontSize: "8px", fontWeight: 400 }}
                          color="textSecondary"
                        >
                          Na+{" "}
                          <span style={{ color: red[500] }}>105 mmol/L</span> |
                          K+ <span style={{ color: red[500] }}>7.2 mmol/L</span>
                        </Typography>
                      </Box>
                    </Box>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
};

export default CardAlert;

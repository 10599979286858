import { Box } from "@mui/material";
import Text from "components/common/Typography/Text";

const DiagnosisModal = ({ currentIcdDescription, currentIcdCode }) => {
  return (
    <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
      <Box>
        <Text variant="bodyM">
          <span style={{ fontWeight: "600" }}> Code :</span> {currentIcdCode}
        </Text>
      </Box>

      <Box sx={{ marginTop: "10px" }}>
        <Text variant="bodyM">
          <span style={{ fontWeight: "600" }}>Description :</span>{" "}
          {currentIcdDescription}
        </Text>
      </Box>
    </Box>
  );
};

export default DiagnosisModal;

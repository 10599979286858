import { Grid } from "@mui/material";
import InputField from "components/common/FormComponents/InputField";
import { debounce } from "utils/debouncer";

const AssesmentPlanNotes = ({
  setAssesmentObj,
  setEnableAutoSaveNotes,
  assesmentObj = {},
  enableAutoSaveNotes,
}) => {
  // Handle input value after delay
  const handleInputChange = debounce((e) => {
    if (e.target.value) {
      setAssesmentObj((prevState) => ({
        ...prevState,
        notes: e.target.value,
      }));
      // State to true to run debouncer when the user starts the typing.
      if (!enableAutoSaveNotes) {
        setEnableAutoSaveNotes(true);
      }
    }
  }, 400);
  return (
    <Grid container sx={{ marginTop: "20px" }}>
      <InputField
        rows={4}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        flex={1}
        multiline
        name={"scd-assesment-notes"}
        labelVariant="formLabel"
        Placeholder={
          "After ending the visit, Smart Scribe will fill this section out for you. Enter any information here you’d like to make special note of. "
        }
        defaultValue={assesmentObj?.notes}
        onChange={(e) => handleInputChange(e)}
      />
    </Grid>
  );
};

export default AssesmentPlanNotes;

import { Box } from "@mui/material";
import TabPill from "components/common/Tabs/TabPill";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import VitalDetailCard from "./VitalDetailCard";

const VitalsDetail = () => {
  const [tabsData, setTabsData] = useState([]);

  const { vitalsData } = useSelector((state) => state.vitals);

  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );

  useEffect(() => {
    let VitalArray = [...vitalsData];
    const sortedArray = [...VitalArray].sort(
      (a, b) => new Date(b.date_time) - new Date(a.date_time)
    );

    setTabsData(
      sortedArray.map((vital, i) => {
        return {
          title: `${
            moment(vital?.date_time).isSame(new Date(), "day")
              ? "Today's Vitals"
              : "Vitals Results"
          } - ${moment(vital?.date_time).format("MM/DD/YYYY")}`,
          tab: (
            <VitalDetailCard
              vitalData={vital}
              patientData={getSpecificPatientData}
              active={i === 0 ? true : false}
            />
          ),
          value: i,
        };
      })
    );
  }, [vitalsData]);

  return (
    <Box>
      <TabPill
        childrenArray={tabsData}
        defaultValue={0}
        backgroundColor="#ffffff"
        tabMinWidth="250px"
        indicatorBackgroundColor={false ? "#1344F1" : "#FC5A5A"}
        tabSelectedColor={false ? "#1344F1" : "#FC5A5A"}
        paddingBtn="0px"
      />
    </Box>
  );
};

export default VitalsDetail;

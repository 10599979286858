import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const series2Data = [
  41098, 60922, 56906, 69672, 52348, 58474, 63897, 54353, 65656, 50468, 61311,
  68069,
];

const minValue = Math.min(...series2Data);
const maxValue = Math.max(...series2Data);
const padding = 5000;

const yaxisConfig = {
  min: Math.floor(minValue / 1000) * 1000,
  max: Math.ceil((maxValue + padding) / 1000) * 1000,
  tickAmount: 6,
  labels: {
    formatter: function (val) {
      const inThousands = val / 1000;
      return inThousands.toFixed(0) + "k";
    },
  },
};

const expectedRevenueData = [
  234844, 348126, 325175, 398124, 299134, 334134, 365126, 310590, 375176,
  288389, 350351, 388964,
];

const expectedRevenueMinValue = Math.min(...expectedRevenueData);
const expectedRevenueMaxValue = Math.max(...expectedRevenueData);
const expectedRevenuePadding = 50000;

const expectedRevenueYAxisConfig = {
  min:
    Math.floor((expectedRevenueMinValue - expectedRevenuePadding) / 10000) *
    10000,
  max:
    Math.ceil((expectedRevenueMaxValue + expectedRevenuePadding) / 10000) *
    10000,
  tickAmount: 5,
  labels: {
    formatter: function (val) {
      const inThousands = val / 1000;
      return "$" + inThousands.toFixed(0) + "k";
    },
  },
};

const ExpectedRevenue = ({ isRevenu }) => {
  const [chartData] = useState({
    series: [
      {
        name: "Revenue",
        data: expectedRevenueData,
      },
    ],
    series2: [
      {
        name: "Non-CKD",
        data: [
          41098, 60922, 56906, 69672, 52348, 58474, 63897, 54353, 65656, 50468,
          61311, 68069,
        ],
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          const inThousands = val / 1000;
          return !isRevenu
            ? "$" + inThousands.toFixed(1) + "k"
            : inThousands.toFixed(1) + "k";
        },
      },
      markers: {
        size: 5,
      },
      stroke: {
        curve: "straight",
        colors: ["#3475DC"],
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: isRevenu ? yaxisConfig : expectedRevenueYAxisConfig,
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        offsetY: 12,
        offsetX: -10,
        itemMargin: {
          horizontal: 5,
          vertical: 7,
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={isRevenu ? chartData?.series2 : chartData?.series}
          type="line"
          height={300}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ExpectedRevenue;

import { Box, Grid } from "@mui/material";
import Flex from "Layouts/Flex/Flex";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  CkdPieChartData,
  ESRDDataset,
  ESRDOption,
  activePatientDataset,
  activePatientdataOption,
  earlyStageCkdDataset,
  lateStagePatientDataset,
  lateStagePatientOption,
  visitOption,
} from "styles/Financials/Financials";
import arrowDown from "../../../../assets/svg/bold/arrowdown.svg";
import arrowup from "../../../../assets/svg/bold/arrowup.svg";

const PatiendGraph = ({ setGraphFlag, graphFlag, targetRef, isChatOpen }) => {
  const viewWidth = window.innerWidth;
  const [patientGraphFlag, setPatientGraphFlag] = useState(0);
  // const [patientSeries, setPatientSeries] = useState([74, 26]);
  const [patientSeriesOptions] = useState(CkdPieChartData);

  const [barChartOptions] = useState(visitOption);

  const graphComponentArray = [
    {
      title: "Early Stage CKD",
      donutData: [56, 44],
      overview: earlyStageCkdDataset,
      barChartOption: visitOption,
    },
    {
      title: "ESRD Patient",
      donutData: [44, 56],
      overview: ESRDDataset,
      barChartOption: ESRDOption,
    },
    {
      title: "Late Stage Patient",
      donutData: [60, 40],
      overview: lateStagePatientDataset,
      barChartOption: lateStagePatientOption,
    },
    {
      title: "Active Patient",
      donutData: [70, 30],
      overview: activePatientDataset,
      barChartOption: activePatientdataOption,
    },
  ];
  const getCurrentDonutData = (flag) => {
    const donutData = graphComponentArray[flag]?.donutData || [];
    return Array.isArray(donutData) ? donutData : [];
  };

  const [patientSeries, setPatientSeries] = useState(
    getCurrentDonutData(graphFlag)
  );

  const getSeriesData = (index) => {
    // Ensure data is an array
    const currentOverview = graphComponentArray[graphFlag].overview[index];
    const seriesData = Array.isArray(currentOverview?.data)
      ? currentOverview.data
      : [];
    return seriesData;
  };

  const handleArrowUpClick = () => {
    setPatientGraphFlag((prevFlag) => {
      if (graphFlag > 0) {
        setGraphFlag((c) => c - 1);
        setPatientSeries(getCurrentDonutData(graphFlag - 1));
      } else if (graphFlag <= 0) {
        setGraphFlag((c) => graphComponentArray.length - 1);
        setPatientSeries(getCurrentDonutData(graphComponentArray.length - 1));
      }
    });
  };

  const handleArrowDownClick = () => {
    setPatientGraphFlag((prevFlag) => {
      if (graphFlag < graphComponentArray.length - 1) {
        setGraphFlag((c) => c + 1);
        setPatientSeries(getCurrentDonutData(graphFlag + 1));
      } else if (graphFlag >= graphComponentArray.length - 1) {
        setGraphFlag(0);
        setPatientSeries(getCurrentDonutData(0));
      }
    });
  };

  useEffect(() => {
    if (graphFlag === 1) {
      setGraphFlag(1);
      setPatientSeries(getCurrentDonutData(1));
    }
  }, [graphFlag]);

  return (
    <>
      <Box sx={{ padding: "0px 16px", marginY: 2 }}>
        <Text sx={{ marginBottom: "10px" }} variant="h2">
          Patients
        </Text>
      </Box>
      <Box sx={{ background: "#fff", padding: "16px" }}>
        <Grid container spacing={1} sx={{ minHeight: "290px" }}>
          <Grid item xl={4} xxl={4} lg={4} md={5} sm={12} xs={12}>
            <Box
              sx={{
                height: "100%",
                borderRadius: "0px",
                borderRight: "1px solid #ccc",
                borderColor: {
                  sm: "transparent",
                  md: "#ccc",
                  lg: "#ccc",
                  xxl: "#ccc",
                },
                padding: "5px 16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  {graphFlag === 1 ? (
                    <Text variant="h2">ESRD Patient</Text>
                  ) : (
                    <Text variant="h2">
                      {graphComponentArray[graphFlag].title}
                    </Text>
                  )}
                </Box>
                <Flex justifyContent="end">
                  <Box
                    onClick={handleArrowUpClick}
                    sx={{
                      cursor: "pointer",
                      padding: "7px",
                      border: "1px solid #000",
                      background: "#ccc",
                      borderRadius: "6px",
                      display: "flex",
                      mr: "4px",
                      justifyContent: "space- between",
                      alignItems: "start",
                    }}
                  >
                    <img
                      src={arrowup}
                      style={{ width: "15px", height: "15px" }}
                      alt="arrowUp"
                    />
                  </Box>
                  <Box
                    onClick={handleArrowDownClick}
                    sx={{
                      cursor: "pointer",
                      padding: "7px",
                      border: "1px solid #000",
                      background: "#ccc",
                      borderRadius: "6px",
                      display: "flex",
                      justifyContent: "space- between",
                      alignItems: "start",
                    }}
                  >
                    <img
                      src={arrowDown}
                      style={{ width: "15px", height: "15px" }}
                      alt="arrowDown"
                    />
                  </Box>
                </Flex>
              </Box>
              <div id="chart">
                <ReactApexChart
                  options={patientSeriesOptions}
                  series={patientSeries}
                  type="donut"
                  height={
                    viewWidth >= 1024 &&
                    isChatOpen === true &&
                    viewWidth <= 1499
                      ? 220
                      : viewWidth >= 1500 && isChatOpen === true
                      ? 350
                      : ""
                  }
                />
                <Box sx={{ mb: 2 }}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ mt: "10px" }}
                      >
                        <Box
                          sx={{
                            background: patientSeriesOptions.fill.colors[0],
                            marginRight: "10px",
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                          }}
                        ></Box>
                        <Text variant="h3">
                          {patientSeries[0] + " from Last Year"}
                        </Text>
                      </Box>
                      <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                        <Box
                          sx={{
                            background: patientSeriesOptions.fill.colors[1],
                            marginRight: "10px",
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                          }}
                        ></Box>
                        <Text variant="h3">
                          {patientSeries[1] + " from Last Month"}
                        </Text>
                      </Box>
                    </Box>
                    <Box>
                      <Text variant="h1">125</Text>
                    </Box>
                  </Flex>
                </Box>
              </div>
            </Box>
          </Grid>
          <Grid item xl={8} xxl={8} lg={8} md={7} sm={12} xs={12}>
            <Box
              sx={{ height: "100%" }}
              id="financial-esrd-graph"
              ref={targetRef}
            >
              <Grid
                container
                sx={{ height: "100%", width: "100%", padding: "5px 16px" }}
              >
                <Grid item lg={9} md={10} sm={10}>
                  <Text variant="h2" sx={{ fontWeight: "bold" }}>
                    Overview
                  </Text>
                </Grid>
                {/* <SelectField
                  defaultValue={financialChartOptions[0]}
                  cols={3}
                  sm={2}
                  md={2}
                  options={financialChartOptions}
                /> */}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ mt: 3, width: "100%" }}>
                    <div id="patient-chart">
                      {getSeriesData(0)?.length > 0 ? (
                        <ReactApexChart
                          options={
                            graphComponentArray[graphFlag]?.barChartOption
                          }
                          series={[
                            {
                              name: graphComponentArray[graphFlag].overview[0]
                                ?.name,
                              data:
                                graphFlag === 1
                                  ? graphComponentArray[graphFlag].overview[0]
                                      ?.data
                                  : getSeriesData(0),
                            },
                            {
                              name: graphComponentArray[graphFlag].overview[1]
                                ?.name,
                              data:
                                graphFlag === 1
                                  ? graphComponentArray[graphFlag].overview[1]
                                      ?.data
                                  : getSeriesData(1),
                            },
                          ]}
                          type="bar"
                          height={350}
                        />
                      ) : (
                        <p>No data available for this chart.</p>
                      )}
                    </div>
                    <div id="html-dist"></div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PatiendGraph;

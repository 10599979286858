import { Box, Grid } from "@mui/material";
import CardsContainer from "Layouts/CardsContainer";
import OverviewModal from "components/common/Modal/OverviewModal";
import SendMessageModal from "components/common/Modal/SendMessageModals";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountID } from "redux/features/Auth/loginSlice";
import { getUsersList } from "redux/features/MyTeam/myTeamSlice";
import { getGroupsOptions } from "redux/features/common/optionSlice";
import { alignItemsCenter, flexColumn } from "styles";
import UpperHeader from "./components/UpperHeader";
import ChatBotComponent from "pages/financials/components/ChatBotComponent/ChatBotComponent";

const Dashboard = () => {
  const [viewWidth, setViewWidth] = useState(window.innerWidth);
  const [viewHeight, setViewHeight] = useState(window.innerHeight);

  const { isChatOpen } = useSelector((state) => state.globalStates);

  const dispatch = useDispatch();
  const [isVerticalView, setIsVerticalView] = useState(false);
  const [healthFilter, setHealthFilter] = useState('all patients')
  const [open, setOpen] = useState(false);
  const [overviewOpen, setOverviewOpen] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [date, setDate] = useState(
    localStorage.getItem("scheduledPatientDate") ??
    moment().format("YYYY-MM-DD")
  );
  const { account_id } = useSelector((state) => state.auth);

  const containerRef = useRef(null);

  useEffect(() => {
    dispatch(getAccountID());
    dispatch(getGroupsOptions());
  }, [dispatch]);

  useEffect(() => {
    account_id && dispatch(getUsersList(account_id));
  }, [dispatch, account_id]);

  let mainDivStyle = {
    marginTop: "0px",
    ...flexColumn,
    ...alignItemsCenter,
    minHeight: "auto",
    borderRadius: "24px",
    background: "#FFFFFFBF",
    padding: "20px",
  };
  
  useEffect(() => {
    const handleResize = () => {
      setViewWidth(window.innerWidth);
      setViewHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const height = window.innerHeight - containerRef.current.offsetTop;
      containerRef.current.style.height = `${height}px`;
    }
  }, [isVerticalView]);

  useEffect(() => {
    date && localStorage.setItem("scheduledPatientDate", date);
  }, [date]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={isChatOpen ? 9 : 12}
        xl={isChatOpen ? 9 : 12}
        xxl={isChatOpen ? 9 : 12}
        md={isChatOpen ? 8 : 12}
        sm={12}
        xs={12}
      >
        <UpperHeader
          setHealthFilter={setHealthFilter}
          isVerticalView={isVerticalView}
          setIsVerticalView={setIsVerticalView}
          date={date}
          setDate={setDate}
          setOpen={setOpen}
          setOverviewOpen={setOverviewOpen}
          setSearchData={setSearchData}
        />
        <Box sx={mainDivStyle} className="settings-main-wrapper">
          <SendMessageModal open={open} setOpen={setOpen} />
          <OverviewModal
            date={date}
            open={overviewOpen}
            setOpen={setOverviewOpen}
          />
          <CardsContainer
            healthFilter={healthFilter}
            isVerticalView={isVerticalView}
            setIsVerticalView={setIsVerticalView}
            date={date}
            searchData={searchData}
            isChatOpen={isChatOpen}
          />
        </Box>
      </Grid>
      {isChatOpen && <ChatBotComponent isChatOpen={true} />}
    </Grid>
  );
};

export default Dashboard;

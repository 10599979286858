import { Box, Grid, IconButton } from "@mui/material";
import Flex from "Layouts/Flex/Flex";
import Button from "components/common/Button/Button";
import React from "react";
import {
  ContainerStying,
  iconButtonStyle,
  menuImgStyle,
} from "styles/Financials/Financials";
import MenuIcon from "../../../../../assets/svg/bold/Hamburgericon.svg";
import downloadIcon from "../../../../../assets/svg/colored/download.svg";
import TrendCharts from "../TrendCharts";
import TrendsAreaChart from "../TrendsAreaChart";
import MenuComponent from "pages/financials/components/MenuComponent/MenuComponent";
import ChatBotComponent from "pages/financials/components/ChatBotComponent/ChatBotComponent";
import { useSelector } from "react-redux";

const SummaryPage = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const { isChatOpen } = useSelector((state) => state.globalStates);
  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={isChatOpen ? 9 : 12}
        xxl={isChatOpen ? 9 : 12}
        md={isChatOpen ? 8 : 12}
        sm={12}
      >
        <Box sx={ContainerStying}>
          <Flex justifyContent="space-between" marginBottom="10px">
            <IconButton
              aria-describedby={"simple-popper"}
              sx={iconButtonStyle}
              onClick={handleClick("bottom-end")}
            >
              <img style={menuImgStyle} src={MenuIcon} alt="menuIcon" />
            </IconButton>
            <MenuComponent
              setOpen={setOpen}
              open={open}
              setPlacement={setPlacement}
              placement={placement}
              anchorEl={anchorEl}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={downloadIcon} alt="" />
              <Button
                color="#246EFD"
                btnColor="#246EFD"
                sx={{ height: "20px" }}
                text={"Download Reports"}
                backgroundColor="none"
                boxShadow={"none"}
                fontSize={"11px"}
              />
            </Box>
          </Flex>
          <Box>
            {Array(1)
              .fill("")
              ?.map((s, i) => (
                <TrendCharts isChatOpen />
              ))}
          </Box>
          <Box>
            {Array(1)
              .fill("")
              ?.map((s, i) => (
                <TrendsAreaChart />
              ))}
          </Box>
        </Box>
      </Grid>
      {isChatOpen && <ChatBotComponent isChatOpen={true} />}
    </Grid>
  );
};

export default SummaryPage;

export let smartChecklistWrapper = {
  backgroundColor: "#FFFFFF",
  height: "100%",
  borderRadius: "24px",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
};
export let smartChecklistTitle = (viewWidth) => {
  return {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: "#2D3748",
    fontSize: "14px",
    fontWeight: "700",
    borderTopLeftRadius: "24px",
    borderTopRightRadius: "24px",
    borderBottom: "2px solid #E1E1E1",
    height: "60px",
    padding:
      viewWidth >= 600 && viewWidth <= 1024
        ? "10px 20px 35px 20px"
        : "10px 20px",
  };
};

export let smartChecklistDetail = (viewWidth) => {
  return {
    backgroundColor: "#FFFFFF",
    color: "#2D3748",
    borderBottomLeftRadius: "24px",
    borderBottomRightRadius: "24px",
    height: "84%",
    padding:
      viewWidth >= 600 && viewWidth <= 1024
        ? "10px 20px 35px 20px"
        : "10px 20px",
  };
};
export let diagnosticChip = {
  display: "flex",
  alignItems: "center",
  color: "#fff",
  minWidth: "35px",
  height: "22px",
  borderRadius: "30px",
  backgroundColor: "#7119FF",
  padding: "2px 5px",
  cursor: "pointer",
};

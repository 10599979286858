export const LiveTranscriptWrapper = {
  "& .MuiBox-root":{
    border: "1px solid #E1E1E1",
    backgroundColor: "#F8F4FF",
    borderRadius: "12px",
    height: "150px",
    overflowY: "auto",
    marginTop: "20px",
    padding: "10px",
  }
};


import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";
import moment from "moment";
import ChatBotComponent from "pages/financials/components/ChatBotComponent/ChatBotComponent";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearMedicineSearchData,
  getSearchMedicineList,
} from "redux/features/Medications/MedicinesSlice";
import { getScheduledAppointmentsForSearch } from "redux/features/Patients/ScheduledAppointmentSlice";
import { alignItemsCenter, flexColumnCenterCenter, flexRow } from "styles";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import { debounce } from "utils/debouncer";
import { toCapitalize } from "utils/toCapitalize";
import lightBulb from "../../../assets/svg/colored/lightbulb.svg";
import person from "../../../assets/svg/colored/person.svg";
import pill from "../../../assets/svg/colored/pill.svg";
import trendUp from "../../../assets/svg/colored/trend_up.svg";
import Search from "../../../assets/svg/light/search.svg";
import InputField from "../FormComponents/InputField";
import SkeletonWrapper from "../Skeleton/SkeletonWrapper";
import Text from "../Typography/Text";
import CustomModal from "./CustomModal";

const MainSearchModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const abortController = useRef(new AbortController());

  const [queryData, setQueryData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [patientsData, setPatientsData] = useState([]);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [nameForTerms, setNameForTerms] = useState("");

  const { account_id } = useSelector((state) => state.auth);

  const { medicinesSearchData, medicinesSearchIsLoading } = useSelector(
    (state) => state.medicines
  );

  const { resultsForSearch } = useSelector((state) => state.patients);

  function boxesSxGenerator(height) {
    return {
      ...flexRow,
      height: height,
      padding: "16px 6px 6px 6px",
      background: "white",
      borderRadius: "10px",
      margin: "0px 4px 8px 5px",
    };
  }

  const getTermsAndMedsData = debounce((value) => {
    if (value?.length > 0) {
      setNameForTerms(value);
      setIsRequestPending(true);
      abortController.current.abort(); // Abort the previous request if any
      const newAbortController = new AbortController();
      abortController.current = newAbortController; // Update the abortController for the next request

      fetch(
        `https://kwslnsn5ztguzqsrgwplc2dlzi0usgyq.lambda-url.us-west-2.on.aws/?term=${value}`,
        {
          method: "GET",
          referrerPolicy: "no-referrer",
          signal: newAbortController.signal,
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setQueryData(data?.data?.message ?? data?.data);
        })
        .catch((error) => {
          if (error.name !== "AbortError") {
            toast.error(`Failed to fetch ${value}`);
          }
        })
        .finally(() => {
          setIsRequestPending(false);
        });
    }
  }, 400);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Make the API call when the user has stopped typing for 400ms
      searchQuery && dispatch(clearMedicineSearchData());
      searchQuery && getTermsAndMedsData(searchQuery);
      searchQuery && dispatch(getSearchMedicineList(searchQuery));
      filterPatientData(searchQuery);
    }, 500);

    return () => {
      abortController.current.abort(); // Abort the ongoing request when the component unmounts
      clearTimeout(timeoutId); // Cleanup the previous timeout
    };
  }, [searchQuery]);

  useEffect(() => {
    if (open) {
      if (resultsForSearch.length <= 0) {
        account_id != null &&
          dispatch(
            getScheduledAppointmentsForSearch(
              null,
              1,
              moment().format("YYYY-MM-DD"),
              1,
              account_id
            )
          );
      }
    }
  }, [open]);

  function filterPatientData(searchQuery) {
    if (searchQuery !== "") {
      setPatientsData(
        resultsForSearch.filter((data) => {
          return (
            data.patient.first_name
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            data.patient.last_name
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            (
              data.patient.first_name.toLowerCase() +
              " " +
              data.patient.last_name.toLowerCase()
            ).includes(searchQuery.toLowerCase())
          );
        })
      );
    }
  }

  return (
    <CustomModal
      setOpen={setOpen}
      open={open}
      title={""}
      noHeader={true}
      width="auto"
      maxWidth={"md"}
      fullWidth={true}
      fade={true}
      sxDialog={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
          mt: "-40px",
          ml: "225px",
          "& .MuiDialogContent-root::-webkit-scrollbar": {
            display: "none !important",
          },
        },
        backdropFilter: "blur(3px)",
        overFlowX: "none",
      }}
      sxDialogContent={{
        background: "transparent",
      }}
      sxPaperProps={{
        mt: "50px",
        boxShadow: "none",
        verticalAlign: "top",
        backgroundColor: "transparent",
      }}
    >
      <Box sx={flexColumnCenterCenter}>
        <InputField
          name="navbar-dropdown"
          cols={12}
          positionLeft={true}
          borderRadius="12px"
          sx={{ width: "300px", marginBottom: "10px" }}
          iconDetails={{
            allowIcon: true,
            iconName: Search,
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search patients, terms, medications, and reports"
        />
        <Box
          sx={{
            ...flexRow,
            width: "100%",
            background: "#ededef",
            borderRadius: "10px",
            padding: "22px 18px 14px 18px",
          }}
        >
          <Box className="leftBox" sx={{ width: "60%", mr: "5px" }}>
            <Box
              className="leftBox"
              sx={{ ...boxesSxGenerator("170px"), width: "98%" }}
            >
              <Box sx={{ margin: "3px 10px 0px 0px" }}>
                <img src={pill} />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box>Medications</Box>
                <Box
                  sx={{
                    height: "120px",
                    overflowY: "scroll",
                    overflowX: "none",
                    width: "100%",
                  }}
                >
                  <SkeletonWrapper
                    condition={!medicinesSearchIsLoading}
                    id={"patient-detail-header-name"}
                    multipleCount={6}
                    props={[
                      SkeletonPropsStyleGenerator("130px", "25px", "350px"),
                      SkeletonPropsStyleGenerator("400px", "25px", "350px"),
                      SkeletonPropsStyleGenerator("130px", "25px", "350px"),
                      SkeletonPropsStyleGenerator("400px", "25px", "350px"),
                      SkeletonPropsStyleGenerator("130px", "25px", "350px"),
                      SkeletonPropsStyleGenerator("400px", "25px", "350px"),
                    ]}
                  >
                    {medicinesSearchData?.map((med, index) => (
                      <Box sx={{ lineHeight: "13px", marginTop: "15px" }}>
                        <Box sx={{ ...flexRow, ...alignItemsCenter }}>
                          <Text marginY={0} variant="h3" color={"darkGrey"}>
                            {med?.label}
                          </Text>
                          <ArrowForwardIcon style={{ fontSize: "20" }} />
                        </Box>
                        <span style={{ fontSize: "13px", color: "#555555" }}>
                          Reduces serum phosphate levels by binding dietary
                          phosphate.
                        </span>
                      </Box>
                    ))}
                  </SkeletonWrapper>
                </Box>
              </Box>
            </Box>
            <Box
              className="leftBox"
              sx={{ ...boxesSxGenerator("120px"), width: "98%" }}
            >
              <Box sx={{ margin: "3px 10px 0px 0px" }}>
                <img src={lightBulb} />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ mt: "2px" }}>Terms</Box>
                <Box
                  sx={{
                    overflowY: "scroll",
                    overflowX: "none",
                    width: "100%",
                  }}
                >
                  <SkeletonWrapper
                    condition={!isRequestPending}
                    id={"patient-detail-header-name"}
                    multipleCount={2}
                    props={[
                      SkeletonPropsStyleGenerator("130px", "25px", "350px"),
                      SkeletonPropsStyleGenerator("400px", "25px", "350px"),
                    ]}
                  >
                    {!queryData.startsWith("No matching") ? (
                      <Box
                        sx={{
                          height: "60px",
                          lineHeight: "13px",
                          marginTop: "15px",
                        }}
                      >
                        <Box sx={{ ...flexRow, ...alignItemsCenter }}>
                          <Text marginY={0} variant="h3" color={"darkGrey"}>
                            {toCapitalize(nameForTerms)}
                          </Text>
                          {nameForTerms && (
                            <ArrowForwardIcon style={{ fontSize: "20" }} />
                          )}
                        </Box>
                        <span style={{ fontSize: "13px", color: "#555555" }}>
                          <div
                            dangerouslySetInnerHTML={{ __html: queryData }}
                            style={{ marginTop: "10px" }}
                          />
                        </span>
                      </Box>
                    ) : (
                      " "
                    )}
                  </SkeletonWrapper>
                </Box>
              </Box>
            </Box>
            <Box
              className="leftBox"
              sx={{ ...boxesSxGenerator("120px"), width: "98%" }}
            >
              <Box sx={{ margin: "3px 10px 0px 0px" }}>
                <img src={person} />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ mt: "2px" }}>Patients</Box>
                <Box
                  sx={{
                    height: "70px",
                    overflowY: "scroll",
                    overflowX: "none",
                    width: "100%",
                  }}
                >
                  <SkeletonWrapper
                    condition={!medicinesSearchIsLoading}
                    id={"patient-detail-header-name"}
                    multipleCount={6}
                    props={[
                      SkeletonPropsStyleGenerator("130px", "25px", "350px"),
                      SkeletonPropsStyleGenerator("400px", "25px", "350px"),
                      SkeletonPropsStyleGenerator("130px", "25px", "350px"),
                      SkeletonPropsStyleGenerator("400px", "25px", "350px"),
                      SkeletonPropsStyleGenerator("130px", "25px", "350px"),
                      SkeletonPropsStyleGenerator("400px", "25px", "350px"),
                    ]}
                  >
                    {patientsData?.map((patient, index) => (
                      <Box sx={{ lineHeight: "13px", marginTop: "15px" }}>
                        <Box sx={{ ...flexRow, ...alignItemsCenter }}>
                          <Text marginY={0} variant="h3" color={"darkGrey"}>
                            {patient?.patient?.first_name +
                              " " +
                              patient?.patient?.last_name}
                          </Text>
                          <ArrowForwardIcon style={{ fontSize: "20" }} />
                        </Box>
                        <span style={{ fontSize: "13px", color: "#555555" }}>
                          {patient?.ckd_info[0]?.ckd_code} | Last seen{" "}
                          {moment(patient?.last_visit).format("YYYY-MM-DD")}
                        </span>
                      </Box>
                    ))}
                  </SkeletonWrapper>
                </Box>
              </Box>
            </Box>
            <Box className="leftBox" sx={boxesSxGenerator("120px")}>
              <Box sx={{ margin: "3px 10px 0px 0px" }}>
                <img src={trendUp} />
              </Box>
              <Box>
                <Box>Reports</Box>
                <Box>
                  <Box sx={{ lineHeight: "13px", marginTop: "15px" }}>
                    <Box sx={{ ...flexRow, ...alignItemsCenter }}>
                      <Text marginY={0} variant="h3" color={"darkGrey"}></Text>
                    </Box>
                    <span style={{ fontSize: "13px", color: "#555555" }}></span>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="rightBox" sx={{ width: "40%", height: "560px" }}>
            <Box>
              <ChatBotComponent isChatOpen={true} forSearch={true} />
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default MainSearchModal;

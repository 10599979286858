import { Box } from "@mui/material";
import React from "react";
import Text from "../../Typography/Text";
import HorizontalTabs from "../../Tabs/HorizontalTabs";
import Urgent from "./Urgent";
import { urgentArray } from "assets/static";
import { useSelector } from "react-redux";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";

const AppointmentAlertModal = ({ setAlert }) => {
  const { results, isLoading } = useSelector((state) => state.patients);
  const { isAccLoading } = useSelector((state) => state.auth);
  const childrenArray = [
    {
      title: "Urgent",
      tab: (
        <Urgent
          setAlert={setAlert}
          data={results?.filter(
            ({ health_status }) => health_status === "urgent"
          )}
        />
      ),
      right: true,
      isNumber: true,
      number: results?.filter(({ health_status }) => health_status === "urgent")
        ?.length,
    },
    {
      title: "Important",
      tab: (
        <Urgent
          setAlert={setAlert}
          data={results?.filter(
            ({ health_status }) => health_status === "important"
          )}
        />
      ),
      right: true,
      isNumber: true,
      numBackground: "#246EFD",
      number: results?.filter(
        ({ health_status }) => health_status === "important"
      )?.length,
    },
    {
      title: "Not-urgent",
      tab: (
        <Urgent
          setAlert={setAlert}
          data={results?.filter(
            ({ health_status }) => health_status === "normal"
          )}
        />
      ),
      right: true,
      isNumber: true,
      numBackground: "#59D281",
      number: results?.filter(({ health_status }) => health_status === "normal")
        ?.length,
    },
    {
      title: "All",
      tab: <Urgent setAlert={setAlert} data={results} />,
      right: true,
      isNumber: true,
      numBackground: "#4645BF",
      number: results?.length,
    },
  ];

  console.log("isAccLoading", isAccLoading || isLoading);
  return (
    <Box>
      <SkeletonWrapper
        condition={!(isAccLoading || isLoading)}
        multipleCount={9}
        props={[SkeletonPropsStyleGenerator("100%", "60px", "150px")]}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "10px 0px 20px 0px",
          }}
        >
          <Text
            variant="h3"
            color={"redLight"}
            sx={{ maxWidth: "550px", textAlign: "center" }}
          >
            There are "{results?.length}" labs recieved yesterday, <br />
            out of those {childrenArray[0]?.number} are Urgent,{" "}
            {childrenArray[1]?.number} are important, {childrenArray[2]?.number}{" "}
            not urgent
          </Text>
        </Box>
        <Box
          sx={{
            borderRadius: "24px",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          <HorizontalTabs childrenArray={childrenArray} defaultValue={0} />
        </Box>
      </SkeletonWrapper>
    </Box>
  );
};

export default AppointmentAlertModal;

import { Box } from "@mui/material";
import TabPill from "components/common/Tabs/TabPill";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLabsResults } from "redux/features/Patients/ViewLabResultsSlice";
import { getNotesHistory } from "redux/features/Patients/ViewNotesHistorySlice";
import { useNotesHistoryModalStyles } from "styles/Modals/ViewNotesHistoryModalStyle";
import PastVisitModalComponent from "./PastVisitModalComponent";

const PastVisitModal = () => {
  const dispatch = useDispatch();
  const classes = useNotesHistoryModalStyles();
  let { patientId, appointmentId } = useParams();
  const [mergedData, setMergedData] = useState([]);
  const { results: notesData, isNotesHistoryLoading } = useSelector(
    (state) => state.historyNotes
  );

  const { vitalsData } = useSelector((state) => state.vitals);

  useEffect(() => {
    patientId && dispatch(getNotesHistory(patientId));
  }, [dispatch, patientId]);

  const { labResults, isLabResultsLoading } = useSelector(
    (state) => state.resultsLabs
  );

  useEffect(() => {
    patientId && !labResults?.length && dispatch(getLabsResults(patientId));
  }, [dispatch, patientId]);

  function mergeArrays() {
    const mergedArray = [];

    const allDates = new Set([
      ...labResults.map(({ date_time }) => date_time),
      ...vitalsData.map(({ date_time }) => date_time),
      ...notesData
        ?.filter((v) => v?.encounter_id == appointmentId)
        ?.map(({ created_on }) => created_on),
    ]);

    allDates.forEach((date) => {
      const obj = { date, data: {} };

      // Get data from arr1
      const arr1Data = labResults.find((item) => item.date_time === date);
      obj.data.labs = arr1Data ? arr1Data : null;

      // Get data from arr2
      const arr2Data = vitalsData.find((item) => item.date_time === date);
      obj.data.vitals = arr2Data ? arr2Data : null;

      // Get data from arr3
      const arr3Data = notesData
        ?.filter((v) => v?.encounter_id == appointmentId)
        ?.find((item) => item.created_on === date);
      obj.data.notes = arr3Data ? arr3Data : null;

      mergedArray.push(obj);
    });

    return mergedArray;
  }

  useEffect(() => {
    setMergedData(
      mergeArrays()
        ?.sort((a, b) => new Date(b.date) - new Date(a.date))
        ?.map(({ date, data }) => ({
          title: "Visit - " + moment(date).format("DD/MM/YYYY"),
          tab: <PastVisitModalComponent data={data} />,
        }))
    );
  }, [vitalsData, labResults, notesData, appointmentId]);

  return (
    <Box>
      <TabPill
        childrenArray={mergedData}
        defaultValue={0}
        backgroundColor="#ffffff"
        tabMinWidth="250px"
        indicatorBackgroundColor={false ? "#1344F1" : "#FC5A5A"}
        tabSelectedColor={false ? "#1344F1" : "#FC5A5A"}
        paddingBtn="0px"
      />
    </Box>
  );
};

export default PastVisitModal;

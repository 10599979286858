import { Autocomplete } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import Text from "../Typography/Text";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    "& .MuiInputBase-root": {
      border: (theme) => theme.border,
      borderRadius: (theme) => theme.borderRadius,
      background: (theme) => theme.backgroundColor,
      color: (theme) => theme.textColor,
      height: (theme) => theme.height,
      width: (theme) => theme.width,
    },
  },
}));

const SelectField = ({
  name,
  label,
  required = false,
  options = [],
  loading = false,
  helperText = "",
  cols = 6,
  fontWeight = 500,
  formik,
  onChange,
  onSearch,
  onBlur,
  isMulti = false,
  color = "",
  defaultValue,
  placeholder = "",
  setIconLabelModal,
  iconLabel = false,
  tooltipTitle = "",
  borderRadius = "10px",
  border = "1px solid #E1E1E1",
  extendedSearchOptions = [],
  showMoreInLabel = { show: false, value: "" },
  sx = {},
  sxTextField = {},
  backgroundColor,
  textColor,
  height,
  width,
  size = "",
  variant = "outlined",
  ...rest
}) => {

  const classes = useStyles({
    border,
    borderRadius,
    backgroundColor,
    textColor,
    height,
    width,
  });
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(
    formik?.values?.[name] || defaultValue || (isMulti ? [] : null)
  );
  const showError = formik
    ? formik?.touched?.[name] && Boolean(formik?.errors?.[name])
    : false;
  const errorText = formik
    ? showError
      ? formik?.errors?.[name]
      : helperText
    : helperText;
  const [fieldError, setFieldError] = useState(
    formik?.errors[name] || helperText
  );
  const [fieldTouched, setFieldTouched] = useState(
    formik?.touched[name] || showError
  );

  useEffect(() => {
    setFieldError(formik?.errors[name] || helperText);
    setFieldTouched(formik?.touched[name] || showError);
  }, [formik, showError]);

  const handleChange = (event, value) => {
    if (formik) {
      if (isMulti) {
        setSelectedOptions(value);
        formik?.setFieldValue(
          name,
          value?.map((option) => option?.value)
        );
      } else {
        setSelectedOptions(value);
        formik?.setFieldValue(name, value?.value || null);
      }
    }
    if (onChange) {
      setSelectedOptions(value);
      onChange(event, value);
    }
  };
  return (
    <Grid item xs={12} md={cols}>
      {label && (
        <Text
          variant="bodyS"
          marginTop="0px"
          marginBottom="10px"
          fontWeight={fontWeight}
          component="label"
        >
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <Autocomplete
        className={classes.autoComplete}
        openOnFocus
        multiple={isMulti}
        id={name}
        size={size}
        name={name}
        defaultValue={defaultValue}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={inputValue}
        InputProps={{
          style: { fontWeight: "900" },
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          // Check if the change is due to user input, not option selection
          if (event?.type == "change") {
            onSearch && onSearch(newInputValue);
          }
          if (rest.onInputChange) {
            rest.onInputChange(event, newInputValue);
          }
        }}
        options={options?.length > 0 ? options : []}
        loading={loading}
        filterOptions={(options, { inputValue }) =>
          options?.filter((option) => {
            return (
              option.label?.toLowerCase().includes(inputValue.toLowerCase()) ||
              (extendedSearchOptions.length > 0 &&
                extendedSearchOptions.some((exOptions) =>
                  option[exOptions]
                    ?.toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                ))
            );
          })
        }
        getOptionLabel={(option) => option.label || ""}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(e, v) => handleChange(e, v)}
        renderOption={(props, option, state) => (
          <li {...props}>
            {showMoreInLabel?.show ? (
              <span style={{ fontSize: "13px" }}>
                <b>{option?.label}</b>
                {`${showMoreInLabel?.show
                  ? ` - ${option[showMoreInLabel?.value]}`
                  : ""
                  }`}
              </span>
            ) : (
              `${option?.label}`
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={sxTextField}
            variant={variant}
            error={showError}
            helperText={errorText}
            placeholder={placeholder}
          />
        )}
        {...rest}
      />
    </Grid>
  );
};
export default SelectField;

import { Box, Grid } from "@mui/material";
import Flex from "Layouts/Flex/Flex";
import {
  TrendsChartData,
  encounterRevenueChartData,
  roicDonutChartContractData,
  roicDonutChartSalaryIncrementtData,
  totalNewPatientDonutChart,
  totalPatientDonutChart,
} from "assets/static";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useLocation } from "react-router-dom";
import RevenueTable from "./Revenue/RevenueTable";
import ContractTable from "./Revenue/ContractTable";

const TrendCharts = ({ isChatOpen }) => {
  const viewWidth = window.innerWidth;
  const [chartData, setChartData] = useState(TrendsChartData);
  const [currentYearValue, setCurrentYearValue] = useState(283090);
  const [oldValue, setOldValue] = useState(2011834);
  const [totalPatientData, setTotalPatientData] = useState(
    totalPatientDonutChart
  );
  const [totalNewPatientData] = useState(totalNewPatientDonutChart);
  const [activePatients, setActivePatients] = useState(1776);
  const [totalPatients, setTotalPatients] = useState(2345);
  const percentageChange = ((currentYearValue - oldValue) / oldValue) * 100;
  const percentage = Math.round((activePatients / totalPatients) * 100);
  const totalPercentage = Math.round((activePatients / totalPatients) * 100);
  const [series, setSeries] = useState([
    percentage,
    percentageChange.toFixed(2),
  ]);
  useEffect(() => {
    const newPercentage = Math.round((activePatients / totalPatients) * 100);
    setSeries([newPercentage, 100 - newPercentage]);
  }, [activePatients, totalPatients]);

  const donutOptions = chartData[1].donutOptions;
  const totalPatientDataOptions = totalPatientDonutChart[0].donutOptionsOne;
  const totalPatientDataSeries = totalPatientDonutChart[0].donutSeriesOne;
  const totalNewPatientDataOptions =
    totalNewPatientDonutChart[0].donutOptionsOne;
  const totaNewlPatientDataSeries = totalNewPatientDonutChart[0].donutSeriesOne;
  const encounterRevenueDonutOptions =
    encounterRevenueChartData[0].donutOptionsTwo;
  const encounteRevenueSeries = encounterRevenueChartData[0].donutSeriesTwo;

  const location = useLocation();
  const offset = 100;

  useEffect(() => {
    if (location.hash) {
      const patientSeen = document.querySelector("#revenue-patient-seen-graph");
      const newPatient = document.querySelector("#revenue-new-patients-graph");
      if (patientSeen) {
        const top = patientSeen.getBoundingClientRect().top;
        const scrollToPosition = top + window.pageYOffset - offset;
        window.scrollTo({
          top: scrollToPosition,
          behavior: "smooth",
        });
      }
      if (newPatient) {
        const top = patientSeen.getBoundingClientRect().top;
        const scrollToPosition = top + window.pageYOffset + 600;
        window.scrollTo({
          top: scrollToPosition,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  const roicDonutChartSalaryIncrementtSeries =
    roicDonutChartSalaryIncrementtData[0]?.donutSeriesTwo;
  const roicDonutChartContractSeries =
    roicDonutChartContractData[0]?.donutSeriesOne;

  return (
    <Grid container spacing={2} sx={{ mt: 1, mb: 2, height: "100%" }}>
      <Grid item lg={9} md={8} sm={12} xs={12}>
        <Box sx={{ background: "#fff", borderRadius: "24px", height: "100%" }}>
          <Box sx={{ borderBottom: "1px solid #ccc" }}>
            <Box sx={{ padding: "24px" }}>
              <Flex justifyContent="space-between">
                <Text variant="h2">{TrendsChartData[0]?.title}</Text>
                <Text variant="h2">{TrendsChartData[0]?.subTitle}</Text>
              </Flex>
            </Box>
          </Box>
          <Box sx={{ padding: "24px" }}>
            <div id="chart">
              <ReactApexChart
                options={chartData[0].options}
                series={chartData[0].series}
                type="line"
                height={350}
              />
              <div id="html-dist"></div>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <Box
          sx={{
            background: "#fff",
            borderRadius: "24px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ padding: "24px" }}>
            <Text variant="h2">{TrendsChartData[1]?.title}</Text>
          </Box>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderBottomLeftRadius: "24px",
              borderBottomRightRadius: "24px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              flex: 1,
            }}
          >
            <Box
              sx={{
                padding: "16px",
              }}
            >
              <Box>
                <Box className="chart-wrap">
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                        }}
                      >
                        Revenue
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                        }}
                      >
                        $4,018,133.17
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                        }}
                      >
                        Expense
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                        }}
                      >
                        $3,609,658.73
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                        }}
                      >
                        Net Profit
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          color: "#3475DC",
                        }}
                      >
                        <span style={{ color: "rgb(54 173 112)" }}>
                          {/* {percentageChange.toFixed(2)}%` */}
                          $408,474.44
                        </span>
                      </Text>
                    </Flex>
                  </Box>
                  <Box id="chart">
                    <ReactApexChart
                      options={donutOptions}
                      series={series}
                      type="donut"
                      height={350}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <Box>
          <RevenueTable />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <ContractTable />
      </Grid>
      <Grid item lg={9} md={8} sm={12} xs={12}>
        <Box sx={{ background: "#fff", borderRadius: "24px", height: "100%" }}>
          <Box sx={{ borderBottom: "1px solid #ccc" }}>
            <Box sx={{ padding: "24px" }}>
              <Flex justifyContent="space-between">
                <Text variant="h2">{TrendsChartData[2]?.title}</Text>
                <Text variant="h2">{TrendsChartData[2]?.subTitle}</Text>
              </Flex>
            </Box>
          </Box>
          <Box sx={{ padding: "24px" }} id="revenue-patient-seen-graph">
            <ReactApexChart
              options={chartData[2].revenueOptions}
              series={chartData[2].revenueOptionSeries}
              type="area"
              height={350}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <Box
          sx={{
            background: "#fff",
            borderRadius: "24px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ padding: "24px" }}>
            <Text variant="h2">{TrendsChartData[1]?.title}</Text>
          </Box>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderBottomLeftRadius: "24px",
              borderBottomRightRadius: "24px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              flex: 1,
            }}
          >
            <Box
              sx={{
                padding: "16px",
              }}
            >
              <Box>
                <Box className="chart-wrap">
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                        }}
                      >
                        Current Year Total Encounters
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                        }}
                      >
                        21,970
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                        }}
                      >
                        Previous year Encounters
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                        }}
                      >
                        20,432
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                        }}
                      >
                        The growth is around
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          color: "#3475DC",
                        }}
                      >
                        <span style={{ color: "rgb(54 173 112)" }}>7%</span>
                      </Text>
                    </Flex>
                  </Box>
                  <Box id="chart">
                    <ReactApexChart
                      options={encounterRevenueDonutOptions}
                      series={encounteRevenueSeries}
                      type="donut"
                      height={350}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      {/*  */}
      <Grid item lg={9} md={8} sm={12} xs={12}>
        <Box sx={{ background: "#fff", borderRadius: "24px", height: "100%" }}>
          <Box sx={{ borderBottom: "1px solid #ccc" }}>
            <Box sx={{ padding: "24px" }}>
              <Flex justifyContent="space-between">
                <Text variant="h2">{TrendsChartData[4]?.title}</Text>
                <Text variant="h2">{TrendsChartData[4]?.subTitle}</Text>
              </Flex>
            </Box>
          </Box>
          <Box sx={{ padding: "24px" }}>
            <div>
              <div id="chart">
                <ReactApexChart
                  options={chartData[4].pateintSeenOptions}
                  series={chartData[4].pateintSeenSeries}
                  type="line"
                  height={350}
                />
              </div>
              <div id="html-dist"></div>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <Box
          sx={{
            background: "#fff",
            borderRadius: "24px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ padding: "24px" }}>
            <Text variant="h2">{TrendsChartData[1]?.title}</Text>
          </Box>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderBottomLeftRadius: "24px",
              borderBottomRightRadius: "24px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              flex: 1,
            }}
          >
            <Box
              sx={{
                padding: "16px",
              }}
            >
              <Box>
                <Box className="chart-wrap">
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="start"
                      flexWrap="noWrap"
                    >
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                          flexWrap: "wrap",
                        }}
                      >
                        Current year patients
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                        }}
                      >
                        12924
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                        }}
                      >
                        Previous year patients
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                        }}
                      >
                        12067
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                        }}
                      >
                        The growth is around
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          color: "#3475DC",
                        }}
                      >
                        7.12%
                      </Text>
                    </Flex>
                  </Box>
                  <Box id="chart">
                    <ReactApexChart
                      options={totalPatientDataOptions}
                      series={totalPatientDataSeries}
                      type="donut"
                      height={350}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      {/*  */}
      <Grid item lg={9} md={8} sm={12} xs={12}>
        <Box
          sx={{ background: "#fff", borderRadius: "24px", height: "100%" }}
          id="revenue-new-patients-graph"
        >
          <Box sx={{ borderBottom: "1px solid #ccc" }}>
            <Box sx={{ padding: "24px" }}>
              <Flex justifyContent="space-between">
                <Text variant="h2">{TrendsChartData[5]?.title}</Text>
                <Text variant="h2">{TrendsChartData[5]?.subTitle}</Text>
              </Flex>
            </Box>
          </Box>
          <Box sx={{ padding: "24px" }}>
            <div>
              <div id="chart">
                <ReactApexChart
                  options={chartData[5].newPatientOptions}
                  series={chartData[5].newPatientseries}
                  type="line"
                  height={350}
                />
              </div>
              <div id="html-dist"></div>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <Box
          sx={{
            background: "#fff",
            borderRadius: "24px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ padding: "24px" }}>
            <Text variant="h2">{TrendsChartData[1]?.title}</Text>
          </Box>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderBottomLeftRadius: "24px",
              borderBottomRightRadius: "24px",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",
              flex: 1,
            }}
          >
            <Box
              sx={{
                padding: "16px",
              }}
            >
              <Box>
                <Box className="chart-wrap">
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex
                      justifyContent="space-between"
                      alignItems="start"
                      flexWrap="noWrap"
                    >
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                          flexWrap: "wrap",
                        }}
                      >
                        Current Year Total New Patients
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                        }}
                      >
                        857
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                        }}
                      >
                        Previous Year
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                        }}
                      >
                        12067
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid #000",
                      paddingBottom: "6px",
                      my: 1,
                    }}
                  >
                    <Flex justifyContent="space-between">
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          fontWeight: "bold",
                        }}
                      >
                        The growth is around
                      </Text>
                      <Text
                        sx={{
                          fontSize: "14px !important",
                          color: "#3475DC",
                        }}
                      >
                        20.70%
                      </Text>
                    </Flex>
                  </Box>
                  <Box id="chart">
                    <ReactApexChart
                      options={totalNewPatientDataOptions}
                      series={totaNewlPatientDataSeries}
                      type="donut"
                      height={350}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TrendCharts;

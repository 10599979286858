// PatientSeenChart.js
import { Box, Grid } from "@mui/material";
import Text from "components/common/Typography/Text";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const PatientSeenChartData = [
  1028, 887, 1191, 1022, 1222, 1079, 1021, 1201, 1082, 1003, 1135, 1052,
];

const PatientSeenChartMinValue = Math.min(...PatientSeenChartData);
const PatientSeenChartMaxValue = Math.max(...PatientSeenChartData);
const PatientSeenChartPadding = 50;

const PatientSeenChartYAxisConfig = {
  min:
    Math.floor((PatientSeenChartMinValue - PatientSeenChartPadding) / 10) * 10,
  max:
    Math.ceil((PatientSeenChartMaxValue + PatientSeenChartPadding) / 10) * 10,
  tickAmount: 5,
  labels: {
    formatter: (val) => val.toFixed(0),
  },
};

const PatientSeenChart = () => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Patients Seen",
        data: PatientSeenChartData,
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        responsive: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#4FD1C5"],
      },
      fill: {
        colors: ["#4FD1C5", "#4FD1C5"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      colors: ["#4FD1C5"],
      yaxis: PatientSeenChartYAxisConfig,
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        offsetY: 12,
        offsetX: -10,
        itemMargin: {
          horizontal: 5,
          vertical: 7,
        },
      },
      tooltip: {
        x: {
          format: "",
        },
      },
      width: "100%",
    },
  });

  return (
    <>
      <Box sx={{ width: "100%", padding: "16px", mb: 3, background: "#fff" }}>
        <Grid container sx={{ height: "100%", width: "100%" }}>
          <Grid item lg={10} md={10} sm={10}>
            <Text variant="h2">Patients Seen</Text>
            {/* <Text variant="bodyM">Last 6 months</Text> */}
          </Grid>
          {/* <SelectField
            defaultValue={financialChartOptions[0]}
            cols={2}
            sm={2}
            md={2}
            options={financialChartOptions}
          /> */}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box>
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="area"
              height={350}
            />
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default PatientSeenChart;

import { Box, Stack } from "@mui/material";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { flexRowCenter, justifyContentSpaceBetween } from "styles";

const VitalDetailCard = ({
  vitalData,
  patientData,
  active = false,
  marginTop,
  pastVisit,
}) => {
  return (
    <Box sx={{ marginTop: marginTop }}>
      {vitalData !== null ? (
        <>
          {!pastVisit ? (
            <>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box>
                  <Box>
                    <Text variant="propertyLabel">Created by</Text>
                  </Box>
                  <Text variant="bodyXs">Khaled Janom MD</Text>
                </Box>
                <Box>
                  <Box>
                    <Text variant="propertyLabel">Reason for Visit</Text>
                  </Box>
                  <Text variant="bodyXs">
                    increased fatigue, swelling in my extremities, and changes
                    in urination patterns
                  </Text>
                </Box>
              </Box>
              <Box
                sx={{
                  color: active ? "#2D3748" : "#FC5A5A",
                  fontSize: "20px",
                  lineHeight: "28.79px",
                  fontWeight: "700",
                  marginTop: "20px",
                }}
              >
                <span style={{ borderBottom: "2px solid  #1344F1" }}>
                  Vital Results
                </span>
              </Box>
            </>
          ) : (
            ""
          )}

          <Box
            sx={{
              padding: "15px",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <Text variant="h2">Patient Information</Text>
            <Box
              sx={{
                ...flexRowCenter,
                marginTop: "10px",
                ...justifyContentSpaceBetween,
              }}
            >
              <Box sx={{ ...flexRowCenter }}>
                <Text
                  variant="h3"
                  data-testid="scd-vitalDetails-label-patient-name"
                >
                  Patient Name:
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-patient-name"
                  marginLeft="10px"
                >
                  {patientData?.first_name + " " + patientData?.last_name}
                </Text>
              </Box>
              {/* <Box sx={{ ...flexRowCenter }}>
                <Text variant="h3" data-testid="scd-vitalDetails-label-DOB">
                  Date of Birth:
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-DOB"
                  marginLeft="10px"
                >
                  {moment(patientData?.birthdate).format("MM/DD/YYYY")}
                </Text>
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                data-testid="labresults-test-labname"
              >
                <Text variant="h3">Lab:</Text>
                <Text variant="bodyXs">
                  &nbsp;{patientData?.testing_lab_name ?? ""}
                </Text>
              </Box>
              <Box sx={{ ...flexRowCenter }}>
                <Text variant="h3" data-testid="scd-vitalDetails-label-DOC">
                  Date of Collection:
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-DOC"
                  marginLeft="11px"
                >
                  {moment(vitalData?.date_time).format("MM/DD/YYYY")}
                </Text>
              </Box>
            </Box>
            <Box sx={{ marginBottom: "10px", marginTop: "10px" }}>
              <Text variant="h3" color={"black"}>
                Vitals
              </Text>
            </Box>
            <Stack direction="column" spacing={2}>
              <Box sx={{ ...flexRowCenter }}>
                <Text
                  variant="h3"
                  data-testid="scd-vitalDetails-label-BP"
                  minWidth="200px"
                >
                  Blood Pressure:
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-BP"
                  marginLeft="20px"
                >
                  {vitalData?.systolic} / {vitalData?.diastolic} mm Hg
                </Text>
              </Box>
              <Box sx={{ ...flexRowCenter }}>
                <Text
                  variant="h3"
                  data-testid="scd-vitalDetails-label-HR"
                  minWidth="200px"
                >
                  Heart Rate:
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-HR"
                  marginLeft="20px"
                >
                  {vitalData?.pulse} beats per minute
                </Text>
              </Box>
              <Box sx={{ ...flexRowCenter }}>
                <Text
                  variant="h3"
                  data-testid="scd-vitalDetails-label-RR"
                  minWidth="200px"
                >
                  Respiratory Rate:
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-RR"
                  marginLeft="20px"
                >
                  {vitalData?.respiration_rate} breaths per minute
                </Text>
              </Box>
              <Box sx={{ ...flexRowCenter }}>
                <Text
                  variant="h3"
                  data-testid="scd-vitalDetails-label-temp"
                  minWidth="200px"
                >
                  Temperature:
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-temp"
                  marginLeft="20px"
                >
                  {vitalData?.temperature} °F ({" "}
                  {Math.round(vitalData?.temperature - (32 * 5) / 9)} °C )
                </Text>
              </Box>
              <Box sx={{ ...flexRowCenter }}>
                <Text
                  variant="h3"
                  data-testid="scd-vitalDetails-label-spo2"
                  minWidth="200px"
                >
                  Oxygen Saturation
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-spo2"
                  marginLeft="20px"
                >
                  {vitalData?.spo2} %
                </Text>
              </Box>
            </Stack>
            <Stack direction="column" spacing={2} marginTop={"40px"}>
              <Box sx={{ ...flexRowCenter }}>
                <Text
                  variant="h3"
                  data-testid="scd-vitalDetails-label-bw"
                  minWidth="200px"
                >
                  Body Weight:
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-bw"
                  marginLeft="20px"
                >
                  {vitalData?.weight} lbs ({" "}
                  {Math.round(vitalData?.weight * 0.45359237)} Kg )
                </Text>
              </Box>
              <Box sx={{ ...flexRowCenter }}>
                <Text
                  variant="h3"
                  data-testid="scd-vitalDetails-label-uo"
                  minWidth="200px"
                >
                  Urine Output (last 24hr):
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-uo"
                  marginLeft="20px"
                >
                  {vitalData?.urine_output} mL
                </Text>
              </Box>
              <Box sx={{ ...flexRowCenter }}>
                <Text
                  variant="h3"
                  data-testid="scd-vitalDetails-label-pain"
                  minWidth="200px"
                >
                  Pain Assessment:
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-pain"
                  marginLeft="20px"
                >
                  {vitalData?.pain_assessment} (Scale of{" "}
                  {vitalData?.pain_assessment} to 10, 10 being worst pain)
                </Text>
              </Box>
              <Box sx={{ ...flexRowCenter }}>
                <Text
                  variant="h3"
                  data-testid="scd-vitalDetails-label-edema"
                  minWidth="200px"
                >
                  Edema Assessment:
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-edema"
                  marginLeft="20px"
                >
                  {vitalData?.edema_assessment}
                </Text>
              </Box>
              <Box sx={{ ...flexRowCenter }}>
                <Text
                  variant="h3"
                  data-testid="scd-vitalDetails-label-others"
                  minWidth="200px"
                >
                  Other Observations:
                </Text>
                <Text
                  variant="bodyXs"
                  data-testid="scd-vitalDetails-value-others"
                  marginLeft="20px"
                >
                  {vitalData?.other_observations}
                </Text>
              </Box>
            </Stack>
          </Box>
        </>
      ) : (
        <Box marginY={4}>
          <Text variant="bodyS">No Data Found.</Text>
        </Box>
      )}
    </Box>
  );
};

export default VitalDetailCard;

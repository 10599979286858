import arrowIconUp from "../../assets/svg/colored/arrowIconDown.svg";
import cardImage from "../../assets/svg/colored/cardicon.svg";
export let financialsCardData = [
  {
    cardImage: cardImage,
    text: "Revenue vs Expense",
    value: "$4,018,133.17/$3,609,658.73",
    trendIcon: arrowIconUp,
  },
];

export const rosChipArray = [
  { label: "Nausea", size: "small", variant: "outlined" },
  { label: "Shortness of breath", size: "small", variant: "outlined" },
  { label: "Headaches", size: "small", variant: "outlined" },
];
export const icdChipArray = [
  { label: "E83.30", size: "small", variant: "outlined" },
  { label: "N18.9", size: "small", variant: "outlined" },
  { label: "D63.1", size: "small", variant: "outlined" },
  { label: "E09.22", size: "small", variant: "outlined" },
];
export const keyPerformanceIndicatorsData = [
  {
    keyName: "Active Patient",
  },
  {
    keyName: "ESRD",
  },
  {
    keyName: "New Patient",
  },
  {
    keyName: "Expected Revenue",
  },
];
export let myTeamData = [
  {
    Title: "Practice Administrator",
    members: [
      {
        name: "Dr John Doe (me)",
        role: "Nephrologist",
      },
    ],
  },
  {
    Title: "Doctors",
    members: [
      {
        name: "Dr Liam Johnson",
        role: "Nephrologist",
      },
      {
        name: "Dr Emily Johnson",
        role: "Nephrologist",
      },
      {
        name: "Dr Lily Brook",
        role: "Nephrologist",
      },
      {
        name: "Dr Amando Smith",
        role: "Nephrologist",
      },
    ],
  },
  {
    Title: "Medical Assistants",
    members: [
      {
        name: "Ethan Thompson",
        role: "Nurse",
      },
      {
        name: "Sophia Martinez",
        role: "Nurse",
      },
    ],
  },
  {
    Title: "Front Desk Representative",
    members: [
      {
        name: "Dr Isabella Martinez Harris Turner",
        role: "Front Desk Representative",
      },
      {
        name: "Lucas Turner",
        role: "Front Desk Representative",
      },
    ],
  },
];

export let EditTeamFieldsData = [
  {
    element: "input",
    type: "text",
    label: "First Name",
    name: "first_name",
    placeHolder: "First Name",
  },
  {
    element: "input",
    type: "text",
    label: "Last Name",
    name: "last_name",
    placeHolder: "Last Name",
  },
  {
    element: "input",
    type: "date",
    label: "DOB",
    name: "birthdate",
    max: new Date().toISOString().split("T")[0],
    placeHolder: "1999/01/01",
  },
  {
    element: "input",
    type: "number",
    label: "Medical License Number",
    name: "medical_license_number",
    placeHolder: "License Number",
  },
  {
    element: "input",
    type: "email",
    label: "E-mail Address",
    name: "email",
    placeHolder: "example@gmail.com",
  },
  {
    element: "input",
    type: "string",
    label: "Phone Number",
    name: "phone_number",
    placeHolder: "+1 (123) 123-1234",
  },
  {
    element: "input",
    type: "string",
    label: "Designation",
    name: "designation",
    placeHolder: "designation",
  },
];

export const FAQSData = [
  {
    title: "What is Nephrolytics?",
    description:
      "Nephrolytics is a comprehensive nephrology management platform designed to streamline clinic operations, enhance patient care, and simplify administrative tasks.",
  },
  {
    title: "How can Nephrolytics benefit my nephrology clinic?",
    description:
      "Nephrolytics brings efficiency to your clinic by offering features like patient management, billing, and reporting, allowing you to focus more on patient care.",
  },
  {
    title: "Is Nephrolytics user-friendly?",
    description:
      "Yes, Nephrolytics is designed with user experience in mind, ensuring ease of use for both healthcare professionals and administrative staff.",
  },
  {
    title: "Can I access Nephrolytics on multiple devices?",
    description:
      "Yes, Nephrolytics is accessible from various devices, including desktops, laptops, tablets, and smartphones, providing flexibility for users.",
  },
  {
    title: "How does Nephrolytics handle patient records?",
    description:
      "Nephrolytics securely stores and organizes patient records, making it easy for healthcare providers to access and manage patient information.",
  },
  {
    title: "Is training available for Nephrolytics users?",
    description:
      "Yes, Nephrolytics offers training resources, including user guides, video tutorials,and webinars, to ensure users can maximize the platform's benefits.",
  },
  {
    title: "Can Nephrolytics help with reporting and analytics?",
    description:
      "Yes, Nephrolytics provides reporting and analytics features, empowering clinics with insights into patient outcomes, financial performance, and operational efficiency.",
  },
  {
    title: "What support is available if I encounter issues with Nephrolytics?",
    description:
      "Nephrolytics has a dedicated support team ready to assist users with any issues, offering prompt and helpful resolutions.",
  },
  {
    title: "Can Nephrolytics integrate with other healthcare systems?",
    description:
      "Nephrolytics is designed for seamless integration with other healthcare systems,facilitating interoperability and data exchange.",
  },
  {
    title: "How often is Nephrolytics updated?",
    description:
      "Nephrolytics undergoes regular updates to introduce new features, enhance performance, and ensure compatibility with evolving healthcare standards (HIPPA, HL7, CCD, FHIR).",
  },
  {
    title: "Can Nephrolytics assist with medication management?",
    description:
      "Yes, Nephrolytics includes features for medication management, helping healthcare providers track and manage patients' prescribed medications.",
  },
  {
    title: "Is Nephrolytics cloud-based or on-premise?",
    description:
      "Nephrolytics is a cloud-based solution, offering the advantage of accessibility from anywhere with an internet connection and reducing the need for on - premise infrastructure.",
  },
  {
    title:
      "What training resources are available for clinic staff using Nephrolytics?",
    description:
      "Nephrolytics provides comprehensive training materials, including guides and tutorials, to ensure that clinic staff can effectively navigate and utilize the platform.",
  },
  {
    title: "Can Nephrolytics help clinics go paperless?",
    description:
      "Yes, Nephrolytics supports a paperless environment by digitizing patient records,appointments, and administrative processes for increased efficiency and reduced environmental impact.",
  },
  {
    title:
      "What security measures does Nephrolytics have in place for data backup?",
    description:
      "Nephrolytics employs robust data backup protocols to ensure the safety and availability of patient data in the event of any unforeseen circumstances.",
  },
  {
    title: "Can Nephrolytics help clinics comply with HIPAA regulations?",
    description:
      "Absolutely, Nephrolytics prioritizes HIPAA compliance, implementing measures to safeguard patient privacy and ensure adherence to healthcare regulatory standards.",
  },
];
let widthForFields = {
  width: { lg: "45%", sm: "100%" },
  marginRight: { lg: "10px" },
};
export let profileInformationData = [
  {
    element: "input",
    type: "text",
    sx: { marginRight: "100px", ...widthForFields },
    sx: widthForFields,
    label: "First Name",
    name: "first_name",
    placeHolder: "First Name",
  },
  {
    element: "input",
    type: "text",
    sx: { ...widthForFields },
    label: "Last Name",
    name: "last_name",
    placeHolder: "Last Name",
  },
  {
    element: "input",
    type: "date",
    sx: { ...widthForFields },
    sx: widthForFields,
    label: "DOB",
    name: "birthdate",
    max: new Date().toISOString().split("T")[0],
    placeHolder: "1999/01/01",
  },
  {
    element: "input",
    type: "number",
    sx: { ...widthForFields },
    label: "Medical License Number",
    name: "medical_license_number",
    placeHolder: "License Number",
  },
  {
    element: "input",
    type: "email",
    sx: { backgroundColor: "red", ...widthForFields },
    sx: widthForFields,
    label: "E-mail Address",
    name: "email",
    placeHolder: "example@gmail.com",
  },
  {
    element: "input",
    type: "string",
    sx: { ...widthForFields },
    label: "Phone Number",
    name: "phone_number",
    placeHolder: "+1 (123) 123-1234",
  },
  {
    element: "input",
    type: "string",
    sx: { ...widthForFields },
    label: "Designation",
    name: "designation",
    placeHolder: "designation",
  },
];
export let clinicHospitalData = [
  {
    element: "select",
    label: "Clinic",
    sx: { width: { lg: "96%", sm: "100%" } },
    name: "clinic",
    defaultValue: "ABC Kidney Institute",
    placeHolder: "Select Location",
  },
  {
    element: "input",
    type: "text",
    sx: widthForFields,
    sx: { width: { lg: "47%", sm: "100%" }, marginRight: { lg: "10px" } },
    label: "Address 1",
    name: "address-one",
    placeHolder: "Address 1",
  },
  {
    element: "input",
    type: "text",
    sx: { width: { lg: "47%", sm: "100%" }, marginRight: { lg: "10px" } },
    label: "Address 2",
    name: "address-two",
    placeHolder: "Address 1",
  },
  {
    element: "input",
    type: "text",
    sx: { width: { lg: "31%", sm: "100%" }, marginRight: { lg: "10px" } },
    label: "City",
    name: "city",
    placeHolder: "Los Angeles",
  },
  {
    element: "input",
    sx: { width: { lg: "31%", sm: "100%" }, marginRight: { lg: "10px" } },
    label: "State",
    name: "Californa",
    defaultValue: "California",
    placeHolder: "California",
  },
  {
    element: "input",
    type: "Number",
    sx: { width: { lg: "31%", sm: "100%" }, marginRight: { lg: "10px" } },
    label: "Zip Code",
    name: "number",
    placeHolder: "434",
  },
];
export let titleOptions = [
  { label: "Dr.", value: "Dr." },
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
];

export let changePasswordUserSelectorStateGenerator = (
  message,
  successOrFail,
  loading
) => {
  return {
    changePasswordMessage: message,
    changePasswordSuccess: successOrFail,
    isChangePasswordLoading: loading,
  };
};

export let healthSummaryStaticData = [
  {
    id: 260,
    optimal_start: "Yes",
    last_hospitalization: "2023-11-01",
    ros: "Swelling in ankles maybe",
    assessment_severity: 9,
    reason:
      "increased fatigue, swelling in my extremities, and changes in urination patterns.",
    ckd_status: "CKD-2",
    yes_only_fields: ["DM", "PVD", "CIRRHOSIS"],
    "yes-field-keys": ["DM", "PVD", "CIRRHOSIS"],
  },
];

export let vitalsStaticData = [
  {
    patient_id: 322,
    date_time: "2022-01-28T04:03:00.000000Z",
    height: 23,
    weight: 93,
    systolic: 71,
    diastolic: 75,
    bmi: 99,
    urine_output: 47,
    pain_assessment: 41,
    edema_assessment: "Voluptas architecto",
    other_observations: "Libero qui vel autem",
    temperature: 29,
    pulse: 93,
    respiration_rate: 60,
    spo2: 45,
    account_id: 1,
  },
  {
    patient_id: 374,
    date_time: "2023-12-20T18:03:17.216815Z",
    height: 170,
    weight: 180,
    systolic: 130,
    diastolic: 110,
    bmi: 25.8,
    urine_output: 800,
    pain_assessment: 2,
    edema_assessment: "mild swelling in ankles",
    other_observations: "no flank pain",
    temperature: 98.6,
    pulse: 80,
    respiration_rate: 12,
    spo2: 98,
    account_id: 1,
  },
];
export let medicationsStaticData = [
  {
    patient_id: 314,
    prescribed_on: "2021-09-24T19:59:00.000000Z",
    discontinued_on: "1988-10-04T20:06:00.000000Z",
    drug_name: "Axel Barton",
    status_ind: "0",
    instructions: "Take 3 times a day",
    account_id: 1,
  },
  {
    patient_id: 314,
    prescribed_on: "2021-09-24T19:59:00.000000Z",
    discontinued_on: "1988-10-04T20:06:00.000000Z",
    drug_name: "Axel Barton",
    status_ind: "0",
    instructions: "Sunt non natus paria",
    account_id: 1,
  },
  {
    patient_id: 314,
    prescribed_on: "2021-09-24T19:59:00.000000Z",
    discontinued_on: "1988-10-04T20:06:00.000000Z",
    drug_name: "Axel Barton",
    status_ind: "0",
    instructions: "Sunt non natus paria",
    account_id: 1,
  },
  {
    patient_id: 314,
    prescribed_on: "1994-05-03T11:43:00.000000Z",
    discontinued_on: "1973-09-28T05:56:00.000000Z",
    drug_name: "Brendan Walton",
    status_ind: "0",
    instructions: "Ut voluptate non qui",
    account_id: 1,
  },
];
export let urgentArray = [
  {
    first_name: "Sophia",
    last_name: "Patel",
    ckd: "CKD-4 Patient",
    status: "Urgent",
    schedule: "schedule",
  },
];
export let getSpecificPatientStaticData = {
  id: 374,
  first_name: "Ger",
  last_name: "Han",
  gender: "Female",
  birthdate: "01-16-1958",
  address: null,
  city: "LAVA HOT SPRINGS",
  state: "ID",
  zipcode: "83246",
  patient_status: null,
  primary_insurance: null,
  fhir_resource_id: "8da27e51-da33-4eb0-847a-76a68aa21461",
  account_id: 1,
  url: "/api/patients/374/",
  medications: [],
  photo: "https://nephro-media.s3.us-west-2.amazonaws.com/patients/374.jpg",
  phone_number: "208 904 5555",
};
export let medicationsActiveStaticData = [
  {
    patient_id: 483,
    prescribed_on: "2023-12-26T10:44:00.901891Z",
    discontinued_on: "2023-12-26T10:44:00.901891Z",
    drug_name: "Emodium (450 mg)",
    status_ind: "1",
    instructions: "two pills in the evening",
    account_id: 1,
  },
];

export let medicationsInActiveStaticData = [
  {
    patient_id: 483,
    prescribed_on: "2023-12-26T09:31:40.576220Z",
    discontinued_on: "2023-12-26T09:31:40.576220Z",
    drug_name: "Panadol",
    status_ind: "0",
    instructions: "two pills in the morning",
    account_id: 1,
  },
  {
    patient_id: 483,
    prescribed_on: "1987-02-11T10:52:00.000000Z",
    discontinued_on: "2009-09-19T19:25:00.000000Z",
    drug_name: "Raja Schmidt",
    status_ind: "0",
    instructions: "Numquam harum volupt",
    account_id: 1,
  },
];
export let labTrendsStaticData = [
  {
    id: 2468,
    date_time: "2023-12-27T13:43:09.175956Z",
    testing_lab_name: "Expresslab",
    practitioner_id: 3,
    patient_id: 524,
    observations: [
      {
        test_name: "LDL",
        test_code: "LDL",
        test_description: "Low Density Lipid",
        value: 180,
        unit: "mg/dL",
        normal_range: "80-200",
        gender: "F",
      },
      {
        test_name: "Chol",
        test_code: "Chol",
        test_description: "Cholestrol",
        value: 230,
        unit: "mg/dL",
        normal_range: "150-240",
        gender: "F",
      },
      {
        test_name: "Ca+",
        test_code: "Ca+",
        test_description: "Calcium",
        value: 10.1,
        unit: "mmol/L",
        normal_range: "8.5-10.2",
        gender: "F",
      },
      {
        test_name: "K+",
        test_code: "K+",
        test_description: "Potassium",
        value: 4.8,
        unit: "mmol/L",
        normal_range: "3.5-5.2",
        gender: "F",
      },
      {
        test_name: "Na+",
        test_code: "Na+",
        test_description: "Sodium",
        value: 141,
        unit: "mmol/L",
        normal_range: "135-145",
        gender: "F",
      },
      {
        test_name: "UACR",
        test_code: "UACR",
        test_description: "urine albumin-creatinine ratio",
        value: 25,
        unit: "mg/g",
        normal_range: "10-30",
        gender: "F",
      },
      {
        test_name: "eGFR",
        test_code: "eGFR",
        test_description: "estimated Glomerular Giltration Rate",
        value: 105,
        unit: "mL/min",
        normal_range: "0-120",
        gender: "F",
      },
      {
        test_name: "BUN",
        test_code: "BUN",
        test_description: "Blood Urea Nitrogen",
        value: 15.5,
        unit: "mg/dL",
        normal_range: "7-17",
        gender: "F",
      },
      {
        test_name: "Cr",
        test_code: "Cr",
        test_description: "Creatinine",
        value: 1.2,
        unit: "mg/dL",
        normal_range: "0.66-1.25",
        gender: "F",
      },
      {
        test_name: "Plt",
        test_code: "plt",
        test_description: "platelet Cells",
        value: 330,
        unit: "x10^9/L",
        normal_range: "150-400",
        gender: "F",
      },
      {
        test_name: "WBC",
        test_code: "WBC",
        test_description: "White Blood Cells",
        value: 9.2,
        unit: "x10^9/L",
        normal_range: "4.5-11",
        gender: "F",
      },
      {
        test_name: "RBC",
        test_code: "RBC",
        test_description: "Red Blood Cells",
        value: 4.9,
        unit: "x10^9/L",
        normal_range: "4.3-5.9",
        gender: "F",
      },
      {
        test_name: "Hgb",
        test_code: "Hb",
        test_description: "Hemoglobin",
        value: 8.8,
        unit: "g/L",
        normal_range: "6-14",
        gender: "F",
      },
    ],
  },
];

export let staticIcdCodesData = [
  {
    id: 235,
    icd_code: "A3981",
    description: "Meningococcal encephalitis",
    url: "/api/icds/235/",
    account_id: 1,
  },
  {
    id: 35,
    icd_code: "Z95828",
    description: "Enterohemorrhagic Escherichia coli infection",
    url: "/api/icds/35/",
    account_id: 1,
  },
];
export let staticCptCodesData = [
  {
    id: 43,
    cpt_code: "99239",
    description: "Hospital Discharge Day Management >",
    url: "/api/cptcodes/43/",
    account_id: 1,
  },
  {
    id: 49,
    cpt_code: "99251",
    description: "Initial Inpatient Consult New/Estab Pt 2",
    url: "api/cptcodes/49/",
    account_id: 1,
  },
];

export let initialStaticCptForAllEncounter = {
  id: 24,
  cpt_code: "99214",
  description: "Office Outpatient Visit",
  url: "/api/cptcodes/24/",
  account_id: 1,
};

export let encounterByAppointmentStaticData = (signCloseId) => {
  return {
    id: 802,
    soap_notes: "This is the notes for the cease Encounter",
    patient_id: 542,
    location_id: 1,
    practitioner_id: 9,
    primary_payer_id: 1,
    secondary_payer_id: 1,
    fhir_resource_id: "1",
    visit_type: "asd",
    outcome: "outcome",
    assessment_notes: "These notes are for my smart notes.",
    plan_note: null,
    appointment_id: 802,
    icds: staticIcdCodesData,
    cpts: staticIcdCodesData,
    datetime: "2023-12-30T12:33:52.615000Z",
    vitals_note:
      "Hello World this is good choice of vital design a bit better than last time.",
    sign_close_ind: signCloseId,
    url: "/api/encounters/802/",
    account_id: 1,
  };
};
export const ckdVals = {
  "CKD-1": { num: 6, time: "months" },
  "CKD-2": { num: 5, time: "months" },
  "CKD-3": { num: 3, time: "months" },
  "CKD-4": { num: 2, time: "months" },
  "CKD-5": { num: 1, time: "months" },
};

export const frequencyOptions = [
  { label: "Once a day", value: "Once a day" },
  { label: "Twice a day", value: "Twice a day" },
  { label: "Thrice a day", value: "Thrice a day" },
];

export let staticMedicineData = [
  {
    id: 10,
    drug_name: "Antiplatelet Agents",
    purpose:
      "stop cells in the blood (platelets) from sticking together and forming a clot",
    account_id: 1,
  },
  {
    id: 9,
    drug_name: "Insulin or Oral Antidiabetic Medications",
    purpose:
      "To help lower blood glucose levels (and your A1c) to a healthy range",
    account_id: 1,
  },
  {
    id: 8,
    drug_name: "Antihypertensive Medications",
    purpose: "Used to treat hypertension (high blood pressure)",
    account_id: 1,
  },
  {
    id: 7,
    drug_name: "Statins",
    purpose: "lower cholesterol",
    account_id: 1,
  },
  {
    id: 6,
    drug_name: "Calcitriol or Vitamin D Analogues",
    purpose:
      "hyperproliferative diseases, immune dysfunction, endocrine disorders, and metabolic bone diseases",
    account_id: 1,
  },
  {
    id: 5,
    drug_name: "Phosphate Binders",
    purpose:
      "Used to reduce positive phosphate balance and to lower serum phosphate levels for people with chronic kidney disease (CKD)",
    account_id: 1,
  },
  {
    id: 4,
    drug_name: "Erythropoiesis-Stimulating Agents (ESAs)",
    purpose: "Stimulate the bone marrow to make red blood cells",
    account_id: 1,
  },
  {
    id: 2,
    drug_name: "Angiotensin II Receptor Blockers (ARBs)",
    purpose: "Used to treat high blood pressure and heart failure.",
    account_id: 1,
  },
  {
    id: 1,
    drug_name: "Angiotensin-Converting Enzyme Inhibitors (ACE Inhibitors)",
    purpose: "Used to treat and manage hypertension",
    account_id: 1,
  },
];

export let graphStaticApiFullFormValue = {
  BPS: "systolic",
  BPD: "diastolic",
  HR: "pulse",
  RR: "respiration_rate",
  WT: "weight",
  Temp: "temperature",
  Spo2: "spo2",
};

export let vitalGraphStaticColors = [
  "#8e44ad",
  "#e74c3c",
  "#e67e22",
  "#1abc9c",
  "#34495e",
  "#0652DD",
  "#FDA7DF",
];
export let graphStaticColors = {
  BPS: "#8e44ad",
  BPD: "#e74c3c",
  HR: "#e67e22",
  RR: "#1abc9c",
  WT: "#34495e",
  Temp: "#0652DD",
  Spo2: "#FDA7DF",
};

export const condtionalStyling = (incoming) => {
  return {
    justifyContent: incoming ? "flex-end" : "flex-start",
    textAlign: incoming ? "end" : "start",
    borderRadius: incoming ? "12px 12px 0px 12px" : "12px 12px 12px 0px",
    name: incoming ? "You" : "Saya",
    background: incoming ? "#F0F0F0" : "#f1e8ff",
    message: incoming
      ? "What's our clinic's revenue-to-expense ratio, especially in dialysis reimbursements and medication costs, compared to industry benchmarks?"
      : "Your current revenue-to-expense ratio stands at 2.5, outperforming the industry average by 0.3 points, with notable efficiency in dialysis reimbursements and medication costs demonstrating a 15% reduction compared to last quarter.",
  };
};
export const financialChartOptions = [
  {
    label: "6 months",
    value: "6 months",
  },
  {
    label: "3 months",
    value: "3 months",
  },
  {
    label: "1 months",
    value: "1 months",
  },
];
export const followUpOptions = [
  { value: "24 hours", label: "Within 24 hours" },
  { value: "1 week", label: "After 1 week" },
  { value: "2 week", label: "After 2 weeks" },
  { value: "3 week", label: "After 3 weeks" },
  { value: "4 week", label: "After 4 weeks" },
  { value: "5 week", label: "After 5 weeks" },
  { value: "1 month", label: "After 1 month" },
  { value: "2 month", label: "After 2 months" },
  { value: "3 month", label: "After 3 months" },
  { value: "4 month", label: "After 4 months" },
  { value: "5 month", label: "After 5 months" },
  { value: "6 month", label: "After 6 months" },
];

const revenueData = [
  234844, 348126, 325175, 398124, 299134, 334134, 365126, 310590, 375176,
  288389, 350351, 388964,
];
const expenseData = [
  248852, 327126, 284125, 374155, 287877, 305125, 298135, 285135, 325161,
  256147, 300805, 317015,
];

const minValue = Math.min(...revenueData, ...expenseData);
const maxValue = Math.max(...revenueData, ...expenseData);

const padding = 20;

const yaxisConfig = {
  min: Math.floor(minValue / 10) * 10,
  max: Math.ceil((maxValue + padding) / 10) * 10,
  tickAmount: 6,
  labels: {
    formatter: function (value) {
      const inThousands = value / 1000;
      return "$" + inThousands.toFixed(0) + "k";
    },
  },
};

const newPatientData = [86, 97, 104, 58, 67, 84, 38, 71, 47, 77, 34, 94];

const minValue1 = Math.min(...newPatientData);
const maxValue2 = Math.max(...newPatientData);

const padding1 = 10;

const yaxisConfig1 = {
  min: Math.floor(minValue1 / 10) * 10,
  max: Math.ceil((maxValue2 + padding1) / 10) * 10,
  tickAmount: 5,
  labels: {
    formatter: (val) => `${val}`,
  },
};

export const TrendsChartData = [
  {
    title: "Total Revenue Posted in a year",
    subTitle: "Revenue Posted",
    series: [
      {
        name: "Revenue",
        data: [
          234844, 348126, 325175, 398124, 299134, 334134, 365126, 310590,
          375176, 288389, 350351, 388964,
        ],
      },
      {
        name: "Expense",
        data: [
          248852, 327126, 284125, 374155, 287877, 305125, 298135, 285135,
          325161, 256147, 300805, 317015,
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#3475DC", "#F14425"],
      dataLabels: {
        enabled: true,
        formatter: function (value) {
          const inThousands = value / 1000;
          return "$" + inThousands.toFixed(0) + "k";
        },
      },
      stroke: {
        curve: "smooth",
      },
      markers: {
        size: 4,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: yaxisConfig,
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        offsetY: 12,
        offsetX: -10,
        itemMargin: {
          horizontal: 5,
          vertical: 7,
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return "$" + value;
          },
        },
      },
    },
  },
  {
    donutSeries: [11, 88],
    donutOptions: {
      chart: {
        type: "donut",
        width: "100%",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#CC3E3E", "#36ad70"],
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            labels: {
              show: false,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "30px",
                color: "#FF0000",
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex }) {
            if (seriesIndex === 0) {
              return "88.68%";
            } else {
              return "11.32%";
            }
          },
        },
      },
      labels: ["Expense", "Net Profit"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        fontSize: "14px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
    },
  },
  {
    title: "Total Encounters in a year",
    subTitle: "Encounters",
    revenueOptionSeries: [
      {
        name: "Total Encounters in a year",
        data: [
          1747, 1508, 2024, 1737, 2077, 1835, 1736, 2042, 1840, 1705, 1930,
          1789,
        ],
      },
    ],
    revenueOptions: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
        dataLabels: {
          enabled: true,
        },
      },
      colors: ["#3475DC"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        min: 1500,
        max: 2100,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      labels: ["Total Encounters in a year"],
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        offsetY: 12,
        offsetX: -10,
        itemMargin: {
          horizontal: 5,
          vertical: 7,
        },
      },
      fill: {
        type: "solid",
        colors: ["transparent"],
      },
    },
  },
  {
    title: "Trend",
    series: [75, 25],
    donutOptions: {
      chart: {
        type: "donut",
        width: "100%",
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ["#3475DC", "#ccc"],
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "30px",
                color: "#3475DC",
                formatter: function () {
                  return "75%";
                },
              },
            },
          },
        },
      },
      labels: ["75% Active"],
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
    },
  },
  {
    title: "Total Patients Seen in a year",
    subTitle: "Patients Seen",
    pateintSeenSeries: [
      {
        name: "Total Patients Seen in a year",
        data: [
          1028, 887, 1191, 1022, 1222, 1079, 1021, 1201, 1082, 1003, 1135, 1052,
        ],
      },
      // {
      //   name: "New Patients",
      //   data: [86, 97, 104, 58, 67, 84, 38, 71, 47, 77, 34, 94],
      // },
    ],
    pateintSeenOptions: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
      },
      colors: ["#3475DC", "#ee2400"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        min: 700,
        max: 1300,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        offsetY: 12,
        offsetX: -10,
        itemMargin: {
          horizontal: 5,
          vertical: 7,
        },
      },
      fill: {
        type: "solid",
        colors: ["transparent"],
      },
    },
  },
  {
    title: "Total New Patients in a year",
    subTitle: "New Patients",
    newPatientseries: [
      {
        name: "Total New Patients in a year",
        data: [86, 97, 104, 58, 67, 84, 38, 71, 47, 77, 34, 94],
      },
    ],
    newPatientOptions: {
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
      },
      colors: ["#3475DC"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: yaxisConfig1,
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        offsetY: 12,
        offsetX: -10,
        itemMargin: {
          horizontal: 5,
          vertical: 7,
        },
      },
    },
  },
];

//Encounters Revenue
export const encounterRevenueChartData = [
  {
    donutSeriesTwo: [7, 93],
    donutOptionsTwo: {
      chart: {
        type: "donut",
        width: "100%",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#36ad70", "#ccc"],
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            labels: {
              show: false,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "30px",
                color: "#FF0000",
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex }) {
            if (seriesIndex === 0) {
              return "7%";
            } else {
              return "93%";
            }
          },
        },
      },
      labels: ["Growth ", "Previous Encounters"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        fontSize: "14px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
    },
  },
];
// donutSeriesOne: [20, 80],

// export const totalPatientDonutChart = [
//   {
//     donutSeriesOne: [20, 80],
//     donutOptionsOne: {
//       chart: {
//         type: "donut",
//         width: "100%",
//       },
//       dataLabels: {
//         enabled: false,
//       },
//       legend: {
//         show: false,
//       },
//       colors: ["#3475DC", "#ccc"],
//       plotOptions: {
//         pie: {
//           customScale: 1,
//           donut: {
//             labels: {
//               show: false,
//               name: {
//                 show: false,
//               },
//               value: {
//                 show: true,
//                 fontSize: "30px",
//                 color: "#3475DC",
//                 formatter: function (val) {
//                   return val + "%";
//                 },
//               },
//             },
//           },
//         },
//       },
//       tooltip: {
//         y: {
//           formatter: function (val) {
//             return "20%";
//           },
//         },
//       },
//       labels: [""],
//     },
//   },
// ];

export const totalPatientDonutChart = [
  {
    donutSeriesOne: [20, 80],
    donutOptionsOne: {
      chart: {
        type: "donut",
        width: "100%",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#36ad70", "#ccc"],
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            labels: {
              show: false,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "30px",
                color: "#3475DC",
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex }) {
            if (seriesIndex === 0) {
              return "7.12%";
            } else {
              return "92.88%";
            }
          },
        },
      },
      labels: ["Growth ", "Previous year patients"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        fontSize: "14px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
    },
  },
];

export const totalNewPatientDonutChart = [
  {
    donutSeriesOne: [20, 80],
    donutOptionsOne: {
      chart: {
        type: "donut",
        width: "100%",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#3475DC", "#ccc"],
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            labels: {
              show: false,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "30px",
                color: "#3475DC",
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex }) {
            if (seriesIndex === 0) {
              return "20%";
            } else {
              return "80%";
            }
          },
        },
      },
      labels: ["Growth ", "Previous year patients"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        fontSize: "14px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
    },
  },
];

// SummaryRevenueArea Chart Static Data

export const revenuePerPatientSeriesData = [
  {
    name: "",
    data: [40, 20, 48, 51, 32, 18, 30],
  },
  {
    name: "",
    data: [21, 32, 75, 62, 97, 22, 40],
  },
];
export const revenuePerPatientOptions = {
  chart: {
    type: "area",
    height: 320,
    responsive: true,
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 1,
    colors: ["#3475DC", "#4FD1C5"],
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4,
    labels: {
      formatter: function (val) {
        return `$${val}k`;
      },
    },
  },
  width: "100%",
};

//
export const revenuePerEncounterSeriesData = [
  {
    name: "",
    data: [70, 40, 85, 62, 45, 78, 90],
  },
  {
    name: "",
    data: [33, 55, 70, 25, 80, 65, 45],
  },
];
export const revenuePerEncounterDataOptions = {
  chart: {
    type: "area",
    height: 320,
    responsive: true,
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 1,
    colors: ["#3475DC", "#4FD1C5"],
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4,
    labels: {
      formatter: function (val) {
        return `$${val}k`;
      },
    },
  },
  width: "100%",
};

// Total Encounters Chart Data
export const totalEncountersSeriesData = [
  {
    name: "",
    data: [90, 15, 72, 38, 50, 85, 20],
  },
  {
    name: "",
    data: [62, 45, 80, 33, 55, 70, 25],
  },
];
export const totalEncountersDataOptions = {
  chart: {
    type: "area",
    height: 320,
    responsive: true,
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 1,
    colors: ["#3475DC", "#4FD1C5"],
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4,
  },
  width: "100%",
};

// Encounters Per Patient Chart Data
export const encountersPerPatientSeriesData = [
  {
    name: "",
    data: [25, 70, 40, 88, 60, 45, 30],
  },
  {
    name: "",
    data: [80, 35, 50, 75, 22, 68, 95],
  },
];
export const encountersPerPatientDataOptions = {
  chart: {
    type: "area",
    height: 320,
    responsive: true,
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 1,
    colors: ["#3475DC", "#4FD1C5"],
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4,
  },
  width: "100%",
};
// Encounters Total Patient Chart Data
export const totalPatientSeriesData = [
  {
    name: "",
    data: [55, 90, 15, 70, 40, 88, 60],
  },
  {
    name: "",
    data: [45, 80, 35, 50, 75, 22, 68],
  },
];
export const totalPatientSeriesDataOptions = {
  chart: {
    type: "area",
    height: 320,
    responsive: true,
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 1,
    colors: ["#3475DC", "#4FD1C5"],
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4,
  },
  width: "100%",
};

// Encounters Total Patient Chart Data
export const newPatientSeriesData = [
  {
    name: "",
    data: [85, 30, 55, 72, 20, 45, 65],
  },
  {
    name: "",
    data: [40, 88, 60, 35, 50, 75, 22],
  },
];
export const newPatientOptionsData = {
  chart: {
    type: "area",
    height: 320,
    responsive: true,
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 1,
    colors: ["#3475DC", "#4FD1C5"],
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4,
  },
  width: "100%",
};

//RevenueAreaChart Chart Data

export const revnueAreaChart = [
  {
    title: "Revenue Per Patient",
    series: [
      {
        name: "",
        data: [31, 40, 28, 51, 42, 98, 60],
      },
      {
        name: "",
        data: [11, 32, 45, 32, 34, 42, 10],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 1,
        colors: ["#3475DC", "#4FD1C5"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
        labels: {
          formatter: function (val) {
            return `$${val}k`;
          },
        },
      },

      width: "100%",
    },
  },
  //
  {
    title: "Total Revenue",
    series: [
      {
        name: "",
        data: [10, 20, 19, 40, 64, 98, 60],
      },
      {
        name: "",
        data: [11, 32, 45, 32, 34, 42, 10],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#3475DC", "#4FD1C5"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
        labels: {
          formatter: function (val) {
            return `$${val}k`;
          },
        },
      },

      width: "100%",
    },
  },
  {
    title: "Revenue Per Encounter",
    series: [
      {
        name: "",
        data: [31, 40, 28, 51, 42, 98, 60],
      },
      {
        name: "",
        data: [11, 32, 45, 32, 34, 42, 10],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#3475DC", "#4FD1C5"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
        labels: {
          formatter: function (val) {
            return `$${val}k`;
          },
        },
      },

      width: "100%",
    },
  },
  {
    title: "Total Encounters",
    series: [
      {
        name: "",
        data: [31, 40, 28, 51, 42, 98, 60],
      },
      {
        name: "",
        data: [11, 32, 45, 32, 34, 42, 10],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#3475DC", "#4FD1C5"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
      },

      width: "100%",
    },
  },
  {
    title: "Encounters Per Patient",
    series: [
      {
        name: "",
        data: [31, 40, 28, 51, 42, 98, 60],
      },
      {
        name: "",
        data: [11, 32, 45, 32, 34, 42, 10],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#3475DC", "#4FD1C5"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
      },

      width: "100%",
    },
  },
  {
    title: "Total Patients",
    series: [
      {
        name: "",
        data: [31, 40, 28, 51, 42, 98, 60],
      },
      {
        name: "",
        data: [11, 32, 45, 32, 34, 42, 10],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#3475DC", "#4FD1C5"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
      },

      width: "100%",
    },
  },
  {
    title: "New Patients",
    series: [
      {
        name: "",
        data: [31, 40, 28, 51, 42, 98, 60],
      },
      {
        name: "",
        data: [11, 32, 45, 32, 34, 42, 10],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#3475DC", "#4FD1C5"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
      },

      width: "100%",
    },
  },
];

export const barChartSeriesData = [
  {
    name: "",
    data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
  },
  {
    name: "",
    data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
  },
];
export const barChartOptionsData = {
  chart: {
    type: "bar",
    height: 300,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "70%",
      borderRadius: [5],
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },

  legend: {
    show: false,
  },

  xaxis: {
    categories: [
      "jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
    ],
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4,
    labels: {
      formatter: function (val) {
        if (
          val === 0 ||
          val === 100 ||
          val === 25 ||
          val === 50 ||
          val === 75
        ) {
          return val + "k"; // Append 'k' to the values 0 and 100
        } else {
          return val;
        }
      },
    },
  },
  fill: {
    opacity: 1,
    colors: ["#F39320", "#4FD1C5"],
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const currentDate = new Date().toLocaleDateString("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
      const value = w.globals.series[seriesIndex][dataPointIndex];
      return `<div style="padding: 18px; border-radius:0px",background: red !important; color: black;"><p style="font-size: 16px; font-weight: 600;">${currentDate}</p></div>`;
    },
  },
};

const percentageContract1 = 25;
const percentageContract2 = 16.7;

const totalPercentageContract = percentageContract1 + percentageContract2;
const remainingPercentageContract = 100 - totalPercentageContract;
export const roicDonutChartContractData = [
  {
    donutSeriesOne: [
      percentageContract1,
      percentageContract2,
      remainingPercentageContract,
    ],
    donutOptionsOne: {
      chart: {
        type: "donut",
        width: "100%",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#36ad70", "#ccc"],
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            labels: {
              show: false,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "30px",
                color: "#FF0000",
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex }) {
            if (seriesIndex === 0) {
              return "25%";
            } else if (seriesIndex === 1) {
              return "40%";
            } else {
              return `${remainingPercentageContract.toFixed(1)}%`;
            }
          },
        },
      },
      labels: [
        "Contract due for Nephrologists",
        "Salary increments due for Nephrologists",
        "Remaining data",
      ],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "left",
        verticalAlign: "middle",
        fontSize: "13px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
    },
  },
];
const percentage1 = 40;
const percentage2 = 20;

const totalPercentage = percentage1 + percentage2;
const remainingPercentage = 100 - totalPercentage;
export const roicDonutChartSalaryIncrementtData = [
  {
    donutSeriesTwo: [percentage1, percentage2, remainingPercentage],
    donutOptionsTwo: {
      chart: {
        type: "donut",
        width: "100%",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#36ad70", "#ccc"],
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            labels: {
              show: false,
              name: {
                show: false,
              },
              value: {
                show: true,
                fontSize: "30px",
                color: "#FF0000",
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex }) {
            if (seriesIndex === 0) {
              return "40%";
            } else if (seriesIndex === 1) {
              return "20%";
            } else {
              return `${remainingPercentage.toFixed(1)}%`;
            }
          },
        },
      },
      labels: [
        "Contracts due for Nephrologists",
        "Salary increments due for Nurse Practitioners ",
        "Remaining data",
      ],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "left",
        verticalAlign: "middle",
        fontSize: "13px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
    },
  },
];

export const getFinancialsSuggestionsAns = (url, question) => {
  const data = {
    "/financials": [
      {
        que: "Recommendations for optimizing physician productivity based on active patient count and revenue per physician?",
        ans: "Considering our active patient count of 129 per month and average revenue per physician of $3,300 per day, we could explore strategies to enhance patient engagement or streamline administrative tasks. For instance, implementing telemedicine options or optimizing appointment scheduling to reduce wait times and improve patient throughput could increase physician productivity by 15-20%.",
      },
      {
        que: "Are there any noticeable trends or patterns in revenue per service type over the last year?",
        ans: "Analyzing trends in revenue per service type reveals interesting insights. While revenue from Early CKD and ESRD services remained steady, we observed a 15-20% increase in revenue from Stage 5 CKD services. This trend indicates a growing demand for specialized care in advanced CKD stages, presenting opportunities for targeted service expansion and marketing efforts.",
      },
      {
        que: "Services to focus on expanding for revenue maximization?",
        ans: "Analyzing revenue per service type reveals opportunities for maximizing revenue potential. For instance, data shows a significant increase in revenue from specialized services like ESRD treatment. By focusing on expanding these high-margin services and promoting related ancillary services, such as nutritional counseling or home dialysis programs, we can capitalize on revenue opportunities. Additionally, targeting patient populations with specific healthcare needs, such as diabetic patients at risk for kidney disease, can drive demand for related services and enhance revenue growth.",
      },
      {
        que: "Analyze our revenue data and patient demographics for targeted marketing or outreach?",
        ans: "Analyzing revenue data alongside patient demographics uncovers opportunities for targeted marketing initiatives. For example, data suggests a growing demand for nephrology services among younger adults with risk factors for kidney disease. By tailoring marketing messages and outreach campaigns to resonate with this demographic, we can attract new patients and increase revenue. Implementing paid ads, such as social media advertising can effectively reach and engage our target audience.",
      },
    ],
    "/financials/revenue/summary": [
      {
        que: "Key factors driving the increase in total revenue compared to the previous year?",
        ans: "The increase in total revenue compared to the previous year can be attributed to several factors. For instance, there was a 15% increase in patient volume across all service types, resulting in higher revenue generation. Additionally, improvements in revenue cycle management processes led to a 10% reduction in outstanding balances, contributing to overall revenue growth.",
      },
      {
        que: "Trends in revenue generation month over month for the current year compared to the previous year?",
        ans: "Analyzing revenue generation month over month, we observe a consistent upward trend in the current year compared to the previous year. This indicates sustained growth in revenue generation over time, potentially reflecting increased patient volume or improved billing processes.",
      },
      {
        que: "Insights into revenue contribution from different payers like Medicare, private insurance, and self-pay patients, and its evolution over time?",
        ans: "Certainly. The revenue contribution from Medicare remains the largest, accounting for 60% of total revenue, followed by private insurance at 30%, and self-pay patients at 10%. Over time, we've observed a gradual increase in revenue from private insurance, indicating potential shifts in payer demographics or reimbursement rates.",
      },
      {
        que: "Correlation between patient acuity levels and revenue generation? Impact on resource allocation strategies?",
        ans: "Examining the correlation between patient acuity levels and revenue generation reveals notable trends. Patients with higher acuity levels, such as those with advanced CKD stages or comorbid conditions, tend to generate higher revenue due to increased service utilization and complexity of care. By allocating resources based on patient acuity levels, we can ensure efficient resource utilization and prioritize high-value care delivery. Additionally, implementing care coordination initiatives for complex patients can further enhance revenue generation and patient outcomes.",
      },
      {
        que: "Any trends in accounts receivable aging and bad debt write-offs? Strategies to improve cash flow management?",
        ans: "Analyzing trends in accounts receivable aging and bad debt write-offs reveals important insights into cash flow management. While our accounts receivable aging remains within industry norms, we've observed a slight increase in bad debt write-offs due to delayed patient payments. Implementing proactive patient financial counseling and payment plans has helped mitigate this trend, reducing bad debt write-offs by 10% compared to last year. By optimizing revenue cycle workflows and enhancing patient communication strategies, we can further improve cash flow management and minimize revenue loss.",
      },
    ],
    "/financials/revenue/PatientMonth": [
      {
        que: "Key insights from the Target vs. Actual patient visits comparison graph? Strategies for enhancing patient visit forecasting?",
        ans: "Analyzing the comparison graph, we notice that we generally meet or exceed expected patient visit targets. However, there are occasional discrepancies, particularly in certain months or CKD stages. For instance, in November, we fell short of the expected visit target for early-stage CKD patients by 15%. To improve forecasting accuracy, we'll refine our predictive models using historical visit data and implement proactive patient engagement strategies, such as appointment reminders and personalized outreach campaigns, to minimize no-show rates and optimize patient visit adherence.",
      },
      {
        que: "Insights on new patient trends and their impact on practice growth?",
        ans: "Examining new patient influx, we've noticed a consistent growth trend over the past six months, with an average of 100 new patients per month. This 10% increase correlates with targeted marketing efforts and expanded referral networks. This influx significantly contributes to practice growth, fostering a robust patient base and enhancing community outreach efforts.",
      },
      {
        que: "Average monthly patient volume per provider across clinic locations? Impact on operational efficiency?",
        ans: "Analyzing the average monthly patient volume per provider across clinic locations reveals variations in operational efficiency. For instance, our downtown clinic sees an average of 200 patients per provider per month, while suburban clinics average 150 patients. This discrepancy may indicate differences in patient demographics, appointment availability, or provider availability. By optimizing scheduling protocols and resource allocation strategies, we can enhance operational efficiency and ensure equitable patient access across all locations.",
      },
      {
        que: "Trends in patient visit volumes during peak vs. off-peak hours? Impact on clinic operations?",
        ans: "Analyzing trends in patient visit volumes during peak vs. off-peak hours reveals important considerations for clinic operations and staffing. We observe that peak hours, typically mid-morning and late afternoon, experience higher patient volumes, requiring additional staffing and resource allocation to maintain quality care delivery and minimize wait times. Conversely, off-peak hours, such as early morning or late evening, have lower patient volumes, allowing for resource optimization and staff training opportunities. Understanding these trends enables us to optimize clinic workflows and enhance patient access during high-demand periods.",
      },
    ],
  };
  const findPageData = data?.[url];
  return findPageData?.find(({ que }) => que === question);
};
export const getFinancialsSuggestionsData = (url) => {
  const data = {
    "/financials": [
      {
        que: "Recommendations for optimizing physician productivity based on active patient count and revenue per physician?",
      },
      {
        que: "Are there any noticeable trends or patterns in revenue per service type over the last year?",
      },
      {
        que: "Services to focus on expanding for revenue maximization?",
      },
      {
        que: "Analyze our revenue data and patient demographics for targeted marketing or outreach?",
      },
    ],
    "/financials/revenue/summary": [
      {
        que: "Key factors driving the increase in total revenue compared to the previous year?",
      },
      {
        que: "Trends in revenue generation month over month for the current year compared to the previous year?",
      },
      {
        que: "Insights into revenue contribution from different payers like Medicare, private insurance, and self-pay patients, and its evolution over time?",
      },
      {
        que: "Correlation between patient acuity levels and revenue generation? Impact on resource allocation strategies?",
      },
      {
        que: "Any trends in accounts receivable aging and bad debt write-offs? Strategies to improve cash flow management?",
      },
    ],
    "/financials/revenue/PatientMonth": [
      {
        que: "Key insights from the Target vs. Actual patient visits comparison graph? Strategies for enhancing patient visit forecasting?",
      },
      {
        que: "Insights on new patient trends and their impact on practice growth?",
      },
      {
        que: "Average monthly patient volume per provider across clinic locations? Impact on operational efficiency?",
      },
      {
        que: "Trends in patient visit volumes during peak vs. off-peak hours? Impact on clinic operations?",
      },
    ],
  };
  return data?.[url] ?? [];
};

export const getLiveTrancript = (name) => {
  const data = {
    "Chloe Martin": `
<strong>Chloe Martin</strong>: I'm feeling exhausted and drained. I've lost my appetite, my legs are swollen, and I've packed on 15 pounds. Something's just not right.<br/>
<strong>Dr. Blake</strong>: Chloe Martin, it seems like your kidney function is deteriorating, and you're heading towards what we call end-stage kidney failure. Your kidneys aren't able to support your daily life activities anymore.<br/>
<strong>Chloe Martin</strong>: What does that entail?<br/>
<strong>Dr. Blake</strong>: It means you'll likely need dialysis and be evaluated for a transplant. We'll need to provide you with more intensive education on your diet to manage this.<br/>
<strong>Chloe Martin</strong>: What else should I be doing?<br/>
<strong>Dr. Blake</strong>: We'll need to monitor you closely, so I want you to come back to the clinic every two weeks. Additionally, I'd like you to see a dietitian for a tailored plan and to understand your options between dialysis and transplant.<br/>
`,
    "Sophia Patel": `
<strong>Sophia</strong>: Doc, I have been feeling really fatigue and tired. Finally, my family doctor got the blood work that showed abnormal blood test, that’s why, I am here.<br/>
<strong>Dr. Blake</strong>: tell me more about the frequency of the diarrhea, are you feeling dizzy or light headed, or during this time, your blood pressure has been low. <br/>
<strong>Sophia</strong>: yeah, I’ve been feeling really dizzy and light headed. I almost passed out last week. I am trying to drink much fluid, but I am unable to damp.<br/>
<strong>Dr. Blake</strong>: Well, I am looking it should clinical exam. It seems like you are really dehydrated, your blood pressure is low. I would suggest you should stop taking high blood pressure medications for having acute kidney injury. I believe I consider giving you intravenous fluid and we will repeat your blood work. We’ll give you fluid in a couple and will see again tomorrow.<br/>
`,
  };

  return data?.[name];
};

export const desiredOrder = [
  "na+",
  "k+",
  "co2",
  "bun",
  "cr",
  "egfr",
  "mg",
  "ca",
  "pth",
  "hpo4",
  "albumin",
  "hba1c",
  "wbc",
  "hb",
  "hct",
  "plts",
];

export const hardCodedLabs = [
  {
    code: "UA: protein",
    val: "300 mg/dl",
    range: "100-150 mg/dl",
  },
  {
    code: "UA: Blood",
    val: "negative",
    range: "negative",
  },
  {
    code: "UA: protein/Cr",
    val: "3.2 grams",
    range: "less than 0.2 grams",
  },
];

export const getRiskInfo = (val) => {
  let stat = "";
  if (val <= 3) {
    stat = "low";
  } else if (val > 3) {
    stat = "moderate";
  } else {
    stat = "high";
  }
  return smartSummaryToolTip?.find(({ status }) => status === stat);
};

export const smartSummaryToolTip = [
  {
    data: [
      { statusHeading: "Low Risk:" },
      {
        heading: "Controlled Conditions:",
        desc: `
      No presence of chronic illnesses or only two conditions
      from the specified list are under control.
      <strong>
      No hospitalization within the last 90 days, verified by
      the absence of specific CPT codes or if the last hospital
      admission date is older than 90 days.`,
      },
      {
        heading: "No Recent Hospitalization:",
        desc: `
      No hospitalization within the last 90 days, verified by
      the absence of specific CPT codes or if the last hospital
      admission date is older than 90 days.`,
      },
    ],
    status: "low",
  },
  {
    data: [
      { statusHeading: "Moderate:" },
      {
        heading: "Multiple Chronic Conditions:",
        desc: `
      Presence of three or more specified chronic illnesses or
      one uncontrolled condition from the provided list.`,
      },
      {
        heading: "Recent Healthcare Utilization:",
        desc: `
      One hospitalization or ER visit in the last 90 days,
      excluding events related to specific conditions. This
      includes specific CPT codes for hospitalizations (99221 to
      99223, 99231 to 99233) and ER visits (99281 to 99288), and
      visits recorded at a place of service coded as "23".
      Additionally, specific ICD codes must not be present.`,
      },
    ],
    status: "moderate",
  },
  {
    data: [
      { statusHeading: "High Risk:" },
      {
        heading: "Multiple Uncontrolled Conditions:",
        desc: `
      Presence of three or more specified chronic illnesses with
      at least one uncontrolled condition, determined by
      specific thresholds or diagnostic criteria.`,
      },
      {
        heading: "Recent Healthcare Utilization:",
        desc: `
     Either a hospitalization within the last 30 days or an ER
      visit within the last 90 days, confirmed by specific CPT
      codes or place of service.`,
      },
      {
        heading: "Critical Healthcare Events:",
        desc: `
     One or more hospitalizations or ER visits within the last
      90 days due to specified critical conditions.`,
      },
      {
        heading: "Optimal Start Precautions:",
        desc: `
     No optimal start for patients with eGFR less than 20,
      necessitating a checklist for dialysis decision, access
      point creation, and vein mapping.`,
      },
      {
        heading: "PHQ-9 Monitoring:",
        desc: `
     For CKD-4 & 5 patients, confirmation of PHQ-9 form
      completion twice a year, with alerts for assessments.
      Referral suggested if PHQ-9 score is 9 or greater.`,
      },
    ],
    status: "high",
  },
];

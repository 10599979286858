import { createSlice } from "@reduxjs/toolkit";

const initialAppointmentState = {
  appointmentData: {},
  isAppointmentLoading: false,
  isAppointmentUpdated: false,
  resetAppointmentLoading: false,
  resetAppointmentSuccess: false,
  isUpdateAppointmentLoading: false,
};

const name = "specificAppointment";

export const specificAppointmentSlice = createSlice({
  name,
  initialState: initialAppointmentState,
  reducers: {
    getAppointment: (state, action) => {
      state.isAppointmentLoading = true;
    },
    resetAppointments: (state, action) => {
      state.resetAppointmentLoading = true;
    },
    getAppointmentSuccess: (state, action) => {
      state.isAppointmentLoading = false;
      state.appointmentData = action.payload;
    },
    getAppointmentFail: (state, action) => {
      state.isAppointmentLoading = false;
      state.appointmentData = {};
    },
    updateAppointment: (state, action) => {
      state.isUpdateAppointmentLoading = true;
    },
    updateAppointmentSuccess: (state, action) => {
      state.isUpdateAppointmentLoading = false;
      state.isAppointmentUpdated = true;
    },
    updateAppointmentFail: (state, action) => {
      state.isUpdateAppointmentLoading = false;
      state.isAppointmentUpdated = false;
    },
    resetAppointmentsSuccess: (state, action) => {
      state.resetAppointmentLoading = false;
      state.resetAppointmentSuccess = true;
    },
    resetAppointmentsFail: (state, action) => {
      state.resetAppointmentLoading = false;
      state.resetAppointmentSuccess = false;
    },
    clearAppointmentData: (state, action) => {
      state.isAppointmentLoading = false;
      state.isAppointmentUpdated = false;
      state.appointmentData = {};
    },
    clearResetAppointmentData: (state, action) => {
      state.resetAppointmentLoading = false;
      state.resetAppointmentSuccess = false;
    },
  },
});

export const {
  clearResetAppointmentData,
  getAppointment,
  clearAppointmentData,
} = specificAppointmentSlice.actions;

export default specificAppointmentSlice.reducer;

export const getSpecificAppointmentDetails = (appointmentId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/appointments/${appointmentId}/`,
  },
  type: "getAppointment",
});

export const updateAppointment = (patientId, appointmentId, data) => ({
  payload: {
    apiName: name,
    method: "PATCH",
    data,
    url: `/api/patients/${patientId}/appointments/${appointmentId}/`,
  },
  type: "updateAppointment",
});

export const resetAppointments = () => ({
  payload: {
    apiName: name,
    method: "POST",
    url: `/api/appointments/resetall/`,
  },
  type: "resetAppointments",
});

import { createSlice } from "@reduxjs/toolkit";

export const initialPharmacyState = {
  pharmacyList: [],
  pharmacyIsLoading: false,
  pharmacyAddingIsLoading: false,
  pharmacyAddedSuccess: false,
  pharmacyUpdatingIsLoading: false,
  pharmacyUpdateSuccess: false,
  pharmacyOptions: [],
};

const name = "pharmacy";

export const pharmacySlice = createSlice({
  name,
  initialState: initialPharmacyState,
  reducers: {
    getPharmacy: (state, action) => {
      state.pharmacyIsLoading = true;
    },
    getPharmacySuccess: (state, action) => {
      state.pharmacyIsLoading = false;
      state.pharmacyList = action.payload?.results;
      state.pharmacyOptions = action.payload?.results?.map(
        ({ pharmacy_name, id }) => ({ value: id, label: pharmacy_name })
      );
    },
    getPharmacyFail: (state, action) => {
      state.pharmacyList = [];
      state.pharmacyIsLoading = false;
    },
    clearPharmacyData: (state, action) => {
      state.pharmacyIsLoading = false;
    },

    //adding pharmacy
    addPharmacy: (state, action) => {
      state.pharmacyAddingIsLoading = true;
      state.pharmacyAddedSuccess = false;
    },
    addPharmacySuccess: (state, action) => {
      state.pharmacyAddingIsLoading = false;
      state.pharmacyAddedSuccess = true;
    },
    addPharmacyFail: (state, action) => {
      state.pharmacyAddingIsLoading = false;
      state.pharmacyAddedSuccess = false;
    },

    //updating pharmacy
    updatePharmacy: (state, action) => {
      state.pharmacyUpdatingIsLoading = true;
      state.pharmacyUpdateSuccess = false;
    },
    updatePharmacySuccess: (state, action) => {
      state.pharmacyUpdatingIsLoading = false;
      state.pharmacyUpdateSuccess = true;
    },
    updatePharmacyFail: (state, action) => {
      state.pharmacyUpdatingIsLoading = false;
      state.pharmacyUpdateSuccess = false;
    },

    ClearPharmacyStates: (state, action) => {
      state.pharmacyAddingIsLoading = false;
      state.pharmacyAddedSuccess = false;
      state.pharmacyUpdatingIsLoading = false;
      state.pharmacyUpdateSuccess = false;
    },
  },
});

export const { getPharmacy, clearPharmacyData, ClearPharmacyStates } =
  pharmacySlice.actions;

export default pharmacySlice.reducer;

export const getPharmacyList = () => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/pharmacy/`,
  },
  type: "getPharmacy",
});
export const AddPharmacy = (data) => ({
  payload: {
    apiName: name,
    method: "post",
    data,
    url: `/api/pharmacy/`,
  },
  type: "addPharmacy",
});
export const UpdatePharmacyById = () => ({
  payload: {
    apiName: name,
    method: "put",
    url: `/api/pharmacy/`,
  },
  type: "updatePharmacy",
});

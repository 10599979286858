import { Box, Grid } from "@mui/material";
import Accordian from "components/common/Accordian/Accordian";
import Text from "components/common/Typography/Text";
import { useSelector } from "react-redux";
import { useNotesHistoryModalStyles } from "styles/Modals/ViewNotesHistoryModalStyle";

const MedicationDetail = () => {
  const classes = useNotesHistoryModalStyles();

  const {
    medicationsIsLoading,
    medicationsActiveData,
    medicationsInActiveData,
  } = useSelector((state) => state.medications);

  const createDataDescription = (data, index) => {
    return (
      <Grid key={index} container rowSpacing={3} sx={{ marginBottom: "5px" }}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box>
            <Text variant="propertyLabel">Dosage</Text>
          </Box>
          <Box>
            <Text variant="bodyXs" fontWeight={400}>
              {data?.dosage}
            </Text>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box>
            <Text variant="propertyLabel">Dispense</Text>
          </Box>
          <Box>
            <Text variant="bodyXs">{data?.dispense}</Text>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box>
            <Text variant="propertyLabel">Instructions</Text>
          </Box>
          <Box>
            <Text variant="bodyXs">{data?.instructions}</Text>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box>
            <Text variant="propertyLabel">Purpose</Text>
          </Box>
          <Box>
            <Text variant="bodyXs">{data?.purpose}</Text>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      {medicationsActiveData.length > 0 ? (
        medicationsActiveData?.map((ele, i) => (
          <Accordian
            data={createDataDescription(ele, i)}
            isActive={true}
            classes={classes}
            title={ele.drug_name}
            isLoading={medicationsIsLoading}
          />
        ))
      ) : (
        <Box>
          <Text variant="propertyLabel">No data found</Text>
        </Box>
      )}
      <Text variant="bodyM" marginY="20px">
        <span data-testid="inactive-medication-detail-heading">Inactive</span>
      </Text>
      {medicationsInActiveData.length > 0 ? (
        medicationsInActiveData?.map((ele, i) => (
          <Accordian
            data={createDataDescription(ele, i)}
            classes={classes}
            title={ele?.drug_name}
            isActive={false}
            isLoading={medicationsIsLoading}
          />
        ))
      ) : (
        <Box>
          <Text variant="propertyLabel">No data found</Text>
        </Box>
      )}
    </Box>
  );
};

export default MedicationDetail;

import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const data = [
  23250, 34464, 32192, 39414, 29614, 33079, 36147, 30748, 37142, 28550, 34685,
  38507,
];

const yaxisConfig = {
  min: Math.floor(Math.min(...data) / 1000) * 1000,
  max: Math.ceil(Math.max(...data) / 1000) * 1000,
  labels: {
    formatter: function (value) {
      const inThousands = value / 1000;
      return inThousands.toFixed(0) + "k";
    },
  },
};

const NewPatientGraph = ({ isRevenu }) => {
  const [chartData] = useState({
    series: [
      {
        name: "New Patients",
        data: [86, 97, 104, 58, 67, 84, 38, 71, 47, 77, 34, 94],
      },
    ],
    series2: [
      {
        name: "Stage 4 CKD",
        data: data,
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: isRevenu
          ? function (value) {
              const inThousands = value / 1000;
              return inThousands.toFixed(1) + "k";
            }
          : function (value) {
              return value;
            },
      },
      markers: {
        size: 5,
      },
      stroke: {
        curve: "straight",
        colors: ["#3475DC"],
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      labels: ["New Patient", "Stage 4 CKD"],
      yaxis: isRevenu
        ? yaxisConfig
        : {
            min: 0,
            max: 120,
            tickAmount: 6,
            labels: {
              formatter: function (val) {
                return `${val}`;
              },
            },
          },
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        offsetY: 2,
        offsetX: 10,
        itemMargin: {
          horizontal: 5,
          vertical: 7,
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={isRevenu ? chartData?.series2 : chartData?.series}
          type="line"
          height={300}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default NewPatientGraph;

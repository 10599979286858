import { Chip } from "@mui/material";

const DraggableChip = ({
  className = "",
  label = "",
  onDelete,
}) => {
  return (
    <Chip
      className={className}
      label={label}
      size="small"
      variant="outlined"
      {...(onDelete ? { onDelete: onDelete } : {})}
        // onDelete={() => onDelete && onDelete()}
    />
  );
};

export default DraggableChip;

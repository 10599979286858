import { Box, Grid } from "@mui/material";
import { frequencyOptions } from "assets/static";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import SelectField from "components/common/FormComponents/SelectField";
import CustomModal from "components/common/Modal/CustomModal";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getMedicationsList } from "redux/features/Medications/MedicationsSlice";
import {
  getMedicinesByEncounter,
  getMedicinesList,
  updateMedicineByEncounter,
} from "redux/features/Medications/MedicinesSlice";
import {
  UpdateOrderMedicationsById,
  clearOrderMedicationsData,
  getMedicationById,
  getOrderMedicationsList,
} from "redux/features/Medications/OrderMedicationsSlice";
import PharmacyModal from "../../PharmacyModal";

const PlanMedicationModal = ({
  medicineId,
  getMedicines,
  setMedicineId,
  medicinesData,
  medicinesIsLoading,
  setShowMedicationModal,
}) => {
  const dispatch = useDispatch();
  let { patientId, appointmentId } = useParams();
  const [isModalOpen, setIsOpenModal] = useState(false);
  const [medName, setMedName] = useState("");
  const { pharmacyOptions } = useSelector((state) => state.pharmacy);
  const {
    specificOrderMedication,
    orderMedicationsAddingIsLoading,
    orderMedicationsAddedSuccess,
    orderMedicationsList,
    orderMedicationsUpdatingIsLoading,
    orderMedicationsUpdateSuccess,
  } = useSelector((state) => state.orderMedications);
  const { medicinesEncounterData } = useSelector((state) => state.medicines);

  const formik = useFormik({
    initialValues: {
      patient_id: patientId,
      prescribed_on: new Date() ?? null,
      discontinued_on: null,
      dispensable_drug_id: medicineId
        ? specificOrderMedication?.medicine_id
        : null,
      drug_name: medicineId ? specificOrderMedication?.drug_name : "",
      status_ind: medicineId ? specificOrderMedication?.status_ind : 1,
      instructions: medicineId ? specificOrderMedication?.instructions : "",
      dosage: medicineId ? specificOrderMedication?.dosage : "",
      dispense: medicineId ? specificOrderMedication?.dispense : "",
      encounter_id: medicineId
        ? specificOrderMedication?.encounter_id
        : appointmentId,
      pharmacy_id: medicineId ? specificOrderMedication?.pharmacy_id : null,
    },
    // validationSchema: OrderMedicationsSchema,
    onSubmit: (values) => {
      if (medicineId) {
        const medicineIdValue = specificOrderMedication?.medicine_id;
        values["medicine_id"] = medicineIdValue;
      }
      values["drug_name"] = medName;
      dispatch(
        medicineId
          ? UpdateOrderMedicationsById(values, specificOrderMedication?.id)
          : /* This api is designed in such a way that previous meds should be
           passed in with the new one to add a new med.*/
            updateMedicineByEncounter({
              encounter_id: appointmentId,
              medicines: [values, ...medicinesEncounterData],
            })
      );
      setShowMedicationModal(false);
    },
  });

  useEffect(() => {
    medicineId !== null &&
      formik.setValues({
        patient_id: patientId,
        prescribed_on: new Date() ?? null,
        discontinued_on: null,
        dispensable_drug_id: medicineId
          ? specificOrderMedication?.medicine_id
          : null,
        drug_name: medicineId ? specificOrderMedication?.drug_name : "",
        status_ind: medicineId ? specificOrderMedication?.status_ind : 1,
        instructions: medicineId ? specificOrderMedication?.instructions : "",
        dosage: medicineId ? specificOrderMedication?.dosage : "",
        dispense: medicineId ? specificOrderMedication?.dispense : "",
        encounter_id: medicineId
          ? specificOrderMedication?.encounter_id
          : appointmentId,
        pharmacy_id: medicineId ? specificOrderMedication?.pharmacy_id : null,
      });
  }, [specificOrderMedication, medicineId]);

  useEffect(() => {
    medicineId !== null &&
      orderMedicationsList?.length &&
      dispatch(getMedicationById(medicineId));
  }, [medicineId]);

  useEffect(() => {
    orderMedicationsAddedSuccess && toast.success("Prescription Added");
    // medicine for enocunter
    orderMedicationsAddedSuccess &&
      dispatch(getMedicinesByEncounter(appointmentId));
    orderMedicationsAddedSuccess && setShowMedicationModal(false);
    orderMedicationsAddedSuccess && setMedicineId(null);
    orderMedicationsAddedSuccess &&
      dispatch(getOrderMedicationsList(appointmentId));
    orderMedicationsAddedSuccess && dispatch(getMedicationsList(appointmentId));
    orderMedicationsAddedSuccess && dispatch(clearOrderMedicationsData());
  }, [orderMedicationsAddedSuccess]);

  useEffect(() => {
    orderMedicationsUpdateSuccess && toast.success("Prescription Updated");
    orderMedicationsUpdateSuccess &&
      dispatch(getMedicationsList(appointmentId));
    // medicine for enocunter
    orderMedicationsUpdateSuccess &&
      dispatch(getMedicinesByEncounter(appointmentId));
    orderMedicationsUpdateSuccess && setShowMedicationModal(false);
    orderMedicationsUpdateSuccess && setMedicineId(null);
    orderMedicationsUpdateSuccess &&
      dispatch(getOrderMedicationsList(appointmentId));
    orderMedicationsUpdateSuccess && dispatch(clearOrderMedicationsData());
  }, [orderMedicationsUpdateSuccess]);

  useEffect(() => {
    dispatch(getMedicinesList("aba"));
  }, []);

  function setMedicineName(e, v) {
    setMedName(v?.label);
  }

  return (
    <Box>
      <CustomModal
        fullWidth
        maxWidth="sm"
        setOpen={setIsOpenModal}
        open={isModalOpen}
        title="Add Pharmacy"
      >
        <PharmacyModal setIsOpenModal={setIsOpenModal} />
      </CustomModal>
      <Grid container rowSpacing={1} columnSpacing={2} alignItems={"flex-end"}>
        <SelectField
          {...(medicineId ? { key: specificOrderMedication?.pharmacy_id } : {})}
          name={"pharmacy_id"}
          defaultValue={
            medicineId && pharmacyOptions
              ? pharmacyOptions?.find(
                  ({ value }) => value === specificOrderMedication?.pharmacy_id
                )
              : {}
          }
          required
          options={pharmacyOptions}
          formik={formik}
          label={"Pharmacy"}
          placeholder="Select One"
        />
        <Grid item xs={12} sm={6} md={6}>
          <Button
            disabled={true}
            onClick={() => setIsOpenModal(true)}
            text="+ Add New Pharmacy"
            border={"1px solid #F8CADE"}
            borderRadius="5px"
            backgroundColor="#FFEDF5"
            color="#272A40"
            btnColor="#272A40"
            sx={{ height: "33px", marginTop: "12px", width: "200px" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        sx={{ marginTop: "1px" }}
      >
        <SelectField
          required
          {...(medicineId ? { key: specificOrderMedication?.dosage } : {})}
          formik={formik}
          defaultValue={
            medicineId
              ? {
                  label: specificOrderMedication?.drug_name,
                  value: medicineId,
                }
              : {}
          }
          disabled={medicineId ? true : false}
          label="Medication"
          onSearch={getMedicines}
          extendedSearchOptions={["description"]}
          showMoreInLabel={{ show: true, value: "description" }}
          loading={medicinesIsLoading}
          options={medicinesData}
          onChange={setMedicineName}
          placeholder="Search Medication"
          name={"dispensable_drug_id"}
        />
        <SelectField
          {...(medicineId ? { key: specificOrderMedication?.dosage } : {})}
          defaultValue={
            medicineId
              ? frequencyOptions?.find(
                  ({ value }) => value === specificOrderMedication?.dosage
                )
              : {}
          }
          required
          options={frequencyOptions}
          formik={formik}
          label="Frequency"
          placeholder="once a day"
          name={"dosage"}
        />
        <InputField
          required
          formik={formik}
          label="Refills"
          placeholder="No"
          name={"dispense"}
        />
        <InputField
          required
          formik={formik}
          name={"instructions"}
          label="Instructions"
          placeholder="Add Instructions Here"
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Button
          text={"Cancel"}
          onClick={() => {
            setShowMedicationModal && setShowMedicationModal(false);
            dispatch(clearOrderMedicationsData());
            setMedicineId(null);
          }}
          sx={{ marginRight: "10px", height: "33px", width: "150px" }}
          border={"1px solid #272A40"}
          borderRadius="5px"
          backgroundColor="#FFFFFF"
          color="#272A40"
          btnColor="#272A40"
        />
        <Button
          isLoading={
            orderMedicationsAddingIsLoading || orderMedicationsUpdatingIsLoading
          }
          onClick={() => formik.submitForm()}
          text={"Save Medication"}
          sx={{ height: "33px", width: "150px" }}
          borderRadius="5px"
          backgroundColor="#315FFF"
          color="#fff"
          btnColor="#fff"
        />
      </Box>
    </Box>
  );
};

export default PlanMedicationModal;

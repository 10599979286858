import { Box, Grid } from "@mui/material";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AddPharmacy,
  ClearPharmacyStates,
  getPharmacyList,
} from "redux/features/Pharmacy/PharmacySlice";
import { AddPharmacySchema } from "validations/Modals/pharmacy.schema";

const PharmacyModal = ({ setIsOpenModal }) => {
  const dispatch = useDispatch();
  const { account_id } = useSelector((state) => state.auth);
  const { pharmacyAddedSuccess, pharmacyAddingIsLoading } = useSelector(
    (state) => state.pharmacy
  );
  const formik = useFormik({
    initialValues: {
      pharmacy_name: "",
      address: "",
      account: account_id,
      phone: "423423423434",
    },
    validationSchema: AddPharmacySchema,
    onSubmit: (values) => {
      dispatch(AddPharmacy(values));
    },
  });

  useEffect(() => {
    if (pharmacyAddedSuccess) {
      dispatch(getPharmacyList());
      dispatch(ClearPharmacyStates());
      toast.success("Pharmacy Added");
      formik.resetForm();
      setIsOpenModal(false);
    }
  }, [pharmacyAddedSuccess]);
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        sx={{ marginTop: "1px" }}
      >
        <InputField
          required
          formik={formik}
          name={"pharmacy_name"}
          label="Add Pharmacy"
          placeholder="Type pharmacy name..."
        />
        <InputField
          required
          formik={formik}
          name={"address"}
          label="Pharmacy Address"
          placeholder="Type address..."
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Button
          text={"Back"}
          onClick={() => {
            setIsOpenModal && setIsOpenModal(false);
            dispatch(ClearPharmacyStates());
            formik.resetForm();
          }}
          sx={{ marginRight: "10px", height: "33px", width: "150px" }}
          border={"1px solid #272A40"}
          borderRadius="5px"
          backgroundColor="#FFFFFF"
          color="#272A40"
          btnColor="#272A40"
        />
        <Button
          isLoading={pharmacyAddingIsLoading}
          onClick={() => formik.submitForm()}
          text={"Save Pharmacy"}
          sx={{ height: "33px", width: "150px" }}
          borderRadius="5px"
          backgroundColor="#EB87B2"
          color="#000"
          btnColor="#000"
        />
      </Box>
    </Box>
  );
};

export default PharmacyModal;

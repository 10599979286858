import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  ClickAwayListener,
  Collapse,
  Fade,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
} from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DollarIcon from "../../../../assets/svg/light/dollar_icon.svg";
import PersonIcon from "../../../../assets/svg/light/person_icon.svg";
import TrendUpIcon from "../../../../assets/svg/light/trend_icon.svg";

const useStyles = makeStyles((theme) => ({
  mainListItemWrapper: {
    "& .MuiCollapse-root .MuiTypography-root": {
      fontSize: "13px",
      marginLeft: "30px",
    },
  },
  mainListItemButton: {
    borderRadius: "12px",
    "&.Mui-selected": {
      borderRadius: "12px",
      backgroundColor: "#EBEDF9 !important",
      "& .MuiTypography-root": {
        fontWeight: "600",
      },
    },
    "&:hover": {
      borderRadius: "12px",
      bgcolor: theme.palette.action.hover,
    },
  },
  subListItemButton: {
    pl: 4,
    borderRadius: "12px",
    "&:hover": {
      borderRadius: "12px",
      bgcolor: theme.palette.action.hover,
    },
  },
}));

const sections = [
  { id: "overview", icon: TrendUpIcon, text: "Overview", url: "/financials" },
  // { id: "quickReports", icon: TimeIcon, text: "Quick Reports" },
  // { id: "optimalStart", icon: StarIcon, text: "Optimal Start" },
  { id: "revenue", icon: DollarIcon, text: "Revenue" },
  { id: "patients", icon: PersonIcon, text: "Patients" },
];

export const MenuComponent = ({
  props,
  setOpen,
  open,
  setPlacement,
  placement,
  anchorEl,
}) => {
  const classes = useStyles();
  const [openSection, setOpenSection] = useState(null);
  const navigate = useNavigate();
  const handleClick = (section, url) => {
    setOpenSection(openSection === section ? null : section);
    url && navigate(url);
  };
  const handleClickAway = () => {
    if (open) {
      setOpen(false);
    }
  };

  return (
    <Popper
      sx={{ zIndex: 1200 }}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Box sx={{ p: 1, borderRadius: "20px" }}>
                <List
                  sx={{
                    width: "250px",
                    bgcolor: "background.paper",
                    padding: "12px",
                    borderRadius: "12px",
                  }}
                  className={classes.mainListItemWrapper}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  {sections.map(({ id, icon: Icon, text, url }, index) => (
                    <React.Fragment key={id}>
                      <ListItemButton
                        onClick={() => handleClick(id, url)}
                        selected={
                          openSection === id ||
                          (index === 0 && openSection === null)
                        }
                        className={classes.mainListItemButton}
                      >
                        <ListItemIcon
                          sx={{ minWidth: "0px", marginRight: "12px" }}
                        >
                          <img src={Icon} alt={`${text} Icon`} />
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {index !== 0 &&
                          (openSection === id ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          ))}
                      </ListItemButton>
                      <Collapse
                        in={
                          openSection === id ||
                          (index === 0 && openSection === null)
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {/* {id === "quickReports" && (
                            <React.Fragment>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="ESRD Patients" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Late CKD Patients" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Practice Revenue" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Others" />
                              </ListItemButton>
                            </React.Fragment>
                          )}
                          {id === "optimalStart" && (
                            <React.Fragment>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Summary" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Drivers" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Home Dialysis" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="New Start Roster" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Late Stage Roster" />
                              </ListItemButton>
                            </React.Fragment>
                          )} */}
                          {id === "revenue" && (
                            <React.Fragment>
                              <ListItemButton
                                className={classes.subListItemButton}
                                onClick={() =>
                                  navigate("/financials/revenue/summary")
                                }
                              >
                                <ListItemText primary="Summary" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Office Service" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Hospital Services" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="MCP Service" />
                              </ListItemButton>
                            </React.Fragment>
                          )}
                          {id === "patients" && (
                            <React.Fragment>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Roster" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Follow-Up Roster" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                                onClick={() =>
                                  navigate("/financials/revenue/PatientMonth")
                                }
                              >
                                <ListItemText primary="Patients Per Month" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="CKD Comparison" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="CKD Patients/BMI" />
                              </ListItemButton>
                              <ListItemButton
                                className={classes.subListItemButton}
                              >
                                <ListItemText primary="Abnormal Labs" />
                              </ListItemButton>
                            </React.Fragment>
                          )}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ))}
                </List>
              </Box>
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

export default MenuComponent;

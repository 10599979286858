import { Box } from "@mui/material";
import TabPill from "components/common/Tabs/TabPill";
import Text from 'components/common/Typography/Text';
import { useSearchParams } from "react-router-dom";
import MyTeam from "../MyTeam";
import Personalization from "../Personalization";
import PrivacySecurity from "../PrivacySecurity";
import ProfileSettings from "../ProfileSettings";
import SupportFaq from "../SupportFaq";

const SettingsWrapper = () => {
  let [queryParameters] = useSearchParams()

  let tabsData = [
    {
      title: "Profile Settings",
      tab: <ProfileSettings />,
      value: 0,
    },
    {
      title: "My Team",
      tab: <MyTeam />,
      value: 1,
    },
    {
      title: "Personalization",
      tab: <Personalization />,
      value: 2,
    },
    {
      title: "Privacy and Security",
      tab: <PrivacySecurity />,
      value: 3,
    },
    {
      title: "Support and FAQs",
      tab: <SupportFaq />,
      value: 4,
    },
  ];

  return (
    <>
      <Box className='title-box' sx={{ padding: "5px 15px" }}>
        <Text id="title-settings" variant="h1" fontWeight="700">
          Settings
        </Text>
      </Box>
      <TabPill childrenArray={tabsData} isSettings={true} defaultValue={(Number(queryParameters.get("tab")) - 1) >= 5 ? 0 : (Number(queryParameters.get("tab")) - 1)} />
    </>
  );
};

export default SettingsWrapper;

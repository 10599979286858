import { createSlice } from '@reduxjs/toolkit'

export const initialHealthSummaryState = {
    healthSummaryIsLoading: false,
    healthSummarySuccess: null,
    healthSummaryData : [],
}

const name = "healthSummary"

export const healthSummarySlice = createSlice({
    name,
    initialState: initialHealthSummaryState,
    reducers: {
        getHealthSummary: (state, action) => {
            state.healthSummaryIsLoading = true
        },
        getHealthSummarySuccess: (state, action) => {
            state.healthSummaryIsLoading = false
            state.healthSummarySuccess = true
            state.healthSummaryData = action.payload
        },
        getHealthSummaryFail: (state, action) => {
            state.healthSummaryIsLoading = false
            state.healthSummarySuccess = false
            state.healthSummaryData = [] 
        },
        clearHealthSummaryData: (state, action) => {
            state.healthSummaryIsLoading = false
            state.healthSummarySuccess = null
            state.healthSummaryData = [] 
        }
    },
})

export const { getHealthSummary, clearHealthSummaryData } = healthSummarySlice.actions

export default healthSummarySlice.reducer

export const getHealthSummaryList = (patientId, appointmentId) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/healthsummary/patienthealthsummary/?patient_id=${patientId}&appointment_id=${appointmentId}`,
    },
    type: "getHealthSummary",
});
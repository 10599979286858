import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const earlyCKDData = [
  58711, 87031, 81294, 99531, 74784, 83534, 91281, 77647, 93794, 72097, 87588,
  97241,
];

const yaxisearlyCKDConfig = {
  min: Math.floor(Math.min(...earlyCKDData) / 1000) * 1000,
  max: Math.ceil(Math.max(...earlyCKDData) / 1000) * 1000,
  labels: {
    formatter: function (value) {
      const inThousands = value / 1000;
      return inThousands.toFixed(0) + "k";
    },
  },
};
const activePatientdata2 = [
  1028, 887, 1191, 1022, 1222, 1079, 1021, 1201, 1082, 1003, 1135, 1052,
];

const yaxisactivePatientdata2Config2 = {
  min: Math.floor(Math.min(...activePatientdata2) / 1000) * 1000,
  max: Math.ceil(Math.max(...activePatientdata2) / 1000) * 1000,
  tickAmount: 6,
  labels: {
    formatter: function (value) {
      return value.toFixed(0);
    },
  },
};

const PerformanceIndicatorChart = ({ isRevenu }) => {
  const [chartData] = useState({
    series: [
      {
        name: isRevenu ? "Early CKD" : "Active Patients",
        data: isRevenu ? earlyCKDData : activePatientdata2,
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      colors: ["#3475DC", "#F14425"],
      dataLabels: {
        enabled: true,
        formatter: function (value) {
          const inThousands = value / 1000;
          return inThousands.toFixed(1) + "k";
        },
      },
      stroke: {
        curve: "smooth",
      },
      markers: {
        size: 4,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: isRevenu ? yaxisearlyCKDConfig : yaxisactivePatientdata2Config2,
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        offsetY: 12,
        offsetX: -10,
        itemMargin: {
          horizontal: 5,
          vertical: 7,
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="line"
          height={300}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default PerformanceIndicatorChart;

import DoneIcon from "@mui/icons-material/Done";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomTooltip from "components/common/CustomTooltip/CustomTooltip";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getRecommendationsData } from "redux/features/AmbientAI/recommendationsSlice";
import { updateLabsByEncounter } from "redux/features/Labs/labsSlice";
import {
  diagnosticChip,
  smartChecklistDetail,
  smartChecklistTitle,
  smartChecklistWrapper,
} from "styles/SCD/smartChecklistStyle";
import Sparkle from "../../../../assets/svg/light/ai_sparkle.svg";
import SparkleWhite from "../../../../assets/svg/light/ai_sparkle_white.svg";
import likeBlueIcon from "assets/svg/light/like-blue.svg";
import unlikeBlueIcon from "assets/svg/light/unlike-blue.svg";
import unlikeEmptyIcon from "assets/svg/light/unlike-empty.svg";
import likeEmptyIcon from "assets/svg/light/like-empty.svg";

const useStyles = makeStyles(() => ({
  tooltipRoot: {
    "& .MuiTooltip-Popper": {
      background: "#ffffff !important",
      borderRadius: "6px !important",
      padding: "10px !important",
      color: "#1b1b1b !important",
    },
  },
}));

const medsSubtractionData = [
  "Diuretics",
  "Lisinopril",
  "Diuretics 10 mg",
  "Diurex Max Oral Tablet 50 MG",
  "Diuril Oral Suspension 250 MG/5ML",
  "Diurex Ultra Oral Tablet 100 MG",
  "Angiotensin II Receptor Blockers (ARBs)",
  "Angiotensin-Converting Enzyme Inhibitors (ACE Inhibitors)",
];

const IncreasedDosage = [
  {
    dispensable_drug_id: "7123",
    drug_name: "Lisinopril Oral Tablet 20 MG",
    dosage: 1,
    prescribed_on: "2024-05-10T06:58:28.629Z",
    instructions: "instructions",
    status_ind: 1,
  },
  {
    dispensable_drug_id: "25800",
    drug_name: "amLODIPine-Atorvastatin Oral Tablet 5-10 MG",
    dosage: 1,
    prescribed_on: "2024-05-10T10:22:21.712Z",
    instructions: "instructions",
    status_ind: 1,
  },
];

const SmartChecklist = ({
  helperMedication,
  viewWidth,
  createDeleteMedicine,
  set_smart_assesment_plan,
  setHelperMedication,
  planLabsData,
  startAppointment,
}) => {
  const dispatch = useDispatch();
  const { patientId, appointmentId } = useParams();
  const classes = useStyles();
  const { recommendationsData, recommendationsIsLoading } = useSelector(
    (state) => state.recommendations
  );
  const { account_id } = useSelector((state) => state.auth);
  const { appointmentData } = useSelector((state) => state.specificAppointment);
  const { medicinesEncounterData } = useSelector((state) => state.medicines);

  const hardCodedRecommendations = [
    {
      id: 1,
      message:
        "Elevated Potassium with accompanied acidosis most likely Type IV RTA",
      recommend:
        "Recommend Low potassium diet, Start bicarb therapy and reduce her lisinopril dose.",
      status: "high",
      actions: [
        { message: "bicarb therapy" },
        { message: "Reduce lisinopril dose" },
      ],
    },
    {
      id: 2,
      message: "Low Blood pressure on multiple BP meds  ",
      recommend: "Reduce amlodipine and lisinopril dose",
      status: "high",
      actions: [
        { message: "Reduce amlodipine" },
        { message: "Reduce lisinopril dose" },
      ],
    },
    {
      id: 3,
      message:
        "Worsening symptoms due to poor diabetes control and Proteinuria  ",
      recommend:
        "Recommend referral to diabetes specialist, start SGLT2 inhibitor consider Farxiga based on patients insurance",
      status: "",
      actions: [
        { message: "referral to diabetes specialist" },
        { message: "start SGLT2 inhibitor" },
        { message: "consider Farxiga based on patients insurance" },
      ],
    },
    {
      id: 4,
      message: "Elevated Phosphorus",
      recommend:
        "Recommend Implement a phosphorus-restricted diet. Consider phosphate binders in the future ",
      status: "",
      actions: [
        { message: "Implement a phosphorus-restricted diet" },
        { message: "Consider phosphate binders in the future" },
      ],
    },
    {
      id: 5,
      message: "Anemia most likely of CKD but no recent colonoscopy",
      recommend:
        "Recommendation: Check iron studies, stool Guaics and recommend colonoscopy ",
      status: "",
      actions: [
        { message: "Check iron studies" },
        { message: "stool Guaics" },
        { message: "recommend colonoscopy" },
      ],
    },
    {
      id: 6,
      message: "Nephrotic range proteinuria of unclear etiology",
      recommend: "Recommend lipid, autoimmune panel",
      status: "",
      actions: [{ message: "lipid" }, { message: "autoimmune panel" }],
    },
    {
      id: 7,
      message:
        "Edema of the Extremities etiology iatrogenic or secondary to primary disease ",
      recommend:
        "Recommend reducing amlodipine to 5 mg daily, compression stockings",
      status: "",
      actions: [
        { message: "reduce amlodipine to 5 mg daily" },
        { message: "compression stockings" },
      ],
    },
    {
      id: 8,
      message:
        "Possible Candidate for research based on inclusion and exclusion criteria.  ",
      recommend:
        "Recommendation: referral to research coordinator for Retatrutide study",
      status: "",
      actions: [
        { message: "referral to research coordinator for Retatrutide study" },
      ],
    },
  ];
  const labsAdditionData = [
    {
      message: "lipid",
      datetime: "2024-05-10T11:16:40.776Z",
      patient_id: patientId,
      practitioner_id: appointmentData?.practitioner_id,
      lab_provider_id: null,
      test_id: 33,
      encounter_id: appointmentId,
      account_id: account_id,
    },
    {
      message: "autoimmune panel",
      datetime: "2024-05-10T11:00:22.715Z",
      patient_id: patientId,
      practitioner_id: appointmentData?.practitioner_id,
      lab_provider_id: null,
      test_id: 34,
      encounter_id: appointmentId,
      account_id: account_id,
    },
    {
      message: "Stool Culture Test",
      datetime: "2024-04-29T10:43:38.192Z",
      patient_id: patientId,
      practitioner_id: appointmentData?.practitioner_id,
      lab_provider_id: null,
      test_id: 24,
      encounter_id: appointmentId,
      account_id: account_id,
    },
    {
      message: "Urine test for protein and blood",
      datetime: "2024-04-29T10:50:48.915Z",
      patient_id: patientId,
      practitioner_id: appointmentData?.practitioner_id,
      lab_provider_id: null,
      test_id: 26,
      encounter_id: appointmentId,
      account_id: account_id,
    },
    {
      message: "Order new Renal Ultrasound",
      datetime: "2024-04-29T10:44:22.242Z",
      patient_id: patientId,
      practitioner_id: appointmentData?.practitioner_id,
      lab_provider_id: null,
      test_id: 21,
      encounter_id: appointmentId,
      account_id: account_id,
    },
    {
      message: "Kidney Ultrasound ",
      datetime: "2024-04-29T10:44:22.242Z",
      patient_id: patientId,
      practitioner_id: appointmentData?.practitioner_id,
      lab_provider_id: null,
      test_id: 21,
      encounter_id: appointmentId,
      account_id: account_id,
    },
    {
      message: "Ultrasound of extremities. ",
      datetime: "2024-04-29T10:52:02.373Z",
      patient_id: patientId,
      practitioner_id: appointmentData?.practitioner_id,
      lab_provider_id: null,
      test_id: 27,
      encounter_id: appointmentId,
      account_id: account_id,
    },
  ];

  const handleTooltip = (msg) => {
    const medMsg = [
      "Reduce lisinopril dose",
      "reduce amlodipine to 5 mg daily",
      "Reduce amlodipine",
      "Stop using ACE inhibitors, ARBs & Diuretics in the setting of acute kidney injury.",
    ];
    if (!medMsg?.includes(msg)) {
      const LabsData = labsAdditionData?.find(({ message, ...rest }) =>
        message?.toLowerCase()?.includes(msg?.toLowerCase())
      );

      let existingLab = planLabsData?.find(
        (s) => s?.test_id == LabsData?.test_id
      );
      if (LabsData?.test_id) {
        if (existingLab?.test_id == LabsData?.test_id) {
          toast.success("Added to the assessment and plan");
        } else {
          if (LabsData?.test_id) {
            let correctedData = { ...LabsData };
            correctedData.hasOwnProperty("message") &&
              delete correctedData["message"];
            dispatch(updateLabsByEncounter(correctedData, "post", ""));
            toast.success("Added to the assessment and plan");
          }
        }
      } else {
        //scenario for adding the non labs and meds in the smart notes
        set_smart_assesment_plan((old) => {
          let deepCopy = [...old];
          if (!deepCopy.includes(msg)) {
            deepCopy.push(msg);
          }
          toast.success("Added to the assessment and plan");
          return deepCopy;
        });
      }
    }
    if (medMsg?.includes(msg)) {
      if (msg?.includes("lisinopril")) {
        let medicineData = [
          ...medicinesEncounterData?.filter(
            ({ drug_name }) =>
              !["Lisinopril Oral Tablet 40 MG"]?.includes(drug_name)
          ),
        ];
        medicineData.push(IncreasedDosage?.[0]);
        let isIncluded = false;
        medicinesEncounterData?.map(({ drug_name }) => {
          if (["Lisinopril Oral Tablet 40 MG"]?.includes(drug_name)) {
            isIncluded = true;
          }
        });

        if (isIncluded) {
          createDeleteMedicine(medicineData);
          toast.success("Added to the assessment and plan");
        } else {
          toast.success("Added to the assessment and plan");
        }
      } else if (msg?.includes("amlodipine")) {
        let medicineData = [
          ...medicinesEncounterData?.filter(
            ({ drug_name }) =>
              !["amLODIPine-Atorvastatin Oral Tablet 5-20 MG"]?.includes(
                drug_name
              )
          ),
        ];
        medicineData.push(IncreasedDosage?.[1]);
        let isIncluded = false;
        medicinesEncounterData?.map(({ drug_name }) => {
          if (
            ["amLODIPine-Atorvastatin Oral Tablet 5-20 MG"]?.includes(drug_name)
          ) {
            isIncluded = true;
          }
        });

        if (isIncluded) {
          createDeleteMedicine(medicineData);
          toast.success("Added to the assessment and plan");
        } else {
          toast.success("Added to the assessment and plan");
        }
      } else {
        let medicineData = [
          ...medicinesEncounterData?.filter(
            ({ drug_name }) => !medsSubtractionData?.includes(drug_name)
          ),
        ];
        let isIncluded = false;
        medicinesEncounterData?.map(({ drug_name }) => {
          if (medsSubtractionData?.includes(drug_name)) {
            isIncluded = true;
          }
        });

        if (isIncluded) {
          createDeleteMedicine(medicineData);
          toast.success("Added to the assessment and plan");
        } else {
          toast.success("Added to the assessment and plan");
        }
      }
    }
  };

  useEffect(() => {
    patientId && dispatch(getRecommendationsData(patientId));
  }, [patientId]);

  const [liked, setLiked] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const storedLikes = localStorage.getItem(`likes_${patientId}`);
    if (storedLikes) {
      setLiked(JSON.parse(storedLikes));
    }
  }, [patientId]);

  useEffect(() => {
    localStorage.setItem(`likes_${patientId}`, JSON.stringify(liked));
  }, [liked, patientId]);

  const handleThumbsUpClick = (id) => {
    const newLiked = { ...liked, [id]: liked?.[id] === true ? null : true };
    setLiked(newLiked);
  };

  const handleThumbsDownClick = (id) => {
    const newLiked = { ...liked, [id]: liked?.[id] === false ? null : false };
    setLiked(newLiked);
  };

  const handleSelectRecommendation = (id) => {
    setSelectedId(id);
  };

  return (
    <Box sx={smartChecklistWrapper}>
      <Box sx={smartChecklistTitle(viewWidth)}>
        <img src={Sparkle} alt="sparkle" />
        <Text variant="h3">Smart Checklist</Text>
      </Box>
      <Box sx={smartChecklistDetail(viewWidth)}>
        {(patientId == 774
          ? hardCodedRecommendations
          : recommendationsData
        )?.map(({ message, status = "", actions, id }, index) => {
          const isLastIndex =
            index ===
            (patientId == 774
              ? hardCodedRecommendations?.length
              : recommendationsData?.length) -
              1;
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: isLastIndex ? "" : "10px",
                borderBottom: isLastIndex ? "" : "1px solid #E1E1E1",
                padding: "5px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box>
                  <DoneIcon sx={{ color: "#7119FF", marginRight: "5px" }} />
                </Box>
                <Box>
                  <Text variant="bodyXs">{message}</Text>
                </Box>
              </Box>
              <CustomTooltip
                tooltipText={
                  <Box sx={{ padding: "5px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      key={id}
                      onClick={() => handleSelectRecommendation(id)}
                    >
                      <Text variant="h3">
                        <strong>Recommendations</strong>
                      </Text>
                      {/* unlike and like commented and will be updated after approval */}
                      {/* <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          onClick={() => handleThumbsUpClick(id)}
                          sx={{
                            cursor: "pointer",
                            "&:hover": { opacity: 1 },
                            width: "24px",
                            height: "24px",
                            backgroundColor: "#f2f2f2",
                            borderRadius: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              liked?.[id] === true
                                ? likeBlueIcon
                                : likeEmptyIcon
                            }
                            alt="like icon"
                            width="14px"
                            height="14px"
                            style={{
                              fill: "#246EFD",
                            }}
                          />
                        </Box>
                        <Box
                          onClick={() => handleThumbsDownClick(id)}
                          sx={{
                            cursor: "pointer",
                            marginLeft: 1,
                            "&:hover": { opacity: 1 },
                            width: "24px",
                            height: "24px",
                            backgroundColor: "#f2f2f2",
                            borderRadius: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              liked?.[id] === false
                                ? unlikeBlueIcon
                                : unlikeEmptyIcon
                            }
                            alt="unlike icon"
                            width="14px"
                            height="14px"
                          />
                        </Box>
                      </Box> */}
                    </Box>
                    {!!actions?.length && (
                      <Box sx={{ marginTop: "5px" }}>
                        {actions?.map(({ message }, index) => (
                          <Box
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={() => handleTooltip(message)}
                          >
                            <Text
                              variant="propertyLabel"
                              color={status ? "red" : "lightPurple"}
                            >
                              {message?.includes("Stop") && "-"}{" "}
                              {!message?.includes("Stop") && index + 1 + "-"}{" "}
                              {message}
                            </Text>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                }
                arrow={true}
              >
                <Box sx={diagnosticChip} onlCik={handleTooltip}>
                  <img src={SparkleWhite} alt="" />
                  <span style={{ fontSize: "12px" }}>{actions?.length}</span>
                </Box>
              </CustomTooltip>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SmartChecklist;

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import { useState } from "react";
import Text from "../Typography/Text";

const Accordian = ({ isActive, classes, title, isLoading, data }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box data-testid="accordion-ui-medications-mainbox">
      <Accordion
        expanded={expanded}
        onClick={(e) => setExpanded(!expanded)}
        data-testid="accordion-ui-medications-details"
        sx={{ boxShadow: "none" }}
        className={classes?.accordionRoot}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          sx={{
            background: isActive ? "#E5EBFF !important" : "#F0F0F0 !important",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-content`}
          id={`panel-header`}
          data-testid={`panel-header-medications-details`}
        >
          <Text
            id="today-heading"
            fontWeight="700"
            sx={{ lineHeight: "0px", margin: "0px" }}
            variant="h4"
            color={"black"}
          >
            <span data-testid="accordian-title-medications-details">
              {title}
            </span>
          </Text>
        </AccordionSummary>

        <AccordionDetails data-testid="accordion-details">
          {data?.key && data}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Accordian;

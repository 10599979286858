import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import Text from "components/common/Typography/Text";
import moment from "moment/moment";
import LabResultDetail from "pages/Dashboard/PatientDetail/components/LabResults/LabResultDetail";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button/Button";
import CustomModal from "./CustomModal";
import ViewNotesHistoryModal from "./ViewNotesHistoryComponents/ViewNotesHistoryModal";

const OverviewModal = ({ date = "", open, setOpen }) => {
  const dispatch = useDispatch();
  const [openNotes, setOpenNotes] = useState(false);
  const [selectedPatientName, setSelectedPatientName] = useState("");
  const [openLabs, setOpenLabs] = useState(false);
  const [selectedPatientID, setSelectedPatientID] = useState(null);
  const { isLoading, results } = useSelector((state) => state.patients);
  const todayData = moment(date).isSame(moment(), "day");

  const fieldStyle = { display: "flex", gap: "6px", alignItems: "center" };
  const fieldStyle2 = { display: "flex", gap: "4px", alignItems: "center" };

  return (
    <CustomModal
      setOpen={setOpen}
      open={open}
      title={`${todayData ? "Todays" : ""} Quick Overview - ${moment(
        date
      ).format("MM/DD/YYYY")}`}
      width="auto"
      padding="10px 10px"
      maxWidth={"md"}
      fullWidth={true}
    >
      <Box sx={{ padding: "3px 0px" }}>
        {isLoading ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {[1, 2].map((v, memberIndex) => (
              <Box sx={{ width: "100%" }} marginX={1} marginY={1}>
                <Card
                  elevation={0}
                  sx={{
                    width: " 100%",
                    border: "1px solid #e2e2e4",
                    background: "#fafafb",
                  }}
                >
                  <CardHeader
                    title={
                      <Skeleton
                        animation="wave"
                        height={15}
                        width="100%"
                        style={{ marginBottom: 6 }}
                      />
                    }
                    subheader={
                      <>
                        <Skeleton
                          sx={{ marginBottom: "5px" }}
                          animation="wave"
                          height={15}
                          width="75%"
                        />
                        <Skeleton
                          sx={{ marginBottom: "5px" }}
                          animation="wave"
                          height={15}
                          width="50%"
                        />
                        <Skeleton
                          sx={{ marginBottom: "5px" }}
                          animation="wave"
                          height={15}
                          width="25%"
                        />
                      </>
                    }
                  />
                </Card>
              </Box>
            ))}
          </Box>
        ) : (
          <>
            {results?.length ? (
              results?.map((res, index) => (
                <Box
                  key={index}
                  sx={{
                    background: "#F8F7FE",
                    border: "1px solid black",
                    borderRadius: "10px",
                    padding: "10px ",
                    marginTop: "8px",
                  }}
                >
                  <Text
                    marginY={1}
                    id={"schedule-date"}
                    variant="h3"
                    color={"black"}
                  >
                    {`${todayData ? "Today -" : ""}`}{" "}
                    {moment.utc(res?.datetime).local().format("h:mm A")}
                  </Text>
                  <Grid container spacing={{ sm: 3, xs: 3, lg: 0 }}>
                    <Grid item lg={3}>
                      <Box sx={fieldStyle}>
                        <Text
                          id={"fullName"}
                          marginY={0.7}
                          variant="subtitle1"
                          color={"black"}
                        >
                          Full Name:{" "}
                        </Text>
                        <Text
                          marginY={0.7}
                          variant="body2"
                        >{`${res?.patient?.first_name} ${res?.patient?.last_name}`}</Text>
                      </Box>
                    </Grid>
                    <Grid item lg={3}>
                      <Box sx={fieldStyle}>
                        <Text
                          id={"dob"}
                          marginY={0.7}
                          variant="subtitle1"
                          color={"black"}
                        >
                          DOB:{" "}
                        </Text>
                        <Text marginY={0.7} variant="body2">
                          {res?.patient?.birthdate
                            ? moment(res?.patient?.birthdate).format(
                                "MM/DD/YYYY"
                              )
                            : ""}
                        </Text>
                      </Box>
                    </Grid>
                    <Grid item lg={3}>
                      <Box sx={fieldStyle}>
                        <Text
                          id={"sex"}
                          marginY={0.7}
                          variant="subtitle1"
                          color={"black"}
                        >
                          Sex:{" "}
                        </Text>
                        <Text marginY={0.7} variant="body2">
                          {res?.patient?.gender}
                        </Text>
                      </Box>
                    </Grid>
                    <Grid item lg={3}>
                      <Box sx={fieldStyle}>
                        <Text
                          id={"phoneNumber"}
                          marginY={0.7}
                          variant="subtitle1"
                          color={"black"}
                        >
                          Phone Number:{" "}
                        </Text>
                        <Text marginY={0.7} variant="body2">
                          {res?.patient?.phone_number ?? "- - -"}
                        </Text>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={{ sm: 3, xs: 3, lg: 0 }}>
                    <Grid item lg={3}>
                      <Box sx={fieldStyle2}>
                        <Text
                          id={"patientType"}
                          marginY={0.7}
                          variant="subtitle1"
                          color={"black"}
                        >
                          Patient Type:{" "}
                        </Text>
                        <Text marginY={0.7} variant="body2">
                          {res?.ckd_info?.[0]?.ckd_code ?? "- - -"}
                        </Text>
                      </Box>
                    </Grid>
                    <Grid item lg={6}>
                      <Box sx={fieldStyle2}>
                        <Text
                          id={"lastFacilityVisited"}
                          marginY={0.7}
                          variant="subtitle1"
                          color={"black"}
                        >
                          Last Facility Visited:{" "}
                        </Text>
                        <Text marginY={0.7} variant="body2">
                          {res?.location?.location_name ?? ""}{" "}
                          {moment(res?.last_visit).format("MM/DD/YYYY")}
                        </Text>
                      </Box>
                    </Grid>
                  </Grid>
                  <Text
                    id={"reasonforvisit"}
                    marginY={0.7}
                    variant="body2"
                    color={"black"}
                  >
                    <strong>Reason For The Last Visit:</strong>{" "}
                    {res?.visit_reason}
                  </Text>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={fieldStyle2}>
                      <Text
                        id={"labs"}
                        marginY={0.7}
                        variant="subtitle1"
                        color={"black"}
                      >
                        Labs:{" "}
                      </Text>
                      <Text marginY={0.7} variant="body2">
                        - - -
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      padding: "12px 0px 0px 10px",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        text="View Notes History"
                        onClick={() => {
                          setSelectedPatientID(null);
                          setSelectedPatientID(res?.patient_id);
                          setOpenNotes(true);
                        }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "10px",
                          width: "266px",
                          height: "38px",
                          padding: "10px, 48px, 10px, 48px",
                          background: "transparent",
                          color: "black",
                        }}
                      />
                      <Button
                        onClick={() => {
                          setSelectedPatientID(null);
                          setSelectedPatientID(res?.patient_id);
                          setSelectedPatientName(
                            `${res?.patient?.first_name} ${res?.patient?.last_name}`
                          );
                          setOpenLabs(true);
                        }}
                        text="View Lab Results"
                        sx={{
                          border: "1px solid black",
                          borderRadius: "10px",
                          width: "266px",
                          height: "38px",
                          padding: "10px, 48px, 10px, 48px",
                          background: "transparent",
                          color: "black",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  height: "100px",
                  fontSize: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Data Found
              </Box>
            )}
          </>
        )}

        <ViewNotesHistoryModal
          patientID={selectedPatientID}
          setSelectedPatientID={setSelectedPatientID}
          open={openNotes}
          setOpen={setOpenNotes}
        />
        <CustomModal
          open={openLabs}
          setOpen={setOpenLabs}
          title={"Lab Results"}
          fullWidth
          maxWidth="lg"
        >
          <LabResultDetail
            patientName={selectedPatientName}
            patientID={selectedPatientID}
            setSelectedPatientID={setSelectedPatientID}
          />
        </CustomModal>
      </Box>
    </CustomModal>
  );
};

export default OverviewModal;

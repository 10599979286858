import { createSlice } from "@reduxjs/toolkit";

export const initialRecommendationStates = {
  recommendationsIsLoading: false,
  recommendationsData: [],
};

const name = "recommendations";

export const recommendationsSlice = createSlice({
  name,
  initialState: initialRecommendationStates,
  reducers: {
    getRecommendations: (state, action) => {
      state.recommendationsIsLoading = true;
    },
    getRecommendationsSuccess: (state, action) => {
      state.recommendationsIsLoading = false;
      state.recommendationsData = action.payload;
    },
    getRecommendationsFail: (state, action) => {
      state.recommendationsIsLoading = false;
      state.recommendationsData = [];
    },
    clearRecommendationsData: (state, action) => {
      state.recommendationsIsLoading = false;
      state.recommendationsData = [];
    },
  },
});

export const { clearRecommendationsData } = recommendationsSlice.actions;

export default recommendationsSlice.reducer;

export const getRecommendationsData = (patientID) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/patients/${patientID}/recommendations/`,
  },
  type: "getRecommendations",
});

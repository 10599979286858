import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, ButtonGroup, Grid, IconButton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AISparkImage from "assets/svg/colored/ai_sparkle_black.svg";
import search from "assets/svg/light/search.svg";
import SelectField from "components/common/FormComponents/SelectField.js";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRecordingData } from "redux/features/AmbientAI/RecordingSlice";
import { getMedicationsList } from "redux/features/Medications/MedicationsSlice";
import { getLabsResults } from "redux/features/Patients/ViewLabResultsSlice";
import { flexRowCenter } from "styles";
import DraggableChip from "./DraggableChip.js";
import CustomTooltip from "components/common/CustomTooltip/CustomTooltip.js";
import SpeechToText from "./SpeechToText.js";
import { debounce } from "utils/debouncer.js";

const dynamicTextColor = { color: "#7119FF" };
const TextColor = "color:#7119FF";
const MajorHeadingsFont = "font-size:17px";

const useStyles = makeStyles((theme) => ({
  dashedBorderChip: {
    border: "1px dashed #7119FF !important",
  },
  loader: {
    border: "8px solid #f3f3f3" /* Light grey */,
    borderTop: "8px solid #7119FF" /* pink */,
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

const EndVisit = ({
  playRef,
  smart_assesment_plan,
  setOpen,
  setSoapData,
  soapData,
  helperMedication,
  getCptCodes,
  getIcdCodes,
  planLabsData,
  followUpData,
  removeCptCode,
  removeIcdCode,
  ceaseEncounter,
  onCptCodeSelect,
  onIcdCodeSelect,
  planMedicineData,
  assesmentObj = {},
  encounterByAppointmentData,
}) => {
  const classes = useStyles();
  const { patientId, appointmentId } = useParams();
  const dispatch = useDispatch();
  const [cptModalOpen, setCptModalOpen] = useState(false);
  const [icdModalOpen, setIcdModalOpen] = useState(false);
  const [showCptSearchBar, setShowCptSearchBar] = useState(false);
  const [showIcdSearchBar, setShowIcdSearchBar] = useState(false);
  const { selectedIcdCodes, selectedCptCodes, notes } = assesmentObj;

  const { getSpecificPatientData = {} } = useSelector(
    (state) => state.specificPatient || {}
  );
  const { cptCodes, cptCodesOptions, isCptCodeLoading } = useSelector(
    (state) => state.cptCode
  );

  let { icdCodesOptions, isIcdCodeLoading } = useSelector(
    (state) => state.icdCode
  );

  const { recordingIsLoading } = useSelector((state) => state.recording);

  useEffect(() => {
    appointmentId && dispatch(getRecordingData(appointmentId));
  }, [appointmentId]);

  return (
    <Box position={"relative"}>
      <Grid container spacing={2}>
        <>
          {!recordingIsLoading ? (
            <>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box display={"flex"} alignItems={"center"} flexWrap="wrap">
                  <Text id={"scd-endvisit-pt-name-val"} variant="h1">
                    {" "}
                    {getSpecificPatientData?.first_name
                      ? getSpecificPatientData?.first_name
                      : ""}
                  </Text>
                  <Box sx={{ fontSize: "22px", marginLeft: "2px" }}>
                    {" " +
                      (getSpecificPatientData?.last_name
                        ? getSpecificPatientData?.last_name
                        : "")}
                  </Box>
                </Box>
                <Box>
                  <Grid container>
                    <Grid item sx={flexRowCenter}>
                      <Text id={"scd-endvisit-pt-id"} variant="bodyM">
                        ID:&nbsp;
                      </Text>
                      <Text id={"scd-endvisit-pt-id-val"} variant="bodyM">
                        {" " + patientId}
                      </Text>
                    </Grid>
                    <hr
                      style={{
                        height: "16px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        border: "1px solid #E1E1E1",
                      }}
                    />
                    <Grid item sx={flexRowCenter}>
                      {getSpecificPatientData?.gender
                        ? getSpecificPatientData?.gender
                        : ""}
                    </Grid>
                    <hr
                      style={{
                        height: "16px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        border: "1px solid #E1E1E1",
                      }}
                    />
                    <Grid item sx={flexRowCenter}>
                      <Text id={"scd-endvisit-pt-dob-val"} variant="bodyM">
                        {moment(getSpecificPatientData?.birthdate).format(
                          "MM/DD/YYYY"
                        ) ?? "- - -"}
                      </Text>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Text
                    id={"scd-endvisit-encounter-date"}
                    variant="propertyLabel"
                  >
                    {" "}
                    Encounter Date&nbsp;{" "}
                  </Text>
                  <Box>
                    <Text
                      id={"scd-endvisit-encounter-date-val"}
                      variant="bodyM"
                    >
                      {encounterByAppointmentData?.datetime
                        ? moment(encounterByAppointmentData?.datetime).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                    </Text>
                  </Box>
                </Box>
                <hr
                  style={{
                    width: "100%",
                    border: "1px solid #E1E1E1",
                    marginBottom: "10px",
                    marginTop: "10px",
                    paddingRight: "30px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <Text variant="h3">Review smart recommendations</Text>
                  <Box>
                    <MoreHorizIcon sx={{ color: "#222222" }} />
                  </Box>
                </Box>
                <Box sx={{ marginTop: "10px" }}>
                  <Text variant="propertyLabel">Diagnosis</Text>
                  <Stack direction="row" rowGap={1} flexWrap="wrap" gap={1}>
                    {selectedIcdCodes.map((item, index) => {
                      return (
                        <CustomTooltip
                          tooltipText={
                            <Box sx={{ padding: "5px" }}>
                              <Box sx={{ marginTop: "5px" }}>
                                <Text variant="formLabel">
                                  {item?.icd_code} - {item?.description}
                                </Text>
                              </Box>
                            </Box>
                          }
                          arrow={true}
                        >
                          <DraggableChip
                            className={classes.dashedBorderChip}
                            label={item?.icd_code}
                            onDelete={() => removeIcdCode(index)}
                          />
                        </CustomTooltip>
                      );
                    })}
                  </Stack>
                  <Box
                    sx={{
                      cursor: "pointer",
                      marginTop: "2px",
                      fontSize: "11px",
                      fontWeight: "600",
                      color: "#246EFD",
                    }}
                    onClick={() => setShowIcdSearchBar(true)}
                  >
                    + Add
                  </Box>
                </Box>
                {showIcdSearchBar ? (
                  <Box sx={{ ...flexRowCenter, marginTop: "5px" }}>
                    <SelectField
                      cols={9}
                      name="icdCodes"
                      placeholder="Add code"
                      onSearch={getIcdCodes}
                      onChange={onIcdCodeSelect}
                      loading={isIcdCodeLoading}
                      extendedSearchOptions={["description"]}
                      showMoreInLabel={{ show: true, value: "description" }}
                      border="1px solid #e0e0de"
                      borderRadius="10px"
                      size={"small"}
                      options={!isIcdCodeLoading ? icdCodesOptions : []}
                      height={"34px"}
                      iconDetails={{ allowIcon: true, iconName: search }}
                      positionLeft={true}
                    />
                    <CloseIcon
                      onClick={() => setShowIcdSearchBar(false)}
                      sx={{ ml: "5px", fontSize: "20px" }}
                    />
                  </Box>
                ) : null}
                <Box sx={{ marginTop: "20px" }}>
                  <Text variant="propertyLabel">CPT Codes</Text>
                  <Stack direction="row" rowGap={1} flexWrap="wrap" gap={1}>
                    {selectedCptCodes?.map((item, index) => {
                      return (
                        <CustomTooltip
                          tooltipText={
                            <Box sx={{ padding: "5px" }}>
                              <Box sx={{ marginTop: "5px" }}>
                                <Text variant="formLabel">
                                  {item?.cpt_code} - {item?.description}
                                </Text>
                              </Box>
                            </Box>
                          }
                          arrow={true}
                        >
                          <DraggableChip
                            className={classes.dashedBorderChip}
                            onDelete={() => removeCptCode(index)}
                            label={item?.cpt_code}
                          />
                        </CustomTooltip>
                      );
                    })}
                  </Stack>
                  <Box
                    sx={{
                      cursor: "pointer",
                      marginTop: "2px",
                      fontSize: "11px",
                      fontWeight: "600",
                      color: "#246EFD",
                    }}
                    onClick={() => setShowCptSearchBar(true)}
                  >
                    + Add
                  </Box>
                </Box>
                {showCptSearchBar ? (
                  <Box sx={{ ...flexRowCenter, marginTop: "5px" }}>
                    <SelectField
                      cols={9}
                      name="cptCodes"
                      placeholder="Add code"
                      onSearch={getCptCodes}
                      onChange={onCptCodeSelect}
                      loading={isCptCodeLoading}
                      extendedSearchOptions={["description"]}
                      showMoreInLabel={{ show: true, value: "description" }}
                      options={!isCptCodeLoading ? cptCodesOptions : []}
                      size="small"
                      height={"34px"}
                      border="1px solid #e0e0de"
                    />
                    <CloseIcon
                      onClick={() => setShowCptSearchBar(false)}
                      sx={{ ml: "5px", fontSize: "20px" }}
                    />
                  </Box>
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                sx={{ borderLeft: "1px solid #E1E1E1" }}
              >
                <Text variant="h3" data-testid="scd-health-summary-heading">
                  Notes
                </Text>
                <Box
                  data-testid="scd-modal-richtexteditor"
                  sx={{ marginTop: "15px", marginBottom: "15px" }}
                >
                  <SoapNotes
                    playRef={playRef}
                    smart_assesment_plan={smart_assesment_plan}
                    assesmentObj={assesmentObj}
                    followUpData={followUpData}
                    setSoapData={setSoapData}
                    name={
                      (getSpecificPatientData?.first_name
                        ? getSpecificPatientData?.first_name
                        : "") +
                      " " +
                      (getSpecificPatientData?.last_name
                        ? getSpecificPatientData?.last_name
                        : "")
                    }
                    age={moment().diff(
                      getSpecificPatientData?.birthdate,
                      "years"
                    )}
                    gender={getSpecificPatientData?.gender}
                    readOnly={
                      encounterByAppointmentData?.id &&
                      encounterByAppointmentData?.sign_close_ind === "c"
                    }
                    planLabsData={planLabsData}
                    planMedicineData={planMedicineData}
                    helperMedication={helperMedication}
                  />
                </Box>
              </Grid>
            </>
          ) : (
            <div
              style={{
                minHeight: "40vh",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "relative" }}>
                <div className={classes.loader}></div>
                <img
                  src={AISparkImage}
                  style={{
                    position: "absolute",
                    top: "31%",
                    left: "29%",
                    height: "34px",
                    width: "34px",
                  }}
                />
              </div>

              <div>
                <Text variant="bodyL" fontWeight={700}>
                  Nephrolytics is generating your smart assessment.{" "}
                </Text>
              </div>
              <div>
                <Text variant="bodyM">This should take about a minute. </Text>
              </div>
            </div>
          )}
        </>
      </Grid>
      {encounterByAppointmentData?.id &&
        encounterByAppointmentData?.sign_close_ind === "c" && (
          <div
            style={{
              position: "absolute",
              top: "20px",
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgb(253 250 250 / 50%)",
              zIndex: 10999,
              pointerEvents: "auto",
              cursor: "not-allowed",
            }}
          ></div>
        )}
    </Box>
  );
};

const SoapNotes = ({
  playRef,
  helperMedication,
  age = "",
  readOnly,
  name = "",
  gender = "",
  smart_assesment_plan,
  setSoapData,
  followUpData,
  assesmentObj = {},
  planLabsData,
  planMedicineData,
}) => {
  const { getSpecificPatientData = {} } = useSelector(
    (state) => state.specificPatient || {}
  );
  const { recordingData, recordingIsLoading } = useSelector(
    (state) => state.recording
  );
  const { selectedIcdCodes, selectedCptCodes, notes } = assesmentObj;
  const { allergiesData } = useSelector((state) => state.allergies);
  const [formattedLabsData, setFormattedLabsData] = useState([]);
  const { healthSummaryData } = useSelector((state) => state.healthSummary);
  const { vitalsData } = useSelector((state) => state.vitals);
  const { medicationsActiveData } = useSelector((state) => state.medications);
  const { labResults, isLabResultsLoading } = useSelector(
    (state) => state.resultsLabs
  );
  const { familyHistoryData } = useSelector((state) => state.familyHistory);
  const { allHistoryData } = useSelector((state) => state.allHistory);
  let dispatch = useDispatch();
  const { patientId, appointmentId } = useParams();
  const [formData, setFormData] = useState({
    subjective: "",
    objective: "",
    assessment: "",
    plan: "",
  });

  const Types = {
    CHIP: "ICD",
  };

  const [speechText, setSpeechText] = useState("");

  useEffect(() => {
    const Subjective = `${ConvertIntoBold(
      recordingData?.hx_of_present_illness_summary
    )}
    <br/>
    <br/>
    <span style=${MajorHeadingsFont}>Allergies:</span>  
    <br/>
    ${
      recordingData?.allergies?.length
        ? ConvertIntoBold(
            recordingData?.allergies
              ?.map((alergyData) => alergyData)
              ?.join(" <br/> ")
          )
        : "Not Available"
    }
    <br/><br/>
    <span style=${MajorHeadingsFont}>Family History:</span><br/>
    ${
      recordingData?.family_history?.length
        ? ConvertIntoBold(
            recordingData?.family_history
              ?.map((familyData) => familyData)
              ?.join("<br/>")
          )
        : "Not Available"
    }
    
     <br/>
     <br/>
      <span style=${MajorHeadingsFont}>Medical History:</span><br/>
    ${
      recordingData?.past_medical_hx?.length
        ? ConvertIntoBold(
            recordingData?.past_medical_hx
              ?.map((disease) => disease)
              ?.join("<br/>")
          )
        : "Not Available"
    }
    
     <br/>
     <br/>
     <span style=${MajorHeadingsFont}>Social History:</span><br/>
    ${
      recordingData?.social_history?.length
        ? ConvertIntoBold(
            recordingData?.social_history
              ?.map((surgicalHistory) => surgicalHistory)
              ?.join("<br/>")
          )
        : "Not Available"
    }
    
     <br/>
     <br/>
      <span style=${MajorHeadingsFont}>Surgical History:</span><br/>
    ${
      recordingData?.social_history?.length
        ? ConvertIntoBold(
            recordingData?.social_history
              ?.map((surgery) => surgery)
              ?.join("<br/>")
          )
        : "Not Available"
    }
    
     <br/>
     <br/>
    <span style=${MajorHeadingsFont} > Smart Summary: </span >
    <br/>
     <span>Last Hospitalization :</span> ${ConvertIntoBold(
       healthSummaryData?.[0]?.last_hospitalization
         ? moment(healthSummaryData?.[0]?.last_hospitalization).format(
             "MM/DD/YYYY"
           )
         : ""
     )} <br/>
     <span>Optimal Start:</span> ${ConvertIntoBold(
       healthSummaryData?.[0]?.optimal_start
     )}<br/>
     <span>Reason:</span> ${ConvertIntoBold(
       healthSummaryData?.[0]?.reason
     )}<br/>
     <span>Review Of Symptoms:</span> ${ConvertIntoBold(
       healthSummaryData?.[0]?.ros
     )}
     <br/>
     
     <br/>
     <span style=${MajorHeadingsFont}>Risk Assesment: </span><br/>
     ${
       recordingData?.assessment?.length
         ? ConvertIntoBold(
             recordingData?.assessment?.map((surgery) => surgery)?.join("<br/>")
           )
         : "Not Available"
     }
      `;
    const objective = `<span style=${MajorHeadingsFont}> Active Lab Results:</span > <br />\n
      
      <div style='display:flex;column-gap:28px;row-gap:5px;flex-wrap:wrap' >
      ${ConvertIntoFlex(
        formattedLabsData
          ?.map(
            ({ code, val }, index) =>
              `<span style='color:#638ace;width:auto'> ${code} : <span style='color:${
                setCKDValColors(code) ? "#F23F3F" : "#638ace"
              }'>${val}</span></span>`
          )
          .join("")
      )}
      </div>
      <br/>
      <span style=${MajorHeadingsFont}>Active Medications:</span><br/>\n
      ${ConvertIntoBold(
        recordingData?.medications?.map((meds) => meds).join("<br/>")
      )}
      
      <br/>
      <br/>
      <span style=${MajorHeadingsFont}>Vital Signs:</span><br/>\n
      <span>
      <div style='display:flex;column-gap:35px;row-gap:5px;flex-wrap:wrap' >
        <span style='color:#7119FF;width:auto'> BP:  <span style='color:${
          setVitalsValColors("BP") ? "#F23F3F" : ""
        }'>${recordingData?.vitals?.bp}</span></span>
        <span style='color:#7119FF;width:auto'> HR: ${
          recordingData?.vitals?.hr
        } bpm </span>
        <span style='color:#7119FF;width:auto'> RR: ${
          recordingData?.vitals?.rr
        } </span>
        <span style='color:#7119FF;width:auto'> Temp: ${
          recordingData?.vitals?.temp
        }°F </span>
        <span style='color:#7119FF;width:auto'> BMI: ${
          recordingData?.vitals?.bmi
        }% </span>
        <span style='color:#7119FF;width:auto'> Wt: ${
          recordingData?.vitals?.o2sat
        } lbs </span>
      </div>
      </span>
<br/>
<span style=${MajorHeadingsFont}>Physical Exam Findings: </span><br/>
      A comprehensive review of systems was performed. The patient reports no significant changes or abnormalities in the following systems: cardiovascular, respiratory, gastrointestinal, genitourinary, musculoskeletal, integumentary, neurological, endocrine and hematologic/lymphatic. There were no complaints or notable findings during the examination of these systems.  
     
      <br/>
  `;
    const assesment = `
    ${
      assesmentPlanNotes?.[
        getSpecificPatientData?.first_name +
          " " +
          getSpecificPatientData?.last_name
      ] ?? ""
    }
    <ul>
    ${smart_assesment_plan
      ?.map((v) => `<li style=" color: #7119FF ">${v}</li>`)
      .join("")}
    </ul>
    
  <span style=${MajorHeadingsFont}> Diagnosis:</span > <br />
${ConvertIntoBold(
  selectedIcdCodes?.length > 0
    ? selectedIcdCodes
        .map(
          (icd_code, index) => `${icd_code?.icd_code} ${icd_code?.description}`
        )
        ?.join("<br/>")
    : ""
)}
<br/>
 
    <br/>
    <span style=${MajorHeadingsFont}>CPT Codes:</span><br/>
    ${ConvertIntoBold(
      selectedCptCodes?.length > 0
        ? selectedCptCodes
            .map(
              (cpt_code, index) =>
                `${cpt_code?.cpt_code} ${cpt_code?.description}`
            )
            ?.join("<br/>")
        : ""
    )}
<br/>
    <br/>
`;
    const plan = `
    <span style=${MajorHeadingsFont}>Labs: </span> <br />
      ${ConvertIntoBold(
        planLabsData?.length > 0
          ? planLabsData.map((lab, index) => `${lab?.name}`)?.join("<br/>")
          : ""
      )}
    <br /><br /><span style=${MajorHeadingsFont}>Medications: </span><br />
      ${ConvertIntoBold(
        [...helperMedication, ...planMedicineData]?.length > 0
          ? [...helperMedication, ...planMedicineData]
              ?.filter(
                (item, index, array) =>
                  array.findIndex(
                    (obj) => (obj.drug_name ?? obj) === (item.drug_name ?? item)
                  ) === index
              )
              .map((med) => med.drug_name ?? med)
              .join("<br/>")
          : ""
      )}
      <br/>
      
    <br /><br /><span style=${MajorHeadingsFont}>Notes: </span>
      ${notes == null ? " " : notes}<br />
    <br /><span style=${MajorHeadingsFont}>Follow Up: </span><br />
      ${ConvertIntoBold(
        followUpData?.date
          ? moment(followUpData?.date).format("MM/DD/YYYY")
          : ""
      )} ${ConvertIntoBold(followUpData?.selection ?? "")}
    `;

    // Combine all sections into a single string
    const combinedText = `${Subjective}\n\n${objective}\n\n${assesment}\n\n${plan}`;

    updateSpeechText(combinedText);

    setFormData({
      subjective: Subjective,
      objective: objective,
      assessment: assesment,
      plan: plan,
    });

    setSoapData({
      subjective: Subjective,
      objective: objective,
      assessment: assesment,
      plan: plan,
    });
  }, [
    healthSummaryData,
    formattedLabsData,
    selectedCptCodes,
    selectedIcdCodes,
    followUpData?.date,
  ]);
  const updateSpeechText = debounce((combinedText) => {
    setSpeechText(combinedText);
  }, 300);

  const getFormattedLabs = () => {
    let formattedData = [];
    labResults?.[0]?.lab_details?.map(({ details }) => {
      details?.map(({ test_name, value, unit }) => {
        formattedData.push({
          code: test_name,
          val: `${value} ${unit}`,
        });
      });
    });
    setFormattedLabsData(formattedData);
  };

  useEffect(() => {
    getFormattedLabs();
  }, [labResults]);

  useEffect(() => {
    dispatch(getLabsResults(patientId));
  }, [patientId]);

  useEffect(() => {
    dispatch(getMedicationsList(appointmentId));
  }, [appointmentId]);

  const ConvertIntoBold = (txt) => {
    return `<span style=${TextColor}>${txt}</span>`;
  };
  const ConvertIntoFlex = (txt) => {
    return `${txt}`;
  };

  const setCKDValColors = (key) => {
    let status = healthSummaryData?.[0]?.ckd_status;

    const ckdVals = {
      "CKD-4": ["NA+", "K+", "HPO4", "Albumin", "Glucose", "BUN", "CR", "EGFR"],
      "CKD-5": [
        "HGB",
        "RBC",
        "NA+",
        "K+",
        "HPO4",
        "ALBUMIN",
        "GLUCOSE",
        "BUN",
        "CR",
        "EGFR",
      ],
    };

    if (status === "CKD-4" || status === "CKD-5") {
      return ckdVals[status]?.includes(key?.trim()?.toUpperCase())
        ? true
        : false;
    } else {
      return false;
    }
  };

  const setVitalsValColors = (key) => {
    let status = healthSummaryData?.[0]?.ckd_status;

    const ckdVals = {
      "CKD-4": ["BP"],
      "CKD-5": ["BP"],
    };

    if (status === "CKD-4" || status === "CKD-5") {
      return ckdVals[status]?.includes(key?.toUpperCase()) ? true : false;
    } else {
      return false;
    }
  };

  const assesmentPlanNotes = {
    "Chloe Martin": `
    Progressively worsening chronic kidney disease now on stage-5/renal failure 
<br/>
Worsening volume overload, secondary to the renal failure. 
<br/>
Hyperphosphatemia 
<br/>
Anemia due to kidney failure. 
<br/>
Diastolic heart failure. 
<br/><br/>
  

 

Consultation with dietitian. Start using diuretic for edema and retacrit for anemia. Referred her to vascular surgeon for vein mapping. The patient is advised to monitor fluid intake and output closely. Provide comprehensive education to the patient about their conditions, treatment options, and self-management strategies. 

Schedule regular follow-up appointments to monitor progress, adjust treatment as needed, and address any concerns or questions. 

Educate the patient about the importance of medication adherence and lifestyle modifications, such as salt restriction and regular physical activity 
    <br/>`,
    "Sophia Patel": `
    Acute Kidney Injury in the setting of diabetes and hypertension, likely from some gastrointestinal issue due to food poisoning, 	Volume Depletion 
<br/>
The patient has acute kidney injury. We will give her intravenous normal saline infusion 2 LTRs today. We will hold her medicines, lisinopril and diuretic (hydrochlorothiazide). 
<br/>
We will repeat her labs tomorrow and she will visit us again on tomorrow along with her recent lab reports. 
   <br/> `,
  };

  useEffect(() => {
    const lvl =
      healthSummaryData?.[0]?.assessment_severity <= 3
        ? "normal"
        : healthSummaryData?.[0]?.assessment_severity > 3 &&
            healthSummaryData?.[0]?.assessment_severity <= 7
          ? "Medium"
          : "High";
    const Subjective = `${ConvertIntoBold(
      recordingData?.hx_of_present_illness_summary
    )}
    <br/>
    <br/>

    <span style=${MajorHeadingsFont}>Allergies:</span>  
    <br/>
    ${
      recordingData?.allergies?.length
        ? ConvertIntoBold(
            recordingData?.allergies
              ?.map((alergyData) => alergyData)
              ?.join(" <br/> ")
          )
        : "Not Available"
    }
    <br/><br/>

    <span style=${MajorHeadingsFont}>Family History:</span><br/>
    ${
      recordingData?.family_history?.length
        ? ConvertIntoBold(
            recordingData?.family_history
              ?.map((familyData) => familyData)
              ?.join("<br/>")
          )
        : "Not Available"
    }
    
     <br/>
     <br/>

      <span style=${MajorHeadingsFont}>Medical History:</span><br/>
    ${
      recordingData?.past_medical_hx?.length
        ? ConvertIntoBold(
            recordingData?.past_medical_hx
              ?.map((disease) => disease)
              ?.join("<br/>")
          )
        : "Not Available"
    }
    
     <br/>
     <br/>

     <span style=${MajorHeadingsFont}>Social History:</span><br/>
    ${
      recordingData?.social_history?.length
        ? ConvertIntoBold(
            recordingData?.social_history
              ?.map((surgicalHistory) => surgicalHistory)
              ?.join("<br/>")
          )
        : "Not Available"
    }
    
     <br/>
     <br/>

      <span style=${MajorHeadingsFont}>Surgical History:</span><br/>
    ${
      recordingData?.social_history?.length
        ? ConvertIntoBold(
            recordingData?.social_history
              ?.map((surgery) => surgery)
              ?.join("<br/>")
          )
        : "Not Available"
    }
    
     <br/>
     <br/>

    <span style=${MajorHeadingsFont} > Smart Summary: </span >
    <br/>
     <span>Last Hospitalization :</span> ${ConvertIntoBold(
       healthSummaryData?.[0]?.last_hospitalization
         ? moment(healthSummaryData?.[0]?.last_hospitalization).format(
             "MM/DD/YYYY"
           )
         : ""
     )} <br/>
     <span>Optimal Start:</span> ${ConvertIntoBold(
       healthSummaryData?.[0]?.optimal_start
     )}<br/>
     <span>Reason:</span> ${ConvertIntoBold(
       healthSummaryData?.[0]?.reason
     )}<br/>
     <span>Review Of Symptoms:</span> ${ConvertIntoBold(
       healthSummaryData?.[0]?.ros
     )}
     <br/>
     
     <br/>
     <span style=${MajorHeadingsFont}>Risk Assesment: </span><br/>
     ${
       recordingData?.assessment?.length
         ? ConvertIntoBold(
             recordingData?.assessment?.map((surgery) => surgery)?.join("<br/>")
           )
         : "Not Available"
     }
      `;
    const objective = `<span style=${MajorHeadingsFont}> Active Lab Results:</span > <br />\n
      
      <div style='display:flex;column-gap:28px;row-gap:5px;flex-wrap:wrap' >
      ${ConvertIntoFlex(
        formattedLabsData
          ?.map(
            ({ code, val }, index) =>
              `<span style='color:#638ace;width:auto'> ${code} : <span style='color:${
                setCKDValColors(code) ? "#F23F3F" : "#638ace"
              }'>${val}</span></span>`
          )
          .join("")
      )}
      </div>

      <br/>
      <span style=${MajorHeadingsFont}>Active Medications:</span><br/>\n
      ${ConvertIntoBold(
        recordingData?.medications?.map((meds) => meds).join("<br/>")
      )}
      
      <br/>
      <br/>
      <span style=${MajorHeadingsFont}>Vital Signs:</span><br/>\n

      <span>
      <div style='display:flex;column-gap:35px;row-gap:5px;flex-wrap:wrap' >
        <span style='color:#7119FF;width:auto'> BP:  <span style='color:${
          setVitalsValColors("BP") ? "#F23F3F" : ""
        }'>${recordingData?.vitals?.bp}</span></span>
        <span style='color:#7119FF;width:auto'> HR: ${
          recordingData?.vitals?.hr
        } bpm </span>
        <span style='color:#7119FF;width:auto'> RR: ${
          recordingData?.vitals?.rr
        } </span>
        <span style='color:#7119FF;width:auto'> Temp: ${
          recordingData?.vitals?.temp
        }°F </span>
        <span style='color:#7119FF;width:auto'> BMI: ${
          recordingData?.vitals?.bmi
        }% </span>
        <span style='color:#7119FF;width:auto'> Wt: ${
          recordingData?.vitals?.o2sat
        } lbs </span>
      </div>
      </span>
<br/>

<span style=${MajorHeadingsFont}>Physical Exam Findings: </span><br/>

      A comprehensive review of systems was performed. The patient reports no significant changes or abnormalities in the following systems: cardiovascular, respiratory, gastrointestinal, genitourinary, musculoskeletal, integumentary, neurological, endocrine and hematologic/lymphatic. There were no complaints or notable findings during the examination of these systems.  
     
      <br/>
  `;
    const assesment = `
    ${
      assesmentPlanNotes?.[
        getSpecificPatientData?.first_name +
          " " +
          getSpecificPatientData?.last_name
      ] ?? ""
    }
    <ul>
    ${smart_assesment_plan
      ?.map((v) => `<li style=" color: #7119FF ">${v}</li>`)
      .join("")}
    </ul>
    
  <span style=${MajorHeadingsFont}> Diagnosis:</span > <br />

${ConvertIntoBold(
  selectedIcdCodes?.length > 0
    ? selectedIcdCodes
        .map(
          (icd_code, index) => `${icd_code?.icd_code} ${icd_code?.description}`
        )
        ?.join("<br/>")
    : ""
)}
<br/>
 
    <br/>
    <span style=${MajorHeadingsFont}>CPT Codes:</span><br/>
    ${ConvertIntoBold(
      selectedCptCodes?.length > 0
        ? selectedCptCodes
            .map(
              (cpt_code, index) =>
                `${cpt_code?.cpt_code} ${cpt_code?.description}`
            )
            ?.join("<br/>")
        : ""
    )}
<br/>
    <br/>
`;
    const plan = `
    <span style=${MajorHeadingsFont}>Labs: </span> <br />
      ${ConvertIntoBold(
        planLabsData?.length > 0
          ? planLabsData.map((lab, index) => `${lab?.name}`)?.join("<br/>")
          : ""
      )}
    <br /><br /><span style=${MajorHeadingsFont}>Medications: </span><br />
      ${ConvertIntoBold(
        [...helperMedication, ...planMedicineData]?.length > 0
          ? [...helperMedication, ...planMedicineData]
              ?.filter(
                (item, index, array) =>
                  array.findIndex(
                    (obj) => (obj.drug_name ?? obj) === (item.drug_name ?? item)
                  ) === index
              )
              .map((med) => med.drug_name ?? med)
              .join("<br/>")
          : ""
      )}
      <br/>
      
    <br /><br /><span style=${MajorHeadingsFont}>Notes: </span>
      ${notes == null ? " " : notes}<br />
    <br /><span style=${MajorHeadingsFont}>Follow Up: </span><br />
      ${ConvertIntoBold(
        followUpData?.date
          ? moment(followUpData?.date).format("MM/DD/YYYY")
          : ""
      )} ${ConvertIntoBold(followUpData?.selection ?? "")}
    `;
    setFormData({
      subjective: Subjective,
      objective: objective,
      assessment: assesment,
      plan: plan,
    });

    setSoapData({
      subjective: Subjective,
      objective: objective,
      assessment: assesment,
      plan: plan,
    });
  }, [
    healthSummaryData,
    formattedLabsData,
    selectedCptCodes,
    selectedIcdCodes,
    followUpData?.date,
  ]);

  const textDesign = {
    width: "100%",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "Montserrat",
    color: "#272A40",
  };
  const labelDesign = {
    fontWeight: "600",
    fontSize: "22px",
    fontFamily: "Montserrat",
    color: "#272A40",
  };

  const handleButtonClick = (style) => {
    document.execCommand(style, false, null);
  };

  const handleTextChange = (id, updatedText) => {
    setFormData((old) => ({ ...old, [id]: updatedText }));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ButtonGroup
          style={{
            backgroundColor: "#F0F0F2",
            borderRadius: "4px",
            margin: "5px 0px",
          }}
        >
          <IconButton
            data-testid="FormatBold-icon"
            disabled={readOnly}
            sx={{
              borderRadius: "0px",
              cursor: readOnly ? "not-allowed" : "pointer",
            }}
            onClick={() => handleButtonClick("BOLD")}
          >
            <FormatBoldIcon />
          </IconButton>
          <IconButton
            data-testid="FormatItalic-icon"
            disabled={readOnly}
            sx={{
              borderRadius: "0px",
              cursor: readOnly ? "not-allowed" : "pointer",
            }}
            onClick={() => handleButtonClick("ITALIC")}
          >
            <FormatItalicIcon />
          </IconButton>
          <IconButton
            data-testid="FormatUnderlined-icon"
            disabled={readOnly}
            sx={{
              borderRadius: "0px",
              cursor: readOnly ? "not-allowed" : "pointer",
            }}
            onClick={() => handleButtonClick("UNDERLINE")}
          >
            <FormatUnderlinedIcon />
          </IconButton>
        </ButtonGroup>
        <Box className="text-to-speech">
          <SpeechToText ref={playRef} speech={speechText} />
        </Box>
      </Box>
      <Box
        className="cus-textFocus"
        sx={{
          padding: "10px",
          borderRadius: "12px",
          border: "1px solid #E1E1E1",
          backgroundColor: "white",
          minHeight: "350px",
          opacity: readOnly ? 0.5 : 1,
          cursor: readOnly ? "not-allowed" : "pointer",
        }}
      >
        <>
          <div>
            <label htmlFor="subjectiveInput">
              <h2 style={labelDesign}>Subjective</h2>
            </label>
            <div
              data-testid="subjective-area"
              placeholder="Subjective notes ..."
              className="cus-textarea"
              style={{ ...textDesign, border: "none", outline: "none" }}
              contentEditable={`${readOnly ? "false" : "true"}`}
              dangerouslySetInnerHTML={{ __html: formData.subjective }}
              onBlur={(e) => handleTextChange("subjective", e.target.innerHTML)}
            ></div>
          </div>
          <div>
            <label htmlFor="ObjInput">
              <h2 style={labelDesign}>Objective</h2>
            </label>
            <div
              data-testid="objective-area"
              placeholder="Objective notes ..."
              className="cus-textarea"
              style={{ ...textDesign, border: "none", outline: "none" }}
              contentEditable={`${readOnly ? "false" : "true"}`}
              dangerouslySetInnerHTML={{ __html: formData.objective }}
              onBlur={(e) => handleTextChange("objective", e.target.innerHTML)}
            ></div>
          </div>
          <div>
            <label htmlFor="AssesmentInput">
              <h2 style={labelDesign}>Assesment & Plan</h2>
            </label>
            <div
              data-testid="assesment-area"
              placeholder="Assesment notes ..."
              className="cus-textarea"
              style={{ ...textDesign, border: "none", outline: "none" }}
              contentEditable={`${readOnly ? "false" : "true"}`}
              dangerouslySetInnerHTML={{ __html: formData.assessment }}
              onBlur={(e) => handleTextChange("assessment", e.target.innerHTML)}
            ></div>
          </div>
          <div>
            <div
              data-testid="plan-area"
              placeholder="Plan notes ..."
              className="cus-textarea"
              style={{ ...textDesign, border: "none", outline: "none" }}
              contentEditable={`${readOnly ? "false" : "true"}`}
              dangerouslySetInnerHTML={{ __html: formData.plan }}
              onBlur={(e) => handleTextChange("plan", e.target.innerHTML)}
            ></div>
          </div>
        </>
      </Box>
    </>
  );
};

export default EndVisit;

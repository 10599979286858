import { Box, useTheme } from "@mui/material";
import { useFormik } from "formik";
import * as toast from 'hooks/notify';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSendMessageData, sendMessageToEmail } from "redux/features/SendMessage/SendMessageSlice";
import { getGroupWiseUsers } from "utils/getGroupsWiseUsers";
import { sendMessageValidationSchema } from "validations/Modals/sendMessage.schema";
import Button from '../Button/Button';
import InputField from '../FormComponents/InputField';
import CustomModal from './CustomModal';

const SendMessageModal = ({ open, setOpen }) => {
    const theme = useTheme();
    const dispatch = useDispatch()

    const { groupsOptions } = useSelector(state => state.options);
    const { usersList } = useSelector(state => state.MyTeams);
    const { isSendMessageLoading, sendMessageSuccess } = useSelector(state => state.sendMessage);

    const formik = useFormik({
        initialValues: {
            subject: "",
            message: "",
        },
        validationSchema: sendMessageValidationSchema,
        onSubmit: (values) => {
            values.to_email = getGroupWiseUsers(groupsOptions, usersList).filter(res => res.name === "Front Desk Representatives")[0]?.data[0]?.email
            dispatch(sendMessageToEmail({ ...values, recipient_type: 'front_desk' }))
        },
    })
    useEffect(() => {
        return () => {
            dispatch(clearSendMessageData())
        }
    }, [])

    useEffect(() => {
        setOpen(false)
        sendMessageSuccess && toast.success("Message sent successfully")
        sendMessageSuccess === false && toast.error("Message not sent")
        dispatch(clearSendMessageData())
        formik.resetForm()
    }, [sendMessageSuccess])

    useEffect(() => {
        if (open === false) {
            formik.resetForm()
        }
    }, [open])

    return (
        <CustomModal
            setOpen={setOpen}
            open={open}
            title={"Send Message to front desk"}
            width="auto"
            maxWidth={"sm"}
            fullWidth={true}
        >
            <>
                <InputField
                    label="Subject"
                    formik={formik}
                    name="subject"
                    placeholder={"Subject goes here...."}
                />
                <InputField
                    label="Message"
                    formik={formik}
                    name="message"
                    multiline={true}
                    rows={6}
                    placeholder={"Please type your message here..."}
                />
                <Box sx={{ display: "flex", padding: "25px 0px 0px 10px", justifyContent: "flex-end" }}>
                    <Button isLoading={isSendMessageLoading} text="Send Message" data-testid={"sendMessage-modal-send-button"} onClick={formik.handleSubmit} sx={{ width: "150px", color: theme.palette.button }} />
                </Box>
            </>
        </CustomModal>
    )
}

export default SendMessageModal 
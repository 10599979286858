import { Box } from "@mui/material";
import ESRDGraph from "../KeyIndicatorsCharts/ESRDGraph";
import ExpectedRevenue from "../KeyIndicatorsCharts/ExpectedRevenue";
import NewPatientGraph from "../KeyIndicatorsCharts/NewPatientGraph";
import PerformanceIndicatorChart from "../KeyIndicatorsCharts/PerformanceIndicatorChart";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";

const TotalRevenueChart = () => {
  const childrenArr = [
    {
      title: "Early CKD",
      tab: <PerformanceIndicatorChart isRevenu />,
    },
    {
      title: "Stage 4 CKD",
      tab: <NewPatientGraph isRevenu />,
    },
    {
      title: "ESRD",
      tab: <ESRDGraph />,
    },
    {
      title: "Non-CKD",
      tab: <ExpectedRevenue isRevenu />,
    },
  ];
  return (
    <Box>
      <Box></Box>
      <Box
        sx={{
          background: "#fff",
          height: "100%",
          borderRadius: "10px",
          border: "1px solid #ccc",
          padding: "15px 20px",
        }}
      >
        <HorizontalTabs childrenArray={childrenArr} />
      </Box>
    </Box>
  );
};

export default TotalRevenueChart;

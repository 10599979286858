import { Box, CircularProgress, Grid } from "@mui/material";
import search from "assets/svg/light/search.svg";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { clearTermsMedsData } from "redux/features/TermsAndMeds/TermsMedsSlice";
import { flexRowCenterCenter } from "styles";
import { upperHeaderSearchInput } from "styles/Dashboard/SchedulePatientsStyle";
import { debounce } from "utils/debouncer";
import InputField from "../FormComponents/InputField";
import CustomModal from "./CustomModal";

const TermsMedsModal = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const [queryData, setQueryData] = useState("");
    const [termMedsIsLoading, setTermMedsIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState(""); // Different state variable
    const abortController = useRef(new AbortController());
    const [isRequestPending, setIsRequestPending] = useState(false);

    useEffect(() => {
        dispatch(clearTermsMedsData());
    }, []);

    const getTermsAndMedsData = debounce((value) => {
        if (value?.length > 0) {
            setIsRequestPending(true);
            abortController.current.abort(); // Abort the previous request if any
            const newAbortController = new AbortController();
            abortController.current = newAbortController; // Update the abortController for the next request

            fetch(`https://kwslnsn5ztguzqsrgwplc2dlzi0usgyq.lambda-url.us-west-2.on.aws/?term=${value}`, {
                method: 'GET',
                referrerPolicy: 'no-referrer',
                signal: newAbortController.signal,
            })
                .then(response => response.json())
                .then(data => {
                    setQueryData(data?.data?.message ?? data?.data);
                })
                .catch(error => {
                    if (error.name !== 'AbortError') {
                        toast.error(`Failed to fetch ${value}`);
                    }
                })
                .finally(() => {
                    setIsRequestPending(false);
                });
        }
    }, 400);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            // Make the API call when the user has stopped typing for 400ms
            searchQuery && getTermsAndMedsData(searchQuery);
        }, 500);

        return () => {
            abortController.current.abort(); // Abort the ongoing request when the component unmounts
            clearTimeout(timeoutId); // Cleanup the previous timeout

        };
    }, [searchQuery]);

    return (
        <CustomModal
            setOpen={() => {
                setOpen(false)
                setQueryData("")
            }}
            open={open}
            title={"Term & Meds"}
            width="auto"
            maxWidth={"sm"}
            fullWidth={true}
        >
            <Grid container>
                <InputField
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    positionLeft={true}
                    name="search-id"
                    type={"text"}
                    Placeholder={"Search"}
                    sx={{ ...upperHeaderSearchInput, marginLeft: "0px" }}
                    iconDetails={{
                        allowIcon: true,
                        iconName: search,
                    }}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </Grid>

            {!isRequestPending ? (
                <Box sx={{ height: "300px", fontFamily: "inherit" }}>
                    {queryData ? (
                        <div dangerouslySetInnerHTML={{ __html: queryData }} style={{ marginTop: "10px" }} />
                    ) : <Box sx={{ marginTop: "10px" }}></Box>}
                </Box>
            ) : (
                <Box sx={{ height: "300px", ...flexRowCenterCenter }}>
                    <CircularProgress data-testid="loader-id" />
                </Box>
            )}
        </CustomModal>
    );
};

export default TermsMedsModal;

import moment from "moment/moment";
import { useEffect, useState } from "react";
import { flexRowCenterCenter } from "styles";
import {
  innerTitleDateSearchBox,
  titleDateBox,
  titleDateSearchBox,
  upperHeaderDatePickerStyling,
} from "styles/Dashboard/SchedulePatientsStyle";
import { Box, Grid, useTheme } from "@mui/material";
import Text from "components/common/Typography/Text";
import fourboxActive from "../../../assets/svg/light/boxIcon.svg";
import threeLinesInActive from "../../../assets/svg/light/threelineIcon.svg";
import fourBoxInActive from "../../../assets/svg/colored/fourBoxInActive.svg";
import DatePickerInput from "components/common/FormComponents/DatePickerInput";
import threeLinesActive from "../../../assets/svg/colored/threeLinesActive.svg";
import SelectField from "components/common/FormComponents/SelectField";
import TreatmentStatusMenus from "./TreatmentStatus";

const statusOptions = [
  { value: "urgent", label: "Urgent" },
  { value: "important", label: "Important" },
  { value: "normal", label: "Normal" },
  { value: "all patients", label: "All Patients" },
];
const UpperHeader = ({
  setHealthFilter,
  isVerticalView,
  setIsVerticalView,
  date,
  setDate,
  setOpen,
  setOverviewOpen,
  setSearchData,
}) => {
  let theme = useTheme();
  const [viewWidth, setViewWidth] = useState(window.innerWidth);
  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  const [activeView, setActiveView] = useState("lines");
  useEffect(() => {
    const handleResize = () => {
      setViewWidth(window.innerWidth);
      setViewHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setFilterFlag = (value) => {
    setHealthFilter(value ? value : "all patients");
  };

  return (
    <Box sx={titleDateSearchBox}>
      <Box sx={innerTitleDateSearchBox}>
        <Box sx={titleDateBox}>
          {/* <Text variant="h2" marginRight="20px"> */}
          <span
            style={{ fontSize: "1.3rem", marginRight: "20px", fontWeight: 600 }}
            data-testid="main-scheduled-patient-heading"
          >
            Scheduled Patients
          </span>
          {/* </Text> */}
          <Grid>
            <DatePickerInput
              fontWeight="400"
              setDate={setDate}
              data-testid="date-picker"
              defaultDate={
                localStorage.getItem("scheduledPatientDate")
                  ? moment(localStorage.getItem("scheduledPatientDate"))
                  : moment(date)
              }
              datePickerStyling={upperHeaderDatePickerStyling}
            />
          </Grid>
        </Box>
        <Box sx={flexRowCenterCenter}>
          {/* <SelectField
            cols={12}
            width={'165px'}
            height={'30px'}
            name="labs"
            options={statusOptions}
            placeholder="All Categories"
            backgroundColor={'#246EFD !important'}
            textColor={'#FFFFFF !important'}
            sxTextField={{
              '& .MuiInputBase-input::placeholder': {
                color: 'white !important', // Change this to your desired placeholder color
                opacity: 1, // Ensure the color is applied correctly
              },
              '& .MuiButtonBase-root': {
                color: 'white !important',
              }
            }}
            onChange={setFilterFlag}
          /> */}
          <Box sx={{ marginRight: "15px" }}>
            <TreatmentStatusMenus setFilterFlag={setFilterFlag} />
          </Box>
          <img
            src={!isVerticalView ? threeLinesInActive : threeLinesActive}
            alt="horitzontal"
            style={{
              cursor: "pointer",
              marginRight: "10px",
              padding: "2px",
              width: "25px",
              height: "25px",
            }}
            onClick={() => setIsVerticalView(true)}
          />
          <img
            src={isVerticalView ? fourBoxInActive : fourboxActive}
            alt="Vertical"
            style={{
              cursor: "pointer",
              padding: "5px",
              width: "32px",
              height: "32px",
            }}
            onClick={() => setIsVerticalView(false)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UpperHeader;

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box } from "@mui/material";
import Button from "components/common/Button/Button";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import TimerBox from "components/common/TimerBox/TimerBox";
import Text from "components/common/Typography/Text";
import moment from "moment";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { saveDictation } from "redux/features/AmbientAI/RecordingSlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import {
  convertBlobToM4A,
  startRecording,
  stopRecording,
} from "utils/recording";
import recordingAnime from "../../../../assets/images/recordingAnime.gif";
import micIcon from "../../../../assets/svg/light/micIcon.svg";
import rightArrow from "../../../../assets/svg/light/rightArrow.svg";

const SCDHeader = forwardRef(
  (
    {
      encounterClosed = false,
      patientId,
      getSpecificPatientData,
      getSpecificPatientSuccess,
      dummyPic,
      isReviewLater,
      setOpen,
      startAppointment,
      setStartAppointment,
      setPastVisit,
    },
    ref
  ) => {
    let { appointmentId } = useParams();
    const chunks = useRef([]);
    const mediaStream = useRef(null);
    const mediaRecorder = useRef(null);
    const dispatch = useDispatch();

    const [recordedUrl, setRecordedUrl] = useState("");
    const [isRecording, setIsRecording] = useState(false);

    const { healthSummaryData } = useSelector((state) => state.healthSummary);
    const { appointmentData } = useSelector(
      (state) => state.specificAppointment
    );

    const { listening, finalTranscript, resetTranscript } =
    useSpeechRecognition();

  const startListeningHandler = () => {
    SpeechRecognition.startListening({ continuous: true });
  };

  const stopListeningHandler = () => {
    SpeechRecognition.stopListening();
  };

  const handleListen = () => {
    if (!listening) {
      startListeningHandler();
    } else {
      stopListeningHandler();
    }
  };

  const handleReset = () => {
    resetTranscript();
  };

  useEffect(() => {
    if (finalTranscript !== "") {
      dispatch(saveDictation(finalTranscript));
      // setTranscript((prevTranscript) => prevTranscript + " " + finalTranscript);
      resetTranscript();
    }
  }, [finalTranscript, resetTranscript]);

    useEffect(() => {
      if (recordedUrl && !isRecording) {
        convertBlobToM4A(recordedUrl)
          .then((m4aBlob) => {
            // Create FormData and append M4A blob
            const data = new FormData();
            data.append("filename", m4aBlob);

            // Dispatch postRecordingData with appointmentId and FormData
            // dispatch(postRecordingData(appointmentId, data));
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }, [isRecording, recordedUrl]);

    const isSpecificPatient = () => {
      return (
        getSpecificPatientData?.first_name +
          " " +
          getSpecificPatientData?.last_name ===
          "Sophia Patel" ||
        getSpecificPatientData?.first_name +
          " " +
          getSpecificPatientData?.last_name ===
          "Chloe Martin"
      );
    };

    const handleStartApp = () => {
      setStartAppointment(true);
      !isSpecificPatient() && handleListen();
      startRecording(
        setRecordedUrl,
        setIsRecording,
        mediaStream,
        mediaRecorder,
        chunks
      );
    };

    useImperativeHandle(ref, () => ({
      startAppfunc: handleStartApp,
    }));

    return (
      <Box
        sx={{
          backgroundColor: "#FFFFFFBF",
          borderRadius: "24px",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
          display: "flex",
          alignItems: "center",
          padding: "10px 15px",
        }}
      >
        <Box
          flex={"1"}
          flexWrap={"wrap"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginRight: "10px",
              }}
            >
              <SkeletonWrapper
                id={"patient-detail-header-name"}
                condition={getSpecificPatientSuccess}
                variant="circular"
                props={[
                  SkeletonPropsStyleGenerator("80px", "80px", "", "10px"),
                ]}
              >
                <img
                  src={localStorage.getItem("appointment_img") ?? dummyPic}
                  alt=""
                  style={{
                    marginRight: "10px",
                    borderRadius: "100%",
                    width: "64px",
                    height: "64px",
                  }}
                />
              </SkeletonWrapper>
            </Box>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SkeletonWrapper
                  id={"patient-detail-header-name"}
                  condition={getSpecificPatientSuccess}
                  props={[
                    SkeletonPropsStyleGenerator(
                      "100px",
                      "45px",
                      "150px",
                      "20px"
                    ),
                  ]}
                >
                  <Text
                    id={"scd-patient-name"}
                    variant="h1"
                    color={"black"}
                    marginRight="10px"
                  >
                    {getSpecificPatientData?.first_name
                      ? `${getSpecificPatientData?.first_name} ${" "}`
                      : ""}
                  </Text>
                  <span
                    style={{ fontSize: "1.375rem", lineHeight: "2.063rem" }}
                  >
                    {getSpecificPatientData?.last_name
                      ? getSpecificPatientData?.last_name
                      : ""}
                  </span>
                </SkeletonWrapper>
                <SkeletonWrapper
                  id={"patient-detail-header-empty"}
                  condition={getSpecificPatientSuccess}
                  props={[SkeletonPropsStyleGenerator("5px", "0px")]}
                >
                  <span style={{}} />
                </SkeletonWrapper>
                <SkeletonWrapper
                  id={"patient-detail-header-name"}
                  condition={getSpecificPatientSuccess}
                  props={[
                    SkeletonPropsStyleGenerator("100px", "45px", "150px"),
                  ]}
                >
                  <Box
                    sx={{
                      backgroundColor: "#D9FFE5",
                      borderRadius: "30px",
                      padding: "5px 8px",
                      marginLeft: "10px",
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <AccessTimeIcon
                        sx={{
                          marginRight: "5px",
                          width: "16px",
                          height: "16px",
                        }}
                      />{" "}
                      <Text variant="bodyXs">
                        {startAppointment ? (
                          <TimerBox />
                        ) : (
                          <Timer
                            date={appointmentData?.datetime}
                            time={
                              localStorage.getItem("appointment_time") ?? ""
                            }
                          />
                        )}
                      </Text>
                    </Box>
                  </Box>
                </SkeletonWrapper>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SkeletonWrapper
                  id={"patient-detail-header-gender"}
                  condition={getSpecificPatientSuccess}
                  props={[
                    SkeletonPropsStyleGenerator("100px", "45px", "", "10px"),
                  ]}
                >
                  <Text id={"scd-gender"} variant="bodyXs" color={"lightBlack"}>
                    ID: {patientId ?? ""}
                  </Text>
                </SkeletonWrapper>
                <hr
                  style={{
                    height: "16px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
                <SkeletonWrapper
                  id={"patient-detail-header-gender"}
                  condition={getSpecificPatientSuccess}
                  props={[SkeletonPropsStyleGenerator("100px", "45px", "")]}
                >
                  <Text id={"scd-gender"} variant="bodyXs" color={"lightBlack"}>
                    {getSpecificPatientData?.gender
                      ? getSpecificPatientData?.gender
                      : ""}
                  </Text>
                </SkeletonWrapper>
                <hr
                  style={{
                    height: "16px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
                <SkeletonWrapper
                  id={"patient-detail-header-age"}
                  condition={getSpecificPatientSuccess}
                  props={[SkeletonPropsStyleGenerator("100px", "45px", "")]}
                >
                  <Text id={"scd-age"} variant="bodyXs" color={"lightBlack"}>
                    {getSpecificPatientData?.birthdate
                      ? `${moment(getSpecificPatientData?.birthdate).format(
                          "MM/DD/YYYY"
                        )} ( ${moment().diff(
                          getSpecificPatientData?.birthdate,
                          "years"
                        )} ) y.o.`
                      : ""}
                  </Text>
                </SkeletonWrapper>
                <hr
                  style={{
                    height: "16px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
                <SkeletonWrapper
                  id={"patient-detail-header-age"}
                  condition={getSpecificPatientSuccess}
                  props={[SkeletonPropsStyleGenerator("100px", "45px", "")]}
                >
                  <Text id={"scd-age"} variant="bodyXs" color={"lightBlack"}>
                    {healthSummaryData?.[0]?.ckd_status ?? ""}
                  </Text>
                </SkeletonWrapper>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {startAppointment && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {isRecording && (
                  <img src={recordingAnime} alt="anime file" height={30} />
                )}
                <img
                  src={micIcon}
                  alt=""
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                />
                <hr
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    height: "52px",
                  }}
                />
                <Button
                  id={"scd-view-last-note-btn"}
                  text="End Visit"
                  color="#ffffff"
                  backgroundColor={"#1344f1"}
                  btnColor={"#ffffff"}
                  border="1px solid #1344f1"
                  data-testid="scd-view-last-note-btn-id"
                  borderRadius={"10px"}
                  fontSize="14px"
                  fontWeight="600"
                  sx={{
                    width: "150px",
                    padding: "0px 20px",
                  }}
                  rightSide
                  iconDetails={{ allowIcon: true, icon: rightArrow }}
                  onClick={() => {
                    setOpen(true);
                    stopListeningHandler();
                    stopRecording(mediaStream, mediaRecorder);
                  }}
                />
              </Box>
            )}
            {!startAppointment && (
              <Button
                text="Review Past Visits"
                color="#246EFD"
                backgroundColor={"transparent"}
                btnColor={"#246EFD"}
                border="1px solid #246EFD"
                borderRadius={"10px"}
                fontSize="14px"
                fontWeight="600"
                sx={{
                  width: "150px",
                  padding: "0px 20px",
                  marginRight: "10px",
                  textAlign: "center",
                }}
                onClick={() => {
                  setPastVisit(true);
                }}
              />
            )}
            {encounterClosed && (
              <Button
                onClick={() => {
                  setOpen(true);
                }}
                id={"scd-view-last-note-btn"}
                text="View Last Notes"
                color="#ffffff"
                backgroundColor={"#1344f1"}
                btnColor={"#ffffff"}
                border="1px solid #1344f1"
                data-testid="scd-view-last-note-btn-id"
                borderRadius={"10px"}
                fontSize="14px"
                fontWeight="600"
                sx={{
                  width: "170px",
                  padding: "0px 20px",
                }}
              />
            )}
            {!startAppointment && !encounterClosed && (
              <Button
                onClick={() => {
                  handleStartApp();
                }}
                id={"scd-view-last-note-btn"}
                text={"Start Visit"}
                color="#ffffff"
                backgroundColor={"#1344f1"}
                btnColor={"#ffffff"}
                border="1px solid #1344f1"
                data-testid="scd-view-last-note-btn-id"
                borderRadius={"10px"}
                fontSize="14px"
                fontWeight="600"
                sx={{
                  width: "150px",
                  padding: "0px 20px",
                }}
                rightSide
                iconDetails={{ allowIcon: true, icon: rightArrow }}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);

function Timer({ time = "9:15", date = "" }) {
  return (
    <Text variant="bodyS">
      Scheduled for {time}{" "}
      {moment().isSame(moment(date?.split("T")?.[0]), "day") ? "today" : ""}
    </Text>
  );
}

export default SCDHeader;

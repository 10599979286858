import { Box, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { clearNotesHistoryMessage, getNotesHistory } from 'redux/features/Patients/ViewNotesHistorySlice';
import CustomModal from '../CustomModal';
import NotesAccordion from "./NotesAccordion";
import { useNotesHistoryModalStyles } from "styles/Modals/ViewNotesHistoryModalStyle";

const ViewNotesHistoryModal = ({ open, setOpen, patientID = null, setSelectedPatientID }) => {
    const dispatch = useDispatch();
    const classes = useNotesHistoryModalStyles();
    const { results, isNotesHistoryLoading } = useSelector(state => state.historyNotes);

    useEffect(() => {
        return () => {
            dispatch(clearNotesHistoryMessage())
            setSelectedPatientID && setSelectedPatientID(null)
        }
    }, [])

    useEffect(() => {
        patientID && dispatch(getNotesHistory(patientID))
    }, [dispatch, patientID])

    return (
        <CustomModal
            setOpen={setOpen}
            open={open}
            title={`SOAP Note History`}
            width="auto"
            padding="10px 10px"
            maxWidth={"lg"}
            fullWidth={true}
        >
            <Box data-testid="modal-notes-history" sx={{ padding: "3px 0px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <NotesAccordion
                            isNotesHistoryLoading={isNotesHistoryLoading}
                            results={results}
                            classes={classes}
                        />
                    </Grid>
                </Grid>

            </Box>
        </CustomModal>
    );
};

export default ViewNotesHistoryModal;

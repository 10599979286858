import { Box, Slider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import unionIcon from "assets/svg/light/Union.svg";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getHealthSummaryList } from "redux/features/HealthSummary/HealthSummarySlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import {
  divider,
  healthSummaryDescriptionStyle,
  healthSummaryFieldsBoxStyle,
  riskAssesmentHeadingStyle,
} from "styles/SCD/healthSummaryStyle";
import infoIcon from "assets/svg/light/info_icon.svg";
import CustomTooltip from "components/common/CustomTooltip/CustomTooltip";
import { getRiskInfo, smartSummaryToolTip } from "assets/static";

const useStyles = makeStyles({
  thumb: {
    "& .MuiSlider-thumb": {
      width: "2px",
      height: "15px",
      backgroundColor: "#2D3748",
      "&:hover": {
        backgroundColor: "#2D3748",
        boxShadow: "none",
      },
    },
  },
});

const HealthSummary = ({ setPdfModal }) => {
  const classes = useStyles();
  const [viewWidth, setViewWidth] = useState(window.innerWidth);
  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  const leftSideRef = useRef(null);
  const [lineHeight, setLineHeight] = useState(100);

  let dispatch = useDispatch();
  let { patientId, appointmentId } = useParams();
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );
  const { healthSummarySuccess, healthSummaryData } = useSelector(
    (state) => state.healthSummary
  );

  const labels = ["Low", "Moderate", "High"];

  useEffect(() => {
    dispatch(getHealthSummaryList(patientId, appointmentId));
  }, [patientId]);

  useEffect(() => {
    const updateLineHeight = () => {
      const leftSideHeight =
        leftSideRef.current?.getBoundingClientRect().height || 0;
      setLineHeight(leftSideHeight);
    };
    window.addEventListener("resize", updateLineHeight);
    updateLineHeight();
    return () => {
      window.removeEventListener("resize", updateLineHeight);
    };
  }, [
    healthSummaryData?.[0]?.optimal_start,
    healthSummaryData?.[0]?.last_hospitalization,
  ]);
  useEffect(() => {
    const handleResize = () => {
      setViewWidth(window.innerWidth);
      setViewHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSummaryData = (name) => {
    const data = {
      "Chloe Martin":
        "Chloe Martin has a history of CKD stage-4, which has now progressed to stage-5. She also has a history of diabetes and hypertension and is currently suffering from diabetic nephropathy. Additionally, she has a history of obstructive sleep apnea and is supposed to use BiPAP, but has not been using it for the last six months due to claustrophobia caused by the BiPAP machine. She had a recent hospital admission for pneumonia requiring IV antibiotics, discharged with one new medication of Amlodipine and stopped Lisinopril due to worsening kidney symptoms.",
      "Sophia Patel":
        "Sophia is a 67-year-old female with a 15-year history of type 2 diabetes, which has been marginally controlled; her last hemoglobin A1c was 9.2. She now presents with elevated creatinine of 2.2 with historical Cr in January of 2023 of 2.0, elevated phosphorus, mild anemia, and a urine protein to creatinine ratio of 3.2 grams without hematuria. She reports swelling in her legs and also has diabetic neuropathy and retinopathy. Notably, her albumin is low at 2.9, her potassium is at the high end of normal, and she exhibits mild acidosis.She has low blood pressure on presentation.",
    };
    return data?.[name];
  };
  const getRiskData = (name) => {
    const data = {
      "Chloe Martin": ["CKD-5", "DM-II", "DHF"],
      "Sophia Patel": ["DM-II ", "Proteinuria", "Low BP"],
    };
    return data?.[name];
  };

  let patientName =
    getSpecificPatientData?.first_name +
    " " +
    getSpecificPatientData?.last_name;

  const [sliderValue, setSliderValue] = useState(1);
  useEffect(() => {
    if (patientName == "Chloe Martin") {
      setSliderValue(3);
    } else if (patientName == "Sophia Patel") {
      setSliderValue(3);
    } else {
      healthSummaryData?.[0]?.assessment_severity &&
        setSliderValue(healthSummaryData?.[0]?.assessment_severity);
    }
  }, [healthSummaryData?.[0]?.assessment_severity]);

  const thumbRef = useRef(null);
  const [leftStyle, setLeftStyle] = useState();
  useEffect(() => {
    if (thumbRef.current) {
      const parentSpan = thumbRef.current;
      const childSpans = parentSpan?.querySelectorAll("span");
      if (childSpans.length >= 3) {
        const thirdSpan = childSpans[2];
        if (thirdSpan instanceof Element) {
          const thirdSpanStyles = window.getComputedStyle(thirdSpan);

          setLeftStyle(thirdSpanStyles?.getPropertyValue("left"));
        } else {
        }
      } else {
      }
    } else {
    }
  }, []);
  const getPercentValue = {
    1: "5%",
    2: "10%",
    3: "20%",
    4: "20%",
    5: "30%",
    6: "40%",
    7: "50%",
    8: "65%",
    9: "65%",
    10: "65%",
  };
  return (
    <Box sx={{ marginLeft: "20px", marginRight: "20px", marginBottom: "20px" }}>
      <Box sx={{ marginTop: "20px" }}>
        <Text
          data-testid="scd-health-summary-label-Optimal"
          variant="bodyS"
          minWidth="125px"
        >
          {getSummaryData(
            getSpecificPatientData?.first_name +
              " " +
              getSpecificPatientData?.last_name
          ) ?? (
            <>
              {getSpecificPatientData?.first_name ?? ""}{" "}
              {getSpecificPatientData?.last_name ?? ""} has a history of{" "}
              {healthSummaryData?.[0]?.ckd_status ?? ""} secondary to type II
              diabetes and hypertension. Her visit was scheduled today because
              she is feeling fatigued and tired. Her latest labs indicate
              elevated serum phosphate levels, and her prior 3 labs show
              increasing creatinine levels, indicating worsening kidney
              function. {getSpecificPatientData?.first_name ?? ""}{" "}
              {getSpecificPatientData?.last_name ?? ""} was hospitalized for 3
              days starting on Dec 1, 2023 where she was prescribed Lisinopril.
            </>
          )}
        </Text>
      </Box>
      <Box sx={healthSummaryDescriptionStyle(viewWidth)}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          marginTop={"10px"}
          flexBasis="60%"
          sx={{ borderRight: "1px solid #D9D9D9" }}
        >
          <Box sx={healthSummaryFieldsBoxStyle(healthSummarySuccess)}>
            <Text
              data-testid="scd-health-summary-label-last-hosp"
              variant="propertyLabel"
              minWidth="125px"
            >
              Last Hospitalization
            </Text>
            <Box>
              <SkeletonWrapper
                id={"skeleton-health-summary-last-hosp"}
                condition={healthSummarySuccess}
                props={[SkeletonPropsStyleGenerator("7vmax", "40px", "150px")]}
              >
                <Text
                  data-testid="scd-health-summary-value-last-hosp"
                  variant="bodyXs"
                >
                  {healthSummaryData?.[0]?.last_hospitalization !== null &&
                  healthSummaryData?.[0]?.last_hospitalization !== undefined
                    ? moment(
                        healthSummaryData?.[0]?.last_hospitalization
                      ).format("MM/DD/YYYY")
                    : ""}
                </Text>
                <Box
                  onClick={() => {
                    setPdfModal(true);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <img src={unionIcon} alt="" style={{ marginRight: "10px" }} />
                  <Text variant="bodyXs" color={"blue"}>
                    Discharge report
                  </Text>
                </Box>
              </SkeletonWrapper>
            </Box>
          </Box>
          <Box sx={healthSummaryFieldsBoxStyle(healthSummarySuccess)}>
            <Text
              data-testid="scd-health-summary-label-reason"
              variant="propertyLabel"
              minWidth="125px"
            >
              Reason for Visit
            </Text>
            <Box>
              <SkeletonWrapper
                id={"skeleton-health-summary-reason"}
                condition={healthSummarySuccess}
                props={[SkeletonPropsStyleGenerator("14vmax", "40px", "150px")]}
              >
                <Text
                  data-testid="scd-health-summary-value-reason"
                  variant="bodyXs"
                >
                  {healthSummaryData?.[0]?.reason}
                </Text>
              </SkeletonWrapper>
            </Box>
          </Box>
          <Box sx={healthSummaryFieldsBoxStyle(healthSummarySuccess)}>
            <Text
              data-testid="scd-health-summary-label-ros"
              variant="propertyLabel"
              minWidth="125px"
            >
              Optimal Start Candidate
            </Text>
            <Box>
              <SkeletonWrapper
                id={"skeleton-health-summary-ros"}
                condition={healthSummarySuccess}
                props={[SkeletonPropsStyleGenerator("8vmax", "40px", "150px")]}
              >
                <Text
                  data-testid="scd-health-summary-value-ros"
                  variant="bodyXs"
                >
                  {healthSummaryData?.[0]?.ros}
                </Text>
              </SkeletonWrapper>
            </Box>
          </Box>
        </Box>

        <Box sx={{ flexBasis: "40%", marginLeft: "26px", marginTop: "20px" }}>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Text
              data-testid="scd-health-summary-label-risk"
              variant="h3"
              marginBottom="10px"
            >
              Risk Assesment
            </Text>
            {/* will be added after demo */}
            {/* <Box sx={{ marginLeft: "10px", marginTop: "-2px" }}>
              <SmartSummaryTooltip
                width="650px"
                tooltipText={
                  <Text>
                    {getRiskInfo(
                      healthSummaryData?.[0]?.assessment_severity
                    )?.data?.map(({ statusHeading, heading, desc }) => {
                      return (
                        <>
                          <Box>
                            <Box
                              sx={{
                                fontSize: "14px",
                                lineHeight: "normal",
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              {statusHeading}
                            </Box>
                            <Box
                              style={{
                                fontSize: "12px",
                                lineHeight: "normal",
                                fontWeight: "600",
                              }}
                            >
                              {heading}
                            </Box>
                          </Box>
                          <Box
                            style={{
                              fontSize: "12px",
                              lineHeight: "normal",
                              fontWeight: "400",
                            }}
                          >
                            {desc}
                          </Box>
                        </>
                      );
                    })}
                  </Text>
                }
                placement="top-end"
                arrow={true}
              >
                <img
                  src={infoIcon}
                  alt=""
                  style={{
                    cursor: "pointer",
                  }}
                />
              </SmartSummaryTooltip>
            </Box> */}
            <Box sx={riskAssesmentHeadingStyle}>
              {/* If you are using multipleCount then you send unique props for every element */}
            </Box>
          </Box>
          <SkeletonWrapper
            id={"skeleton-health-summary-"}
            condition={healthSummarySuccess}
            props={[SkeletonPropsStyleGenerator("100%", "60px", "150px")]}
          >
            <Slider
              ref={thumbRef}
              id="custom-slider"
              className={classes.thumb}
              sx={{
                color: "transparent",
                height: "6px !important",
                padding: "1px 0px",
                backgroundImage:
                  "linear-gradient(90deg, #57D281 0%, #EEAB2A 50.5%, #E55D55 100%)",
                borderRadius: "30px",
              }}
              max={10}
              value={
                patientName == "Chloe Martin"
                  ? 9
                  : patientName == "Sophia Patel"
                    ? 5
                    : healthSummaryData?.[0]?.assessment_severity ?? 1
              }
              defaultValue={1}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  position: "absolute",
                  left:
                    patientName == "Chloe Martin"
                      ? "65%"
                      : patientName == "Sophia Patel"
                        ? "30%"
                        : getPercentValue[
                            healthSummaryData?.[0]?.assessment_severity ?? 1
                          ],
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                <Box>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color:
                        patientName == "Chloe Martin"
                          ? "#E76E4B"
                          : patientName == "Sophia Patel"
                            ? "#EC9834"
                            : sliderValue <= 3
                              ? "#6ECB72"
                              : sliderValue <= 7
                                ? "#EC9834"
                                : "#E76E4B",
                    }}
                  >
                    {patientName == "Chloe Martin"
                      ? labels[2]
                      : patientName == "Sophia Patel"
                        ? labels[1]
                        : sliderValue <= 3
                          ? labels[0]
                          : sliderValue <= 7
                            ? labels[1]
                            : labels[2]}
                  </span>
                </Box>

                <Box>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color:
                        patientName == "Chloe Martin"
                          ? "#E76E4B"
                          : patientName == "Sophia Patel"
                            ? "#EC9834"
                            : sliderValue <= 3
                              ? "#6ECB72"
                              : sliderValue <= 7
                                ? "#EC9834"
                                : "#E76E4B",
                    }}
                  >
                    {`- ${
                      patientName == "Chloe Martin"
                        ? 9
                        : patientName == "Sophia Patel"
                          ? 5
                          : sliderValue
                    }/${10}`}
                  </span>
                </Box>
              </Box>
            </Box>
          </SkeletonWrapper>
          <Box sx={{ marginTop: "17px" }}>
            <Text
              data-testid="scd-health-summary-label-Optimal"
              variant="propertyLabel"
              minWidth="125px"
            >
              Risk Factors
            </Text>
            <Box>
              <SkeletonWrapper
                id={"skeleton-health-summary-risk-assesment"}
                condition={healthSummarySuccess}
                multipleCount={3}
                props={[
                  SkeletonPropsStyleGenerator("7vmax", "30px", "150px"),
                  SkeletonPropsStyleGenerator("8vmax", "30px", "150px"),
                  SkeletonPropsStyleGenerator("8vmax", "30px", "150px"),
                ]}
              >
                <>
                  {getRiskData(
                    getSpecificPatientData?.first_name +
                      " " +
                      getSpecificPatientData?.last_name
                  ) ? (
                    getRiskData(
                      getSpecificPatientData?.first_name +
                        " " +
                        getSpecificPatientData?.last_name
                    )?.map((riskAssesmentValue, i) => (
                      <Text
                        variant="bodyXs"
                        data-testid={`scd-health-summary-label-risk-id-${i}`}
                        marginY={1}
                        marginLeft="10px"
                      >
                        {riskAssesmentValue === "cirrhosis"
                          ? `${riskAssesmentValue
                              ?.charAt(0)
                              ?.toUpperCase()}${riskAssesmentValue?.substring(
                              1,
                              riskAssesmentValue?.length
                            )}`
                          : riskAssesmentValue.toUpperCase()}
                      </Text>
                    ))
                  ) : (
                    <>
                      {healthSummaryData?.[0]?.ros &&
                        healthSummaryData?.[0]?.yes_only_fields?.length > 0 &&
                        healthSummaryData?.[0]?.yes_only_fields?.map(
                          (riskAssesmentValue, i) => (
                            <Text
                              variant="bodyXs"
                              data-testid={`scd-health-summary-label-risk-id-${i}`}
                              marginY={1}
                              marginLeft="10px"
                            >
                              {riskAssesmentValue === "cirrhosis"
                                ? `${riskAssesmentValue
                                    ?.charAt(0)
                                    ?.toUpperCase()}${riskAssesmentValue?.substring(
                                    1,
                                    riskAssesmentValue?.length
                                  )}`
                                : riskAssesmentValue.toUpperCase()}
                            </Text>
                          )
                        )}
                    </>
                  )}
                </>
              </SkeletonWrapper>
            </Box>
          </Box>
          <Box sx={divider}></Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginTop: "15px",
            }}
          >
            <Text variant="h3" marginBottom="15px">
              Risk of CKD progression in 5 years  
            </Text>
            <Box sx={riskAssesmentHeadingStyle}></Box>
          </Box>
          <SkeletonWrapper
            condition={healthSummarySuccess}
            props={[SkeletonPropsStyleGenerator("100%", "60px", "150px")]}
          >
            <Slider
              ref={thumbRef}
              id="custom-slider"
              className={classes.thumb}
              sx={{
                color: "transparent",
                height: "6px !important",
                padding: "1px 0px",
                backgroundImage:
                  "linear-gradient(90deg, #57D281 0%, #EEAB2A 50.5%, #E55D55 100%)",
                borderRadius: "30px",
              }}
              max={10}
              value={
                patientName == "Chloe Martin"
                  ? 9
                  : patientName == "Sophia Patel"
                    ? 2
                    : healthSummaryData?.[0]?.assessment_severity ?? 1
              }
              defaultValue={1}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  position: "absolute",
                  left:
                    patientName == "Chloe Martin"
                      ? "70%"
                      : patientName == "Sophia Patel"
                        ? "20%"
                        : getPercentValue[
                            healthSummaryData?.[0]?.assessment_severity ?? 1
                          ],
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                <Box>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color:
                        patientName == "Chloe Martin"
                          ? "#E76E4B"
                          : patientName == "Sophia Patel"
                            ? "#6ECB72"
                            : sliderValue <= 3
                              ? "#6ECB72"
                              : sliderValue <= 7
                                ? "#EC9834"
                                : "#E76E4B",
                    }}
                  >
                    {patientName == "Chloe Martin"
                      ? labels[2]
                      : patientName == "Sophia Patel"
                        ? labels[0]
                        : sliderValue <= 3
                          ? labels[0]
                          : sliderValue <= 7
                            ? labels[1]
                            : labels[2]}
                  </span>
                </Box>

                <Box>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color:
                        patientName == "Chloe Martin"
                          ? "#E76E4B"
                          : patientName == "Sophia Patel"
                            ? "#6ECB72"
                            : sliderValue <= 3
                              ? "#6ECB72"
                              : sliderValue <= 7
                                ? "#EC9834"
                                : "#E76E4B",
                    }}
                  >
                    {`- ${
                      patientName == "Chloe Martin"
                        ? 90
                        : patientName == "Sophia Patel"
                          ? 28.7
                          : sliderValue
                    }%`}
                  </span>
                </Box>
              </Box>
            </Box>
          </SkeletonWrapper>
          <Box sx={{ marginTop: "17px" }}>
            <Text
              data-testid="scd-health-summary-label-Optimal"
              variant="propertyLabel"
              minWidth="125px"
            >
              KFRE Equation Results
            </Text>
            <Box>
              <SkeletonWrapper
                condition={healthSummarySuccess}
                multipleCount={3}
                props={[SkeletonPropsStyleGenerator("7vmax", "30px", "150px")]}
              >
                <Text variant="h4">
                  {" "}
                  {patientName == "Chloe Martin"
                    ? -49
                    : patientName == "Sophia Patel"
                      ? -22
                      : 23}
                </Text>
              </SkeletonWrapper>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default HealthSummary;

import {
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import Text from "../Typography/Text";

const InputField = ({
  label,
  name,
  min,
  max,
  iconDetails = { allowIcon: false, iconName: null },
  positionLeft = false,
  positionRight = false,
  onKeyDown,
  type,
  onChange,
  xs = 6,
  sm = 6,
  md = 6,
  lg = 6,
  flex = 0,
  formik,
  showError = false,
  errorMessage,
  defaultValue = "",
  onFocus,
  required = false,
  isPhone = false,
  middle = false,
  Placeholder,
  fontWeight = "600",
  multiline,
  passwordVisible,
  setPasswordVisible,
  border = "1px solid #E1E1E1",
  borderRadius = "10px",
  className,
  labelVariant = "bodyS",
  rows,
  ...props
}) => {
  const theme = useTheme();
  let { allowIcon, iconName } = iconDetails;
  const togglePaswordIcon = () => {
    setPasswordVisible &&
      setPasswordVisible((prevPasswordVisible) => !prevPasswordVisible);
  };

  const [fieldError, setFieldError] = useState(
    formik?.errors[name] || errorMessage
  );
  const [fieldTouched, setFieldTouched] = useState(
    formik?.touched[name] || showError
  );

  useEffect(() => {
    setFieldError(formik?.errors[name] || errorMessage);
    setFieldTouched(formik?.touched[name] || showError);
  }, [formik, showError]);

  const handleChange = (event) => {
    let { value } = event.target;

    if (middle && value.length > 1) {
      value = value.charAt(0);
      event.target.value = value;
    }

    if (formik) {
      formik.handleChange(event);
    } else {
      onChange && onChange(event);
    }

    if (props.maxLength && value.length > props.maxLength) {
      return;
    }
  };

  const handleKeyPress = (event) => {
    if (isPhone) {
      const pattern = /^\d{0,3}(-\d{0,3}(-\d{0,4})?)?$/;

      const inputChar = String.fromCharCode(event.charCode);

      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  };

  const handleKeyUp = (event) => {
    if (event.ctrlKey && event.key === "Backspace") {
      // Handle Ctrl + Backspace key combination
      if (onChange) {
        onChange({
          target: {
            name: name,
            value: event.target.value,
          },
        });
      }
    }
  };

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} flex={flex}>
      {label && (
        <Text variant={labelVariant} fontWeight={fontWeight} marginY={1}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <FormControl fullWidth variant="outlined" className={className}>
        <TextField
          id={`${name}`}
          name={name}
          type={type}
          multiline={multiline}
          rows={rows}
          data-testid={name}
          onKeyDown={onKeyDown}
          onKeyUp={handleKeyUp}
          onFocus={onFocus}
          defaultValue={defaultValue}
          sx={{ border: border, borderRadius: borderRadius }}
          InputProps={{
            autoComplete: "off",
            startAdornment: allowIcon && positionLeft && (
              <InputAdornment
                position="start"
                sx={{
                  cursor: "pointer",
                  color: theme?.palette?.main,
                  paddingX: "1px",
                  marginLeft: "0px",
                  marginRight: "0px",
                  "& svg": {
                    fontSize: "20px",
                  },
                }}
                onClick={togglePaswordIcon}
              >
                {allowIcon === true ? (
                  <img src={iconName} data-testid={`input-field-left-icon`} />
                ) : null}
              </InputAdornment>
            ),
            endAdornment: allowIcon && positionRight && (
              <InputAdornment
                position="end"
                sx={{
                  cursor: "pointer",
                  color: theme?.palette?.main,
                  marginLeft: "0px",
                  marginRight: "0px",
                  marginTop: "-50px",
                  "& svg": {
                    fontSize: "20px",
                  },
                }}
              >
                {allowIcon === true ? (
                  <img data-testid={`input-field-right-icon`} src={iconName} />
                ) : null}
              </InputAdornment>
            ),
          }}
          placeholder={Placeholder}
          onBlur={formik?.handleBlur}
          inputProps={{
            maxLength: isPhone ? 12 : middle ? 1 : props.maxLength,
            minLength: props.minLength,
            defaultValue: defaultValue,
            min: min,
            max: max,
            autoComplete: "new-password",
          }}
          InputLabelProps={{
            shrink: true,
            ...(label && { style: { display: "none" } }),
          }}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          {...props}
          {...formik?.getFieldProps(name)}
        />
      </FormControl>
      {fieldTouched && fieldError && (
        <Typography variant="caption" color="error" sx={{ marginLeft: "5px" }}>
          {fieldError}
        </Typography>
      )}
    </Grid>
  );
};

export default InputField;

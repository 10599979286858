import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isGroupsLoading: false,
    groupsOptions: [],
}

const name = "options"

export const optionSlice = createSlice({
    name,
    initialState,
    reducers: {
        getGroups: (state, action) => {
            state.isGroupsLoading = true
        },
        getGroupsSuccess: (state, action) => {
            state.isGroupsLoading = false
            const { results = [] } = action.payload;
            state.groupsOptions = results?.map(({ id, name }) => {
                return { value: id, label: name }
            })
        },
        getGroupsFail: (state, action) => {
            state.isGroupsLoading = false
            state.groupsOptions = []
        },
    },
})

export const { getGroups } = optionSlice.actions

export default optionSlice.reducer

export const getGroupsOptions = (query) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/groups/`,
    },
    type: "getGroups",
});
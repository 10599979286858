import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isFeedbackSending: false,
    feedbackSuccess: null
}

const name = "feedback"

export const feedbackSlice = createSlice({
    name,
    initialState,
    reducers: {
        sendFeedback: (state, action) => {
            state.isFeedbackSending = true
        },
        sendFeedbackSuccess: (state, action) => {
            state.isFeedbackSending = false
            state.feedbackSuccess = true 
        },
        sendFeedbackFail: (state, action) => {
            state.isFeedbackSending = false
            state.feedbackSuccess = false
        },
        clearSendFeedbackData:(state, action)=>{
            state.isFeedbackSending =false 
            state.feedbackSuccess = null 
        }
    },
})

export const { sendFeedback, clearSendFeedbackData } = feedbackSlice.actions

export default feedbackSlice.reducer

export const sendFeedbackToEmail = (data) => ({
    payload: {
        apiName: name,
        data,
        method: "post",
        url: `/api/message/`,
    },
    type: "sendFeedback",
});
export let labtrendsMainWrapper = {
  display: "flex",
  "& .MuiTabs-root.lab-trends-tabs": {
    width: "220px !important",
    maxHeight: "250px",
  },
  "& .MuiBox-root": {
    flexBasis: "100%",
  },
  "& .MuiTabs-scroller": {
    maxHeight: "250px",
    overflowX: "hidden",
    overflowY: "auto",
    width: "80%",
    "& .MuiTabs-flexContainer": {
      flexDirection: "column",
      width: "min-content",
      "& .MuiButtonBase-root.MuiTab-root": {
        borderBottom: "none !important",
      },
    },
  },
  "& .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-vertical.MuiTabs-scrollButtons":
    {
      width: "80px",
    },
};

export let trendsMainVitalsTabBoxStyle = {
  width: "22%",
  borderRight: 1,
  textAlign: "left",
  borderColor: "divider",
  margin: "0 !important",
  "& .MuiButtonBase-root.MuiTab-root": {
    flexBasis: "auto",
  },
};
export let trendsVitalsButtonsStyle = (name, theme) => {
  return {
    opacity: "1",
    fontSize: "13px",
    padding: "0 0 0 0px !important",
    minWidth: "33px !important",
    minHeight: "10px !important",
    fontWeight: `${400} !important`,
    background: "transparent",
  };
};

export let vitalTrendsUnitStyle = {
  color: "grey",
  padding: "15px",
  marginTop: "10px",
  writingMode: "vertical-rl",
  transform: "rotate(180deg)",
  display: "flex",
  justifyContent: "center",
};

export let trendsMainLabsTabBoxStyle = {
  marginBottom: "5px",
  marginTop: "5px",
  width: "100%",
  flexDirection: "column",
  borderRight: "1px solid #e1e1e1",
  "& .MuiButtonBase-root.MuiTab-root": {
    minWidth: "fit-content",
    padding: "10px 3px",
    flexBasis: "21%",
  },
};
export let trendsLabsButtonsStyle = (isActive, name, theme) => {
  return {
    fontSize: "13px",
    fontWeight: `${400} !important`,
    lineHeight: "18px",
    background: "transparent",
    color:
      isActive === name
        ? `${theme?.palette?.common?.blue} !important`
        : `${theme?.palette?.common?.black}`,
    borderBottom:
      isActive === name
        ? `2px solid ${theme?.palette?.common?.blue} !important`
        : "#B3B4BD",
  };
};

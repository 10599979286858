import { alignItemsFlexEnd, flexRow, flexRowCenterCenter } from "styles";

export let appBar = {
  ...flexRow,
  ...alignItemsFlexEnd,
  width: "100%",
  height: "75px",
  boxShadow: "none",
  background:
    " linear-gradient(116.41deg, #EFF4FB 16.09%, #F7F3FF 44.79%, #EFF4FB 59.94%, #F7F4FF 95.81%)",
};
export let mainNavbar = (theme) => {
  return {
    height: "68px",
    width: "100%",
    marginLeft: "5px",
    marginRight: "5px",
  };
};
export let logoAdminName = (theme) => {
  return {
    ...flexRowCenterCenter,
    fontSize: "32px",
    fontWeight: "900",
    marginLeft: "7px",
    color: theme.palette.common.black,
  };
};

export let mainNavbarInnerBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
};
export let navbarLocationsBox = {
  ...flexRowCenterCenter,
  width: "400px",
};

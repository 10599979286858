import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import ScreenTitleContext from "contexts/ScreenTitleContext";
import ThemeContext from "contexts/ThemeContext";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "routes/Router";
import { createCustomTheme } from "styles/theming/theme";

function App() {
  const theme = createCustomTheme();
  const [title, setTitle] = useState("");
  const [titleBody, setTitleBody] = useState("");
  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={theme}>
        <ScreenTitleContext.Provider
          value={{
            setTitle: setTitle,
            title: title,
            titleBody: titleBody,
            setTitleBody: setTitleBody,
          }}
        >
          <BrowserRouter>
            <CssBaseline />
            <Router theme={theme} />
          </BrowserRouter>
        </ScreenTitleContext.Provider>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
}

export default App;

import { createSlice } from "@reduxjs/toolkit";
import * as toast from "hooks/notify";
import Cookies from "js-cookie";

export const initialAuthStateValue = {
  userId: null,
  isLoading: false,
  firstTimeLogin: false,
  isLogoutLoading: false,
  isLoadingCSRF: false,
  account_id: null,
  csrfToken: "",
  username: "",
  email: "",
  firstName: "",
  lastName: "",
  photo: "",
  designation: "",
  phoneNumber: "",
  title: "",
  dob: "",
  medicalLicenseNumber: "",
  loggedIn: false,
  message: "",
  isAccLoading: false,
};

export const authName = "auth";

export const loginSlice = createSlice({
  name: "auth",
  initialState: initialAuthStateValue,
  reducers: {
    login: (state, action) => {
      state.isLoading = true;
      state.message = "";
    },
    getAccounts: (state, action) => {
      state.isAccLoading = true;
    },
    getAccountsSuccess: (state, action) => {
      state.isAccLoading = false;
      state.account_id = action.payload?.accounts?.[0];
      state.username = action.payload?.username;
      state.userId = action.payload?.id;
      state.email = action.payload?.email;
      state.firstName = action.payload?.first_name;
      state.lastName = action.payload?.last_name;
      state.photo = action.payload?.photo;
      state.designation = action.payload?.designation;
      state.phoneNumber = action.payload?.phone_number;
      state.title = action.payload?.title;
      state.dob = action.payload?.birthdate;
      state.medicalLicenseNumber = action.payload?.medical_license_number;
    },
    getAccountsFail: (state, action) => {
      state.isAccLoading = false;
    },
    getCSRF: (state, action) => {
      state.isLoadingCSRF = true;
      state.message = "";
    },
    getCSRFSuccess: (state, action) => {
      state.isLoadingCSRF = false;
      state.csrfToken = Cookies.get("csrftoken");
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.firstTimeLogin = action.payload?.first_time_login;
      state.loggedIn = true;
      state.message = action.payload?.message ?? "loggedin";
    },
    loginFail: (state, action) => {
      state.isLoading = false;
      state.isLoadingCSRF = false;
      state.loggedIn = false;
      state.message = "";
    },
    logout: (state) => {
      state.isLogoutLoading = true;
    },
    logoutSuccess: (state, action) => {
      localStorage.clear();
      state.isLogoutLoading = false;
      Cookies.remove("csrftoken");
      if (action?.payload?.actionType === "auth/loginFailed") {
        toast.error(action.payload?.message ?? "logged out");
      } else {
        toast.success(action.payload?.message ?? "logged out");
      }
      state.csrfToken = "";
      state.isLoadingCSRF = false;
      state.userId = null;
      state.username = "";
      state.loggedIn = false;
      state.account_id = null;
    },
    logoutFail: (state) => {
      state.isLogoutLoading = false;
    },
    clearLoginMessage: (state, action) => {
      Cookies.remove("csrftoken");
      state.csrfToken = "";
      state.message = "";
    },
  },
});

export const {
  loginSuccess,
  loginFail,
  logout,
  logoutSuccess,
  clearLoginMessage,
} = loginSlice.actions;

export const selectUser = (state) => state.auth;

export default loginSlice.reducer;

export const onLogin = (data, remainLogin = false) => ({
  payload: {
    apiName: authName,
    data,
    onSuccess: loginSuccess,
    method: "post",
    url: "/api/api-login",
    rememberMe: remainLogin,
  },
  type: "login",
});

export const getCSRF = (query) => ({
  payload: {
    apiName: authName,
    method: "get",
    url: `/api/csrf-token/`,
  },
  type: "getCSRF",
});

export const getAccountID = (query) => ({
  payload: {
    apiName: authName,
    method: "get",
    url: `/api/users/current/`,
  },
  type: "getAccounts",
});

export const logoutApi = (query) => ({
  payload: {
    apiName: authName,
    method: "get",
    url: `/api/api-logout/`,
  },
  type: "logout",
});

import { Box } from "@mui/material";
import { ReactTyped} from "react-typed";
import { useSelector } from "react-redux";
import { getLiveTrancript } from "assets/static";
import { LiveTranscriptWrapper } from "../AssesmentAndPlanStyles";

const LiveTranscript = ({ startAppointment }) => {
  const { dictation } = useSelector((state) => state.recording);
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );
  const isSpecificPatient = () => {
    return (
      getSpecificPatientData?.first_name +
      " " +
      getSpecificPatientData?.last_name ===
      "Sophia Patel" ||
      getSpecificPatientData?.first_name +
      " " +
      getSpecificPatientData?.last_name ===
      "Chloe Martin"
    );
  };

  return (
    <Box className="live_transcript_wrapper" sx={LiveTranscriptWrapper}>
      <Box >
        {isSpecificPatient() && startAppointment ? (
          <Box style={{padding:"10px !important", margin:"10px 10px 10px 10px",}}>
          <ReactTyped
          startWhenVisible
          typeSpeed={40}
          style={{fontSize:"13px",padding:'10px !important'}}
          strings={[getLiveTrancript(
               `${getSpecificPatientData?.first_name} ${getSpecificPatientData?.last_name}`)]}/>
          </Box>
        ) : (
          dictation
        )}
      </Box>
    </Box>
  );
};

export default LiveTranscript;
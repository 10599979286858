import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import playIcon from "assets/svg/light/Recording-indicator.svg";
import pauseIcon from "assets/svg/light/speech-pause.svg";
import stopIcon from "assets/images/rotate.png";
function removeHtmlTags(inputString) {
  return inputString.replace(/<\/?[^>]+>/gi, "");
}
const SpeechToText = forwardRef(({ speech = "" }, ref) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [utterance, setUtterance] = useState(null);
  const [voicesLoaded, setVoicesLoaded] = useState(false);

  useEffect(() => {
    const loadVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      if (voices.length > 0) {
        setVoicesLoaded(true);
      }
    };

    loadVoices();

    window.speechSynthesis.addEventListener("voiceschanged", loadVoices);

    return () => {
      window.speechSynthesis.removeEventListener("voiceschanged", loadVoices);
    };
  }, []);

  useEffect(() => {
    if (voicesLoaded) {
      handlePlay();
      const stopTimer = setTimeout(() => {
        handleStop();
      }, 500);

      return () => clearTimeout(stopTimer);
    }
  }, [voicesLoaded]);

  const handlePlay = () => {
    if (!voicesLoaded) return;

    if (isPaused && utterance) {
      window.speechSynthesis.resume();
      setIsPlaying(true);
      setIsPaused(false);
    } else {
      if (utterance) {
        window.speechSynthesis.cancel();
      }

      const speechSynthesisUtterance = new SpeechSynthesisUtterance(
        removeHtmlTags(speech)
      );
      speechSynthesisUtterance.onend = () => {
        setIsPlaying(false);
        setUtterance(null);
      };

      setUtterance(speechSynthesisUtterance);

      setTimeout(() => {
        window.speechSynthesis.speak(speechSynthesisUtterance);
        setIsPlaying(true);
        setIsPaused(false);
      }, 10);
    }
  };

  const handlePause = () => {
    if (utterance) {
      window.speechSynthesis.pause();
      setIsPlaying(false);
      setIsPaused(true);
    }
  };

  const handleStop = () => {
    if (utterance) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
      setIsPaused(false);
      setUtterance(null);
    }
  };

  useImperativeHandle(ref, () => ({
    stop: handleStop,
  }));

  return (
    <div className="speech-wrapper">
      {!isPlaying && !isPaused ? (
        <button onClick={handlePlay} className="play-button">
          <img src={playIcon} alt="Play" />
        </button>
      ) : isPlaying ? (
        <button onClick={handlePause} className="pause-button">
          <img src={pauseIcon} alt="Pause" />
        </button>
      ) : (
        <button onClick={handlePlay} className="play-button">
          <img src={playIcon} alt="Play" />
        </button>
      )}
      <button onClick={handleStop} className="stop-button">
        <img src={stopIcon} alt="Stop" />
      </button>
    </div>
  );
});

export default SpeechToText;

import { Box, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import dashboardCard from "assets/images/dashboardCard.png";
import dashboardList from "assets/images/dashboardList.png";
import separateBoxes from "assets/images/separateBoxes.png";
import soapCombined from "assets/images/soapCombined.png";
import soapDivided from "assets/images/soapDivided.png";
import vitalsDefault from "assets/images/vitalsDefault.png";
import Text from "components/common/Typography/Text";

const Personalization = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginTop={2} marginBottom={4}>
        <Text id={"personalization-heading"} variant="h1">
          Personalization
        </Text>
      </Box>
      <Text marginY={2} color={"blueDark"} variant="h2">
        Customize your patient dashboard view
      </Text>
      <Box marginTop={2} marginBottom={2}>
        <Text marginY={1} variant="h1">
          Patient Dashboard:
        </Text>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} lg={6}>
            <Card
              data-testid={"card1-id"}
              sx={{
                background: "#f8f7fe",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CardContent>
                <Text
                  marginY={1}
                  center
                  color={"blue"}
                  variant="h3"
                  fontWeight="600"
                >
                  Card View
                </Text>
                <img src={dashboardCard} alt="dashboardCard" width="100%" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Card
              data-testid={"card2-id"}
              sx={{
                width: "100%",
                background: "#f8f7fe",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CardContent>
                <Text
                  center
                  marginY={1}
                  color={"blue"}
                  variant="h3"
                  fontWeight="600"
                >
                  List View
                </Text>
                <img src={dashboardList} alt="dashboardList" width="100%" />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2} marginBottom={2}>
        <Text marginY={1} variant="h1">
          Soap Notes:
        </Text>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} lg={6}>
            <Card
              data-testid={"card3-id"}
              sx={{
                background: "#f8f7fe",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CardContent>
                <Text
                  marginY={1}
                  center
                  color={"blue"}
                  variant="h3"
                  fontWeight="600"
                >
                  Divided into 4 boxes
                </Text>
                <img src={soapDivided} alt="soapDivided" width="100%" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Card
              data-testid={"card4-id"}
              sx={{
                background: "#f8f7fe",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CardContent>
                <Text
                  marginY={1}
                  center
                  color={"blue"}
                  variant="h3"
                  fontWeight="600"
                >
                  In a combined box
                </Text>
                <img src={soapCombined} alt="soapCombined" width="100%" />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2} marginBottom={2}>
        <Text marginY={1} variant="h1">
          Vitals, Lab Results & Medications:
        </Text>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} lg={6}>
            <Card
              data-testid={"card5-id"}
              sx={{
                background: "#f8f7fe",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CardContent>
                <Text
                  marginY={1}
                  center
                  color={"blue"}
                  variant="h3"
                  fontWeight="600"
                >
                  In a separate boxes
                </Text>
                <img src={separateBoxes} alt="separateBoxes" width="100%" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Card
              data-testid={"card6-id"}
              sx={{
                background: "#f8f7fe",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CardContent>
                <Text
                  marginY={1}
                  center
                  color={"blue"}
                  variant="h3"
                  fontWeight="600"
                >
                  In a combined versatile box
                </Text>
                <img src={vitalsDefault} alt="vitalsDefault" width="100%" />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Personalization;

import {
    alignItemsFlexStart,
    flexRow,
    flexWrap,
    justifyContentFlexEnd,
    justifyContentSpaceBetween,
} from "styles"

export let mainPrivacySecurityBox = { padding: "5px 15px" }
export let upperPasswordAuthenticationBox = { ...flexRow, ...justifyContentSpaceBetween, ...flexWrap }

export let changePasswordBoxStyling = {
    width: {
        xl: "50%",
        lg: "50%",
        md: "100%",
        sm: "100%"
    },
    padding: "9px",

    borderRight: {

        xl: "1px solid #B3B4BD",
        lg: "1px solid #B3B4BD",
        md: "none",
        sm: "none"
    }
}

export let changePasswordButtonStyling = (theme) => {
    return {
        fontSize: "14px",
        height: "29px",
        width: "179px",
        backgroundColor: "transparent",
        color: theme?.palette?.common?.black,
        border: "1px solid black"
    }
}
export let changePasswordButtonBoxStyling = (theme) => {
    return {
        ...flexRow,
        ...justifyContentFlexEnd,
        width: {
            xl: "100%",
            lg: "100%",
            md: "100%",
            sm: "100%",
        },
        marginTop: "10px"
    }
}

export let twoFactorAuthBoxStyling = {
    width: {
        xl: "45%",
        lg: "45%",
        md: "100%",
        sm: "100%"
    },
    padding: "2px",
    opacity: 0.6,
}
export let twoFactorInnerBox = {
    ...flexRow,
    ...alignItemsFlexStart,
    padding: "0px 10px 0px 10px",
}
export let twoFactorParagraph = {
    width: "60%"
}
export let twoFactorTogglerBox = {
    marginTop: "5px"
}
export let parentContainerBox = {
  background: "rgba(255, 255, 255, 0.50)",
  borderRadius: "24px",
  padding: "24px",
  width: "100%",
  minHeight: "90vh",
  height: "auto",
};

export let menuImgStyle = {
  width: "27px",
  height: "27px",
};
export let iconButtonStyle = {
  padding: "5px",
  boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.05)",
};
export let cardImageStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export let financialsCardStyle = {
  boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.05)",
  padding: "10px 12px",
  borderRadius: "15px",
};
// Saya Component Stylihg
export let headerStyle = {
  display: "flex",
  alignItems: "center",
};
export let startImageStyle = {
  width: "20px",
  height: "20px",
  marginRight: "3px",
};
export let closeButtonStyle = {
  width: "24px",
  height: "24px",
};

export let chatSearchBarStyling = {
  marginTop: "10px",
};
export let sendBtnStyle = {
  width: " 40px",
  height: "40px",
};

// Charts Styling
export const CkdPieChartData = {
  chart: {
    type: "donut",
    width: "100%",
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 1,
    colors: ["#4FD1C5", "#F39320"],
  },
  legend: {
    show: false, // Hide default legend
  },
  plotOptions: {
    pie: {
      customScale: 1,
    },
  },
  tooltip: {
    theme: "light",
    custom: function ({ patientSeries, seriesIndex, dataPointIndex, w }) {
      const currentDate = new Date().toLocaleDateString("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      });
      const value = w.globals.series[seriesIndex][dataPointIndex];
      return `<div style="padding: 8px; border-radius:10px",background: white; color: black;"><p style="font-size: 16px; font-weight: 600;">${currentDate}</p></div>`;
    },
  },
};

// barchart Series
// export const barChartSeriesData = [
//   {
//     name: "Net Profit",
//     data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
//   },
//   {
//     name: "Revenue",
//     data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
//   },
// ];

// export const barChartOptionsData = {
//   chart: {
//     type: "bar",
//     height: 300,
//     toolbar: {
//       show: false,
//     },
//   },
//   plotOptions: {
//     bar: {
//       horizontal: false,
//       columnWidth: "70%",
//       borderRadius: [5],
//     },
//   },
//   dataLabels: {
//     enabled: false,
//   },
//   stroke: {
//     show: true,
//     width: 2,
//     colors: ["transparent"],
//   },

//   legend: {
//     show: false,
//   },

//   xaxis: {
//     categories: [
//       "jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//     ],
//   },
//   yaxis: {
//     min: 0,
//     max: 100,
//     tickAmount: 4,
//     labels: {
//       formatter: function (val) {
//         if (
//           val === 0 ||
//           val === 100 ||
//           val === 25 ||
//           val === 50 ||
//           val === 75
//         ) {
//           return val + "k"; // Append 'k' to the values 0 and 100
//         } else {
//           return val;
//         }
//       },
//     },
//   },
//   fill: {
//     opacity: 1,
//     colors: ["#F39320", "#4FD1C5"],
//   },
//   tooltip: {
//     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
//       const currentDate = new Date().toLocaleDateString("en-US", {
//         month: "long",
//         day: "2-digit",
//         year: "numeric",
//       });
//       const value = w.globals.series[seriesIndex][dataPointIndex];
//       return `<div style="padding: 18px; border-radius:0px",background: red !important; color: black;"><p style="font-size: 16px; font-weight: 600;">${currentDate}</p></div>`;
//     },
//   },
// };

//
export const ContainerStying = {
  width: "100%",
  background: "rgba(255, 255, 255, 0.50)",
  padding: "24px",
  borderRadius: "24px",
};

export const cardContainerStyling = {
  width: "100%",
  height: "100%",
  borderRadius: "24px",
  backdropFilter: "blur(5.25px)",
  background: "#fff",
};
export const cardContainerBodyStyling = {
  background: "#fff",
  borderRadius: "24px",
  backdropFilter: "blur(5.25px)",
  padding: "24px",
};
export const RevenueHeaderStyle = {
  width: "100%",
  borderBottom: "1px solid #ccc",
  padding: "0px",
};
export const trendBoxStyle = {
  width: "100%",
  border: "1px solid #ccc",
};

// barchart Series

export const barChartSeriesData = [
  {
    name: "Expected Visits",
    data: [
      2114, 1825, 2449, 2102, 2513, 2220, 2101, 2471, 2226, 2063, 2335, 2165,
    ],
  },
  {
    name: "Actual Visits",
    data: [
      1747, 1508, 2024, 1737, 2077, 1835, 1736, 2042, 1840, 1705, 1930, 1789,
    ],
  },
];

const expectedVisitMinValue = Math.min(
  ...barChartSeriesData.map((series) => Math.min(...series.data))
);
const expectedVisitMaxValue = Math.max(
  ...barChartSeriesData.map((series) => Math.max(...series.data))
);

const expectedVisitPadding = 100;

const expectedVisitYAxisConfig = {
  min: Math.floor((expectedVisitMinValue - expectedVisitPadding) / 100) * 100,
  max: Math.ceil((expectedVisitMaxValue + expectedVisitPadding) / 100) * 100,
  tickAmount: 5,
  labels: {
    formatter: (val) => val.toFixed(0),
  },
};

export const barChartOptionsData = {
  chart: {
    type: "bar",
    height: 300,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "25%",
      borderRadius: [5],
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  legend: {
    show: true,
    markers: {
      fillColors: ["#F39320", "#4FD1C5"],
    },
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: expectedVisitYAxisConfig,
  fill: {
    opacity: 1,
    colors: ["#F39320", "#4FD1C5"],
  },
};

export const visitSeriesData = [
  {
    name: "",
    data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 90],
  },
  {
    name: "",
    data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 67],
  },
];

export const earlyStageCkdDataset = [
  {
    name: "Current Year",
    data: [617, 532, 715, 613, 733, 647, 613, 721, 649, 602, 681, 631],
  },
  {
    name: "Previous Year",
    data: [574, 495, 665, 570, 682, 602, 570, 670, 604, 560, 633, 587],
  },
];
const earlyCKDData = [
  617, 532, 715, 613, 733, 647, 613, 721, 649, 602, 681, 631, 574, 495, 665,
  570, 682, 602, 570, 670, 604, 560, 633, 587,
];

const earlyCKDMinValue = Math.min(...earlyCKDData);
const earlyCKDMaxValue = Math.max(...earlyCKDData);
const earlyCKDPadding = 50;

const yaxisearlyCKDConfig = {
  min: Math.floor((earlyCKDMinValue - earlyCKDPadding) / 10) * 10,
  max: Math.ceil((earlyCKDMaxValue + earlyCKDPadding) / 10) * 10,
  tickAmount: 6,
  labels: {
    formatter: (val) => val.toFixed(0),
  },
};

export const visitOption = {
  chart: {
    type: "bar",
    height: 300,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "35%",
      borderRadius: [5],
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 1,
    colors: ["transparent"],
  },
  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    offsetY: 12,
    offsetX: -10,
    itemMargin: {
      horizontal: 5,
      vertical: 7,
    },
    markers: {
      fillColors: ["#F39320", "#4FD1C5"],
    },
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  labels: ["Previous Year"],
  yaxis: yaxisearlyCKDConfig,
  fill: {
    opacity: 1,
    colors: ["#F39320", "#4FD1C5"],
  },
  tooltip: {
    y: {
      formatter: function (value) {
        return value;
      },
    },
  },
};
export const ESRDDataset = [
  {
    name: "Current Year",
    data: [183, 159, 210, 182, 216, 191, 182, 212, 192, 179, 201, 187],
  },
  {
    name: "Previous Year",
    data: [163, 140, 188, 162, 193, 171, 161, 190, 171, 159, 179, 166],
  },
];
const esrdData = [183, 159, 210, 182, 216, 191, 182, 212, 192, 179, 201, 187];
const esrdMinValue = Math.min(...esrdData);
const esrdMaxValue = Math.max(...esrdData);
const esrdPadding = 20;

const yaxisEsrdConfig = {
  min: Math.floor((esrdMinValue - esrdPadding) / 10) * 10,
  max: Math.ceil((esrdMaxValue + esrdPadding) / 10) * 10,
  tickAmount: 5,
  labels: {
    formatter: (val) => val.toFixed(0),
  },
};
export const ESRDOption = {
  chart: {
    type: "bar",
    height: 300,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "35%",
      borderRadius: [5],
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  labels: ["ESRD Patient"],
  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    offsetY: 12,
    offsetX: -10,
    itemMargin: {
      horizontal: 5,
      vertical: 7,
    },
    markers: {
      fillColors: ["#F39320", "#4FD1C5"],
    },
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: yaxisEsrdConfig,
  fill: {
    opacity: 1,
    colors: ["#F39320", "#4FD1C5"],
  },
  tooltip: {
    y: {
      formatter: function (value) {
        return value;
      },
    },
  },
};
export const lateStagePatientDataset = [
  {
    name: "Current Year",
    data: [206, 177, 238, 204, 244, 216, 204, 240, 216, 201, 227, 210],
  },
  {
    name: "Previous Year",
    data: [191, 165, 222, 190, 227, 201, 190, 223, 201, 187, 211, 196],
  },
];
const lateStagePatienData = [
  206, 177, 238, 204, 244, 216, 204, 240, 216, 201, 227, 210,
];

const lateStagePatientMinValue = Math.min(...lateStagePatienData);
const lateStagePatientMaxValue = Math.max(...lateStagePatienData);
const lateStagePatientPadding = 20;

const yaxisstage4CKDConfig = {
  min:
    Math.floor((lateStagePatientMinValue - lateStagePatientPadding) / 10) * 10,
  max:
    Math.ceil((lateStagePatientMaxValue + lateStagePatientPadding) / 10) * 10,
  tickAmount: 5,
  labels: {
    formatter: (val) => val.toFixed(0),
  },
};
export const lateStagePatientOption = {
  chart: {
    type: "bar",
    height: 300,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "35%",
      borderRadius: [5],
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  labels: ["Current Year", "Previous Year"],
  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    offsetY: 12,
    offsetX: -10,
    itemMargin: {
      horizontal: 5,
      vertical: 7,
    },
    markers: {
      fillColors: ["#F39320", "#4FD1C5"],
    },
  },
  Colors: ["#F39320", "#4FD1C5"],
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: yaxisstage4CKDConfig,
  fill: {
    opacity: 1,
    colors: ["#F39320", "#4FD1C5"],
  },
  tooltip: {
    y: {
      formatter: function (value) {
        return value;
      },
    },
  },
};

export const activePatientDataset = [
  {
    name: "Current Year",
    data: [
      1028, 887, 1191, 1022, 1222, 1079, 1021, 1201, 1082, 1003, 1135, 1052,
    ],
  },
  {
    name: "Previous Year",
    data: [956, 825, 1108, 950, 1136, 1003, 950, 1117, 1006, 933, 1056, 978],
  },
];

const activePatientdata2 = [
  1028, 887, 1191, 1022, 1222, 1079, 1021, 1201, 1082, 1003, 1135, 1052,
];

const activePatientMinValue = Math.min(...activePatientdata2);
const activePatientMaxValue = Math.max(...activePatientdata2);
const activePatientPadding = 80;

const yaxisactivePatientdata2Config2 = {
  min: Math.floor((activePatientMinValue - activePatientPadding) / 10) * 10,
  max: Math.ceil((activePatientMaxValue + activePatientPadding) / 10) * 10,
  tickAmount: 6,
  labels: {
    formatter: (val) => val.toFixed(0),
  },
};

export const activePatientdataOption = {
  chart: {
    type: "bar",
    height: 300,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "35%",
      borderRadius: [5],
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  labels: ["Current Year", "Previous Year"],
  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    offsetY: 12,
    offsetX: -10,
    itemMargin: {
      horizontal: 5,
      vertical: 7,
    },
    markers: {
      fillColors: ["#F39320", "#4FD1C5"],
    },
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: yaxisactivePatientdata2Config2,
  fill: {
    opacity: 1,
    colors: ["#F39320", "#4FD1C5"],
  },
  tooltip: {
    y: {
      formatter: function (value) {
        return value;
      },
    },
  },
};

const data = [
  1028, 887, 1191, 1022, 1222, 1079, 1021, 1201, 1082, 1003, 1135, 1052,
];

const minValue = Math.min(...data);
const maxValue = Math.max(...data);
const padding = 50;

const yaxisConfig = {
  min: Math.floor((minValue - padding) / 10) * 10,
  max: Math.ceil((maxValue + padding) / 10) * 10,
  tickAmount: 5,
  labels: {
    formatter: (val) => val.toFixed(0),
  },
};

export const NumberOfPatientLineChartOptions = {
  chart: {
    height: 350,
    type: "line",
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  },
  colors: ["#3475DC"],
  dataLabels: {
    enabled: true,
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 1,
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: yaxisConfig,
  legend: {
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    offsetY: 12,
    offsetX: -10,
    itemMargin: {
      horizontal: 5,
      vertical: 7,
    },
  },
};
//
export const TotalNumOfPatientSeriesData = [
  {
    name: "Avg Patients per month per provider",
    data: [206, 177, 238, 204, 244, 216, 204, 240, 216, 201, 227, 210],
  },
];

const NumberOfPatientsProvidedData = [
  206, 177, 238, 204, 244, 216, 204, 240, 216, 201, 227, 210,
];

const minValueNumberOfPatientProvided = Math.min(
  ...NumberOfPatientsProvidedData
);
const maxValueNumberOfPatientProvided = Math.max(
  ...NumberOfPatientsProvidedData
);
const paddingNumberOfPatientProvided = 20;

const yaxisConfigNumberOfPatientProvided = {
  min:
    Math.floor(
      (minValueNumberOfPatientProvided - paddingNumberOfPatientProvided) / 10
    ) * 10,
  max:
    Math.ceil(
      (maxValueNumberOfPatientProvided + paddingNumberOfPatientProvided) / 10
    ) * 10,
  tickAmount: 5,
  labels: {
    formatter: (val) => val.toFixed(0),
  },
};
export const TotalNumOfPatientDataOptions = {
  chart: {
    height: 350,
    type: "line",
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  },
  colors: ["#3475DC"],
  dataLabels: {
    enabled: true,
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 1,
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: yaxisConfigNumberOfPatientProvided,
  legend: {
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    offsetY: 12,
    offsetX: -10,
    itemMargin: {
      horizontal: 5,
      vertical: 7,
    },
  },
};
//
export const newPatientSeriesData = [
  {
    name: "New Patients Per Month",
    data: [86, 97, 104, 58, 67, 84, 38, 71, 47, 77, 34, 94],
  },
];
const newPatientPerMonthData = [
  86, 97, 104, 58, 67, 84, 38, 71, 47, 77, 34, 94,
];

const newPatientPerMonthMinValue = Math.min(...newPatientPerMonthData);
const newPatientPerMonthMaxValue = Math.max(...newPatientPerMonthData);
const newPatientPerMonthPadding = 10;

const newPatientPerMonthYAxisConfig = {
  min:
    Math.floor((newPatientPerMonthMinValue - newPatientPerMonthPadding) / 10) *
    10,
  max:
    Math.ceil((newPatientPerMonthMaxValue + newPatientPerMonthPadding) / 10) *
    10,
  tickAmount: 5,
  labels: {
    formatter: (val) => val.toFixed(0),
  },
};
export const newPatientDataOption = {
  chart: {
    height: 350,
    type: "line",
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  },
  colors: ["#3475DC"],
  dataLabels: {
    enabled: true,
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 1,
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: newPatientPerMonthYAxisConfig,
  legend: {
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    offsetY: 12,
    offsetX: -10,
    itemMargin: {
      horizontal: 5,
      vertical: 7,
    },
  },
};

export const patientBillSeriesData = [
  {
    name: "Patients Who Paid Bills Per Month",
    data: [437, 377, 506, 434, 519, 459, 434, 511, 460, 426, 483, 447],
  },
];
const patientBillPerMonthData = [
  437, 377, 506, 434, 519, 459, 434, 511, 460, 426, 483, 447,
];

const patientBillPerMonthMinValue = Math.min(...patientBillPerMonthData);
const patientBillPerMonthMaxValue = Math.max(...patientBillPerMonthData);
const patientBillPerMonthPadding = 10;

const patientBillPerMonthYAxisConfig = {
  min:
    Math.floor(
      (patientBillPerMonthMinValue - patientBillPerMonthPadding) / 10
    ) * 10,
  max:
    Math.ceil((patientBillPerMonthMaxValue + patientBillPerMonthPadding) / 10) *
    10,
  tickAmount: 5,
  labels: {
    formatter: (val) => val.toFixed(0),
  },
};
export const patientBillOptons = {
  chart: {
    height: 350,
    type: "line",
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  },
  colors: ["#3475DC"],
  dataLabels: {
    enabled: true,
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 1,
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  yaxis: patientBillPerMonthYAxisConfig,
  legend: {
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    offsetY: 12,
    offsetX: -10,
    itemMargin: {
      horizontal: 5,
      vertical: 7,
    },
  },
};

export const NumberOfPatientSeriesData = [
  {
    name: "Number of patients Per Month",
    data: [
      1028, 887, 1191, 1022, 1222, 1079, 1021, 1201, 1082, 1003, 1135, 1052,
    ],
  },
];

import { Box, Grid } from "@mui/material";
import {
  followUpOptions,
  initialStaticCptForAllEncounter,
} from "assets/static";
import Button from "components/common/Button/Button";
import SelectField from "components/common/FormComponents/SelectField";
import CustomModal from "components/common/Modal/CustomModal";
import PDFModal from "components/common/Modal/pdfModal";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";
import Text from "components/common/Typography/Text";
import * as toast from "hooks/notify";
import moment from "moment";
import ChatBotComponent from "pages/financials/components/ChatBotComponent/ChatBotComponent";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  clearAllHistoryData,
  getAllHistoryList,
} from "redux/features/AllHistories/AllHistorySlice";
import {
  clearAllergiesData,
  getAllergiesByPatientId,
} from "redux/features/Allergies/AllergiesSlice";
import {
  clearAppointmentData,
  getSpecificAppointmentDetails,
  updateAppointment,
} from "redux/features/Appointments/AppointmentsSlice";
import { getCptCodeList } from "redux/features/CptCodes/CptCodeSlice";
import {
  clearEncounterByAppointmentData,
  clearEncounterByPatientData,
  clearEncounterData,
  createOrUpdateEncounter,
  getEncounterByAppointment,
  getEncounterByPatient,
} from "redux/features/Encounter/EncounterSlice";
import { getFamilyHistoryList } from "redux/features/FamilyHistory/FamilyHistorySlice";
import {
  clearHealthSummaryData,
  getHealthSummaryList,
} from "redux/features/HealthSummary/HealthSummarySlice";
import { getIcdCodeList } from "redux/features/IcdCodes/IcdCodesSlice";
import {
  getLabsByEncounter,
  getLabsList,
  updateLabsByEncounter,
} from "redux/features/Labs/labsSlice";
import { getMedicationsList } from "redux/features/Medications/MedicationsSlice";
import {
  clearMedicinesData,
  clearMedicinesStates,
  getMedicinesByEncounter,
  getMedicinesList,
  updateMedicineByEncounter,
} from "redux/features/Medications/MedicinesSlice";
import { getOrderMedicationsList } from "redux/features/Medications/OrderMedicationsSlice";
import {
  clearLabsResultsMessage,
  getLabsResults,
} from "redux/features/Patients/ViewLabResultsSlice";
import {
  clearSpecificPatientData,
  getSpecificPatientDetails,
} from "redux/features/Patients/getSpecificPatientsSlice";
import { getPharmacyList } from "redux/features/Pharmacy/PharmacySlice";
import { clearVitalsData } from "redux/features/Vitals/VitalsSlice";
import { healthsummaryMain } from "styles/SCD/healthSummaryStyle";
import {
  planBoxDescriptionStyling,
  planBoxStylingWrapper,
  planBoxTitleStyling,
} from "styles/SCD/planStyles";
import { debounce } from "utils/debouncer";
import SmartSummaryIcon from "../../../assets/svg/light/ai_sparkle.svg";
import dummyPic from "../../../assets/svg/light/dummyPic.svg";
import rightArrow from "../../../assets/svg/light/rightArrow.svg";
import Assessment from "./components/AssesmentAndPlan/Assessment";
import Plan from "./components/AssesmentAndPlan/Plan";
import AssesmentPlanNotes from "./components/AssesmentAndPlan/components/AssesmentPlanNotes";
import EndVisit from "./components/EndVisit";
import HealthSummary from "./components/HealthSummary";
import Medications from "./components/Medications/Medications";
import PastVisitModal from "./components/PastVisit/PastVisitModal";
import SCDHeader from "./components/SCDHeader";
import SmartChecklist from "./components/SmartChecklist";
import VitalLabMedWrapper from "./components/VitalLabMedWrapper";
import LiveTranscript from "./components/AssesmentAndPlan/components/LiveTranscript";

const PatientDetail = () => {
  const viewWidth = window.innerWidth;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [helperMedication, setHelperMedication] = useState([]);
  const [smart_assesment_plan, set_smart_assesment_plan] = useState([]);
  const playRef = useRef();
  const { patientId, appointmentId } = useParams();

  let { cptCodes, cptCodesOptions, isCptCodeLoading } = useSelector(
    (state) => state.cptCode
  );
  const { isChatOpen } = useSelector((state) => state.globalStates);
  const [showCptOptions, setShowCptOptions] = useState(true);
  const [startAppointment, setStartAppointment] = useState(false);

  const [encounterCeased, setEncounterCeased] = useState(false);

  const { account_id } = useSelector((state) => state.auth);
  const { healthSummaryData } = useSelector((state) => state.healthSummary);
  const { allLabsData, updateLabsLoading, updateLabsSuccess } = useSelector(
    (state) => state.labs
  );
  const { updateMedicineSuccess } = useSelector((state) => state.medicines);

  // Get patient data for header
  const { getSpecificPatientData, getSpecificPatientSuccess } = useSelector(
    (state) => state.specificPatient
  );

  // Get appointment data for future encounter request
  const { appointmentData, isAppointmentUpdated } = useSelector(
    (state) => state.specificAppointment
  );

  let { icdCodes } = useSelector((state) => state.icdCode);
  const scdRef = useRef();
  // encounter request response
  const {
    encounterSuccess,
    encounterMessage,
    isEncounterLoading,
    encounterByPatientData,
    encounterByPatientSuccess,
    encounterByAppointmentData,
    isEncounterByAppointmentLoading,
  } = useSelector((state) => state.encounter);
  //get query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  //State for end visit modal
  const [soapData, setSoapData] = useState([]);
  const [open, setOpen] = useState(queryParams.get("review_later") ?? false);
  const [pastVisit, setPastVisit] = useState(false);
  const [planLabsData, setPlanLabsData] = useState([]);
  const [autoSaveStatus, setAutoSaveStatus] = useState("");
  const [planMedicineData, setPlanMedicineData] = useState([]);
  const [validationsObj, setValidationObj] = useState({
    assesmentNotes: null,
  });

  const [pdfModal, setPdfModal] = useState(false);
  const [showEmptyMessages, setShowEmptyMessages] = useState(false);

  const [followUp, setFollowUp] = useState({ date: "", selection: "" });
  const [followUpData, setFollowUpData] = useState({ date: "", selection: "" });

  // State to fetch healthSummary only when Icd/Cpt is changed by user.
  const [fetchHealthSummaryTrigger, setFetchHealthSummaryTrigger] =
    useState(false);

  // States to not run debouncer when default data comer from the Api.
  const [enableAutoSaveIcds, setEnableAutoSaveIcds] = useState(false);

  // States to not run debouncer when default data comer from the Api.
  const [enableAutoSaveCpts, setEnableAutoSaveCpts] = useState(false);
  const [enableAutoSaveNotes, setEnableAutoSaveNotes] = useState(false);
  const [enableAutoSaveFollowUp, setEnableAutoSaveFollowUp] = useState(false);

  // State for assesment comAbacavir Sulfate Oral Solution 20 MG/MLponents
  const [assesmentObj, setAssesmentObj] = useState({
    selectedIcdCodes: [],
    selectedCptCodes: [],
    notes: "",
  });

  // Clear all encounter data
  function clearAllEncounterData() {
    // Clear encounter data from global state
    dispatch(clearEncounterData());

    // Clear encounter data by patient
    dispatch(clearEncounterByPatientData());

    // Clear encounter data by appointment
    dispatch(clearEncounterByAppointmentData());

    // Clear encounter data by appointment
    dispatch(clearAllergiesData());
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Navigate back to appointment page on review later
  useEffect(() => {
    isAppointmentUpdated && navigate("/schedule");
  }, [isAppointmentUpdated]);

  // setting icds from previous encounter of patient
  useEffect(() => {
    if (encounterByPatientData?.length > 0) {
      // Api response order changes on updation. filtering data according to the appointment
      let filteredEncounterByAppointment = encounterByPatientData?.filter(
        (s) => s.id === Number(appointmentId)
      );

      if (filteredEncounterByAppointment.length > 0) {
        setAssesmentObj((prevState) => ({
          ...prevState,
          selectedIcdCodes: filteredEncounterByAppointment[0]?.icds,
          selectedCptCodes:
            filteredEncounterByAppointment[0]?.cpts?.length > 0
              ? filteredEncounterByAppointment[0]?.cpts
              : [initialStaticCptForAllEncounter],
          notes: filteredEncounterByAppointment[0]?.assessment_notes,
        }));
        setShowEmptyMessages(true);

        if (filteredEncounterByAppointment[0]?.followup_date) {
          setFollowUp((prevState) => ({
            date: filteredEncounterByAppointment[0]?.followup_date,
            selection: filteredEncounterByAppointment[0]?.followup_selection,
          }));
        } else {
          const ckd_status_val = {
            "CKD-1": "6 month",
            "CKD-2": "6 month",
            "CKD-3": "3 month",
            "CKD-4": "2 month",
            "CKD-5": "1 month",
          };
          const validCKDS = ["CKD-1", "CKD-2", "CKD-3", "CKD-4", "CKD-5"];
          let status = healthSummaryData?.[0]?.ckd_status;
          if (validCKDS.includes(status)) {
            findAndSetFollowDate("", { value: ckd_status_val[status] });
            // setFollowUp((prevState) => ({ date: filteredEncounterByAppointment[0]?.followup_date, selection: ckd_status_val }))
          } else {
            findAndSetFollowDate("", { value: "1 week" });
          }
        }
      } else {
        // if there is no encounter created before than icds of previous encounter

        setAssesmentObj((prevState) => ({
          ...prevState,
          selectedIcdCodes: encounterByPatientData[0]?.icds,
        }));
      }
    }
  }, [encounterByPatientData?.length]);

  // Toast pop up on encounter response
  useEffect(() => {
    if (encounterSuccess && encounterSuccess) {
      updateTheExistingData();
      dispatch(getMedicationsList(appointmentId));
    }
    // encounterSuccess === false && toast.error(encounterMessage);

    setAutoSaveStatus("Saved");
    setTimeout(() => setAutoSaveStatus(""), 300);
  }, [encounterMessage]);

  // Medicines in plan will be updated when the encounter part is done
  useEffect(() => {
    if (encounterSuccess) {
      if (fetchHealthSummaryTrigger) {
        // Clearing out health summary data for refetching.
        dispatch(clearHealthSummaryData());
        dispatch(getHealthSummaryList(patientId, appointmentId));
      }
      setFetchHealthSummaryTrigger(false);
    } else {
      setAutoSaveStatus("");
    }
  }, [encounterMessage]);

  useEffect(() => {
    if (updateMedicineSuccess) {
      setAutoSaveStatus("Saved");
      setTimeout(() => setAutoSaveStatus(""), 300);
      // medicine for enocunter
      dispatch(getOrderMedicationsList(appointmentId));
      dispatch(getMedicationsList(appointmentId));
      dispatch(getMedicinesByEncounter(appointmentId));
    }
    return () => dispatch(clearMedicinesStates());
  }, [updateMedicineSuccess]);

  useEffect(() => {
    // Api hit for labs data
    dispatch(getLabsResults(patientId));

    // Api hit for encounter for specific patient
    dispatch(getEncounterByPatient(patientId));

    // Api hit for familyhistory for specific patient
    dispatch(getFamilyHistoryList(patientId));

    // Api hit for specific patient
    dispatch(getSpecificPatientDetails(patientId));

    // Api hit for allergies by patient id
    dispatch(getAllergiesByPatientId(patientId));

    // Api hit for all histories by patient id
    dispatch(getAllHistoryList(patientId));
  }, [patientId]);

  // Api hit for specific appointment
  useEffect(() => {
    // get encounter on the base of appointment
    dispatch(getEncounterByAppointment(appointmentId));

    // sepecific appointment details
    dispatch(getSpecificAppointmentDetails(appointmentId));

    // medicine for enocunter
    dispatch(getMedicinesByEncounter(appointmentId));

    // Per lab by encounter
    dispatch(getLabsByEncounter(appointmentId));
  }, [appointmentId]);

  useEffect(() => {
    if (isEncounterLoading) {
      setAutoSaveStatus(" ");
    }
  }, [isEncounterLoading]);

  useEffect(() => {
    // Per lab by encounter
    if (updateLabsSuccess) {
      dispatch(getLabsByEncounter(appointmentId));
      setAutoSaveStatus("Saved");
      setTimeout(() => setAutoSaveStatus(""), 400);
    }
  }, [updateLabsSuccess]);

  useEffect(() => {
    // To have some default options in cpt dropdown.
    dispatch(getCptCodeList("25"));
  }, []);

  useEffect(() => {
    if (encounterSuccess) {
      if (encounterCeased) {
        setOpen(false);
        setEncounterCeased(false);
      }
    }
  }, [encounterSuccess]);

  useEffect(() => {
    return () => {
      // Clear vitals data
      dispatch(clearVitalsData());

      // clear labs data
      dispatch(clearLabsResultsMessage());

      // Clear patient data from global state
      dispatch(clearSpecificPatientData());

      // Clear appointment data from global state
      dispatch(clearAppointmentData());

      // Clear all history data from global state
      dispatch(clearAllHistoryData());

      // Clear all medication data
      dispatch(clearMedicinesData());

      // Clear all encounter data
      clearAllEncounterData();
    };
  }, []);

  // Triggers and restart the autosaving process when cpts changes
  useEffect(() => {
    if (enableAutoSaveCpts) setAutoSaveStatus(" ");

    const debouncedFunc = callAutoEncSaver(
      500,
      () => createOrUpdate("Soap Notes", "s"),
      enableAutoSaveCpts
    );
    debouncedFunc();

    return () => {
      // Clear the debounce timer on unmount or when notes change
      debouncedFunc.cancel();
    };
  }, [assesmentObj?.selectedCptCodes?.length]);

  // Triggers and restart the autosaving process when notes changes
  useEffect(() => {
    if (enableAutoSaveNotes) setAutoSaveStatus(" ");

    const debouncedFunc = callAutoEncSaver(
      3000,
      () => createOrUpdate("Soap Notes", "s"),
      enableAutoSaveNotes
    );
    debouncedFunc();

    return () => {
      // Clear the debounce timer on unmount or when notes change
      debouncedFunc.cancel();
    };
  }, [assesmentObj?.notes]);

  useEffect(() => {
    if (enableAutoSaveFollowUp) setAutoSaveStatus(" ");

    const debouncedFunc = callAutoEncSaver(
      1000,
      () => createOrUpdate("Soap Notes", "s"),
      enableAutoSaveFollowUp
    );
    debouncedFunc();

    return () => {
      // Clear the debounce timer on unmount or when notes change
      debouncedFunc.cancel();
    };
  }, [followUpData?.selection]);

  function updateTheExistingData() {
    // Api hit for encounter for specific patient
    dispatch(getEncounterByPatient(patientId));

    // get encounter on the base of appointment
    dispatch(getEncounterByAppointment(appointmentId));

    // sepecific appointment details
    dispatch(getSpecificAppointmentDetails(appointmentId));
  }

  // To send the encounter after validations
  function sendEncounter(soapNotes, signCloseIn, isReviewLater = false) {
    let encounterData = {
      soap_notes: soapNotes,
      patient_id: appointmentData?.patient_id,
      location_id: appointmentData?.location_id,
      practitioner_id: appointmentData?.practitioner_id,
      primary_payer_id: 1,
      secondary_payer_id: 1,
      fhir_resource_id: 1,
      visit_type: "asd",
      outcome: "outcome",
      assessment_notes:
        assesmentObj?.notes === null ? " . " : assesmentObj?.notes,
      appointment_id: appointmentData?.id,
      icds: assesmentObj?.selectedIcdCodes.map((s) => s.id),
      cpts: assesmentObj?.selectedCptCodes.map((s) => s.id),
      datetime: new Date(),
      followup_date: followUpData.date,
      account_id: account_id,
      // below ternary statement is temporary for 's' for simple save
      followup_selection: followUpData.selection,
      sign_close_ind: signCloseIn,
    };

    // adding id if encounter exists
    if (encounterByAppointmentData?.id) {
      encounterData.id = encounterByAppointmentData?.ids;
    }

    // Clear encounter data from global state
    dispatch(clearEncounterData());

    isReviewLater &&
      dispatch(
        updateAppointment(patientId, appointmentId, { review_later: true })
      );

    // create or update encounter on the basis of data and validations
    dispatch(
      createOrUpdateEncounter(
        encounterData,
        encounterByAppointmentData?.id ? "PUT" : "POST",
        encounterByAppointmentData?.id
      )
    );
  }

  function createOrUpdate(soapNotes, signCloseIn, review_later) {
    if (encounterByAppointmentData?.sign_close_ind !== "c") {
      // For save no validations required.
      if (signCloseIn === "s") {
        sendEncounter(soapNotes, signCloseIn, review_later);
      }
      // For cease check the validations.
      else if (signCloseIn === "c") {
        if (!followUpData?.date || !followUpData?.selection) {
          toast.error("Please select a follow up date");
          setAutoSaveStatus("");
        } else {
          sendEncounter(soapNotes, signCloseIn);
        }
      }
    }
  }

  function callAutoEncSaver(timeOut, func, condition) {
    return debounce(() => {
      if (condition) {
        func();
      }
    }, timeOut);
  }

  function findAndSetFollowDate(e, v) {
    const dateString = v.value;
    const selectDate = v.value;
    let followUpDate;
    const currentDate = moment();
    const separateCountandMonths = dateString?.split(" ");

    if (separateCountandMonths?.[1] === "month") {
      followUpDate = currentDate.add(
        parseInt(separateCountandMonths?.[0]),
        "months"
      );
    } else if (separateCountandMonths?.[1] === "week") {
      followUpDate = currentDate.add(
        parseInt(separateCountandMonths?.[0]),
        "weeks"
      );
    } else if (separateCountandMonths?.[1] === "hours") {
      followUpDate = currentDate.add(24, "hours");
    }

    setFollowUpData({
      date: followUpDate.format("YYYY-MM-DD"),
      selection: selectDate,
    });
  }

  function createUpdateDeleteLabs(id, test_id, instruction, method) {
    let labsData = {
      datetime: new Date(),
      patient_id: patientId,
      practitioner_id: appointmentData?.practitioner_id,
      lab_provider_id: null, // for now we will consider it null
      test_id: test_id,
      instructions: instruction,
      encounter_id: appointmentId,
      account_id: account_id,
    };
    dispatch(updateLabsByEncounter(labsData, method, id));
  }

  function createDeleteMedicine(data) {
    dispatch(
      updateMedicineByEncounter({
        encounter_id: appointmentId,
        medicines: data,
      })
    );
  }

  function setFollowUpDateOnSelectField(e, v) {
    findAndSetFollowDate(e, v);
    if (!enableAutoSaveFollowUp) setEnableAutoSaveFollowUp(true);
  }

  // Api hitting for icd after delay
  const getIcdCodes = debounce((value) => {
    if (value?.length > 0) {
      dispatch(getIcdCodeList(value));
    }
  }, 400);

  // Filter the selected icd code
  const onIcdCodeSelect = (e, v) => {
    const { selectedIcdCodes } = assesmentObj;
    // Check icd_code already exist
    let icdCodeAlreadyExists = selectedIcdCodes?.some(
      (s) => s.icd_code === v?.label
    );

    // Making stack for selected icd_codes
    if (!icdCodeAlreadyExists) {
      let filteredIcdCode = icdCodes.filter((s) => s.icd_code === v?.label);
      v?.label &&
        setAssesmentObj((prevState) => ({
          ...prevState,
          selectedIcdCodes: [...filteredIcdCode, ...selectedIcdCodes],
        }));
      if (!enableAutoSaveIcds) {
        setEnableAutoSaveIcds(true);
      }
    } else {
      toast.warning("ICD code already exists");
    }
  };

  // Specific selected icd_code removal
  const removeIcdCode = (index) => {
    const { selectedIcdCodes } = assesmentObj;
    let filteredIcdCodeArray = selectedIcdCodes.filter((s, i) => i !== index);
    setAssesmentObj((prevState) => ({
      ...prevState,
      selectedIcdCodes: filteredIcdCodeArray,
    }));

    if (!enableAutoSaveIcds) {
      setEnableAutoSaveIcds(true);
    }
  };

  // Triggers and restart the autosaving process when Icds get created or deleted
  useEffect(() => {
    if (enableAutoSaveIcds) {
      if (!fetchHealthSummaryTrigger) setFetchHealthSummaryTrigger(true);
      setAutoSaveStatus(" ");
    }

    const debouncedFunc = callAutoEncSaver(
      500,
      () => createOrUpdate("Soap Notes", "s"),
      enableAutoSaveIcds
    );
    debouncedFunc();

    return () => {
      // Clear the debounce timer on unmount or when notes change
      debouncedFunc.cancel();
    };
  }, [assesmentObj?.selectedIcdCodes?.length]);

  // Api hitting for cpt after delay
  const getCptCodes = debounce((value) => {
    if (value?.length > 0) {
      setShowCptOptions(true);
      dispatch(getCptCodeList(value));
    } else {
      setShowCptOptions(false);
    }
  }, 400);

  // Filter the selected cpt code
  function onCptCodeSelect(e, v) {
    const { selectedCptCodes } = assesmentObj;
    // Check cpt_code already exist
    let cptCodeAlreadyExists = selectedCptCodes?.some(
      (s) => s.cpt_code === v?.label
    );

    // Making stack for selected cpt_codes
    if (!cptCodeAlreadyExists) {
      let filteredCptCode = cptCodes.filter((s) => s.cpt_code === v?.label);
      v?.label &&
        setAssesmentObj((prevState) => ({
          ...prevState,
          selectedCptCodes: [...filteredCptCode, ...selectedCptCodes],
        }));
      if (!enableAutoSaveCpts) {
        setEnableAutoSaveCpts(true);
      }
    } else {
      toast.warning("CPT code already exists");
    }
  }

  // Specific selected cpt_code removal
  function removeCptCode(index) {
    const { selectedCptCodes } = assesmentObj;
    let filteredCptCodeArray = selectedCptCodes?.filter((s, i) => i !== index);
    setAssesmentObj((prevState) => ({
      ...prevState,
      selectedCptCodes: filteredCptCodeArray,
    }));
    if (!enableAutoSaveCpts) {
      setEnableAutoSaveCpts(true);
    }
  }
  const handleCancel = () => {
    setPastVisit(false);
  };
  // To have some default options in cpt dropdown.
  useEffect(() => {
    dispatch(getCptCodeList("25"));
    dispatch(getPharmacyList());
    dispatch(getMedicinesList("aba"));
    dispatch(getLabsList("h"));
    dispatch(getOrderMedicationsList(appointmentId));
  }, []);

  useEffect(() => {
    encounterByAppointmentData?.sign_close_ind === "c" &&
      setStartAppointment(false);
  }, [encounterByAppointmentData]);

  const healthMedTabsArray = [
    {
      title: "Smart Summary",
      tab: <HealthSummary setPdfModal={setPdfModal} />,
      tabIcon: true,
      tabIconSrc: SmartSummaryIcon,
      left: true,
    },
    {
      title: "Medications",
      tab: <Medications />,
    },
  ];

  function setFollowUpDefaultData() {
    let defaultValue = followUpOptions?.find(
      (v) => v.value === followUpData?.selection
    );
    if (defaultValue === undefined) {
      setFollowUpDateOnSelectField("", followUpOptions?.[0]);
      return followUpOptions?.[0];
    }
    return defaultValue;
  }

  const AssessmentChildrenArray = [
    {
      title: "Live Transcript",
      tab: <LiveTranscript startAppointment={startAppointment} />,
    },
    {
      title: "My Notes",
      tab: (
        <AssesmentPlanNotes
          setAssesmentObj={setAssesmentObj}
          assesmentObj={assesmentObj}
          setEnableAutoSaveNotes={setEnableAutoSaveNotes}
          enableAutoSaveNotes={enableAutoSaveNotes}
        />
      ),
    },
  ];

  useEffect(() => {
    window.speechSynthesis.cancel();
  }, []);

  return (
    <Box>
      <PDFModal setOpen={setPdfModal} open={pdfModal} />
      <CustomModal
        open={open}
        setOpen={() => {
          playRef.current.stop();
          setOpen(false);
        }}
        title={"Finalize Smart Assesment"}
        fullWidth
        maxWidth="lg"
        actions={[
          <Box position={"relative"}>
            <Box display={"flex"} justifyContent={"space-between"} flex={1}>
              <Box>
                <SelectField
                  key={followUpData?.selection}
                  defaultValue={setFollowUpDefaultData}
                  onChange={setFollowUpDateOnSelectField}
                  options={followUpOptions}
                  placeholder="Follow up"
                />
              </Box>
              <Box>
                <Button
                  text="Cancel"
                  backgroundColor="transparent"
                  color="#246EFD"
                  btnColor="#246EFD"
                  border={"1px solid #246EFD"}
                  disabled={isEncounterLoading}
                  borderRadius="12px"
                  sx={{ padding: "1px 20px", marginRight: "20px" }}
                  onClick={() => {
                    playRef.current.stop();
                    setOpen(false);
                  }}
                />
                <Button
                  text="Review Later"
                  backgroundColor="transparent"
                  color="#246EFD"
                  disabled={isEncounterLoading}
                  btnColor="#246EFD"
                  border={"1px solid #246EFD"}
                  borderRadius="12px"
                  sx={{ padding: "1px 20px", marginRight: "20px" }}
                  onClick={() => {
                    playRef.current.stop();
                    createOrUpdate(
                      `Subjective - ${soapData?.subjective} | Objective - ${soapData?.objective} | Assesment - ${soapData?.assessment} | Plan - ${soapData?.plan}`,
                      "s",
                      true
                    );
                    setOpen(false);
                  }}
                />
                <Button
                  text="Sign Notes & End Visit"
                  backgroundColor="#246EFD"
                  color="#FFFFFF"
                  btnColor="#FFFFFF"
                  borderRadius="12px"
                  sx={{ padding: "1px 20px", marginRight: "20px" }}
                  rightSide
                  isLoading={isEncounterLoading}
                  iconDetails={{ allowIcon: true, icon: rightArrow }}
                  onClick={() => {
                    playRef.current.stop();
                    setEncounterCeased(true);
                    createOrUpdate(
                      `Subjective - ${soapData?.subjective} | Objective - ${soapData?.objective} | Assesment - ${soapData?.assessment} | Plan - ${soapData?.plan}`,
                      "c"
                    );
                  }}
                />
              </Box>
            </Box>
            {encounterByAppointmentData?.sign_close_ind === "c" && (
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgb(253 250 250 / 50%)",
                  zIndex: 1000,
                  pointerEvents: "auto",
                  cursor: "not-allowed",
                }}
              ></div>
            )}
          </Box>,
        ]}
      >
        <EndVisit
          smart_assesment_plan={smart_assesment_plan}
          helperMedication={helperMedication}
          soapData={soapData}
          setSoapData={setSoapData}
          setOpen={setOpen}
          getCptCodes={getCptCodes}
          getIcdCodes={getIcdCodes}
          removeCptCode={removeCptCode}
          removeIcdCode={removeIcdCode}
          followUpData={followUpData}
          assesmentObj={assesmentObj}
          planLabsData={planLabsData}
          onCptCodeSelect={onCptCodeSelect}
          onIcdCodeSelect={onIcdCodeSelect}
          ceaseEncounter={createOrUpdate}
          planMedicineData={planMedicineData}
          encounterByAppointmentData={encounterByAppointmentData}
          playRef={playRef}
        />
      </CustomModal>
      <CustomModal
        open={pastVisit}
        setOpen={setPastVisit}
        title={"Past Visit"}
        fullWidth
        maxWidth="md"
        actions={[
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              text="Close"
              color="#ffffff"
              backgroundColor={"#1344f1"}
              btnColor={"#ffffff"}
              onClick={handleCancel}
            />
          </Box>,
        ]}
      >
        <PastVisitModal setPastVisit={setPastVisit} />
      </CustomModal>
      <Grid container spacing={2}>
        <Grid
          item
          lg={isChatOpen ? 9 : 12}
          xl={isChatOpen ? 9 : 12}
          xxl={isChatOpen ? 9 : 12}
          md={isChatOpen ? 8 : 12}
          sm={12}
          xs={12}
        >
          <SCDHeader
            ref={scdRef}
            patientId={patientId}
            isReviewLater={encounterByAppointmentData?.sign_close_ind === "s"}
            encounterClosed={encounterByAppointmentData?.sign_close_ind === "c"}
            setStartAppointment={setStartAppointment}
            startAppointment={startAppointment}
            getSpecificPatientData={getSpecificPatientData}
            getSpecificPatientSuccess={getSpecificPatientSuccess}
            dummyPic={dummyPic}
            setOpen={setOpen}
            setPastVisit={setPastVisit}
          />

          <Box sx={{ marginTop: "20px" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                className="healthsummary_wrapper"
              >
                <Box
                  sx={healthsummaryMain()}
                  data-testid="scd-health-summary-box-id"
                >
                  <HorizontalTabs
                    childrenArray={healthMedTabsArray}
                    borderTab={true}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                data-testid="scd-vital-labs-box-id"
                className="vital-lab-med-wrapper"
              >
                <Box sx={healthsummaryMain()}>
                  <VitalLabMedWrapper />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{ position: "relative", marginTop: "10px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="scd-vitals_lab_med_wrapper"
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={!isChatOpen ? 5 : 12}
                    lg={!isChatOpen ? 5 : 12}
                    sx={{ marginBottom: isChatOpen ? "25px" : "" }}
                  >
                    <SmartChecklist
                      set_smart_assesment_plan={set_smart_assesment_plan}
                      createDeleteMedicine={createDeleteMedicine}
                      startAppointment={startAppointment}
                      planLabsData={planLabsData}
                      helperMedication={helperMedication}
                      setHelperMedication={setHelperMedication}
                      viewWidth={viewWidth}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={!isChatOpen ? 7 : 12}
                    lg={!isChatOpen ? 7 : 12}
                    sx={{ marginTop: isChatOpen ? "20px" : "" }}
                    data-testid="scd-plan-box-id"
                  >
                    <Box
                      sx={{
                        ...planBoxStylingWrapper,
                        minHeight: "250px",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={planBoxTitleStyling(viewWidth)}
                        data-testid={"scd-assesment-heading"}
                      >
                        <Text variant="h3">Assessment and Plan</Text>
                      </Box>
                      <Box sx={planBoxDescriptionStyling(viewWidth)}>
                        {startAppointment ||
                        encounterByAppointmentData?.sign_close_ind === "c" ? (
                          <>
                            <HorizontalTabs
                              childrenArray={AssessmentChildrenArray}
                            />

                            <Grid container sx={{ marginTop: "10px" }}>
                              <Grid item xs={12} sm={4} md={4}>
                                <Assessment
                                  getIcdCodes={getIcdCodes}
                                  assesmentObj={assesmentObj}
                                  removeIcdCode={removeIcdCode}
                                  createOrUpdate={createOrUpdate}
                                  validationsObj={validationsObj}
                                  setAssesmentObj={setAssesmentObj}
                                  onIcdCodeSelect={onIcdCodeSelect}
                                  callAutoEncSaver={callAutoEncSaver}
                                  setAutoSaveStatus={setAutoSaveStatus}
                                  showEmptyMessages={showEmptyMessages}
                                  encounterByPatientSuccess={
                                    encounterByPatientSuccess
                                  }
                                  fetchHealthSummaryTrigger={
                                    fetchHealthSummaryTrigger
                                  }
                                  setFetchHealthSummaryTrigger={
                                    setFetchHealthSummaryTrigger
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={8} md={8}>
                                <Plan
                                  helperMedication={helperMedication}
                                  setHelperMedication={setHelperMedication}
                                  followUp={followUp}
                                  planLabsData={planLabsData}
                                  encounterId={appointmentId}
                                  createOrUpdate={createOrUpdate}
                                  setFollowUpData={setFollowUpData}
                                  setPlanLabsData={setPlanLabsData}
                                  planMedicineData={planMedicineData}
                                  callAutoEncSaver={callAutoEncSaver}
                                  setAutoSaveStatus={setAutoSaveStatus}
                                  setPlanMedicineData={setPlanMedicineData}
                                  createDeleteMedicine={createDeleteMedicine}
                                  createUpdateDeleteLabs={
                                    createUpdateDeleteLabs
                                  }
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <Box
                            sx={{
                              textAlign: "center",
                              minHeight: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text variant="bodyS">
                              <span
                                style={{ cursor: "pointer", color: "#246EFD" }}
                                onClick={() => {
                                  scdRef.current.startAppfunc();
                                }}
                              >
                                Start the visit
                              </span>{" "}
                              to begin filling out this section.
                            </Text>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {encounterByAppointmentData?.sign_close_ind === "c" && (
                <div
                  style={{
                    position: "absolute",
                    top: "20px",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgb(253 250 250 / 50%)",
                    zIndex: 1000,
                    pointerEvents: "auto",
                    cursor: "not-allowed",
                  }}
                ></div>
              )}
            </Grid>
          </Box>
        </Grid>
        {isChatOpen && <ChatBotComponent isChatOpen={true} />}
      </Grid>
    </Box>
  );
};

export default PatientDetail;

import { Box, Grid } from "@mui/material";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  NumberOfPatientLineChartOptions,
  NumberOfPatientSeriesData,
  TotalNumOfPatientDataOptions,
  TotalNumOfPatientSeriesData,
  barChartOptionsData,
  barChartSeriesData,
  newPatientDataOption,
  newPatientSeriesData,
  patientBillOptons,
  patientBillSeriesData,
} from "styles/Financials/Financials";
const PatientMonthIndex = () => {
  const [numOfDataSeries] = useState(NumberOfPatientSeriesData);
  const [numOfDataOptions] = useState(NumberOfPatientLineChartOptions);
  const [totalNumOfPatientDataSeries] = useState(TotalNumOfPatientSeriesData);
  const [totalNumOfPatientDataOptions] = useState(TotalNumOfPatientDataOptions);
  const [newPatientSeries] = useState(newPatientSeriesData);
  const [newPatientOptions] = useState(newPatientDataOption);
  const [patientBillSeries] = useState(patientBillSeriesData);
  const [patientBillsOptionsData] = useState(patientBillOptons);
  const [activePatients] = useState(1776);
  const [totalPatients] = useState(2345);
  const percentage = Math.round((activePatients / totalPatients) * 100);
  const totalPercentage = Math.round((activePatients / totalPatients) * 100);
  const [series, setSeries] = useState([percentage, 100 - percentage]);

  useEffect(() => {
    const newPercentage = Math.round((activePatients / totalPatients) * 100);
    setSeries([newPercentage, 100 - newPercentage]);
  }, [activePatients, totalPatients]);

  const options = {
    chart: {
      type: "donut",
      width: "100%",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: ["#3475DC", "#ccc"],
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "30px",
              color: "#3475DC",
              formatter: function () {
                return totalPercentage + "%";
              },
            },
          },
        },
      },
    },
    labels: [`${percentage}% Active`],
  };
  const [patientSeries] = useState([74, 26]);
  //
  const [barChartSeries] = useState(barChartSeriesData);
  const [barChartOptions] = useState(barChartOptionsData);

  return (
    <Grid container spacing={2} sx={{ mt: 1, mb: 2, height: "100%" }}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Box sx={{ background: "#fff", borderRadius: "24px", height: "100%" }}>
          <Box sx={{ borderBottom: "1px solid #ccc" }}>
            <Box sx={{ padding: "24px" }}>
              <Text variant="h2">Number of patients Per Month</Text>
            </Box>
          </Box>
          <Box sx={{ padding: "24px" }}>
            <div>
              <div id="chart">
                <ReactApexChart
                  options={numOfDataOptions}
                  series={numOfDataSeries}
                  type="line"
                  height={350}
                />
              </div>
              <div id="html-dist"></div>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Box sx={{ background: "#fff", borderRadius: "24px", height: "100%" }}>
          <Box sx={{ borderBottom: "1px solid #ccc" }}>
            <Box sx={{ padding: "24px" }}>
              <Text variant="h2">Avg Patients per month per provider</Text>
            </Box>
          </Box>
          <Box sx={{ padding: "24px" }}>
            <div>
              <div id="chart">
                <ReactApexChart
                  options={totalNumOfPatientDataOptions}
                  series={totalNumOfPatientDataSeries}
                  type="line"
                  height={350}
                />
              </div>
              <div id="html-dist"></div>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Box sx={{ background: "#fff", borderRadius: "24px", height: "100%" }}>
          <Box sx={{ borderBottom: "1px solid #ccc" }}>
            <Box sx={{ padding: "24px" }}>
              <Text variant="h2">New Patients Per Month</Text>
            </Box>
          </Box>
          <Box sx={{ padding: "24px" }}>
            <div>
              <div id="chart">
                <ReactApexChart
                  options={newPatientOptions}
                  series={newPatientSeries}
                  type="line"
                  height={350}
                />
              </div>
              <div id="html-dist"></div>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Box sx={{ background: "#fff", borderRadius: "24px", height: "100%" }}>
          <Box sx={{ borderBottom: "1px solid #ccc" }}>
            <Box sx={{ padding: "24px" }}>
              <Text variant="h2">
                Number of Patients Who Paid Bills Per Month
              </Text>
            </Box>
          </Box>
          <Box sx={{ padding: "24px" }}>
            <div>
              <div id="chart">
                <ReactApexChart
                  options={patientBillsOptionsData}
                  series={patientBillSeries}
                  type="line"
                  height={350}
                />
              </div>
              <div id="html-dist"></div>
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={12} md={6} sm={12} xs={12}>
        <Box sx={{ background: "#fff", borderRadius: "24px", height: "100%" }}>
          <Box sx={{ borderBottom: "1px solid #ccc" }}>
            <Box sx={{ padding: "24px" }}>
              <Text variant="h2">Expected Visits vs Actual Visits</Text>
            </Box>
          </Box>
          <Box sx={{ padding: "24px" }}>
            <div id="patient-chart">
              <ReactApexChart
                options={barChartOptions}
                series={barChartSeries}
                type="bar"
                height={350}
              />
            </div>
            <div id="html-dist"></div>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PatientMonthIndex;

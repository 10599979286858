import { Box, Grid } from "@mui/material";
import Text from "components/common/Typography/Text";
import PerformanceIndicatorChart from "./KeyIndicatorsCharts/PerformanceIndicatorChart";
import KeyIndicatorPieChart from "./KeyIndicatorsCharts/KeyIndicatorPieChart";
import ESRDGraph from "./KeyIndicatorsCharts/ESRDGraph";
import NewPatientGraph from "./KeyIndicatorsCharts/NewPatientGraph";
import ExpectedRevenue from "./KeyIndicatorsCharts/ExpectedRevenue";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";
import { useState } from "react";
import KeyIdicatorESRDPieChart from "./KeyIndicatorsCharts/KeyIdicatorESRDPieChart";
import KeyIdicatorNewPatientsPieChart from "./KeyIndicatorsCharts/KeyIdicatorNewPatientsPieChart";
import KeyIndicatorExpectedRevenuPieChart from "./KeyIndicatorsCharts/KeyIndicatorExpectedRevenuPieChart";

const PerformanceIndicator = () => {
  const [keyindicatorFlag, setKeyindicatorFlag] = useState(1);
  const childrenArr = [
    {
      title: "Active Patients",
      tab: <PerformanceIndicatorChart />,
    },
    {
      title: "ESRD",
      tab: <ESRDGraph />,
    },
    {
      title: "New Patients",
      tab: <NewPatientGraph />,
    },
    {
      title: "Revenue",
      tab: <ExpectedRevenue />,
    },
  ];
  return (
    <Box>
      <Box sx={{ padding: "0px 16px", mb: 1 }}>
        <Text variant="h2">Key performance Indicators</Text>
      </Box>
      <Box></Box>
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <Box
            sx={{
              background: "#fff",
              height: "100%",
              borderRadius: "10px",
              border: "1px solid #ccc",
              padding: "15px 20px",
            }}
          >
            <HorizontalTabs
              childrenArray={childrenArr}
              setKeyindicatorFlag={setKeyindicatorFlag}
            />
          </Box>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Box
            sx={{
              background: "#fff",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              border: "1px solid #ccc",
            }}
          >
            {keyindicatorFlag === 1 && <KeyIndicatorPieChart />}
            {keyindicatorFlag === 2 && <KeyIdicatorESRDPieChart />}
            {keyindicatorFlag === 3 && <KeyIdicatorNewPatientsPieChart />}
            {keyindicatorFlag === 4 && <KeyIndicatorExpectedRevenuPieChart />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PerformanceIndicator;

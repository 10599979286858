import { Box, Grid } from "@mui/material";
import { profileInformationData, titleOptions } from "assets/static";
import Button from "components/common/Button/Button";
import ImageDropzone from "components/common/FileUploader";
import InputField from "components/common/FormComponents/InputField";
import SelectField from "components/common/FormComponents/SelectField";
import Text from "components/common/Typography/Text";
import { useFormik } from "formik";
import moment from "moment/moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearAdminStatuses,
  getAdminInfo,
  updateAdminInfo,
} from "redux/features/Admin/AdminSlice";
import { getAccountID } from "redux/features/Auth/loginSlice";
import { getUsersList } from "redux/features/MyTeam/myTeamSlice";
import { getGroupsOptions } from "redux/features/common/optionSlice";
import * as Yup from "yup";

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.auth);
  const { isAdminUpdateLoading, adminData, adminUpdateSuccess } = useSelector(
    (state) => state.admin
  );
  const { account_id } = useSelector((state) => state.auth);

  useEffect(() => {
    userId && dispatch(getAdminInfo(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (adminUpdateSuccess) {
      dispatch(getAdminInfo(userId));
      dispatch(getAccountID());
      toast.success("Profile Updated");
    }
  }, [dispatch, userId, adminUpdateSuccess]);

  const formik = useFormik({
    initialValues: {
      title: adminData?.title
        ? titleOptions?.find(({ value }) => value === adminData?.title)?.title
        : titleOptions[0]?.title,
      first_name: adminData?.first_name ?? "",
      last_name: adminData?.last_name ?? "",
      username: adminData?.username ?? "",
      designation: adminData?.designation ?? "",
      birthdate: adminData?.birthdate
        ? moment(adminData?.birthdate).format("YYYY-MM-DD")
        : "",
      medical_license_number: adminData?.medical_license_number ?? "",
      email: adminData?.email ?? "",
      phone_number: adminData?.phone_number ?? "",
      photo: adminData?.photo ?? "",
    },
    validationSchema: Yup.object({
      birthdate: Yup.string().required("Birth date is required"),
      title: Yup.string().required("Title is required"),
    }),
    onSubmit: (data) => {
      let formData = new FormData();
      formData.append("title", data?.title?.value ?? data?.title);
      formData.append("first_name", data?.first_name);
      formData.append("last_name", data?.last_name);
      formData.append("groups", adminData?.groups);
      formData.append("accounts", adminData?.accounts);
      formData.append("username", data?.username);
      formData.append("designation", data?.designation);
      formData.append("birthdate", data?.birthdate);
      formData.append("medical_license_number", data?.medical_license_number);
      formData.append("email", data?.email);
      formData.append("phone_number", data?.phone_number);
      typeof data?.photo !== "string" && formData.append("photo", data?.photo);
      dispatch(updateAdminInfo(userId, formData));
    },
  });

  useEffect(() => {
    formik.setValues({
      title: adminData?.title
        ? titleOptions?.find(({ value }) => value === adminData?.title)?.label
        : titleOptions[0]?.label,
      first_name: adminData?.first_name ?? "",
      last_name: adminData?.last_name ?? "",
      username: adminData?.username ?? "",
      designation: adminData?.designation ?? "",
      birthdate: adminData?.birthdate
        ? moment(adminData?.birthdate).format("YYYY-MM-DD")
        : "",
      medical_license_number: adminData?.medical_license_number ?? "",
      email: adminData?.email ?? "",
      phone_number: adminData?.phone_number ?? "",
      photo: adminData?.photo ?? "",
    });
  }, [adminData]);

  useEffect(() => {
    return () => dispatch(clearAdminStatuses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAccountID());
    dispatch(getGroupsOptions());
  }, [dispatch]);

  useEffect(() => {
    account_id && dispatch(getUsersList(account_id));
  }, [dispatch, account_id]);

  return (
    <>
      <Box className="title-box" sx={{ padding: "4px 15px" }}>
        <Text marginY={2} id={"profile-heading"} variant="h1" fontWeight="700">
          {" "}
          Profile Settings{" "}
        </Text>
      </Box>
      <>
        {adminData && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box sx={{ padding: "20px", width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "76px",
                    width: { lg: "100%", sm: "100%" },
                  }}
                >
                  <Box sx={{ display: "flex", width: "41%" }}>
                    <SelectField
                      key={adminData?.title}
                      name={"title"}
                      formik={formik}
                      options={titleOptions}
                      fontWeight={700}
                      defaultValue={
                        adminData?.title
                          ? titleOptions?.find(
                              ({ value }) => value === adminData?.title
                            )
                          : titleOptions[0]
                      }
                      label={"Title"}
                      cols={12}
                      placeholder={"Select Title"}
                    />
                  </Box>
                  <Box sx={{ display: "flex", width: "50%" }}>
                    <ImageDropzone formik={formik} name="photo" />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    paddingLeft: "0px",
                    gap: "10px",
                  }}
                >
                  {profileInformationData.map((item) =>
                    item?.element === "input" ? (
                      <Box sx={item?.sx}>
                        <InputField
                          {...(item.label === "DOB" && { max: item.max })}
                          {...(item.type === "email" && { disabled: true })}
                          name={item?.name}
                          formik={formik}
                          type={item?.type}
                          label={item?.label}
                          fontWeight="700"
                          xs={11}
                          sm={11}
                          md={11}
                          lg={11}
                          placeholder={item?.placeHolder}
                        />
                      </Box>
                    ) : null
                  )}
                </Box>
                <Button
                  sx={{ marginTop: "15px" }}
                  isLoading={isAdminUpdateLoading}
                  text="Update Profile"
                  onClick={formik.handleSubmit}
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    </>
  );
};

export default ProfileSettings;

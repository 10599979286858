import { IOSSwitch } from 'styles/Common/Toggler';
import FormControlLabel from '@mui/material/FormControlLabel';

const Toggler = () => {
    return (
        <FormControlLabel className='toggler-mui'
            control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
        />
    )

}
export default Toggler;
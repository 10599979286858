import { createSlice } from "@reduxjs/toolkit";

export const initialMedicationsState = {
  medicationsSuccess: null,
  medicationsActiveData: [],
  medicationsIsLoading: false,
  medicationsInActiveData: [],
};

const name = "medications";

export const medicationsSlice = createSlice({
  name,
  initialState: initialMedicationsState,
  reducers: {
    getMedications: (state, action) => {
      state.medicationsIsLoading = true;
    },
    getMedicationsSuccess: (state, action) => {
      state.medicationsSuccess = true;
      state.medicationsIsLoading = false;
      state.medicationsActiveData = action.payload?.medicines?.filter(
        (s) => s.status_ind === "1" && s.discontinued_on === null
      );
      state.medicationsInActiveData = action.payload?.medicines?.filter(
        (s) => s.status_ind === "0"
      );
    },
    getMedicationsFail: (state, action) => {
      state.medicationsSuccess = false;
      state.medicationsActiveData = [];
      state.medicationsInActiveData = [];
      state.medicationsIsLoading = false;
    },
    clearMedicationsData: (state, action) => {
      state.medicationsSuccess = null;
      state.medicationsActiveData = [];
      state.medicationsInActiveData = [];
      state.medicationsIsLoading = false;
    },
  },
});

export const { getMedications, clearMedicationsData } =
  medicationsSlice.actions;

export default medicationsSlice.reducer;

export const getMedicationsList = (encounterId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/prescriptions/get_prescription_data/?encounter_id=${encounterId}`,
  },
  type: "getMedications",
});

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FAQSData } from "assets/static";
import Text from "components/common/Typography/Text";
import { Fragment } from "react";

const useStyles = makeStyles(() => ({
  accordionRoot: {
    marginTop: "15px",
    borderRadius: "8px !important",
    "&::before": {
      height: "0px !important",
    },
    "& .MuiTypography-root": {
      color: "white",
      fontWeight: "700",
    },
    "& .MuiAccordionSummary-root ": {
      backgroundColor: "#272a40",
      borderRadius: "8px",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "white",
    },
    "& .MuiButtonBase-root.MuiTab-root": {
      alignItems: "flex-start",
      textTransform: "capitalize",
      marginBottom: "8px",
      minWidth: "206px",
    },
    "& .MuiAccordionDetails-root": {
      backgroundColor: "#f8f7fe",
      padding: "15px",
      "& .MuiTypography-root": {
        color: "#393838 !important",
        fontWeight: "500",
      },
    },
  },
}));

const SupportFaq = () => {
  const classes = useStyles();
  return (
    <Box className="title-box" sx={{ padding: "5px 15px" }}>
      <Text marginY={2} id="faq-heading" variant="h1">
        Support and FAQ
      </Text>
      <Text marginY={2} id={"faq-sub-heading"} variant="h2">
        Frequently Asked Questions
      </Text>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {FAQSData?.map(({ title, description }, index) => (
            <Fragment key={index}>
              {index % 2 !== 0 && (
                <Accordion
                  className={classes?.accordionRoot}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    data-testid={`panel${index}-header`}
                  >
                    <Typography variant="bodyS">{title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="bodyXs">{description}</Typography>
                  </AccordionDetails>
                </Accordion>
              )}
            </Fragment>
          ))}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {FAQSData?.map(({ title, description }, index) => (
            <Fragment key={index}>
              {index % 2 === 0 && (
                <Accordion
                  className={classes?.accordionRoot}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography variant="bodyS">{title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="bodyXs">{description}</Typography>
                  </AccordionDetails>
                </Accordion>
              )}
            </Fragment>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SupportFaq;

export function getVariance(arr) {
    /*This part of function calculates the < ∑i=1 n ​ (xi ​ − x) **2 >
    xi = pre (initially it will be 0), x = cur (subtracted from mean and then squared) 
     */
    function getVariance(arr, mean) {
        return arr?.reduce(function (pre, cur) {
            pre = pre + Math.pow((cur - mean), 2);
            return pre;
        }, 0)
    }

    // This function calculates sum of all array passed to it.
    var totalAddition = arr?.reduce(function (pre, cur) {
        return pre + cur;
    })

    // Passing array and mean to function to get the semission of values
    var totalSem = getVariance(arr, totalAddition / arr?.length);

    // Divide the semission with total array length
    var variance = totalSem / arr?.length

    return variance.toFixed(2)
}
import { Box, Stack } from "@mui/material";
import CustomModal from "components/common/Modal/CustomModal";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import VitalsIcon from "../../../../../assets/svg/light/timeIcon.svg";
import VitalsDetail from "./VitalsDetail";

const Vitals = () => {
  const leftSideRef = useRef(null);
  const [lineHeight, setLineHeight] = useState(100);
  const [openVitals, setOpenVitals] = useState(false);
  const [vitalsSortedData, setVitalsSortedData] = useState(false);
  const [leftSideHeight, setLeftSideHeight] = useState("auto");

  const { healthSummaryData } = useSelector((state) => state.healthSummary);
  const { vitalsSuccess, vitalsData } = useSelector((state) => state.vitals);

  useEffect(() => {
    if (leftSideRef.current) {
      const height = leftSideRef.current.clientHeight;
      setLeftSideHeight(`${height}px`);
    }

    if (vitalsData.length > 0) {
      let tempData = [...vitalsData];
      setVitalsSortedData(
        tempData?.sort(
          (a, b) => new Date(b?.date_time) - new Date(a?.date_time)
        )
      );
    }
  }, [vitalsData]);

  const setCKDValColors = (key) => {
    let status = healthSummaryData?.[0]?.ckd_status;

    const ckdVals = {
      "CKD-4": ["BP"],
      "CKD-5": ["BP"],
    };

    if (status === "CKD-4" || status === "CKD-5") {
      return ckdVals[status]?.includes(key?.toUpperCase()) ? true : false;
    } else {
      return false;
    }
  };

  return (
    <>
      <Box sx={{ padding: "2px", height: "100%", marginTop: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Text
            data-testid="scd-vitals-today"
            variant="formLabel"
            marginLeft="20px"
          >
            Measured Today
          </Text>
        </Box>
        <Stack
          direction="row"
          spacing={2}
          useFlexGap
          alignItems="center"
          mt={1}
          sx={{
            paddingLeft: "20px",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
                marginBottom: `${vitalsSuccess ? "20px" : "0px"}`,
              }}
            >
              <Text
                data-testid="scd-vitals-label-bp"
                variant="h3"
                minWidth="54px"
              >
                BP
              </Text>

              <SkeletonWrapper
                id={"skeleton-vitals-blood-pressure"}
                condition={vitalsSuccess}
                props={[SkeletonPropsStyleGenerator("5vmax", "40px", "150px")]}
              >
                <Text
                  data-testid="scd-vitals-value-bp"
                  variant="bodyS"
                  marginLeft="30px"
                  marginRight="30px"
                  color={setCKDValColors("BP") ? "redLight" : ""}
                >
                  {vitalsSortedData?.[0]?.systolic} /{" "}
                  {vitalsSortedData?.[0]?.diastolic}
                </Text>
              </SkeletonWrapper>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: `${vitalsSuccess ? "20px" : "0px"}`,
              }}
            >
              <Text
                data-testid="scd-vitals-label-hr"
                variant="h3"
                minWidth="54px"
              >
                HR
              </Text>
              <SkeletonWrapper
                id={"skeleton-vitals-heart-rate"}
                condition={vitalsSuccess}
                props={[SkeletonPropsStyleGenerator("5vmax", "40px", "150px")]}
              >
                <Text
                  data-testid="scd-vitals-value-hr"
                  variant="bodyS"
                  marginLeft="30px"
                  marginRight="30px"
                >
                  {vitalsSortedData?.[0]?.pulse} bpm
                </Text>
              </SkeletonWrapper>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: vitalsSuccess
                  ? { md: "10px", lg: "20px", xl: "20px" }
                  : "0px",
              }}
            >
              <Text
                data-testid="scd-vitals-label-bw"
                variant="h3"
                minWidth="54px"
              >
                Wt
              </Text>
              <SkeletonWrapper
                id={"skeleton-vitals-body-weight"}
                condition={vitalsSuccess}
                props={[SkeletonPropsStyleGenerator("5vmax", "40px", "150px")]}
              >
                <Text
                  data-testid="scd-vitals-value-bw"
                  variant="bodyS"
                  marginLeft="30px"
                  marginRight="30px"
                >
                  {vitalsSortedData?.[0]?.weight} lbs
                </Text>
              </SkeletonWrapper>
            </Box>
          </Box>

          <Box sx={{ flexBasis: "39%", marginLeft: "30px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: `${vitalsSuccess ? "20px" : "0px"}`,
              }}
            >
              <Text
                data-testid="scd-vitals-label-rr"
                variant="h3"
                minWidth="54px"
              >
                RR
              </Text>
              <SkeletonWrapper
                id={"skeleton-vitals-respiration-rate"}
                condition={vitalsSuccess}
                props={[SkeletonPropsStyleGenerator("5vmax", "40px", "150px")]}
              >
                <Text
                  data-testid="scd-vitals-value-rr"
                  variant="bodyS"
                  marginLeft="30px"
                  marginRight="30px"
                >
                  {vitalsSortedData?.[0]?.respiration_rate}
                </Text>
              </SkeletonWrapper>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: vitalsSuccess
                  ? { md: "10px", lg: "20px", xl: "20px" }
                  : "0px",
              }}
            >
              <Text
                data-testid="scd-vitals-label-temp"
                variant="h3"
                minWidth="54px"
              >
                Temp
              </Text>
              <SkeletonWrapper
                id={"skeleton-vitals-temp"}
                condition={vitalsSuccess}
                props={[SkeletonPropsStyleGenerator("5vmax", "40px", "150px")]}
              >
                <Text
                  data-testid="scd-vitals-value-temp"
                  variant="bodyS"
                  marginLeft="30px"
                  marginRight="30px"
                >
                  {vitalsSortedData?.[0]?.temperature} °F
                </Text>
              </SkeletonWrapper>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: vitalsSuccess
                  ? { md: "10px", lg: "20px", xl: "20px" }
                  : "0px",
              }}
            >
              <Text
                data-testid="scd-vitals-label-spo2"
                variant="h3"
                minWidth="54px"
              >
                SpO2
              </Text>
              <SkeletonWrapper
                id={"skeleton-vitals-spo2"}
                condition={vitalsSuccess}
                props={[SkeletonPropsStyleGenerator("5vmax", "40px", "150px")]}
              >
                <Text
                  data-testid="scd-vitals-value-spo2"
                  variant="bodyS"
                  marginLeft="30px"
                  marginRight="30px"
                >
                  {vitalsSortedData?.[0]?.spo2}%
                </Text>
              </SkeletonWrapper>
            </Box>
          </Box>
        </Stack>

        <Box
          sx={{
            position: "absolute",
            right: "10px",
            top: "12px",
          }}
        >
          <Box
            data-testid="scd-vitals-modal-btn"
            sx={{
              width: "29px",
              height: "29px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setOpenVitals(true);
            }}
          >
            <img src={VitalsIcon} alt="" />
          </Box>
          <CustomModal
            open={openVitals}
            setOpen={setOpenVitals}
            title={"Previous Vitals"}
            fullWidth
            maxWidth="lg"
          >
            <VitalsDetail />
          </CustomModal>
        </Box>
      </Box>
    </>
  );
};

export default memo(Vitals);

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const GenerateBreadcrumbs = () => {
  const location = useLocation();
  const path = location.pathname;
  // Get patient data for header
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );

  const findClosestMatch = (url) => {
    const keys = Object.keys(breadcrumbsData);
    const closestMatch = keys.reduce((acc, curr) => {
      const regex = new RegExp(`^${curr.replace(/:[^\s/]+/g, "[^/]+")}`);
      return path.match(regex) ? curr : acc;
    }, "/");
    return closestMatch;
  };

  const getBreadcrumbs = () => {
    const closestMatch = findClosestMatch(path);
    return breadcrumbsData[closestMatch] || [];
  };

  const breadcrumbsData = {
    "/": {
      current: "/",
      crumbsData: ["Nephrolytics", "All patients"],
    },
    "/schedule": {
      current: "/",
      crumbsData: ["Nephrolytics", "All patients"],
    },
    "/financials": {
      current: "",
      midUrl: "",
      crumbsData: ["Nephrolytics", "All patients", "Overview"],
    },
    "/financials/revenue/summary": {
      current: "",
      midUrl: "/financials",
      crumbsData: ["Nephrolytics", "Analytics Dashboard", "Revenue Summary"],
    },
    "/financials/revenue/PatientMonth": {
      current: "",
      midUrl: "/financials",
      crumbsData: ["Nephrolytics", "Analytics Dashboard", "Patient Per Month"],
    },
    "/schedule/patient/:patientId/appointment/:appointmentId": {
      current: "",
      crumbsData: [
        "Nephrolytics",
        "All Patients",
        `${getSpecificPatientData?.first_name ?? ""} ${
          getSpecificPatientData?.last_name ?? ""
        }`,
      ],
    },
  };

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {getBreadcrumbs()?.crumbsData?.map((crumb, index) => (
        <Link
          key={index}
          underline="hover"
          color="inherit"
          style={{
            textDecoration: "none", // Remove default underline
            color: index === 1 ? "#246EFD" : "#2D3748",
          }}
          to={
            index === 2
              ? window.location.pathname
              : getBreadcrumbs()?.midUrl
                ? getBreadcrumbs()?.midUrl
                : "/"
          }
        >
          <span
            style={{ borderBottom: "1px solid transparent" }}
            onMouseEnter={(e) =>
              (e.target.style.borderBottom = "1px solid #000")
            }
            onMouseLeave={(e) =>
              (e.target.style.borderBottom = "1px solid transparent")
            }
          >
            {crumb}
          </span>
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default GenerateBreadcrumbs;

import { Box } from "@mui/material";
import CustomModal from "components/common/Modal/CustomModal";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { useState } from "react";

const LabResultDetailCard = ({
  name = "",
  data,
  active = false,
  marginTop,
  pastVisit,
}) => {
  const [openOrignalFile, setOpenOrignalFile] = useState(false);

  return (
    <Box sx={{ marginTop: marginTop }}>
      {data !== null ? (
        <>
          {!pastVisit ? (
            <>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box>
                  <Box>
                    <Text variant="propertyLabel">Created by</Text>
                  </Box>
                  <Text variant="bodyXs">Khaled Janom MD</Text>
                </Box>
                <Box>
                  <Box>
                    <Text variant="propertyLabel">Reason for Visit</Text>
                  </Box>
                  <Text variant="bodyXs">
                    increased fatigue, swelling in my extremities, and changes
                    in urination patterns
                  </Text>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Box
                  data-testid="labresults-heading"
                  sx={{
                    color: active ? "#2D3748" : "#FC5A5A",
                    fontSize: "20px",
                    lineHeight: "28.79px",
                    fontWeight: "700",
                  }}
                >
                  <span style={{ borderBottom: "2px solid  #1344F1" }}>
                    Lab Results
                  </span>
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}

          {/* <Button
              sx={{ height: "28px" }}
              text={"View Original File"}
              width="141px"
              fontSize={"14px"}
              fontWeight={"700"}
              padding="1px 20px"
              borderRadius="5px"
              border={"1px solid #272A40"}
              backgroundColor={"#ffffff"}
              btnColor={"#272A40"}
              data-testid="view-original-file-button-id"
              onClick={() => setOpenOrignalFile(true)}
            /> */}

          <Box
            sx={{
              padding: "15px !important",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <Text variant="h2">Patient Information</Text>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                data-testid="labresults-patient-name"
              >
                <Text variant="h3">Patient Name: </Text>
                <Text variant="bodyXs">&nbsp; {name ?? "---"} </Text>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                data-testid="labresults-test-labname"
              >
                <Text variant="h3">Lab:</Text>
                <Text variant="bodyXs">
                  &nbsp;{data?.testing_lab_name ?? ""}
                </Text>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                data-testid="labresults-date-of-collection"
              >
                <Text variant="h3">Date of Collection:</Text>
                <Text variant="bodyXs">
                  &nbsp; {moment(data?.date_time).format("YYYY-MM-DD")}
                </Text>
              </Box>
            </Box>
            <Box
              data-testid="labresults-tests-details"
              sx={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <Box>
                {data?.observations?.map(
                  (
                    {
                      normal_range,
                      test_code,
                      test_description,
                      unit,
                      value,
                      test_name,
                      gender,
                    },
                    i
                  ) => {
                    return (
                      <>
                        <Box marginY={1}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Text
                              id={`lab-test_code-${i}`}
                              variant="h3"
                              minWidth="160px"
                            >
                              {test_code ?? ""}:
                            </Text>
                            <Text
                              id={`lab-value-unit-${i}`}
                              variant="bodyXs"
                              marginLeft="20px"
                            >
                              &nbsp; {value} &nbsp; {unit}
                            </Text>
                          </Box>
                          <Text variant="formLabel">
                            Ref: {normal_range ?? ""} {unit}
                          </Text>
                        </Box>
                      </>
                    );
                  }
                )}
              </Box>
            </Box>
            <CustomModal
              open={openOrignalFile}
              setOpen={setOpenOrignalFile}
              title={"Original File "}
              fullWidth
              maxWidth="lg"
            >
              <Box>
                {data?.original_file_url ? (
                  <iframe
                    src={data?.original_file_url}
                    width="100%"
                    height="500px"
                    title="PDF Viewer"
                  ></iframe>
                ) : (
                  "No File Found"
                )}
              </Box>
            </CustomModal>
          </Box>
        </>
      ) : (
        <Box marginY={4}>
          <Text variant="bodyS">No Data Found.</Text>
        </Box>
      )}
    </Box>
  );
};

export default LabResultDetailCard;

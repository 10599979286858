import { fontSize, height, width } from "@mui/system";
import {
  alignItemsCenter,
  flexColumn,
  flexRow,
  justifyContentCenter,
  justifyContentSpaceBetween,
} from "styles";

// Styling for UPPER HEADER
export let upperHeaderDatePickerStyling = {
  background: "#FFFFFF",
  border: "1px solid #E1E1E1",
  boxShadow: "none",
  borderRadius: "12px",
};

export let titleDateSearchBox = {
  ...flexRow,
  width: "100%",
  background: "#FFFFFFBF",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  borderRadius: "24px",
  padding: "10px 20px",
  marginBottom: "20px",
  height: "82px",
};
export let innerTitleDateSearchBox = {
  width: "100%",
  ...flexRow,
  ...alignItemsCenter,
  ...justifyContentSpaceBetween,
};
export let titleDateBox = {
  ...flexRow,
  ...alignItemsCenter,
  ...justifyContentCenter,
};
export let upperHeaderSearchInput = {
  marginTop: "0px",
  marginLeft: "10px",
  border: "1px solid black",
  borderRadius: "12px",
};
export let upperHeaderButtonsBox = {
  ...flexRow,
  width: "99%",
  margin: "25px 0px 0px 10px",
};
export let upperHeaderOverviewButton = (theme) => {
  return {
    width: {
      xl: "200px",
      lg: "160px",
      md: "120px",
      sm: "140px",
    },
    height: {
      xxl: "33px",
      xl: "33px",
      lg: "38px",
      md: "45px",
      sm: "40px",
    },
    fontSize: {
      xl: "15px",
      lg: "14px",
      md: "14px",
      sm: "12px",
    },
    padding: {
      xl: "17px",
      lg: "5px",
      md: "5px",
      sm: "5px",
    },
    borderRadius: "10px",
    backgroundColor: theme?.palette?.common?.blue,
    color: theme?.palette?.common?.white,
  };
};
export let upperHeaderSendMessageButton = (theme) => {
  return {
    width: {
      xl: "200px",
      lg: "150px",
      md: "110px",
      sm: "150px",
    },
    height: {
      xxl: "33px",
      xl: "33px",
      lg: "38px",
      md: "45px",
      sm: "40px",
    },
    fontSize: {
      xl: "15px",
      lg: "14px",
      md: "14px",
      sm: "12px",
    },
    padding: {
      xl: "17px",
      lg: "5px",
      md: "5px",
      sm: "5px",
    },
    borderRadius: "10px",
    marginLeft: "9px",
    backgroundColor: "#FFFFFF",
    color: "#246EFD",
    border: "1px solid #246EFD",
    boxShadow: "none",
  };
};
// Styling for CARD-CONTAINER.js
export const swiperLibraryStyling = (theme, dataLength, isChatOpen) => {
  return {
    "& .swiper-slide-prev .visit-reason-box": {
      height: "110px",
    },
    "& .swiper-slide-next .visit-reason-box": {
      height: "110px",
    },
    "& .swiper-slide-prev .profile-pic": {
      width: "125px",
      height: "155px",
    },
    "& .swiper-slide-next .profile-pic": {
      width: "125px",
      height: "155px",
    },
    [theme.breakpoints.up("sm")]: {
      "& .swiper-slide": {
        width: "450px !important",
      },
      "& .swiper-pagination-bullet": {
        width: `${300 / dataLength}px !important`,
      },
    },
    [theme.breakpoints.up("md")]: {
      "& .swiper-slide": {
        width: "450px !important",
      },
      "& .swiper-pagination-bullet": {
        height: "3px !important",
        width: `${500 / dataLength}px !important`,
      },
    },
    [theme.breakpoints.up("lg")]: {
      ".swiper-slide-prev .main-card-body": {
        marginLeft: "25px",
      },
      ".swiper-slide-next .main-card-body": {
        marginRight: "25px",
      },
      "& .swiper-pagination-bullet": {
        height: "4px !important",
        width: `${700 / dataLength}px !important`,
      },
    },
    [theme.breakpoints.up("xl")]: {
      "& .swiper-slide": {
        width: isChatOpen ? "300px !important" : "25% !important",
        marginBottom: isChatOpen ? "20px" : "",
      },
      ".swiper-slide-prev .main-card-body": {
        marginLeft: "30px",
      },
      ".swiper-slide-next .main-card-body": {
        marginRight: "30px",
      },
      "& .swiper-pagination-bullet": {
        height: "5px important",
        width: `${1000 / dataLength}px !important`,
      },
    },
    [theme.breakpoints.up("xxl")]: {
      "& .swiper-slide": {
        width: "25% !important",
      },
      ".swiper-slide-prev .main-card-body": {
        marginLeft: "35px",
      },
      ".swiper-slide-next .main-card-body": {
        marginRight: "35px",
      },
      "& .swiper-pagination-bullet": {
        height: "5px important",
        width: `${1000 / dataLength}px !important`,
      },
    },
    [`@media (min-width: 1024px) and (max-width:1024px) and (min-height:1366px) and (max-height:1366px)`]:
      {
        "& .swiper-slide": {
          width: "530px !important",
          height: "75vh",
        },
        "& .swiper-pagination-bullet": {
          height: "5px important",
          width: `${600 / dataLength}px !important`,
        },

        "& .status-image": {
          height: "18px",
          width: "18px",
          marginBottom: "4px",
        },
        "& .patient-profile-box": {
          marginTop: "30px",
        },
        "& .patient-name": {
          fontSize: "32px !important",
        },
        "& .card-disease": {
          marginTop: "10px",
          fontSize: "20px !important",
        },
        "& .profile-pic": {
          marginTop: "10px",
          height: "200px !important",
          width: "180px !important",
          objectFit: "cover",
        },
        "& .card-time": {
          fontSize: "13px !important",
        },
        "& .reason-heading": {
          fontSize: "14px !important",
        },
        "& .visit-heading": {
          fontSize: "13px !important",
        },
        "& .checkin-status": {
          fontSize: "13px !important",
        },
        "& .visit-reason": {
          fontSize: "22px !important",
        },
        "& .visit-reason-box": {
          height: "275px !important",
        },
        "& .visit-date": {
          fontSize: "20px !important",
        },
        "& .main-card-body .button-box .MuiButtonBase-root": {
          fontSize: "22px !important",
          height: "50px !important",
        },
      },
    [`@media (min-width:1280px ) and (max-width:1300px) and (min-height:580px) and (max-height:853px)`]:
      {
        "& .swiper-slide": {
          width: "350px !important",
        },
      },
    [`@media (min-width: 912px) and (max-width:912px) and (min-height:1368px) and (max-height:1368px)`]:
      {
        "& .swiper-slide": {
          width: "470px !important",
        },
      },
    [`@media (min-width: 1024px) and (max-width:1024px) and (min-height:900px) and (max-height:900px)`]:
      {
        "& .swiper-slide": {
          width: "370px !important",
        },
      },
    [`@media (min-width:1368px ) and (max-width:1368px) and (min-height:912px) and (max-height:912px)`]:
      {
        "& .swiper-slide": {
          width: "450px !important",
        },
      },
    [`@media (min-width: 1366px) and (max-width:1366px) and (min-height:1024px) and (max-height:1024px)`]:
      {
        "& .swiper-slide": {
          width: "450px !important",
        },
      },
    [`@media (min-width: 1440px) and (max-width:1440px) and (min-height:800px) and (max-height:900px)`]:
      {
        "& .swiper-slide": {
          width: "350px !important",
        },
      },
  };
};
// Styling for CARD.js
export let cardResponsiveStyling = (status, theme, isActive) => {
  return {
    ...flexColumn,
    ...justifyContentSpaceBetween,
    backgroundColor: "white",
    borderRadius: "10px",
    paddingTop: "5px",
    flex: 1,
    [`@media  (min-height:500px) and (max-height:630px)`]: {
      flex: "unset",
    },
    border:
      status === "Cancelled"
        ? "1px solid rgba(255, 0, 0, 0.5) !important"
        : "1px solid gray",
    transition: "all 0.4s ease-in-out",
    ...cardStyling(theme, isActive),
  };
};

export const cardStyling = (theme, isActive) => {
  return {
    "&.main-card-body": {
      width: "100%",
      padding: "15px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      margin: "0px 5px 0px 5px",
    },
    "& .profile-pic": {
      width: "115px",
      height: "145px",
    },
    "& .visit-reason-box": {
      height: "120px",
      overflow: "auto",
    },
    "& .reason-heading": {
      fontSize: "10px",
    },
    "& .visit-heading": {
      fontSize: "10px",
    },
    "& .status-image": {
      width: "20px",
      height: "20px",
    },
  };
};

export let cardDateTimeStatusStyling = {
  ...flexRow,
  ...alignItemsCenter,
  ...justifyContentSpaceBetween,
  padding: "none",
  marginBottom: "20px",
  height: "5%",
};
export let cardPatientStatus = (obj) => {
  const { cardTextClr, cardUrgentBackgroundColor, urgentCardBorder } = obj;
  return {
    border: urgentCardBorder,
    backgroundColor: cardUrgentBackgroundColor,
    borderRadius: "30px",
    padding: "2px 10px !important",
    fontSize: "10px",
    fontWeight: "600",
    color: cardTextClr,
  };
};
export let cardSmartSummary = {
  padding: "10px 20px",
};
export let cardNameDiseaseImageBoxStyling = {
  ...flexRow,
  ...justifyContentSpaceBetween,
  marginTop: "20px",
  height: "27%",
};

export let cardReasonWrapperStyling = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "50%",
  marginTop: "20px",
};

export let cardVisitReasonBoxStyling = {};
export let cardButtonStyling = (theme, isActive) => {
  return {
    borderRadius: "10px",
    width: "100%",
    fontSize: "100%",
    lineHeight: "0.2",
    height: "42px",
  };
};
// skeleton styling for CARDS.js
export let cardSkeletonResponsiveStyling = {
  ...flexColumn,
  ...justifyContentSpaceBetween,
  backgroundColor: "white",
  width: {
    xl: "350px",
    lg: "280px",
    md: "230px",
    sm: "350px",
  },
  height: {
    xl: "545px",
    lg: "380px",
    md: "280px",
    sm: "545px",
  },
  padding: {
    xl: "25px",
    lg: "15px",
    md: "20px",
    sm: "25px",
  },
  borderRadius: "10px",
  paddingTop: "5px",
};
export let cardImageSkeletonStyling = {
  borderRadius: "10px",
  width: {
    xl: 160,
    lg: 110,
    md: 90,
    sm: 160,
  },
  height: {
    xl: 150,
    lg: 120,
    md: 80,
    sm: 150,
  },
};
export let cardImageBoxStyling = {
  ...flexRow,
  ...justifyContentSpaceBetween,
  marginTop: "20%",
};
export let cardNameSkeletonStyling = {
  borderRadius: "10px",
  height: 30,
  width: {
    xl: 170,
    lg: 130,
    md: 110,
    sm: 170,
  },
};
export let cardDiseaseSkeletonStyling = {
  borderRadius: "10px",
  height: 25,
  marginTop: { xl: "5px", lg: "5px", md: "3px" },
  width: {
    xl: 150,
    lg: 110,
    md: 90,
    sm: 150,
  },
};
export let cardSpaceStyling = {
  background: "transparent",
  borderRadius: "10px",
  width: 370,
  height: {
    xl: 31,
    lg: 21,
    md: 8,
    sm: 31,
  },
};
export let cardVisitReasonSkeletonStyling = {
  borderRadius: "10px",
  width: {
    xl: 350,
    lg: 280,
    md: 230,
    sm: 350,
  },
  height: {
    xl: 200,
    lg: 120,
    md: 100,
    sm: 200,
  },
};
export let cardButtonSkeletonStyling = {
  borderRadius: "10px",
  width: 370,
  height: {
    xl: 40,
    lg: 30,
    md: 25,
    sm: 40,
  },
};

export const chatBotTitle = (forSearch) => {
  return {
    top: "90px",
    zIndex: "999",
    padding: "10px",
    backgroundColor: "#FFFFFF",
    width: forSearch ? "100%" : "26%",
    maxWidth: forSearch ? "100%" : "23%",
    position: forSearch ? "static" : "fixed",
    borderTopLeftRadius: forSearch ? "10px" : "24px",
    borderTopRightRadius: forSearch ? "10px" : "24px",
  };
};

export const chatBotConversation = (theme, forSearch) => {
  return {
    flex: "1",
    top: "140px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    width: forSearch ? "100%" : "23%",
    maxWidth: forSearch ? "100%" : "23%",
    position: forSearch ? "static" : "fixed",
    maxHeight: forSearch ? "453px" : "78vh",
    minHeight: forSearch ? "453px" : "78vh",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",

    [`@media (min-width: 1024px) and (max-width:1201px)`]: {
      maxHeight: forSearch ? "453px" : "75vh",
      minHeight: forSearch ? "453px" : "75vh",
    },
    [`@media (min-width: 1200px) and (max-width:1400px)`]: {
      maxHeight: forSearch ? "453px" : "67vh",
      minHeight: forSearch ? "453px" : "67vh",
    },
    [`@media (min-width: 1401px) and (max-width:1780px)`]: {
      maxHeight: forSearch ? "453px" : "76vh",
      minHeight: forSearch ? "453px" : "76vh",
      borderBottomLeftRadius: forSearch ? "" : "",
      borderBottomRightRadius: forSearch ? "" : "",
    },
    [theme.breakpoints.up("xxl")]: {
      maxHeight: forSearch ? "453px" : "79vh",
      minHeight: forSearch ? "453px" : "79vh",
      borderBottomLeftRadius: forSearch ? "" : "",
      borderBottomRightRadius: forSearch ? "" : "",
    },
  };
};

export const chatBottomStyling = (forSearch) => {
  return {
    bottom: "10px",
    padding: "10px",
    mt: forSearch ? 0 : 2,
    backgroundColor: "#FFFFFF",
    width: forSearch ? "100%" : "23%",
    maxWidth: forSearch ? "100%" : "23%",
    position: forSearch ? "static" : "fixed",
    borderBottomLeftRadius: forSearch ? "10px" : "24px",
    borderBottomRightRadius: forSearch ? "10px" : "24px",
  };
};

import {
  AppBar,
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputField from "components/common/FormComponents/InputField";
import GenerateBreadcrumbs from "components/common/GenerateBreadcrumbs";
import FeedBackModal from "components/common/Modal/FeedBackModal";
import MainSearchModal from "components/common/Modal/MainSearchModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutApi } from "redux/features/Auth/loginSlice";
import { getLocationsList } from "redux/features/Locations/locationsSlice";
import {
  setFirstLogin,
  toggleChatStates,
} from "redux/features/globalStateSlice";
import {
  appBar,
  logoAdminName,
  mainNavbar,
  mainNavbarInnerBox,
  navbarLocationsBox,
} from "styles/AppContainer/NavbarStyle";
import AvaIcon from "../../assets/svg/light/Ava.svg";
import Logo from "../../assets/svg/light/Logo.svg";
import dummyPic from "../../assets/svg/light/dummyPic.svg";
import Search from "../../assets/svg/light/search.svg";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import CustomModal from "components/common/Modal/CustomModal";
import AppointmentAlertModal from "components/common/Modal/AlertModal/AppointmentAlertModal";
import AlertIcon from "assets/svg/light/alert-icon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .navbar-select-dropdown": {
      "& .MuiGrid-root.MuiGrid-container": {
        width: "auto !important",
        marginRight: "10px",
      },
    },
  },

  navSearch: {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      backgroundColor: "#ffffff",
      border: "1px solid #E1E1E1",
      borderRadius: "15px",
      height: "30px",
    },
  },
  navIcons: {
    color: "#1C4588",
  },
  logOut: {
    "& .MuiList-root.MuiList-padding.MuiMenu-list": {
      paddingTop: "1px !important",
      paddingBottom: "1px !important",
    },
    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "500",
    },
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [viewWidth, setViewWidth] = useState(window.innerWidth);
  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  const dispatch = useDispatch();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [open, setOpen] = useState(false);

  const { firstName, lastName, title, photo } = useSelector(
    (state) => state.auth
  );
  const { locations, isLocationsLoading } = useSelector(
    (state) => state.locations
  );

  const { results } = useSelector((state) => state.patients);

  // Get appointment data for future encounter request
  const { appointmentData, isAppointmentLoading } = useSelector(
    (state) => state.specificAppointment
  );
  //global states
  const { isfirstLogin } = useSelector((state) => state.globalStates);

  const handleLogoutAndClose = () => {
    dispatch(logoutApi());
    handleCloseUserMenu();
  };
  const settings = [{ title: "Logout", onClick: () => handleLogoutAndClose() }];

  useEffect(() => {
    dispatch(getLocationsList());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewWidth(window.innerWidth);
      setViewHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const theme = useTheme();
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    isfirstLogin === true && setAlert(true);
    dispatch(setFirstLogin());
  }, [isfirstLogin]);

  return (
    <>
      <AppBar sx={appBar} className="navbar">
        <Box sx={mainNavbar(theme)} className={classes.root}>
          <Toolbar sx={{ paddingLeft: "10px !important" }}>
            <Box sx={mainNavbarInnerBox}>
              <Box sx={logoAdminName(theme)}>
                <img
                  src={Logo}
                  data-testid="nephrolyist-logo"
                  alt="logo"
                  style={{ marginRight: "30px", width: "42px", height: "42px" }}
                />
                <GenerateBreadcrumbs />
              </Box>
              <Box
                style={navbarLocationsBox}
                className="navbar-select-dropdown"
              >
                <Grid container columnSpacing={0}>
                  <InputField
                    className={classes.navSearch}
                    name="navbar-dropdown"
                    cols={12}
                    positionLeft={true}
                    borderRadius="12px"
                    sx={{ minWidth: "300px", width: "auto" }}
                    iconDetails={{
                      allowIcon: true,
                      iconName: Search,
                    }}
                    onClick={() => setOpenSearchModal(true)}
                    placeholder="Search patients, terms, medications, and reports"
                  />
                </Grid>
                <img
                  style={{ cursor: "pointer" }}
                  src={AvaIcon}
                  alt=""
                  onClick={() => dispatch(toggleChatStates())}
                />
              </Box>
              <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
                {/* <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ mr: 1 }}
                >
                  <MoreHorizIcon sx={{ color: "#222222" }} />
                </IconButton> */}
                <Box
                  sx={{
                    marginRight: "20px",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <img
                    src={AlertIcon}
                    onClick={() => {
                      setAlert(true);
                    }}
                    style={{
                      width: "27px",
                      height: "27px",
                    }}
                    alt=""
                  />
                  {!!results.length && (
                    <div
                      style={{
                        position: "absolute",
                        height: "15px",
                        width: "15px",
                        background: "red",
                        borderRadius: "50px",
                        top: "15px",
                        right: "-8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "8px",
                      }}
                    >
                      {results.length}
                    </div>
                  )}
                </Box>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className={classes.logOut}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={() => setting.onClick()}>
                      <Typography textAlign="center">
                        {setting.title}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>

                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {photo ? (
                    <Box
                      sx={{
                        borderRadius: "100px",
                        width: "62px",
                        height: "63px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={photo}
                        alt="Profile"
                        data-testid="patient-picture"
                        style={{
                          width: "46px",
                          height: "46px",
                          borderRadius: "100px",
                          backgroundSize: "contain",
                          objectFit: "cover",
                          objectPosition: "50% 0",
                        }}
                      />
                    </Box>
                  ) : (
                    <Avatar alt="Remy Sharp" src={dummyPic} />
                  )}
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
          <FeedBackModal open={open} setOpen={setOpen} />
          <CustomModal
            setOpen={setAlert}
            open={alert}
            title={"Alert"}
            width="auto"
            maxWidth={"md"}
            fullWidth={true}
            minHeight="350px"
          >
            <AppointmentAlertModal alert={alert} setAlert={setAlert} />
          </CustomModal>
        </Box>
      </AppBar>

      <MainSearchModal setOpen={setOpenSearchModal} open={openSearchModal} />
    </>
  );
};

export default Navbar;

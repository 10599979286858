import { Box, Grid } from "@mui/material";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import SelectField from "components/common/FormComponents/SelectField";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearPerLabsData } from "redux/features/Labs/labsSlice";
import { flexRowCenter } from "styles";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";

const OrderLabModal = ({
  toBeEditedLab,
  getLabs,
  onLabsSelect,
  onLabsUpdate,
  labsIsLoading,
  options,
  setOpen,
}) => {
  let dispatch = useDispatch();
  const { perLabData, perLabLoading } = useSelector((state) => state.labs);
  const [toBeSavedLab, setToBeSavedLab] = useState({
    label: "",
    value: "",
    ins: perLabData?.instructions,
  });

  function onSaveAddLabValues() {
    if (toBeEditedLab?.label?.length > 0) {
      onLabsUpdate(null, {
        label: toBeEditedLab?.label,
        value: toBeEditedLab?.val,
        instructions: toBeSavedLab?.ins,
        test_id: toBeEditedLab?.test_id,
      });
    } else {
      onLabsSelect(
        null,
        { label: toBeSavedLab?.label, value: toBeSavedLab?.value },
        toBeSavedLab?.ins
      );
    }
    setOpen(false);
  }

  useEffect(() => {
    return () => {
      dispatch(clearPerLabsData());
    };
  }, []);
  

  return (
    <Box>
      <Box>
      <Grid container spacing={2}>
      </Grid>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        {toBeEditedLab?.label?.length > 0 ? (
          <>
            <span style={{ margin: "20px 0px 0px 17px" }}>
              Test name & Code:
            </span>
            <span style={{ fontWeight: "bold", margin: "20px 0px 0px 10px" }}>
              {" "}
              {toBeEditedLab?.label}
            </span>
          </>
        ) : (
        <>
        <SelectField
          cols={6}
          disabled
          label={"Labs"}
          defaultValue={{
            value: 1,
            label: "Express Labs",
          }}
          placeholder="Select Lab"
        
        />
          <SelectField
            label={"Test Name & Code"}
            placeholder="Choose test name and code"
            onSearch={getLabs}
            onChange={(e, v) =>
              setToBeSavedLab((prev) => ({
                ...prev,
                label: v?.label,
                value: v?.value,
              }))
            }
            loading={labsIsLoading}
            options={options}
          />
        </>
        )}
      </Grid>
      <Grid container sx={{ marginTop: "10px" }}>
        <SkeletonWrapper
          condition={!perLabLoading}
          props={[SkeletonPropsStyleGenerator("100%", "180px", "150px")]}
        >
          <InputField
            label="Instructions"
            rows={3}
            multiline
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            defaultValue={perLabData?.instructions}
            onChange={(e) =>
              setToBeSavedLab((prev) => ({ ...prev, ins: e?.target?.value }))
            }
            placeholder="Notes here..."
          />
        </SkeletonWrapper>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Button
          text={"Cancel"}
          sx={{ marginRight: "10px", height: "33px", width: "150px" }}
          border={"1px solid #272A40"}
          borderRadius="5px"
          backgroundColor="#FFFFFF"
          color="#272A40"
          btnColor="#272A40"
          onClick={() => setOpen(false)}
        />
        <Button
          text={"Save Lab"}
          sx={{ height: "33px", width: "150px" }}
          borderRadius="5px"
          backgroundColor="#315FFF"
          color="#fff"
          btnColor="#fff"
          onClick={() => onSaveAddLabValues()}
        />
      </Box>
    </Box>
  );
};

export default OrderLabModal;

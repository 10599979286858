import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { Fragment, useState } from "react";
import elipses from "utils/ellipsis";

const dynamicTextColor = { color: "#638ace" };
const TextColor = "color:#638ace";
const MajorHeadingsFont = "font-size:20px";
const MinorHeadingsFont = "font-size:17px";

const NotesAccordion = ({
  results = [],
  isNotesHistoryLoading = false,
  classes = {},
}) => {
  let currentDate = moment();
  const todayData = results?.find((v) =>
    moment(v?.created_on).isSame(currentDate, "day")
  );
  return (
    <>
      {isNotesHistoryLoading ? (
        <Box
          data-testid="loading-ui-notes-history"
          sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}
        >
          {[1, 2, 3].map((v, memberIndex) => (
            <Box
              key={memberIndex}
              marginX={1}
              marginY={1}
              sx={{ width: "100%" }}
            >
              <Card
                elevation={0}
                sx={{
                  width: "100%",
                  border: "1px solid #e2e2e4",
                  borderRadius: "12px",
                  background: "#fafafb",
                }}
              >
                <CardHeader
                  sx={{ padding: "10px" }}
                  title={
                    <Grid
                      container
                      spacing={1}
                      sx={{ padding: "0px 0px 0px 15px" }}
                    >
                      {[1, 2, 3, 4, 4, 4].map((v, i) => (
                        <Grid key={i} item md={2}>
                          <Skeleton
                            animation="wave"
                            height={20}
                            width="100%"
                            style={{ marginBottom: 6 }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  }
                />
              </Card>
            </Box>
          ))}
        </Box>
      ) : (
        <>
          {
            <>
              {results?.length ? (
                <>
                  {!isNotesHistoryLoading && todayData ? (
                    <AccordionNotes
                      isToday={true}
                      obj={todayData}
                      classes={classes}
                    />
                  ) : null}
                  <>
                    {results?.filter(
                      (v) => !moment(v?.created_on).isSame(moment(), "day")
                    )?.length ? (
                      <Grid
                        container
                        spacing={1}
                        sx={{ padding: "0px 0px 0px 15px" }}
                      >
                        <Grid item md={2}>
                          {" "}
                          <Text
                            id="head-createdon"
                            variant="caption"
                            color={"black"}
                          >
                            Created on
                          </Text>
                        </Grid>
                        <Grid item md={3}>
                          {" "}
                          <Text
                            id="head-createdby"
                            variant="caption"
                            color={"black"}
                          >
                            Created by
                          </Text>
                        </Grid>
                        <Grid item md={3}>
                          {" "}
                          <Text
                            id="head-reason"
                            variant="caption"
                            color={"black"}
                          >
                            Reason for the visit
                          </Text>
                        </Grid>
                        <Grid item md={2}>
                          {" "}
                          <Text
                            id="head-visit"
                            variant="caption"
                            color={"black"}
                          >
                            Visit type
                          </Text>
                        </Grid>
                        <Grid item md={2}>
                          {" "}
                          <Text
                            id="head-outcome"
                            variant="caption"
                            color={"black"}
                          >
                            Outcome
                          </Text>
                        </Grid>
                        <Grid item md={1}>
                          {" "}
                          <Text
                            id="head-empty"
                            variant="caption"
                            color={"black"}
                          ></Text>
                        </Grid>
                      </Grid>
                    ) : null}
                  </>

                  {!isNotesHistoryLoading && results?.length
                    ? results
                        ?.filter(
                          (v) => !moment(v?.created_on).isSame(moment(), "day")
                        )
                        ?.map((obj, resIndex) => {
                          return (
                            <Fragment key={resIndex}>
                              <AccordionNotes
                                isToday={false}
                                obj={obj}
                                classes={classes}
                              />
                            </Fragment>
                          );
                        })
                    : null}
                </>
              ) : (
                <Box
                  data-testid="notes-no-data-ui"
                  sx={{
                    height: "100px",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  No Data Found
                </Box>
              )}
            </>
          }
        </>
      )}
    </>
  );
};

const AccordionNotes = ({ obj = {}, isToday = false, classes = {} }) => {
  const [expanded, setExpanded] = useState(isToday ?? false);
  const dd = obj?.soap_notes?.split("|");

  const [formData] = useState({
    subjective: dd
      ?.find((v) => v?.includes("Subjective -"))
      ?.split("Subjective -")?.[1],
    objective: dd
      ?.find((v) => v?.includes("Objective -"))
      ?.split("Objective -")?.[1],
    assessment: dd
      ?.find((v) => v?.includes("Assesment -"))
      ?.split("Assesment -")?.[1],
    plan: dd?.find((v) => v?.includes("Plan -"))?.split("Plan -")?.[1],
  });
  const textDesign = {
    width: "100%",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "21px",
    fontFamily: "Istok Web",
    color: "#272A40",
  };
  const labelDesign = {
    fontWeight: "700",
    fontSize: "26px",
    fontFamily: "Istok Web",
    color: "#272A40",
  };
  return (
    <Accordion
      expanded={expanded}
      onChange={(e) => setExpanded(!expanded)}
      data-testid="accordion-ui-notes-history"
      sx={{ boxShadow: "none" }}
      className={classes?.accordionRoot}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        sx={{
          background: isToday ? "#BECBF8 !important" : "#FDA2C966 !important",
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-content`}
        id={`panel-header`}
        data-testid={`panel-header-notes-history`}
      >
        {isToday ? (
          <Text
            id="today-heading"
            sx={{ lineHeight: "0px", margin: "0px" }}
            variant="subtitle1"
            color={"black"}
          >
            Today's Notes
          </Text>
        ) : (
          <Grid container spacing={1}>
            <Grid item md={2}>
              {" "}
              <Text
                id="val-createdon"
                sx={{ lineHeight: "0px", margin: "0px" }}
                variant="subtitle1"
                color={"black"}
              >
                {obj?.created_on
                  ? moment(obj?.created_on).format("MM/DD/YYYY")
                  : ""}
              </Text>
            </Grid>
            <Grid item md={3}>
              {" "}
              <Text
                id="val-createdby"
                sx={{ lineHeight: "0px", margin: "0px" }}
                variant="subtitle1"
                color={"black"}
              >
                {elipses(
                  `${obj?.created_by?.first_name} ${obj?.created_by?.last_name}`,
                  25
                ) ?? ""}
              </Text>
            </Grid>
            <Grid item md={3}>
              {" "}
              <Text
                id="val-reason"
                sx={{
                  lineHeight: "0px",
                  margin: "0px",
                  marginLeft: obj?.visit_reason ? "0px" : "-20px",
                  textAlign: obj?.visit_reason ? "left" : "center",
                }}
                variant="subtitle1"
                color={"black"}
              >
                {elipses(obj?.visit_reason, 30) ?? "---"}
              </Text>
            </Grid>
            <Grid item md={2}>
              {" "}
              <Text
                id="val-outcome"
                sx={{
                  lineHeight: "0px",
                  margin: "0px",
                  marginLeft: obj?.visit_type ? "0px" : "-20px",
                  textAlign: obj?.visit_type ? "left" : "center",
                }}
                variant="subtitle1"
                color={"black"}
              >
                {obj?.visit_type ?? "---"}
              </Text>
            </Grid>
            <Grid item md={2}>
              {" "}
              <Text
                id="val-empty"
                sx={{
                  lineHeight: "0px",
                  margin: "0px",
                  marginLeft: obj?.outcome ? "0px" : "-20px",
                  textAlign: obj?.outcome ? "left" : "center",
                }}
                variant="subtitle1"
                color={"black"}
              >
                {obj?.outcome ?? "---"}
              </Text>
            </Grid>
          </Grid>
        )}
      </AccordionSummary>
      <AccordionDetails data-testid="accordion-details">
        <>
          <Box
            className="cus-textFocus"
            sx={{
              padding: "10px",
              borderRadius: "11px",
              border: "1px solid black",
              background: "white",
              opacity: 0.5,
              cursor: "not-allowed",
            }}
          >
            <div>
              <label htmlFor="subjectiveInput">
                <h2 style={labelDesign}>Subjective</h2>
              </label>
              <div
                data-testid="subjective-area"
                placeholder="Subjective notes ..."
                className="cus-textarea"
                style={{ ...textDesign, border: "none", outline: "none" }}
                dangerouslySetInnerHTML={{ __html: formData.subjective }}
              ></div>
            </div>
            <div>
              <label htmlFor="ObjInput">
                <h2 style={labelDesign}>Objective</h2>
              </label>
              <div
                data-testid="objective-area"
                placeholder="Objective notes ..."
                className="cus-textarea"
                style={{ ...textDesign, border: "none", outline: "none" }}
                dangerouslySetInnerHTML={{ __html: formData.objective }}
              ></div>
            </div>
            <div>
              <label htmlFor="AssesmentInput">
                <h2 style={labelDesign}>Assesment</h2>
              </label>
              <div
                data-testid="assesment-area"
                placeholder="Assesment notes ..."
                className="cus-textarea"
                style={{ ...textDesign, border: "none", outline: "none" }}
                dangerouslySetInnerHTML={{ __html: formData.assessment }}
              ></div>
            </div>
            <div>
              <label htmlFor="planInput">
                <h2 style={labelDesign}>Plan</h2>
              </label>
              <div
                data-testid="plan-area"
                placeholder="Plan notes ..."
                className="cus-textarea"
                style={{ ...textDesign, border: "none", outline: "none" }}
                dangerouslySetInnerHTML={{ __html: formData.plan }}
              ></div>
            </div>
          </Box>
        </>
      </AccordionDetails>
    </Accordion>
  );
};

export default NotesAccordion;

import { createSlice } from "@reduxjs/toolkit";

export const initialOrderMedicationsState = {
  orderMedicationsList: [],
  orderMedicationsIsLoading: false,
  orderMedicationsAddingIsLoading: false,
  orderMedicationsAddedSuccess: false,
  orderMedicationsUpdatingIsLoading: false,
  orderMedicationsUpdateSuccess: false,
  specificOrderMedication: {},
};

const name = "orderMedications";

export const orderMedicationsSlice = createSlice({
  name,
  initialState: initialOrderMedicationsState,
  reducers: {
    getOrderMedications: (state, action) => {
      state.orderMedicationsIsLoading = true;
    },
    getMedicationById: (state, action) => {
      const id = action.payload;
      state.specificOrderMedication = "";
      state.specificOrderMedication =
        state.orderMedicationsList.find(
          ({ medicine_id }) => medicine_id === id
        ) || {};
    },
    getOrderMedicationsSuccess: (state, action) => {
      state.orderMedicationsIsLoading = false;
      state.orderMedicationsList = "";
      state.orderMedicationsList = action.payload?.medicines;
    },
    getOrderMedicationsFail: (state, action) => {
      state.orderMedicationsList = [];
      state.orderMedicationsIsLoading = false;
    },

    //adding orderMedications
    addOrderMedications: (state, action) => {
      state.orderMedicationsAddingIsLoading = true;
      state.orderMedicationsAddedSuccess = false;
    },
    addOrderMedicationsSuccess: (state, action) => {
      state.orderMedicationsAddingIsLoading = false;
      state.orderMedicationsAddedSuccess = true;
    },
    addOrderMedicationsFail: (state, action) => {
      state.orderMedicationsAddingIsLoading = false;
      state.orderMedicationsAddedSuccess = false;
    },

    //updating orderMedications
    updateOrderMedications: (state, action) => {
      state.orderMedicationsUpdatingIsLoading = true;
      state.orderMedicationsUpdateSuccess = false;
    },
    updateOrderMedicationsSuccess: (state, action) => {
      state.orderMedicationsUpdatingIsLoading = false;
      state.orderMedicationsUpdateSuccess = true;
    },
    updateOrderMedicationsFail: (state, action) => {
      state.orderMedicationsUpdatingIsLoading = false;
      state.orderMedicationsUpdateSuccess = false;
    },
    clearOrderMedicationsData: (state, action) => {
      state.orderMedicationsIsLoading = false;
      state.orderMedicationsAddedSuccess = false;
      state.specificOrderMedication = {};
      state.orderMedicationsUpdatingIsLoading = false;
      state.orderMedicationsUpdateSuccess = false;
    },
  },
});

export const {
  getOrderMedications,
  clearOrderMedicationsData,
  getMedicationById,
} = orderMedicationsSlice.actions;

export default orderMedicationsSlice.reducer;

export const getOrderMedicationsList = (encounterId) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/prescriptions/get_prescription_data/?encounter_id=${encounterId}`,
  },
  type: "getOrderMedications",
});
export const AddOrderMedications = (data) => ({
  payload: {
    apiName: name,
    method: "post",
    data,
    url: `/api/prescriptions/save_prescription_data/`,
  },
  type: "addOrderMedications",
});
export const UpdateOrderMedicationsById = (data, id) => ({
  payload: {
    apiName: name,
    method: "put",
    data,
    url: `/api/prescriptions/${id}/`,
  },
  type: "updateOrderMedications",
});

import { Box } from "@mui/material";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  clearMedicationsData,
  getMedicationsList,
} from "redux/features/Medications/MedicationsSlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import MedicationDetail from "./MedicationDetail";

const Medications = () => {
  let dispatch = useDispatch();
  let { patientId, appointmentId } = useParams();

  const [openVitals, setOpenVitals] = useState(false);

  const { medicationsSuccess, medicationsActiveData } = useSelector(
    (state) => state.medications
  );

  useEffect(() => {
    dispatch(getMedicationsList(appointmentId));
  }, [appointmentId]);

  useEffect(() => {
    return () => {
      dispatch(clearMedicationsData());
    };
  }, []);

  return (
    <Box sx={{ marginLeft: "20px", marginRight: "20px" }}>
      <Box sx={{ marginTop: "15px" }}>
        <Text id="scd-medications-data" variant="bodyM">
          Active
        </Text>
      </Box>
      <Box sx={{ maxHeight: "300px", overflow: "auto" }}>
        <SkeletonWrapper
          id={"skeleton-medication-strip"}
          condition={medicationsSuccess}
          multipleCount={3}
          props={[SkeletonPropsStyleGenerator("43vmax", "60px", "150px")]}
        >
          <MedicationDetail />
        </SkeletonWrapper>
      </Box>
    </Box>
  );
};

export default Medications;

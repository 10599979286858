import { useTheme } from "@mui/material";
import Button from "components/common/Button/Button";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearResetAppointmentData,
  resetAppointments,
} from "redux/features/Appointments/AppointmentsSlice";

const ResetAppointment = () => {
  const theme = useTheme();
  let dispatch = useDispatch();

  let { resetAppointmentLoading, resetAppointmentSuccess } = useSelector(
    (state) => state.specificAppointment
  );

  useEffect(() => {
    resetAppointmentSuccess && toast.success("Appointments reset successfully");
    return () => dispatch(clearResetAppointmentData());
  }, [resetAppointmentSuccess]);

  return (
    <Button
      isLoading={resetAppointmentLoading}
      onClick={() => dispatch(resetAppointments())}
      text="Reset Appointments"
      sx={{ width: "190px", color: theme.palette.button }}
    />
  );
};

export default ResetAppointment;

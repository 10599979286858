import { Box, Grid } from "@mui/material";
import Flex from "Layouts/Flex/Flex";
import {
  encountersPerPatientDataOptions,
  encountersPerPatientSeriesData,
  newPatientOptionsData,
  newPatientSeriesData,
  revenuePerEncounterDataOptions,
  revenuePerEncounterSeriesData,
  revenuePerPatientOptions,
  revenuePerPatientSeriesData,
  revnueAreaChart,
  totalEncountersDataOptions,
  totalEncountersSeriesData,
  totalPatientSeriesData,
  totalPatientSeriesDataOptions,
} from "assets/static";
import Text from "components/common/Typography/Text";
import { useState } from "react";
import TotalRevenueChart from "./TotalRevenueChart";
const TrendsAreaChart = () => {
  const [chartData] = useState(revnueAreaChart);
  const [revenuePerPatientSeries] = useState(revenuePerPatientSeriesData);
  const [revenuePerPatientOptionsData] = useState(revenuePerPatientOptions);
  const [revenuePerEncounterSeries] = useState(revenuePerEncounterSeriesData);
  const [revenuePerEncounterData] = useState(revenuePerEncounterDataOptions);
  const [totalEncountersSeries] = useState(totalEncountersSeriesData);
  const [totalEncountersOptions] = useState(totalEncountersDataOptions);
  const [encountersPerPatientSeries] = useState(encountersPerPatientSeriesData);
  const [encountersPerPatientOptions] = useState(
    encountersPerPatientDataOptions
  );
  const [totalPatientSeries] = useState(totalPatientSeriesData);
  const [totalPatientOptions] = useState(totalPatientSeriesDataOptions);
  const [newPatientSeries] = useState(newPatientSeriesData);
  const [newPatientOptions] = useState(newPatientOptionsData);
  return (
    <>
      <Grid container spacing={1} sx={{ mt: 1, mb: 2, height: "100%" }}>
        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            sx={{
              background: "#fff",
              borderRadius: "24px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ padding: "24px", borderBottom: "1px solid #ccc" }}>
              <Text variant="h2">{revnueAreaChart[0].title}</Text>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ padding: "16px" }}>
                <Box>
                  <Box sx={{ paddingBottom: "6px", my: 1 }}>
                    <div id="chart">
                      <ReactApexChart
                        options={chartData[0].options}
                        series={chartData[0].series}
                        type="area"
                        height={350}
                      />
                    </div>
                    <div id="html-dist"></div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            sx={{
              background: "#fff",
              borderRadius: "24px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ padding: "24px", borderBottom: "1px solid #ccc" }}>
              <Text variant="h2">{revnueAreaChart[1].title}</Text>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ padding: "16px" }}>
                <Box>
                  <Box sx={{ paddingBottom: "6px", my: 1 }}>
                    <div id="chart">
                      <ReactApexChart
                        options={revenuePerPatientOptionsData}
                        series={revenuePerPatientSeries}
                        type="area"
                        height={350}
                      />
                    </div>
                    <div id="html-dist"></div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            sx={{
              background: "#fff",
              borderRadius: "24px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ padding: "24px", borderBottom: "1px solid #ccc" }}>
              <Text variant="h2">{revnueAreaChart[2].title}</Text>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ padding: "16px" }}>
                <Box>
                  <Box sx={{ paddingBottom: "6px", my: 1 }}>
                    <div id="chart">
                      <ReactApexChart
                        options={revenuePerEncounterData}
                        series={revenuePerEncounterSeries}
                        type="area"
                        height={350}
                      />
                    </div>
                    <div id="html-dist"></div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            sx={{
              background: "#fff",
              borderRadius: "24px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ padding: "24px", borderBottom: "1px solid #ccc" }}>
              <Text variant="h2">{revnueAreaChart[3].title}</Text>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ padding: "16px" }}>
                <Box>
                  <Box sx={{ paddingBottom: "6px", my: 1 }}>
                    <div id="chart">
                      <ReactApexChart
                        options={totalEncountersOptions}
                        series={totalEncountersSeries}
                        type="area"
                        height={350}
                      />
                    </div>
                    <div id="html-dist"></div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            sx={{
              background: "#fff",
              borderRadius: "24px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ padding: "24px", borderBottom: "1px solid #ccc" }}>
              <Text variant="h2">{revnueAreaChart[4].title}</Text>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ padding: "16px" }}>
                <Box>
                  <Box sx={{ paddingBottom: "6px", my: 1 }}>
                    <div id="chart">
                      <ReactApexChart
                        options={encountersPerPatientOptions}
                        series={encountersPerPatientSeries}
                        type="area"
                        height={350}
                      />
                    </div>
                    <div id="html-dist"></div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            sx={{
              background: "#fff",
              borderRadius: "24px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ padding: "24px", borderBottom: "1px solid #ccc" }}>
              <Text variant="h2">{revnueAreaChart[5].title}</Text>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ padding: "16px" }}>
                <Box>
                  <Box sx={{ paddingBottom: "6px", my: 1 }}>
                    <div id="chart">
                      <ReactApexChart
                        options={totalPatientOptions}
                        series={totalPatientSeries}
                        type="area"
                        height={350}
                      />
                    </div>
                    <div id="html-dist"></div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box
            sx={{
              background: "#fff",
              borderRadius: "24px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ padding: "24px", borderBottom: "1px solid #ccc" }}>
              <Text variant="h2">{revnueAreaChart[6].title}</Text>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box sx={{ padding: "16px" }}>
                <Box>
                  <Box sx={{ paddingBottom: "6px", my: 1 }}>
                    <div id="chart">
                      <ReactApexChart
                        options={newPatientOptions}
                        series={newPatientSeries}
                        type="area"
                        height={350}
                      />
                    </div>
                    <div id="html-dist"></div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{ background: "#fff", borderRadius: "24px", height: "100%" }}
          >
            <Box sx={{ borderBottom: "1px solid #ccc" }}>
              <Box sx={{ padding: "24px" }}>
                <Flex justifyContent="space-between">
                  <Text variant="h2">Total Revenue (Service Wise)</Text>
                </Flex>
              </Box>
            </Box>
            <Box sx={{ padding: "24px" }}>
              <TotalRevenueChart />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default TrendsAreaChart;

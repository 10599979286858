import { Box } from "@mui/material";
import CustomTooltip from "components/common/CustomTooltip/CustomTooltip";
import CustomModal from "components/common/Modal/CustomModal";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLabsResults } from "redux/features/Patients/ViewLabResultsSlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import VitalsIcon from "../../../../../assets/svg/light/timeIcon.svg";
import LabResultDetail from "./LabResultDetail";
import { desiredOrder, hardCodedLabs } from "assets/static";

const LabResults = ({ setReportHeigh, isOutOfRange, patientName }) => {
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const [openVitals, setOpenVitals] = useState(false);
  const [formattedLabsData, setFormattedLabsData] = useState([]);
  const [leftSideHeight, setLeftSideHeight] = useState("auto");
  const leftSideRef = useRef(null);
  const { labResults, labsResultsSuccess, isLabResultsLoading } = useSelector(
    (state) => state.resultsLabs
  );
  const { healthSummaryData } = useSelector((state) => state.healthSummary);

  const getFormattedLabs = () => {
    let formattedData = [];
    labResults?.[0]?.observations?.map((details) => {
      formattedData.push({
        code: details?.test_name,
        val: `${details?.value} ${details?.unit}`,
        range: `${details?.normal_range} ${details?.unit}`,
      });
    });

    setFormattedLabsData(formattedData);
  };


  const sortedArrFun = (arr) => {
    const data = arr.sort((a, b) => {
      const indexA = desiredOrder.indexOf(a.code?.toLowerCase());
      const indexB = desiredOrder.indexOf(b.code?.toLowerCase());

      // If either name is not found in the desired order, move it to the end
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return indexA - indexB;
    });
    return data;
  };

  useEffect(() => {
    getFormattedLabs();
    if (leftSideRef.current) {
      const height = leftSideRef.current.clientHeight;
      setLeftSideHeight(`${height}px`);
    }
  }, [labResults]);

  useEffect(() => {
    patientId && dispatch(getLabsResults(patientId));
  }, [dispatch, patientId]);

  const setCKDValColors = (key) => {
    let status = healthSummaryData?.[0]?.ckd_status;

    const ckdVals = {
      "CKD-4": [
        "NA+",
        "K+",
        "UA: PROTEIN/CR",
        "UA: PROTEIN",
        "HPO4",
        "Albumin",
        "Glucose",
        "BUN",
        "CR",
        "EGFR",
      ],
      AKI: ["NA+", "K+", "BUN", "CR", "EGFR"],
      "CKD-5": [
        "HGB",
        "RBC",
        "NA+",
        "K+",
        "HPO4",
        "ALBUMIN",
        "GLUCOSE",
        "BUN",
        "CR",
        "EGFR",
      ],
    };

    if (status === "CKD-4" || status === "CKD-5" || status === "AKI") {
      return ckdVals[status]?.includes(key?.toUpperCase()) ? true : false;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // Calculate the number of items with redLight
    const count = [
      ...formattedLabsData,
      ...(patientName === "Sophia Patel" ? hardCodedLabs : []),
    ].filter((data) => isOutOfRange(data?.val, data?.range))?.length;
    // setRedLightCount(count);

    if (setReportHeigh) {
      setReportHeigh(count);
    }
  }, [formattedLabsData]);

  return (
    <Box
      sx={{ marginRight: "20px", marginBottom: "5px", position: "relative" }}
    >
      <Box
        data-testid="scd-labs-modal-btn"
        sx={{
          width: "29px",
          height: "29px",
          cursor: "pointer",
          position: "absolute",
          right: "-13px",
          top: "0",
        }}
        onClick={() => {
          setOpenVitals(true);
        }}
      >
        <img src={VitalsIcon} alt="" />
      </Box>
      <Box sx={{ padding: "2px", marginTop: "15px", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            maxHeight: "max-content",
            overflow: "auto",
            height: "100%",
          }}
        >
          <Box
            data-testid="lab-res-details-id"
            sx={{ flexBasis: "50%", height: "100%", marginLeft: "20px" }}
            ref={leftSideRef}
          >
            <SkeletonWrapper
              condition={labsResultsSuccess}
              multipleCount={5}
              props={[SkeletonPropsStyleGenerator("18vmax", "40px", "150px")]}
            >
              {formattedLabsData?.length ? (
                <>
                  {[
                    ...sortedArrFun(formattedLabsData),
                    ...(patientName === "Sophia Patel" ? hardCodedLabs : []),
                  ]?.map(({ code, val, range }, index) => (
                    <>
                      {index <=
                      [
                        ...formattedLabsData,
                        ...(patientName === "Sophia Patel"
                          ? hardCodedLabs
                          : []),
                      ]?.length /
                        2 ? (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text
                            data-testid="scd-labs-label-cr"
                            variant="h3"
                            minWidth="70px"
                          >
                            {code}
                          </Text>
                          <CustomTooltip
                            tooltipText={
                              <Box sx={{ padding: "5px" }}>
                                <Box>
                                  <Text variant="h3">
                                    <strong>{code} Reference Range</strong>
                                  </Text>
                                </Box>
                                <Box sx={{ marginTop: "5px" }}>
                                  <Text variant="formLabel">{range}</Text>
                                </Box>
                              </Box>
                            }
                            arrow={true}
                          >
                            <Text
                              data-testid="scd-labs-value-cr"
                              variant="bodyS"
                              marginLeft="10px"
                              marginRight="10px"
                              color={isOutOfRange(val, range) ? "redLight" : ""}
                              sx={{ cursor: "context-menu" }}
                            >
                              {val}
                            </Text>
                          </CustomTooltip>
                        </Box>
                      ) : null}
                    </>
                  ))}
                </>
              ) : null}
            </SkeletonWrapper>
          </Box>

          <Box sx={{ flexBasis: "50%", height: "100%", marginLeft: "20px" }}>
            <SkeletonWrapper
              condition={labsResultsSuccess}
              multipleCount={5}
              props={[SkeletonPropsStyleGenerator("18vmax", "40px", "150px")]}
            >
              {formattedLabsData?.length ? (
                <>
                  {[
                    ...sortedArrFun(formattedLabsData),
                    ...(patientName === "Sophia Patel" ? hardCodedLabs : []),
                  ]?.map(({ code, val, range }, index) => (
                    <>
                      {index >
                      [
                        ...formattedLabsData,
                        ...(patientName === "Sophia Patel"
                          ? hardCodedLabs
                          : []),
                      ]?.length /
                        2 ? (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text
                            data-testid="scd-labs-label-cr"
                            variant="h3"
                            minWidth="70px"
                          >
                            {code}
                          </Text>
                          <CustomTooltip
                            tooltipText={
                              <Box sx={{ padding: "5px" }}>
                                <Box>
                                  <Text variant="h3">
                                    <strong>{code} Reference Range</strong>
                                  </Text>
                                </Box>
                                <Box sx={{ marginTop: "5px" }}>
                                  <Text variant="formLabel">{range}</Text>
                                </Box>
                              </Box>
                            }
                            arrow={true}
                          >
                            <Text
                              data-testid="scd-labs-value-cr"
                              variant="bodyS"
                              marginLeft="10px"
                              marginRight="10px"
                              color={isOutOfRange(val, range) ? "redLight" : ""}
                              sx={{ cursor: "context-menu" }}
                            >
                              {val}
                            </Text>
                          </CustomTooltip>
                        </Box>
                      ) : null}
                    </>
                  ))}
                </>
              ) : null}
            </SkeletonWrapper>
          </Box>
        </Box>

        <CustomModal
          open={openVitals}
          setOpen={setOpenVitals}
          title={"Previous Lab Results"}
          fullWidth
          maxWidth="lg"
        >
          <LabResultDetail
            patientName={
              (getSpecificPatientData?.first_name
                ? getSpecificPatientData?.first_name
                : "") +
              " " +
              (getSpecificPatientData?.last_name
                ? getSpecificPatientData?.last_name
                : "")
            }
          />
        </CustomModal>
      </Box>
    </Box>
  );
};

export default LabResults;

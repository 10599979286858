import { Box, Grid } from "@mui/material";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import TabPill from "components/common/Tabs/TabPill";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLabsResults } from "redux/features/Patients/ViewLabResultsSlice";
import LabResultDetailCard from "./LabResultDetailCard";

const LabResultDetail = ({ patientName = "", patientID = null, setSelectedPatientID }) => {
  const dispatch = useDispatch();
  const { labResults, isLabResultsLoading } = useSelector(state => state.resultsLabs);

  useEffect(() => {
    return () => {
      setSelectedPatientID && setSelectedPatientID(null)
    }
  }, [])

  useEffect(() => {
    patientID && !labResults?.length && dispatch(getLabsResults(patientID))
  }, [dispatch, patientID])

  const createLabsData = labResults?.map((d, i) => {
    return {
      title: i === 0 ? `Last Lab Results - ${moment(d?.date_time).format("MM/DD/YYYY")}` : `Lab Results - ${moment(d?.date_time).format("MM/DD/YYYY")}`,
      tab: <LabResultDetailCard name={patientName} data={d ?? []} active={i === 0 ? true : false} />,
      value: i,
    }
  })

  return (
    <Box>
      {
        <>
          {
            isLabResultsLoading ?
              <SkeletonLoader />
              :
              createLabsData?.length ?
                <TabPill
                  childrenArray={createLabsData}
                  defaultValue={0}
                  backgroundColor="#ffffff"
                  tabMinWidth="250px"
                  indicatorBackgroundColor={false ? "#1344F1" : "#FC5A5A"}
                  tabSelectedColor={false ? "#1344F1" : "#FC5A5A"}
                  paddingBtn="0px"
                />
                :
                <Box
                  sx={{
                    textAlign: "center",
                    marginY: "25px"
                  }}
                >
                  <Text id="lab-no-data" variant="h5" >
                    No Data Found.
                  </Text>
                </Box>
          }
        </>

      }

    </Box>
  );
};

export default LabResultDetail;

const SkeletonLoader = () => {
  return <>
    <Box data-testid="lab-loading" sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
      <Box sx={{ width: "100%" }} marginX={1} marginY={1}>
        <Grid container spacing={1} >
          <Grid item lg={4} md={4} xs={4} sm={4} >
            <Card elevation={0} sx={{ width: ' 100%', border: "1px solid #e2e2e4", background: '#fafafb' }}>
              <CardHeader
                subheader={
                  <>
                    <Skeleton sx={{ marginBottom: "5px" }} animation="wave" height={50} width="100%" />
                    <Skeleton sx={{ marginBottom: "5px" }} animation="wave" height={50} width="100%" />
                    <Skeleton sx={{ marginBottom: "5px" }} animation="wave" height={50} width="100%" />
                    <Skeleton sx={{ marginBottom: "5px" }} animation="wave" height={50} width="100%" />
                    <Skeleton sx={{ marginBottom: "5px" }} animation="wave" height={50} width="100%" />
                  </>
                }
              />
            </Card>
          </Grid>
          <Grid item md={8} xs={8} sm={8} lg={8} spacing={1} >
            {[1, 2, 3].map((v, memberIndex) => (
              <Card elevation={0} sx={{ width: ' 100%', border: "1px solid #e2e2e4", background: '#fafafb', marginBottom: "5px" }}>
                <CardHeader
                  subheader={
                    <>
                      <Skeleton sx={{ marginBottom: "5px" }} animation="wave" height={15} width="75%" />
                      <Skeleton sx={{ marginBottom: "5px" }} animation="wave" height={15} width="50%" />
                      <Skeleton sx={{ marginBottom: "5px" }} animation="wave" height={15} width="25%" />
                    </>
                  }
                />
              </Card>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  </>
}

import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { Box, MenuList, Select } from "@mui/material";
import { styled } from "@mui/system";

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "14px",
  "&.Mui-selected": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    color: "#1b1b1b",
    fontSize: "14px",
  },
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    color: "#1b1b1b",
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: "#246EFD",
    color: "#ffffff",
    width: "120px",
    height: "30px !important",
    alignContent: "center",
    paddingTop: "0px",
    paddingLeft: "10px",
    paddingBottom: "0px !important",
    borderRadius: "10px",
    fontSize: "14px",
  },
  "& .MuiSvgIcon-root": {
    color: "#ffffff",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#246EFD !important",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#246EFD !important",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#246EFD !important",
  },
}));

export default function TreatmentStatusMenus({ setFilterFlag }) {
  const [value, setValue] = React.useState("all patients");

  const handleChange = (event) => {
    setFilterFlag(event.target.value);
    setValue(event.target.value);
  };
  const statusOptions = [
    { value: "urgent", label: "Urgent" },
    { value: "important", label: "Important" },
    { value: "normal", label: "Normal" },
    { value: "all patients", label: "All Patients" },
  ];
  return (
    <CustomSelect
      value={value}
      onChange={handleChange}
      inputProps={{ "aria-label": "Without label" }}
    >
      <CustomMenuItem value={"all patients"}>All Patients</CustomMenuItem>
      <CustomMenuItem value={"urgent"}>Urgent</CustomMenuItem>
      <CustomMenuItem value={"important"}>Important</CustomMenuItem>
      <CustomMenuItem value={"normal"}>Normal</CustomMenuItem>
    </CustomSelect>
  );
}

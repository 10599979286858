import { createSlice } from "@reduxjs/toolkit";

export const initialLabsState = {
  labsSuccess: null,
  labsData: [],
  labsIsLoading: false,
  updateLabsLoading: false,
  updateLabsSuccess: null,
  labsByEncounterLoading: false,
  labsByEncounterData: [],
  labsByEncounterSuccess: null,
  perLabLoading: false,
  perLabData: [],
  perLabSuccess: null,
};

const name = "labs";

export const labsSlice = createSlice({
  name,
  initialState: initialLabsState,
  reducers: {
    getLabs: (state, action) => {
      state.labsIsLoading = true;
    },
    updateLabs: (state, action) => {
      state.updateLabsLoading = true;
      state.updateLabsSuccess = false;
    },
    getLabByEncounter: (state, action) => {
      state.labsByEncounterLoading = true;
    },
    getPerLab: (state, action) => {
      state.perLabLoading = true;
    },
    getLabsSuccess: (state, action) => {
      state.labsSuccess = true;
      state.labsIsLoading = false;
      state.labsData = action.payload.results?.length
        ? action.payload.results.map((s) => ({
            label: s?.test_name,
            value: s?.id,
            instruction: s?.instructions,
          }))
        : state.labsData;
    },
    updateLabsSuccess: (state, action) => {
      state.updateLabsLoading = false;
      state.updateLabsSuccess = true;
    },
    getLabByEncounterSuccess: (state, action) => {
      state.labsByEncounterLoading = false;
      state.labsByEncounterSuccess = true;
      state.labsByEncounterData = action.payload.results;
    },
    getPerLabSuccess: (state, action) => {
      state.perLabLoading = false;
      state.perLabData = action.payload;
      state.perLabSuccess = true;
    },
    getLabsFail: (state, action) => {
      state.labsSuccess = false;
      state.labsData = [];
      state.labsIsLoading = false;
    },
    updateLabsFail: (state, action) => {
      state.updateLabsLoading = false;
      state.updateLabsSuccess = false;
    },
    getLabByEncounterFail: (state, action) => {
      state.labsByEncounterLoading = false;
      state.labsByEncounterSuccess = false;
      state.labsByEncounterData = [];
    },
    getPerLabFail: (state, action) => {
      state.perLabLoading = false;
      state.perLabData = [];
      state.perLabSuccess = false;
    },
    clearLabsData: (state, action) => {
      state.labsSuccess = null;
      state.labsData = [];
      state.labsIsLoading = false;
      state.updateLabsLoading = false;
      state.updateLabsSuccess = null;
      state.labsByEncounterLoading = false;
      state.labsByEncounterData = [];
      state.labsByEncounterSuccess = null;
    },
    clearPerLabsData: (state, action) => {
      state.perLabLoading = false;
      state.perLabData = [];
      state.perLabSuccess = null;
    },
  },
});

export const { getlabs, clearLabsData, clearPerLabsData } = labsSlice.actions;

export default labsSlice.reducer;

export const getLabsList = (searchQuery) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/tests/search/?q=${searchQuery}`,
  },
  type: "getLabs",
});

export const updateLabsByEncounter = (data, method, id) => ({
  payload: {
    apiName: name,
    data,
    method: method,
    formData: false,
    url: `/api/laborders/${
      method === "PATCH" || method === "DELETE" ? `${id}/` : ""
    }`,
  },
  type: "updateLabs",
});

export const getLabsByEncounter = (id) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/laborders/search/?encounter=${id}`,
  },
  type: "getLabByEncounter",
});

export const getPerLab = (id) => ({
  payload: {
    apiName: name,
    method: "get",
    url: `/api/laborders/${id}/`,
  },
  type: "getPerLab",
});

import { Box, useTheme } from "@mui/material";
import Button from "components/common/Button/Button";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { useNavigate } from "react-router-dom";
import { flexColumn, flexRowCenterCenter, justifyContentCenter } from "styles";
import {
  cardButtonStyling,
  cardDateTimeStatusStyling,
  cardNameDiseaseImageBoxStyling,
  cardPatientStatus,
  cardReasonWrapperStyling,
  cardResponsiveStyling,
  cardVisitReasonBoxStyling,
} from "styles/Dashboard/SchedulePatientsStyle";
import circleleftarrow from "../../../assets/svg/colored/circleleftarrow.svg";
import correct from "../../../assets/svg/colored/correct.svg";
import correctBox from "../../../assets/svg/colored/correctBox.svg";
import wrong from "../../../assets/svg/colored/wrong.svg";
import CardAlert from "./CardAlert";
import { helper } from "utils/helper";

const Cards = ({
  healthStatus,
  id,
  time,
  status,
  img,
  latest_lab_date,
  latestVitals,
  name,
  disease,
  description,
  lastVisitDate,
  isActive,
  patientId,
  isReviewLater,
  appointmentId,
}) => {
  const navigate = useNavigate();
  let theme = useTheme();
  const [statusImg, setStatusImg] = useState("");

  const [fontSize, setFontSize] = useState(16); // Default font size

  useEffect(() => {
    const handleResize = () => {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      const newFontSize = vw / 20;
      setFontSize(newFontSize);
    };

    // Initial font size calculation
    handleResize();

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // handling checkin-status images
  useEffect(() => {
    if (status == "Checked Out") {
      setStatusImg(circleleftarrow);
    } else if (status == "Cancelled") {
      setStatusImg(wrong);
    } else if (status == "Scheduled") {
      setStatusImg(correctBox);
    } else {
      setStatusImg(correct);
    }
  }, []);

  return (
    <Box
      id={id}
      className="main-card-body"
      sx={{
        ...cardResponsiveStyling(status, theme, isActive),
      }}
    >
      <Box
        sx={{
          flex: 1,
          [`@media  (min-height:500px) and (max-height:630px)`]: {
            flex: "unset",
          },
        }}
      >
        <Box sx={cardDateTimeStatusStyling}>
          <span
            classes={"card-time"}
            data-testid="card-time"
            style={{
              fontWeight: 600,
              fontSize: "15px",
              color: "#2D3748",
            }}
          >
            {moment(time).format("h:mm A")}
          </span>
          <CardAlert
            healthStatus={healthStatus}
            latest_lab_date={latest_lab_date}
            latestVitals={latestVitals}
            disease={disease}
            name={name}
            img={img}
          />
          <Box sx={{ ...flexRowCenterCenter }}>
            <img
              src={statusImg}
              data-testid="checkedin-status-img"
              className="status-image"
              alt=""
            />
            <span
              classes={"checkin-status"}
              data-testid="checkedin-status"
              style={{
                marginLeft: "5px",
                fontWeight: 400,
                fontSize: "15px",
                color: "#2D3748",
              }}
            >
              {status}
            </span>
          </Box>
        </Box>
        <Box
          sx={cardNameDiseaseImageBoxStyling}
          className="patient-profile-box"
        >
          <Box style={{ ...flexColumn, ...justifyContentCenter }}>
            <span
              data-testid={"card-name"}
              style={{
                fontWeight: 700,
                fontSize: "22px",
                lineHeight: 1.438,
                color: `${isActive ? "#315FFF" : "#2D3748"}`,
              }}
            >
              {helper().checkNullOrUndefinedString(name)}
            </span>
            <span
              classes={"card-disease"}
              data-testid={"card-disease"}
              style={{
                fontWeight: 600,
                fontSize: "18px",
                color: "#2D3748",
                margin: "5px 0px 0px 0px",
              }}
            >
              {disease}
            </span>
          </Box>
          <LazyLoadImage
            src={img}
            className="profile-pic"
            effect="opacity"
            data-testid="patient-img"
            style={{
              borderRadius: "10px",
            }}
          />
        </Box>
        <Box sx={cardReasonWrapperStyling}>
          <Box sx={{ flex: "2" }}>
            <Text classes={"reason-heading"} variant="span">
              <i>Reason for the visit:</i>
            </Text>
            <Box className="visit-reason-box" sx={cardVisitReasonBoxStyling}>
              <Box
                data-testid="card-reason-for-visit"
                classes={"visit-reason"}
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "#2D3748",
                  letterSpacing: "0.5px",
                }}
              >
                {description}
              </Box>
            </Box>
          </Box>
          <Box sx={{ height: { lg: "60px", md: "40px" } }}>
            <Text classes={"visit-heading"} variant="span" padding="none">
              <i>Last Visit:</i>
            </Text>
            <Box
              classes={"visit-date"}
              data-testid="card-date-visit"
              style={{
                fontWeight: 600,
                fontSize: "14px",
                color: "#2D3748",
              }}
            >
              {moment(lastVisitDate).format("MM/DD/YYYY")}
            </Box>
          </Box>
        </Box>
        <Box className="button-box" sx={{ height: "10%" }}>
          <Button
            id="start-appointment"
            backgroundColor={
              isActive
                ? theme?.palette?.themePrimary?.tertiary
                : theme?.palette?.common?.black
            }
            btnColor={theme?.palette?.common?.white}
            sx={cardButtonStyling(theme, isActive)}
            onClick={() => {
              localStorage.setItem("appointment_img", img);
              localStorage.setItem(
                "appointment_time",
                moment(time).format("h:mm A")
              );
              navigate(
                isReviewLater &&
                  status?.toLowerCase() !== "Checked Out"?.toLowerCase()
                  ? `/schedule/patient/${patientId}/appointment/${appointmentId}?review_later=${true}`
                  : `/schedule/patient/${patientId}/appointment/${appointmentId}`
              );
            }}
            text={`${
              isReviewLater &&
              status?.toLowerCase() !== "Checked Out"?.toLowerCase()
                ? "Review Notes"
                : isActive &&
                    status !== "Checked Out" &&
                    status !== "checked out"
                  ? "Start Appointment"
                  : "View Patient Profile"
            }`}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default memo(Cards);

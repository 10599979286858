import { Box, Grid, IconButton, useTheme } from "@mui/material";
import {
  getFinancialsSuggestionsAns,
  getFinancialsSuggestionsData,
} from "assets/static";
import search from "assets/svg/light/search.svg";
import InputField from "components/common/FormComponents/InputField";
import Text from "components/common/Typography/Text";
import { useFormik } from "formik";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSayaData, getSayaResult } from "redux/features/Saya/SayaSlice";
import { toggleChatStates } from "redux/features/globalStateSlice";
import {
  closeButtonStyle,
  headerStyle,
  sendBtnStyle,
  startImageStyle,
} from "styles/Financials/Financials";
import starImage from "../../../../assets/svg/colored/ai_sparkle.svg";
import closeImage from "../../../../assets/svg/colored/close.svg";
import sendButton from "../../../../assets/svg/colored/sendButton.svg";
import sayaPatientBackground from "../../../../assets/svg/light/saya_patient-background.svg";
import MessagesComponent from "../MessagesComponent/MessagesComponent";
import {
  chatBotConversation,
  chatBotTitle,
  chatBottomStyling,
} from "./ChatBotStyles";

const ChatBotComponent = ({ isChatOpen = false, forSearch = false }) => {
  const dispatch = useDispatch();

  const { getSayaIsLoading, getSayaSuccess, getSayaData } = useSelector(
    (state) => state.saya
  );
  let theme = useTheme();

  const [userMessages, setUserMessages] = useState([]);

  const formik = useFormik({
    initialValues: {
      user: "",
    },
    onSubmit: (value) => {
      if (value?.user?.length > 0 && getSayaIsLoading === false) {
        dispatch(clearSayaData());
        setUserMessages((oldArray) => [...oldArray, value?.user]);
        formik.resetForm();
        dispatch(getSayaResult("813", { request: value?.user }));
      }
    },
  });

  function addSayaMessage() {
    setUserMessages((oldArray) => [...oldArray, getSayaData?.["generic"]]);
  }

  useEffect(() => {
    if (getSayaSuccess) addSayaMessage();

    return () => dispatch(clearSayaData());
  }, [getSayaSuccess]);

  document.addEventListener("keydown", function (event) {
    // Check if the pressed key is Enter (key code 13)
    if (event.keyCode === 13) {
      formik.handleSubmit();
    }
  });

  const handleSuggestionClick = (query) => {
    //run the local search
    const runLocalQuery = getFinancialsSuggestionsAns(
      window.location.pathname,
      query
    );
    if (runLocalQuery) {
      setUserMessages((oldArray) => [...oldArray, runLocalQuery?.que]);
      setUserMessages((oldArray) => [...oldArray, runLocalQuery?.ans]);
    } else {
      formik.setFieldValue("user", query);
      formik.handleSubmit();
    }
  };

  const suggestionsStyle = {
    cursor: "pointer",
    marginBottom: "5px",
    borderRadius: "10px",
    border: "1px solid #246EFD",
    padding: forSearch ? "4px 9px 4px 9px" : "6px 12px 6px 12px",
  };
  return (
    <Grid
      item
      lg={isChatOpen ? 3 : 12}
      xl={isChatOpen ? 3 : 12}
      xxl={isChatOpen ? 3 : 12}
      md={isChatOpen ? 4 : 12}
      sm={12}
      xs={12}
    >
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <Box sx={chatBotTitle(forSearch)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={headerStyle}>
              <img src={starImage} style={startImageStyle} alt="startImage" />
              <span>
                <Text variant="h3">Saya</Text>
              </span>
            </Box>

            {forSearch ? (
              ""
            ) : (
              <Box>
                <IconButton sx={{ padding: "3px" }}>
                  <img
                    src={closeImage}
                    onClick={() => {
                      dispatch(toggleChatStates());
                    }}
                    style={closeButtonStyle}
                    alt="closeImage"
                  />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={chatBotConversation(theme, forSearch)}>
          <Box
            sx={{
              overflowY: "auto",
              height: "100%",
              backgroundColor: "#FFFFF",
              padding: "10px",
              width: "100%",
              maxWidth: "100%",
              flex: 1,
            }}
          >
            {!userMessages?.length && (
              <>
                <Box sx={{ display: "flex", marginBottom: "20px" }}>
                  <img
                    src={sayaPatientBackground}
                    style={{ marginRight: "10px" }}
                    alt=""
                  />
                  <Text variant="h4">
                    Ask me about the patient’s background, lab results,
                    treatment options, and more.
                  </Text>
                </Box>
                {getFinancialsSuggestionsData(window.location.pathname)?.map(
                  ({ que }, index) => (
                    <Box
                      sx={suggestionsStyle}
                      onClick={() => {
                        handleSuggestionClick(que);
                      }}
                    >
                      <Text variant="bodyXs" color={"blue"}>
                        {que}
                      </Text>
                    </Box>
                  )
                )}
              </>
            )}
            {userMessages.map((s, i) => (
              <MessagesComponent
                incoming={i % 2 === 0 ? true : false}
                message={s}
              />
            ))}
            {getSayaIsLoading ? (
              <MessagesComponent
                incoming={false}
                showLoader={true}
                forSearch={forSearch}
              />
            ) : null}
          </Box>
        </Box>
        <Box sx={chatBottomStyling(forSearch)}>
          <Grid container>
            <InputField
              borderRadius="12px"
              lg={10}
              md={10}
              sm={10}
              xs={10}
              positionLeft={true}
              type={"text"}
              iconDetails={{
                allowIcon: true,
                iconName: search,
              }}
              formik={formik}
              name="user"
            />
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <img
                  style={sendBtnStyle}
                  src={sendButton}
                  onClick={() => formik.handleSubmit()}
                  alt="sendButton"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default memo(ChatBotComponent);

import { Box, useTheme } from "@mui/material";
import { useFormik } from "formik";
import * as toast from 'hooks/notify';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSendFeedbackData, sendFeedbackToEmail } from "redux/features/Feedback/FeedbackSlice";
import { FeedbackValidationSchema } from "validations/Modals/feedback.schema";
import Button from '../Button/Button';
import InputField from '../FormComponents/InputField';
import Text from "../Typography/Text";
import CustomModal from './CustomModal';

const FeedBackModal = ({ open, setOpen }) => {
    const theme = useTheme();
    const dispatch = useDispatch()

    const { isFeedbackSending, feedbackSuccess } = useSelector(state => state.feedback);

    const formik = useFormik({
        initialValues: {
            subject: "",
            message: "",
        },
        validationSchema: FeedbackValidationSchema,
        onSubmit: (values) => {
            values.to_email = "umer@nephrolytics.ai"
            dispatch(sendFeedbackToEmail({
                ...values, recipient_type: 'practice_admin'
            }))
        },
    })
    useEffect(() => {
        return () => {
            dispatch(clearSendFeedbackData())
        }
    }, [])

    useEffect(() => {
        setOpen(false)
        feedbackSuccess && toast.success("Feedback sent successfully")
        feedbackSuccess === false && toast.error("Message not sent")
        dispatch(clearSendFeedbackData())
        formik.resetForm()
    }, [feedbackSuccess])

    useEffect(() => {
        if (open === false) {
            formik.resetForm()
        }
    }, [open])


    return (
        <CustomModal
            setOpen={setOpen}
            open={open}
            title={"Feedback"}
            width="auto"
            maxWidth={"sm"}
            fullWidth={true}
        >
            <Text variant="h3" color={"black"} marginY={2}>
                <span data-testid="feedback-modal-heading">
                    Help us improve Nephrolytics
                </span>
            </Text>
            <InputField
                label="Subject"
                formik={formik}
                name="subject"
                placeholder={"Subject goes here...."}
            />
            <InputField
                label="Message"
                formik={formik}
                name="message"
                multiline={true}
                rows={6}
                placeholder={"Please type your message here..."}
            />
            <Box sx={{ display: "flex", padding: "25px 0px 0px 10px", justifyContent: "flex-end" }}>
                <Button text="Send" isLoading={isFeedbackSending} data-testid="feedback-modal-send-button" onClick={formik.handleSubmit} sx={{ width: "150px", color: theme.palette.button }} />
            </Box>
        </CustomModal>
    )
}

export default FeedBackModal